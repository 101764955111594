import React from "react";
import { CreateinitialAPISettings, APISettings } from '../model/apisettings'



var intusers: APISettings[] = new Array<APISettings>()

export const LoadMagentoCOnnectorreducers = (state: APISettings[] =intusers, action: any) => {
    switch (action.type) {
        case "MAGENTO_CONNECTOR_COMPLETED":
            return handleFetchCompleted(state,action.payload)
        case "MAGENTO_CONNECTOR_FAILED":
            return state
    }
    return state;
}

const handleFetchCompleted = (state: APISettings[], payload: APISettings[]) => payload;