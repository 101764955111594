import React, { useState, useEffect } from "react";
import InfoIcon from "@material-ui/icons/Info";
import Popover from "@material-ui/core/Popover";
import { products } from "../../dataservices/model/mpproducts";

const ReadOnlyRow = ({ item, allProducts, handleEditClick, handleDeleteClick, ebtn, }) => {

    const [anchorEl, setAnchorEl] = useState(null)

    const [currentEntity, setCurrentEntity] = useState(allProducts);
    const [isAllNull, setIsAllNull] = useState(false);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleInfoClick = (event: any, data: any) => {
        setAnchorEl(event.currentTarget);
        setCurrentEntity(data);
        const arePropertiesNull = !data.pgroup && !data.productgrp && !data.pmax && !data.pmin && !data.pmfg;
        setIsAllNull(arePropertiesNull);
        console.log("currentEntity....", data)

    }
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
        <tr >
            <td className="col-td-011">
                {item.id}
            </td>
            <td className="col-td-022">
                {item.sku}
            </td>
            <td className="col-td-033">
                {item.uom}
            </td>
            <td className="col-td-066">
                {item.asin}
            </td>
            <td className="col-td-077">
                {item.touom}
            </td>
            <td className="col-td-044">
                {item.displayDescription}
            </td>
            <td className="col-td-055">
                {item.msku}
            </td>

            <td className="col-td-077">
                {item.brand}
            </td>
            {/*<td className="col-td-077">*/}
            {/*    {item.pgroup}*/}
            {/*</td>*/}
            {/*<td className="col-td-077">*/}
            {/*    {item.productgrp}*/}
            {/*</td>*/}
            {/*<td className="col-td-077">*/}
            {/*    {item.pmax}*/}
            {/*</td>*/}
            {/*<td className="col-td-077">*/}
            {/*    {item.pmin}*/}
            {/*</td>*/}
            {/*<td className="col-td-077">*/}
            {/*    {item.pmfg}*/}
            {/*</td>*/}
            <td className="col-td-10">
                <div
                    onClick={(e) => handleInfoClick(e, item)}
                    className="attribute_icon"
                >
                    <InfoIcon className="Icon_color_global" />
                </div>
            </td>
            <td>
                <button
                    type="button"
                    className="btn btn-sm btn-primary" disabled={ebtn != true ? true : false}
                    onClick={(event) => handleEditClick(event, item)}
                >
                    <i className="bi bi-pencil-square"></i>
                </button>
                {/*<button type="button" onClick={() => handleDeleteClick(item.id)}>*/}
                {/*    Delete*/}
                {/*</button>*/}
            </td>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {isAllNull != true ? (
                    <div className="order_attribute_container">
                        <div>

                            {currentEntity != null && currentEntity.pgroup != null && (
                                <div>
                                    <span className="attribute_label">Product Group Major:</span>
                                    <span className="attribute_Item">{currentEntity.pgroup}</span>
                                </div>

                            )
                            }
                            {currentEntity != null && currentEntity.productgrp != null && (
                                <div>
                                    <span className="attribute_label">Product Group Minor:</span>
                                    <span className="attribute_Item">{currentEntity.productgrp}</span>
                                </div>

                            )
                            }
                            {currentEntity != null && currentEntity.pmax != null && (
                                <div>
                                    <span className="attribute_label">Price Group Major:</span>
                                    <span className="attribute_Item">{currentEntity.pmax}</span>
                                </div>

                            )}


                            {currentEntity != null && currentEntity.pmin != null && (
                                <div>
                                    <span className="attribute_label">Price Group Minor:</span>
                                    <span className="attribute_Item">{currentEntity.pmin}</span>
                                </div>

                            )
                            }
                            {currentEntity != null && currentEntity.pmfg != null && (
                                <div>
                                    <span className="attribute_label">Supplier:</span>
                                    <span className="attribute_Item">{currentEntity.pmfg}</span>
                                </div>

                            )

                            }
                        </div>
                    </div>
                ) : (
                    <div className="order_attribute_container">
                        <div>
                            <span className="attribute_Item">No Records Found</span>
                        </div>
                    </div>

                )}


            </Popover>


        </tr>


    );
};

export default ReadOnlyRow;
