import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { SSL_OP_COOKIE_EXCHANGE } from "constants";
import SideBar from "../sidebar";
import { useHistory } from "react-router";
import { CreateinitialLoadermessage, Loadermessage } from "../../dataservices/model/loadermessage";
import { channellist } from "../../dataservices/model/channellist";
import { GetChannellistService } from "../../dataservices/action/dashboard/channellist";
import { entities, jobDetails, createinitialjobDetails } from "../../dataservices/model/jobDetails";
import { inventoryproducts } from "../../dataservices/model/inventoryproduct";
import { DeleteEntity,DeactivateRules, GetAllCustomerGroup, GetAllMarketplacebyAccountId, GetAllMarketplaces, GetCategoryMPProducts, GetFilterDatabyModuleIdandFilterId, GetFilters, GetFiltersjson, GetInventoryentitylist, GetInventoryLoadbyFilter, GetMPEntityDetailsByFIlter, GetMPEntityDetailsById, GetmpLocationsANDagilityBranch, Getrolepermissionbyuserid, GetSuppliers, GetValuesFromMpProducts, SaveEntityDetailsById } from "../../dataservices/action/marketplace/marketplace";
import { PaginationValues } from "../pagenation/pageflow";
import { confirmAlert } from "react-confirm-alert";
import { debug } from "console";
import Loader from "../loader";
import { moduleinfo } from "../../dataservices/model/user";
import { GetEditPermission } from "../../dataservices/action/account/register";
import { filterentities, products, createinitialProducts } from "../../dataservices/model/mpproducts";
import moment from "moment";
import AFailed from "../alretbox/failed";
import ASuccess from "../alretbox/success";
import { filters, filtersobjs, storeFilter } from "../../dataservices/model/filters";
import axios from "axios";
import InfoIcon from "@material-ui/icons/Info";
import Popover from "@material-ui/core/Popover";
import Multiselect from "multiselect-react-dropdown";
import Checkbox from "@material-ui/core/Checkbox";
const label = { inputProps: { "aria-label": "Checkbox demo" } };




const inventoryRules = () => {
    const history = useHistory();
    const inputFile: any = useRef();
    const scrollRef = useRef<HTMLDivElement>(null);
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    var intcgrouplist: channellist[] = new Array<channellist>();
    const [cglist, setcglist] = useState(intcgrouplist);

    const store = useSelector((state: any) => { return state })
    const dispatch = useDispatch();
    var intchannelsiteslist: channellist[] = new Array<channellist>();
    const [channellist, Setchannellist] = useState(intchannelsiteslist);
    const [channelId, setChannelId] = useState(0);
    const [iscategoryorothers, setiscategoryorothers] = useState(1);
    const [activecatclass, setactivecatclass] = useState(true);
    const [activeprdclass, setactiveprdclass] = useState(false);
    const [activeitemclass, setactiveitemclass] = useState(false);
    const [entityid, setentityid] = useState("");
    const [entityname, setentityname] = useState("");
    //Search List
    var intlist: products[] = new Array<products>();
    const [entitylist, Setentitylist] = useState(intlist);

    const [isChecked, setischecked] = useState(false);
    const [minimumqty, setminimumqty] = useState("");
    const [fixedinv, setfixedinv] = useState("");
    var intentylist: jobDetails[] = new Array<jobDetails>();
    const [entylist, Setentylist] = useState(intentylist);

    const [invalue, setinvalue] = useState("");
    const [chkValue, setchkValue] = useState(false);
    const [invtypeValue, setinvtypeValue] = useState(0);
    const [isvalue, setisvalue] = useState("");
    const [ispervalue, setispervalue] = useState(false);
    const [isfixvalue, setisfixvalue] = useState(false);
    var activeclassname = "nav-link active";
    var inactiveclassname = "nav-link";
    var etylist: string[] = new Array<string>();
    const [enlist, setenlist] = useState(etylist);
    const [IsallMP, setIsallMP] = useState(false);
    const [IsMarkdown, setIsMarkdown] = useState(false);
    const etylist1: string[] = [];
    const [activePage, setActivePage] = useState(0);
    const [TotalCount, setTotalCount] = useState(0);
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [PageSizeList, setPageSizeList] = useState([50, 100, 200]);
    const [SearchValue, setSearchValue] = useState("");
    const [tablescrollsection, settablescrollsection] = useState(false);
    var intcatlist: jobDetails[] = new Array<jobDetails>();
    const [catList, setcatList] = useState(intcatlist);
    const [categoryId, setcategoryID] = useState(0);
    const [supplierId, setsupplierId] = useState("");

    const [isLoader, setisLoader] = useState(false);
    const [activePage1, setActivePage1] = useState(0);
    const [TotalCount1, setTotalCount1] = useState(0);
    const [Page1, setPage1] = useState(1);
    const [PageSize1, setPageSize1] = useState(50);
    const [PageSizeList1, setPageSizeList1] = useState([50, 100, 200]);
    var vperList: moduleinfo[] = new Array<moduleinfo>();
    const [VPList, setVPList] = useState(vperList);
    const [ECategoryAttributes, setVCategoryAttributes] = useState(false);
    const [EAPIsettings, setVAPIsettings] = useState(false);
    const [EMagentoSync, setVMagentoSync] = useState(false);
    const [EInriverSync, setVInriverSync] = useState(false);
    const [EProducts, setVProducts] = useState(false);
    const [EInventoryRules, setVInventoryRules] = useState(false);
    const [Elogs, setVlogs] = useState(false);
    const [ESettings, setVSettings] = useState(false);
    const [isDisabled, setisDisabled] = useState("disabled");

    var mpentityList: entities[] = new Array<entities>();
    const [mpList, setmpList] = useState(mpentityList);

    var intMPAcclist: channellist[] = new Array<channellist>();
    const [mpacclist, setmpacclist] = useState(intMPAcclist);

    const [stateval, setStateval] = useState("");
    const [isaccChecked, setisaccchecked] = useState(false);
    const [IsaccOrLocation, setIsaccOrLocation] = useState(false);

    const [isAllChecked, setisAllChecked] = useState(false);
    const [isdisabled, setisdisabled] = useState(false);
    const [isfdisabled, setisfdisabled] = useState(false);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date("2049-12-31T23:59:59"));
    const [searchDate, setsearchDate] = useState("");
    const [searchEndDate, setsearchEndDate] = useState("");
    const [utcStartDate, setutcStartDate] = useState("");
    const [utcEndDate, setutcEndDate] = useState(moment.utc(moment("2049-12-31T23:59:59").utc()).format());
    const [isDate, setisDate] = useState(false);
    var intsupplist: jobDetails[] = new Array<jobDetails>();
    const [suppList, setsuppList] = useState(intsupplist);

    var intPrdgrplist: channellist[] = new Array<channellist>();
    const [Prdgrplist, setPrdgrplist] = useState(intPrdgrplist);

    var intPricemaxlist: channellist[] = new Array<channellist>();
    const [Pricemaxlist, setPricemaxlist] = useState(intPricemaxlist);

    var intPriceminlist: channellist[] = new Array<channellist>();
    const [Priceminlist, setPriceminlist] = useState(intPriceminlist);


    var intmfglist: channellist[] = new Array<channellist>();
    const [mfglist, setmfglist] = useState(intmfglist);

    const [prdgrp, setprdgrp] = useState("");
    const [pricemax, setpricemax] = useState("");
    const [pricemin, setpricemin] = useState("");
    const [mfg, setmfg] = useState("");

    const [prdgrpname, setprdgrpname] = useState("");
    const [pricemaxname, setpricemaxname] = useState("");
    const [priceminname, setpriceminname] = useState("");
    const [mfgname, setmfgname] = useState("");
    const [fsku, setfsku] = useState("");
    const [tsku, settsku] = useState("");
    const [efsku, setefsku] = useState("");
    const [etsku, setetsku] = useState("");
    var intfilterlist: filters[] = new Array<filters>();
    const [filter, setfilterlist] = useState(intfilterlist);

    var intfilterobj: filtersobjs[] = new Array<filtersobjs>();
    const [filterobj, setfilterobj] = useState(intfilterobj);
    var mpfilter: filterentities[] = new Array<filterentities>();
    const [mpfilters, setmpfilters] = useState(mpfilter);

    var intfilterlist1: filters[] = new Array<filters>();
    const [filter1, setfilterlist1] = useState(intfilterlist1);

    var intfilterobj1: filtersobjs[] = new Array<filtersobjs>();
    const [filterobj1, setfilterobj1] = useState(intfilterobj1);
    var mpfilter1: filterentities[] = new Array<filterentities>();
    const [mpfilters1, setmpfilters1] = useState(mpfilter1);
    const [cgid, setcgid] = useState(0);
    const [isApplyfilter, setisApplyfilter] = useState(false);
    const [filename, setFilename] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [checkAll, setCheckAll] = useState(false);
    var intentyproduct: string[] = new Array<string>();
    const [selectedProduct, setSelectedProduct] = useState(intentyproduct)

    const changeFilterSku = (e: any) => {

        setfsku(e.target.value);
    }
    const changeFilterTSku = (e: any) => {
        settsku(e.target.value);

    }


    const changeEFilterSku = (e: any) => {
        setefsku(e.target.value);

    }


    const changeEFilterTSku = (e: any) => {
        setetsku(e.target.value);

    }
    useEffect(() => {
        // Scroll to the top when the component mounts or updates
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
    },[Page]);

    //useEffect(() => {
    //    setisLoader(true);
    //    GetValuesFromMpProducts(1, function (result: any) {
    //        setPrdgrplist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    //useEffect(() => {
    //    setisLoader(true);
    //    GetValuesFromMpProducts(2, function (result: any) {
    //        setPricemaxlist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    //useEffect(() => {
    //    setisLoader(true);
    //    GetValuesFromMpProducts(3, function (result: any) {
    //        setPriceminlist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    //useEffect(() => {
    //    setisLoader(true);
    //    GetValuesFromMpProducts(4, function (result: any) {
    //        setmfglist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    const [AINVRules, setAINVRules] = useState(false);
    const [EINVRules, setEINVRules] = useState(false);
    const [DINVRules, setDINVRules] = useState(false);
    //const [CSIMsync, setCSIMsync] = useState(false);
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 9:
                                if (result[i].canadd == "1") {
                                    setAINVRules(true);
                                }
                                if (result[i].canedit == "1") {
                                    setEINVRules(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDINVRules(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])

    //useEffect(() => {
    //    setisLoader(true);
    //    GetChannellistService(function (result: any) {
    //        Setchannellist(result);
    //        setisLoader(false);

    //    })
    //}, [])

    //useEffect(() => {
    //    setisLoader(true);
    //    GetMarketplaceAccountNumbers(function (result: any) {
    //        setmpacclist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    //useEffect(() => {
    //    setisLoader(true);
    //    GetAllMarketplaces(function (result: any) {
    //        setmpacclist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    //useEffect(() => {
    //    setisLoader(true);
    //    GetCategoryMPProducts(function (result: any) {
    //        setcatList(result);
    //        setisLoader(false);
    //    })
    //}, [])

    useEffect(() => {
        //setisLoader(true);
        GetAllCustomerGroup(function (result: any) {
            setcglist(result);
            //setisLoader(false);

        })
    }, [])


    //useEffect(() => {
    //    setisLoader(true);
    //    //supplierList
    //    GetSuppliers(function (result: any) {
    //        setsuppList(result);
    //        setisLoader(false);
    //    })
    //}, [])
    useEffect(() => {
        setisLoader(true);
        GetFilters(3, function (result: any) {
            setfilterlist1(result);
            setisLoader(true);
            GetFiltersjson(3, function (result: any) {
                var ifilter = [...result];

                if (ifilter.filter(x => x.id == 17).length == 0) {
                    var obj = ifilter.filter
                    let filobj: filtersobjs = {
                        id: 17,
                        code: "Account Number",
                        attributename: "MpProducts",
                        modelname: "",
                        filtertype: "Dropdown",
                        value1: "",
                        value2: ""

                    }
                    ifilter.push(filobj)
                }
                if (ifilter.filter(x => x.id == 8).length == 0) {
                    var obj = ifilter.filter
                    let filobj: filtersobjs = {
                        id: 8,
                        code: "Branch",
                        attributename: "MpProducts",
                        modelname: "",
                        filtertype: "Dropdown",
                        value1: "",
                        value2: ""

                    }
                    ifilter.push(filobj)
                }
                setfilterobj1(ifilter);
                console.log("res....", result)
                setisLoader(true);
                GetMPEntityDetailsByFIlter(ifilter, Page1, PageSize1, false, function (result: products[]) {
                    if (result != null && result.length > 0) {
                        Setentitylist(result);
                        setTotalCount1(result[0].totalRecord);
                        setPage1(result[0].index);
                        setPageSize1(result[0].recordPerPage);
                        setActivePage1(Page1);
                        settablescrollsection(true);
                        setisLoader(false);
                    }
                    else {
                        Setentitylist(result);
                        setisLoader(false);
                    }
                    //ExistingInvProductLoad();

                })
            });
        });
    }, [])


    const ExistingInvProductLoad = () => {
        setisLoader(true);

        GetFilters(4, function (result: any) {

            setfilterlist(result);
            GetFiltersjson(4, function (result: any) {

                setfilterobj(result);
                GetInventoryLoadbyFilter(result, Page, PageSize, function (result: any) {
                    if (result != null && result.length > 0) {
                        Setentylist(result);
                        setTotalCount(result[0].totalRecord);
                        setPage(result[0].index);
                        setPageSize(result[0].recordPerPage);
                        setActivePage(Page);
                        setisLoader(false);
                    }
                    else {
                        Setentylist(result);
                        setisLoader(false);
                    }
                })
            })

        })

    }



    const ApplyFilterRules = (e: any) => {
        setisLoader(true);


        var ifilter = [...filterobj1];
        ifilter.forEach(element => {
            if (element.code === "SKU") {
                element.value1 = fsku;
                element.value2 = tsku;
            }
        });
        setfilterobj1(ifilter);
        GetMPEntityDetailsByFIlter(filterobj1, Page1, PageSize1, true, function (result: products[]) {
            debugger;
            if (result != null && result.length > 0) {
                Setentitylist(result);
                setTotalCount1(result[0].totalRecord);
                setPage1(result[0].index);
                setPageSize1(result[0].recordPerPage);
                setActivePage1(Page1);
                settablescrollsection(true);
                setisLoader(false);
            }
            else {
                Setentitylist(result);
                setTotalCount1(0);
                setisLoader(false);
            }

        })

    }

    const ApplyRules = (e: any) => {
        setisLoader(true);


        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.code === "SKU") {
                element.value1 = efsku;
                element.value2 = etsku;
            }
            else if (element.code === "Date") {
                element.value1 = searchDate;
                element.value2 = searchEndDate;
            }
        });
        setfilterobj(ifilter);
        GetInventoryLoadbyFilter(filterobj, 1, 50, function (result: any) {
            debugger;
            if (result != null && result.length > 0) {
                Setentylist(result);
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
                setisLoader(false);
            }
            else {
                Setentylist(result);
                setTotalCount(0);
                setPage(1);
                setPageSize(50);
                setisLoader(false);
            }
        });

    }


    const RefreshList = () => {
        setisLoader(true);
        debugger;
        GetInventoryLoadbyFilter(filterobj, Page, PageSize, function (result: any) {
            debugger;
            if (result != null && result.length > 0) {
                Setentylist(result);
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
                setisLoader(false);
            }
            else {
                Setentylist(result);
                setTotalCount(0);
                setisLoader(false);
            }
        });
    }

    const changeChannel = (e: any) => {
        setChannelId(e.target.value);
    }

    const changeCategoryId = (e: any) => {
        setcategoryID(e.target.value);
        console.log(categoryId);
        setisLoader(true);
        let catid = Number(e.target.value);

        if (catid == 0 && entityname == "") {
            GetMPEntityDetailsById(catid, "", 3, "", prdgrp, pricemax, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
                if (result.length > 0 && result != null) {
                    Setentitylist(result);
                    setTotalCount1(result[0].totalRecord);
                    setPage1(result[0].index);
                    setPageSize1(result[0].recordPerPage);
                    setActivePage1(Page1);
                    settablescrollsection(true);
                    setisLoader(false);
                }
                else {
                    Setentitylist(result);
                    setTotalCount1(0);

                    setisLoader(false);
                }

            });
        }
        else if (entityname != "") {
            GetMPEntityDetailsById(catid, entityname, 1, supplierId, prdgrp, pricemax, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
                if (result.length > 0 && result != null) {
                    Setentitylist(result);
                    setTotalCount1(result[0].totalRecord);
                    setPage1(result[0].index);
                    setPageSize1(result[0].recordPerPage);
                    setActivePage1(Page1);
                    settablescrollsection(true);
                    setisLoader(false);
                }
                else {
                    Setentitylist(result);
                    setTotalCount1(0);

                    setisLoader(false);
                }

            });
        }
        else {
            GetMPEntityDetailsById(catid, "", iscategoryorothers, supplierId, prdgrp, pricemax, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
                if (result.length > 0 && result != null) {
                    Setentitylist(result);
                    setTotalCount1(result[0].totalRecord);
                    setPage1(result[0].index);
                    setPageSize1(result[0].recordPerPage);
                    setActivePage1(Page1);
                    settablescrollsection(true);
                    setisLoader(false);
                }
                else {
                    Setentitylist(result);
                    setTotalCount1(0);

                    setisLoader(false);
                }

            });
        }
    }


    const changeSupplierId = (e: any) => {
        setsupplierId(e.target.value);
        console.log(supplierId);
        setisLoader(true);
        let Catid = Number(categoryId);
        debugger;
        GetMPEntityDetailsById(Catid, "", 4, e.target.value, prdgrp, pricemax, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
            if (result.length > 0 && result != null) {
                Setentitylist(result);
                setTotalCount1(result[0].totalRecord);
                setPage1(result[0].index);
                setPageSize1(result[0].recordPerPage);
                setActivePage1(Page1);
                settablescrollsection(true);
                setisLoader(false);
            }
            else {
                Setentitylist(result);
                setTotalCount1(0);
                setisLoader(false);
            }

        });

    }


    const changeProductGroupId = (e: any) => {
        setprdgrp(e.target.value);
        console.log(supplierId);
        setisLoader(true);
        let Catid = Number(categoryId);
        debugger;
        GetMPEntityDetailsById(Catid, "", 4, supplierId, e.target.value, pricemax, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
            if (result.length > 0 && result != null) {
                Setentitylist(result);
                setTotalCount1(result[0].totalRecord);
                setPage1(result[0].index);
                setPageSize1(result[0].recordPerPage);
                setActivePage1(Page1);
                settablescrollsection(true);
                setisLoader(false);
            }
            else {
                Setentitylist(result);
                setTotalCount1(0);
                setisLoader(false);
            }

        });
    }
    const changePriceMax = (e: any) => {
        setpricemax(e.target.value);
        console.log(supplierId);
        setisLoader(true);
        let Catid = Number(categoryId);
        debugger;
        GetMPEntityDetailsById(Catid, "", 4, supplierId, prdgrp, e.target.value, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
            if (result.length > 0 && result != null) {
                Setentitylist(result);
                setTotalCount1(result[0].totalRecord);
                setPage1(result[0].index);
                setPageSize1(result[0].recordPerPage);
                setActivePage1(Page1);
                settablescrollsection(true);
                setisLoader(false);
            }
            else {
                Setentitylist(result);
                setTotalCount1(0);
                setisLoader(false);
            }

        });
    }
    const changePriceMin = (e: any) => {
        setpricemin(e.target.value);
        console.log(supplierId);
        setisLoader(true);
        let Catid = Number(categoryId);
        debugger;
        GetMPEntityDetailsById(Catid, "", 4, supplierId, prdgrp, pricemax, e.target.value, mfg, Page1, PageSize1, function (result: products[]) {
            if (result.length > 0 && result != null) {
                Setentitylist(result);
                setTotalCount1(result[0].totalRecord);
                setPage1(result[0].index);
                setPageSize1(result[0].recordPerPage);
                setActivePage1(Page1);
                settablescrollsection(true);
                setisLoader(false);
            }
            else {
                Setentitylist(result);
                setTotalCount1(0);
                setisLoader(false);
            }

        });
    }
    const changeMfg = (e: any) => {
        setmfg(e.target.value);
        console.log(supplierId);
        setisLoader(true);
        let Catid = Number(categoryId);
        debugger;
        GetMPEntityDetailsById(Catid, "", 4, supplierId, prdgrp, pricemax, pricemin, e.target.value, Page1, PageSize1, function (result: products[]) {
            if (result.length > 0 && result != null) {
                Setentitylist(result);
                setTotalCount1(result[0].totalRecord);
                setPage1(result[0].index);
                setPageSize1(result[0].recordPerPage);
                setActivePage1(Page1);
                settablescrollsection(true);
                setisLoader(false);
            }
            else {
                Setentitylist(result);
                setTotalCount1(0);
                setisLoader(false);
            }

        });
    }




    const changeType = (e: any) => {
        setinvtypeValue(e.target.value);
        setmpacclist(intMPAcclist);
        if (e.target.value == 2) {
            setisLoader(true);
            GetAllMarketplaces(function (result: any) {
                setmpacclist(result);
                setisLoader(false);
                setIsaccOrLocation(false);

            });
            if (stateval == '') {
                setStateval("clicked");
            }

        }
        else if (e.target.value == 4) {
            GetmpLocationsANDagilityBranch(3, function (result: any) {
                setmpacclist(result);
                setisLoader(false);
                setIsaccOrLocation(true);
            });
        }
        else if (e.target.value == 1) {
            setStateval("");
            setenlist(etylist);
        }
        else {
            setStateval("");

        }
    }

    const handleChange = (e: any) => {
        if (e.target.id == 1) {
            setactivecatclass(true);
            setactiveprdclass(false);
            setactiveitemclass(false);
            setiscategoryorothers(1);
        }
        if (e.target.id == 2) {
            setactivecatclass(false);
            setactiveprdclass(true);
            setactiveitemclass(false);
            setiscategoryorothers(2);
        }
        if (e.target.id == 3) {
            setactivecatclass(false);
            setactiveprdclass(false);
            setactiveitemclass(true);
            setiscategoryorothers(3);
            ExistingInvProductLoad();
        }
    }

    const changeEntityId = (e: any) => {
        setentityid(e.target.value)
    }
    const changeEntityName = (e: any) => {
        setentityname(e.target.value)
    }

    const changeMimQty = (e: any) => {
        setminimumqty(e.target.value);

        if (e.target.value == "") {
            setisdisabled(false);
            setisfdisabled(false);
        }
        else {
            setisdisabled(false);
            setisfdisabled(true);
        }

    }

    const changeFixedQty = (e: any) => {
        setfixedinv(e.target.value);
        if (e.target.value == "") {
            setisdisabled(false);
            setisfdisabled(false);
        }
        else {
            setisdisabled(true);
            setisfdisabled(false);
        }
    }
    const changeinValue = (e: any) => {
        debugger;
        setinvalue(e.target.value)
    }


    const SearchValues = (e: any) => {
        console.log(entityid);
        console.log(entityname);
        console.log(iscategoryorothers);
        setisLoader(true);

        if (entityname == '') {

            GetMPEntityDetailsById(0, entityname, 3, "", prdgrp, pricemax, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
                if (result.length > 0 && result != null) {
                    Setentitylist(result);
                    setTotalCount1(result[0].totalRecord);
                    setPage1(result[0].index);
                    setPageSize1(result[0].recordPerPage);
                    setActivePage1(Page1);
                    settablescrollsection(true);
                    setisLoader(false);
                }
                else {
                    Setentitylist(result);
                    setTotalCount1(0);

                    setisLoader(false);
                }

            })
        }
        else {
            if (iscategoryorothers != 1) {
                GetMPEntityDetailsById(0, entityname, iscategoryorothers, "", prdgrp, pricemax, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
                    if (result.length > 0 && result != null) {
                        Setentitylist(result);
                        setTotalCount1(result[0].totalRecord);
                        setPage1(result[0].index);
                        setPageSize1(result[0].recordPerPage);
                        setActivePage1(Page1);
                        settablescrollsection(true);
                        setisLoader(false);
                    }
                    else {
                        Setentitylist(result);
                        setTotalCount1(0);

                        setisLoader(false);
                    }

                })
            } else {
                let catid = Number(categoryId);
                GetMPEntityDetailsById(catid, entityname, iscategoryorothers, supplierId, prdgrp, pricemax, pricemin, mfg, Page1, PageSize1, function (result: products[]) {
                    if (result.length > 0 && result != null) {
                        Setentitylist(result);
                        setTotalCount1(result[0].totalRecord);
                        setPage1(result[0].index);
                        setPageSize1(result[0].recordPerPage);
                        setActivePage1(Page1);
                        settablescrollsection(true);
                        setisLoader(false);
                    }
                    else {
                        Setentitylist(result);
                        setTotalCount1(0);
                        setisLoader(false);
                    }


                })
            }
        }
    }

    const SetPage1 = (page: number) => {
        setPage1(page);
        setActivePage1(page);
        //RefreshList();
        setisLoader(true);
        let catid = Number(categoryId);
        if (catid == 0 && entityname == "") {
            debugger;
            GetMPEntityDetailsByFIlter(filterobj1, page, PageSize1, true, function (result: products[]) {
                if (result != null && result.length > 0) {
                    Setentitylist(result);
                    setTotalCount1(result[0].totalRecord);
                    setPage1(result[0].index);
                    setPageSize1(result[0].recordPerPage);
                    setActivePage1(page);
                    settablescrollsection(true);
                    setisLoader(false);
                } else {
                    Setentitylist(result);
                    setisLoader(false);
                }
                if (isAllChecked) {
                    let checked = isAllChecked;
                    let tmpacc = entitylist.map((mp) => {
                        return { ...mp, isselected: checked };

                    });
                    Setentitylist(tmpacc);

                    if (isAllChecked) {
                        var ival = [...entitylist];
                        ival.forEach(element => {
                            let mplistval: entities = {
                                entityid: element.sku.toString(),
                                uom: element.uom,
                                isactive: true
                            }
                            setmpList(mpList => [...mpList, mplistval]);
                        });
                        setisAllChecked(true);
                    }
                    else {
                        var ival1 = [...mpList];
                        ival1.forEach(element => {
                            element.isactive = false;
                        });
                        setmpList(ival1);
                        setisAllChecked(false);
                    }

                }

            })
        }
        else {
            GetMPEntityDetailsByFIlter(filterobj1, page, PageSize1, true, function (result: products[]) {
                if (result != null && result.length > 0) {

                    Setentitylist(result);
                    setTotalCount1(result[0].totalRecord);
                    setPage1(result[0].index);
                    setPageSize1(result[0].recordPerPage);
                    setActivePage1(page);
                    settablescrollsection(true);
                    setisLoader(false);
                } else {
                    Setentitylist(result);
                    setisLoader(false);

                }

            })
        }

    }
    const changeAllMP = (e: any) => {
        if (e.target.checked) {
            setIsallMP(true);
        } else {
            setIsallMP(false);

        }
    }
    const changeMarkdown = (e: any) => {
        if (e.target.checked) {
            setIsMarkdown(true);
        } else {
            setIsMarkdown(false);

        }
    }
    const handleMPAccClick = (id: number, e: any) => {
        const { value, checked } = e.target;
        let entityval = e.target.id;

        if (e.target.checked) {
            setenlist(enlist => [...enlist, entityval]);
        }
        else {
            setenlist(enlist.filter((e) => e !== entityval));
        }
        var ifilter1 = [...mpacclist];
        ifilter1.forEach(element => {
            let entityval = e.target.id;
            if (element.customerId == entityval) {
                element.isSuccess = e.target.checked;
            }


        });
        console.log("enlist..",enlist);
        //setisaccchecked(false);

        var objfilter = [...filterobj1];

        if (!IsaccOrLocation) {

            if (objfilter.length > 0) {
                objfilter.forEach(element => {
                    if (element.id == 17) {
                        element.value1 = ifilter1.filter(e => e.isSuccess == true).map(e => e.customerId).join(','); // ifilter1.filter(x => x.isSuccess == true).filter(x)
                        //element.accountid = accid.toString();
                    }
                    if (element.id == 8) {
                        element.value1 = "";
                    }
                })
                setfilterobj1(objfilter);
            }
        }
        else {
            if (objfilter.length > 0) {
                objfilter.forEach(element => {
                    if (element.id == 8) {
                        element.value1 = ifilter1.filter(e => e.isSuccess == true).map(e => e.customerId).join(','); // ifilter1.filter(x => x.isSuccess == true).filter(x)
                        //element.accountid = accid.toString();
                    }
                    if (element.id == 17) {
                        element.value1 = "";
                    }
                })
                setfilterobj1(objfilter);
            }
        }
    }

    const handlempAllClick = (e: any) => {
        debugger;
        const { value, checked } = e.target;
        let tmpproduct = mpacclist.map((mp) => {

            return { ...mp, isSuccess: checked };
        });

        var ifilter = [...tmpproduct];
        ifilter.forEach(element => {
            let entityval = element.customerId;
            if (element.isSuccess) {
                setenlist(enlist => [...enlist, String(entityval)]);
            }
            else {
                setenlist(enlist.filter((e) => e !== String(entityval)));
            }

        });
        console.log(enlist);
        console.log("enlist..",enlist);


        setmpacclist(tmpproduct);
        var objfilter = [...filterobj1];

        if (!IsaccOrLocation) {
            if (objfilter.length > 0) {
                objfilter.forEach(element => {
                    if (element.id == 17) {
                        element.value1 = ifilter.filter(e => e.isSuccess == true).map(e => e.customerId).join(','); // ifilter1.filter(x => x.isSuccess == true).filter(x)
                    }
                    else if (element.id == 8) {
                        element.value1 = "";//ifilter.filter(e => e.isSuccess == true).map(e => e.customerId).join(','); // ifilter1.filter(x => x.isSuccess == true).filter(x)

                    }
                })
                setfilterobj1(objfilter);
            }
        } else {
            if (objfilter.length > 0) {
                objfilter.forEach(element => {
                    if (element.id == 8) {
                        element.value1 = ifilter.filter(e => e.isSuccess == true).map(e => e.customerId).join(','); // ifilter1.filter(x => x.isSuccess == true).filter(x)
                        //element.accountid = accid.toString();
                    }
                    else if (element.id == 17) {
                        element.value1 = "";//ifilter.filter(e => e.isSuccess == true).map(e => e.customerId).join(','); // ifilter1.filter(x => x.isSuccess == true).filter(x)

                    }
                })
                setfilterobj1(objfilter);
            }
        }
        //confirmAlert({
        //    title: 'Update Product Status',
        //    message: 'Pls confirm to Update Status ?',
        //    buttons: [
        //        {
        //            label: 'Yes',
        //            onClick: () => Inactiveallproduct(checked)
        //        },
        //        {
        //            label: 'No',
        //            onClick: () => { }
        //        }
        //    ]
        //});

    }

    const handleAllClick = (e: any) => {
        const { value, checked } = e.target;
        let tmpacc = entitylist.map((mp) => {
            return { ...mp, isselected: checked };

        });
        Setentitylist(tmpacc);

        if (e.target.checked) {
            var ival = [...entitylist];
            ival.forEach(element => {
                let mplistval: entities = {
                    entityid: element.sku.toString(),
                    uom: element.uom,
                    isactive: true
                }
                setmpList(mpList => [...mpList, mplistval]);
            });
            setisAllChecked(true);
        }
        else {
            var ival1 = [...mpList];
            ival1.forEach(element => {
                //if (element.entityid === id && element.uom == uom) {
                element.isactive = false;
                // }
            });
            setmpList(ival1);
            setisAllChecked(false);
        }

    }

    const handleClick = (sku: string, uom: string, e: any) => {
        const { value, checked } = e.target;
        let entityval = e.target.id;
        let tmpacc = entitylist.map((mp) => mp.sku === sku && mp.uom === uom ? { ...mp, isselected: checked } : mp);
        Setentitylist(tmpacc);
        if (e.target.checked) {
            let mplistval: entities = {
                entityid: sku,
                uom: uom,
                isactive: true
            }
            setmpList(mpList => [...mpList, mplistval]);

        }
        else {
            setisAllChecked(false);
            var ival = [...mpList];
            ival.forEach(element => {
                if (element.entityid === sku && element.uom == uom) {
                    element.isactive = false;
                }
            });
            setmpList(ival);
        }
    }

    const onChangeValue = (e: any) => {
        setispervalue(e.target.checked);
        if (e.target.checked) {
            setisvalue("per");
        } else {
            setisvalue("");

        }
        setisfixvalue(false);
    }
    const onChangefixValue = (e: any) => {
        setisfixvalue(e.target.checked);
        setispervalue(false);
        if (e.target.checked) {
            setisvalue("fix");
        }
        else {
            setisvalue("");

        }
    }

    const SaveRule = (e: any) => {
        debugger;
        //let load: Loadermessage = {
        //    message: "",
        //    isLoader: true,
        //    isSuccess: false,
        //    isfailed: false
        //}
        //Setisloader(load);
        setisLoader(true);
        var date = moment(new Date()).format('MM/DD/YYYY');
        var sldate = moment(new Date(startDate)).format('MM/DD/YYYY');
        let isstartdate = false;
        let isenddate = false;
        if (sldate == date) {
            isstartdate = true;
        }
        else if (sldate < date) {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Expire StartDate -  don't select Past date"
            Setisloader(result);
        }
        else {
            isstartdate = true;
        }


        //var sldate1 = moment(new Date(endDate)).format('MM/DD/YYYY');
        //if (sldate1 == date) {
        //    isenddate = true;
        //}
        //else if (sldate1 < date) {
        //    let result = CreateinitialLoadermessage()
        //    result.isfailed = true;
        //    result.message = "Expire EndDate -  don't select Past date"
        //    Setisloader(result);
        //}
        //else {
        //    isenddate = true;
        //}

        if (mpList.length > 0 && invtypeValue.toString() != "0" && isstartdate && utcStartDate != "") {

            if (enlist.length == 0 && invtypeValue.toString() == "1") {
                if (minimumqty == "" && fixedinv == "") {
                    let result = CreateinitialLoadermessage()
                    result.isfailed = true;
                    result.message = "Please Enter the Reserved Quantity (or) Fixed Inventory"
                    Setisloader(result);
                    setisLoader(false);
                }
                else if (invalue != "" && isvalue == "") {
                    let result = CreateinitialLoadermessage()
                    result.isfailed = true;
                    result.message = "Please Select the Level-Up"
                    Setisloader(result);
                    setisLoader(false);
                }
                else if ((isvalue == "fix" || isvalue == "per") && invalue == "") {
                    let result = CreateinitialLoadermessage()
                    result.isfailed = true;
                    result.message = "Enter the Compute Inventory"
                    Setisloader(result);
                    setisLoader(false);

                } else {
                    SaveEntityDetailsById(enlist, mpList, minimumqty, isvalue, invalue, invtypeValue.toString(), iscategoryorothers, fixedinv, IsallMP, IsMarkdown, IsaccOrLocation, utcStartDate, utcEndDate, isAllChecked, filterobj1, function (result: any) {
                        // if (result.isSuccess) {
                        let result1 = CreateinitialLoadermessage();
                        result1.isSuccess = true;
                        result1.message = "Saved Successfully"
                        Setisloader(result1);
                        setisLoader(false);
                        //RefreshList();
                        history.go(0);
                        //}
                    });
                }
            }
            else if (enlist.length > 0 && invtypeValue.toString() != "1") {
                if (minimumqty == "" && fixedinv == "") {
                    let result = CreateinitialLoadermessage()
                    result.isfailed = true;
                    result.message = "Please Enter the Reserved Quantity (or) Fixed Inventory"
                    Setisloader(result);
                    setisLoader(false);
                }
                else if (invalue != "" && isvalue == "") {
                    let result = CreateinitialLoadermessage()
                    result.isfailed = true;
                    result.message = "Please Select the Level-Up"
                    Setisloader(result);
                    setisLoader(false);
                }
                else if ((isvalue == "fix" || isvalue == "per") && invalue == "") {
                    let result = CreateinitialLoadermessage()
                    result.isfailed = true;
                    result.message = "Enter the Compute Inventory"
                    Setisloader(result);
                    setisLoader(false);

                } else {
                    SaveEntityDetailsById(enlist, mpList, minimumqty, isvalue, invalue, invtypeValue.toString(), iscategoryorothers, fixedinv, IsallMP, IsMarkdown, IsaccOrLocation, utcStartDate, utcEndDate, isAllChecked, filterobj1, function (result: any) {
                        //if (result.isSuccess) {
                        let result1 = CreateinitialLoadermessage();
                        result1.isSuccess = true;
                        result1.message = "Saved Successfully"
                        Setisloader(result1);
                        setisLoader(false);
                        RefreshList();
                        history.go(0);
                        //}
                    });
                }
            } else {
                let result = CreateinitialLoadermessage()
                result.isfailed = true;
                result.message = "select Atleast 1  Account Number (or) Locations"
                Setisloader(result);
                setisLoader(false);


                // setisLoader(false);
            }

        }
        else {
            if (mpList.length == 0) {
                let result = CreateinitialLoadermessage()
                result.isfailed = true;
                result.message = "select Atleast One Item#"
                Setisloader(result);
                //setisLoader(false);
            }
            else if (invtypeValue.toString() == "0") {
                let result = CreateinitialLoadermessage()
                result.isfailed = true;
                result.message = "Select Rule Type"
                Setisloader(result);
                //setisLoader(false);
            }
            else if (invalue == "" && fixedinv == "") {
                let result = CreateinitialLoadermessage()
                result.isfailed = true;
                result.message = "Please Enter the Complute Inventory (or) Enter the Fixed Quantity"
                Setisloader(result);
            }
            else if (utcStartDate == "") {
                let result = CreateinitialLoadermessage()
                result.isfailed = true;
                result.message = "Please Select the Start Date"
                Setisloader(result);
            }
            else if (invalue != "" && isvalue == "") {
                let result = CreateinitialLoadermessage()
                result.isfailed = true;
                result.message = "Please Select the Level-Up"
                Setisloader(result);
            }
            //else if (fixedinv == "") {
            //    let result = CreateinitialLoadermessage()
            //    result.isfailed = true;
            //    result.message = "Fixed Inventory- Enter the Fixed Quantity"
            //    Setisloader(result);
            //}
            //alert("select atleast one item - enter Value -  select rule type or enter shared Qty");
            setisLoader(false);
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }

    const Pagenation = () => {
        let Pagination = new PaginationValues();
        Pagination.TotalItemCount = TotalCount;
        Pagination.CurrentPage = Page;
        Pagination.PageSlide = 2;
        Pagination.IsSort = true;
        Pagination.PageSize = PageSize;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page - 1) * PageSize) + 1}    - {((((Page - 1) * PageSize) + 1) + PageSize) > TotalCount ? TotalCount : ((((Page - 1) * PageSize)) + PageSize)} of {TotalCount} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-2">
                                {
                                    Pagination.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination.Pages().map((x, i) =>
                                        x == Page ?
                                            (
                                                <li className={`page-item  ${activePage == x ? 'active' : null}`} key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className="page-link" onClick={() => SetPage(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }

    const Pagenation1 = () => {
        let Pagination1 = new PaginationValues();
        Pagination1.TotalItemCount = TotalCount1;
        Pagination1.CurrentPage = Page1;
        Pagination1.PageSlide = 2;
        Pagination1.IsSort = true;
        Pagination1.PageSize = PageSize1;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page1 - 1) * PageSize1) + 1}    - {((((Page1 - 1) * PageSize1) + 1) + PageSize1) > TotalCount1 ? TotalCount1 : ((((Page1 - 1) * PageSize1)) + PageSize1)} of {TotalCount1} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-2">
                                {
                                    Pagination1.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage1(Page1 - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination1.Pages().map((x, i) =>
                                        x == Page1 ?
                                            (
                                                <li className={`page-item  ${activePage1 == x ? 'active' : null}`} key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className="page-link" onClick={() => SetPage1(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination1.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage1(Page1 + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }

    const SetPageSize = (size: number) => {
        setPageSize(size);
        //RefreshList();
        setisLoader(true);
        GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, searchEndDate, ivalidity, supplierkey, prdgrpname, pricemaxname, priceminname, mfgname, Page, size, function (result: any) {
            if (result != null) {
                Setentylist(result)
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setisLoader(false);
            }
        })
    }

    const SetPage = (page: number) => {
        setPage(page);
        setActivePage(page);
        //RefreshList();
        setisLoader(true);
        GetInventoryLoadbyFilter(filterobj, page, PageSize, function (result: any) {
            if (result != null) {
                Setentylist(result)
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setisLoader(false);
            }
        })
    }

    const DeleteAlert = (id: number) => {
        confirmAlert({
            title: 'Deactivate Rule',
            message: 'Are you sure you want to deactivate the Rule?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Delete(id)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const Delete = (id: number) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        DeleteEntity(id, function (result: any) {
            if (result.isSuccess) {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "Rule deactivated successfully"
                Setisloader(result);
                RefreshList();
                //setisLoader(true)
                //GetInventoryLoadbyFilter(filterobj, Page, PageSize, function (result: any) {
                //    if (result != null) {
                //        Setentylist(result)
                //        setTotalCount(result[0].totalRecord)
                //        setPage(result[0].index);
                //        setPageSize(result[0].recordPerPage);
                //        setisLoader(false);
                //    }
                //})
                //let load: Loadermessage = {
                //    message: "",
                //    isLoader: true,
                //    isSuccess: false,
                //    isfailed: false
                //}
                //Setisloader(load);

                //GetInventoryLoadbyFilter(filterobj, Page, PageSize, function (result: any) {
                //    debugger;
                //    if (result != null && result.length > 0) {
                //        Setentylist(result);
                //        setTotalCount(result[0].totalRecord);
                //        setPage(result[0].index);
                //        setPageSize(result[0].recordPerPage);
                //        setActivePage(Page);
                //        setisLoader(false);
                //    }
                //    else {
                //        Setentylist(result);
                //        setTotalCount(0);
                //        setisLoader(false);
                //    }
                //    let load: Loadermessage = {
                //        message: "",
                //        isLoader: false,
                //        isSuccess: false,
                //        isfailed: false
                //    }
                //    Setisloader(load);
                //});
            }
        })
    }
    const backUserDB = () => {
        history.push('/userdashboard');
    }
    var intAcclist: channellist[] = new Array<channellist>();
    const [acclist, setacclist] = useState(intAcclist);

    var intlocationlist: channellist[] = new Array<channellist>();
    const [loclist, setloclist] = useState(intlocationlist);
    const [sku, setsku] = useState("");
    const [locationid, setlocationid] = useState("");
    const [accno, setaccno] = useState("");
    const [iruletype, setiruletype] = useState("");
    const [ivalidity, setivalidity] = useState("");
    const [supplierkey, setsupplierkey] = useState("");




    const changeSearchSku = (e: any) => {
        setsku(e.target.value);
        console.log(sku);

    }
    useEffect(() => {
        // setisLoader(true);
        GetmpLocationsANDagilityBranch(1, function (result: any) {
            setloclist(result);
            //setisLoader(false);

        })
    }, [])

    const changeFilters = (id: any, name: string, e: any) => {
        setisLoader(true);


        var ifilter = [...filterobj1];
        ifilter.forEach(element => {
            if (element.id === id) {
                if (name == "SKU") {
                    element.value1 = fsku

                } else {
                    element.value1 = e.target.value;
                }
            }
        });

        const { value, checked } = e.target;
        let entityval = e.target.id;

        let mplistval: filterentities = {
            id: id
        }
        setmpfilters1(mpfilters => [...mpfilters, mplistval]);
        setfilterobj1(ifilter);


        setisLoader(false);
    }

    const changeEFilters = (id: any, name: string, e: any) => {
        setisLoader(true);


        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === id) {
                if (name == "SKU") {
                    element.value1 = efsku

                } else {
                    element.value1 = e.target.value;
                }
            }
        });

        const { value, checked } = e.target;
        let entityval = e.target.id;

        let mplistval: filterentities = {
            id: id
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);
        setfilterobj(ifilter);


        setisLoader(false);
    }

    useEffect(() => {
        //setisLoader(true);
        GetAllMarketplaces(function (result: any) {
            setacclist(result);
            // setisLoader(false);

        })
    }, [])
    const SearchInventory = (e: any) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, searchEndDate, ivalidity, supplierkey, prdgrpname, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        })
    }
    const changelocation = (e: any) => {
        let locid = e.target.value;
        setlocationid(locid);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetInventoryentitylist(sku, e.target.value, accno, iruletype, searchDate, searchEndDate, ivalidity, supplierkey, prdgrpname, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }
    const changesupplier = (e: any) => {
        let supkey = e.target.value;
        setsupplierkey(supkey);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, searchEndDate, ivalidity, e.target.value, prdgrpname, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }

    const changeprdgrp = (e: any) => {
        let supkey = e.target.value;
        debugger;
        setprdgrpname(supkey);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, searchEndDate, ivalidity, supplierkey, e.target.value, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }
    const changeprimax = (e: any) => {
        let supkey = e.target.value;
        setpricemaxname(supkey);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, searchEndDate, ivalidity, supplierkey, prdgrpname, e.target.value, priceminname, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }
    const changeprimin = (e: any) => {
        let supkey = e.target.value;
        setpriceminname(supkey);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, searchEndDate, ivalidity, supplierkey, prdgrpname, priceminname, e.target.value, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }
    const changemfgname = (e: any) => {
        let supkey = e.target.value;
        setmfgname(supkey);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        setisLoader(true);
        GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, searchEndDate, ivalidity, supplierkey, prdgrpname, priceminname, priceminname, e.target.value, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }


    const changeacc = (e: any) => {
        let id = e.target.value;
        setaccno(id);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetInventoryentitylist(sku, locationid, e.target.value, iruletype, searchDate, searchEndDate, ivalidity, supplierkey, prdgrpname, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }
    const changerule = (e: any) => {
        let id = e.target.value;
        setiruletype(id);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetInventoryentitylist(sku, locationid, accno, e.target.value, searchDate, searchEndDate, ivalidity, supplierkey, prdgrpname, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }
    const changevalidity = (e: any) => {
        let id = e.target.value;
        setivalidity(id);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, searchEndDate, e.target.value, supplierkey, prdgrpname, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
            Setentylist(result);
            if (result.length > 0) {
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
            }
            else {
                setTotalCount(result.length);
            }
            setisLoader(false);
        });
    }
    const onChangeStartDate = (e: any) => {
        debugger;
        const newDate = e.target.value;
        var date = moment(new Date()).format('MM/DD/YYYY');
        var sldate = moment(new Date(newDate)).format('MM/DD/YYYY');
        let isdatevalid = true;
        if (sldate == date) {
            setstartDate(newDate);
            setutcStartDate(moment.utc(moment(newDate).utc()).format());
            console.log('current date', newDate);
            console.log('UTC Date', moment.utc().format());
            //console.log(moment.utc(newDate).format());
            console.log('local date convert UTC Date', moment.utc(moment(newDate).utc()).format())
            //console.log(moment.utc(moment(date).utc()).toISOString());
            //console.log(newDate.toUTCString());

            Setisloader(CreateinitialLoadermessage());
        }
        else if (sldate < date) {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Start Date - Please don't select Past date"
            Setisloader(result);
            isdatevalid = false;
        } else {
            setstartDate(newDate);
            setutcStartDate(moment.utc(moment(newDate).utc()).format());

            Setisloader(CreateinitialLoadermessage());

        }
    };
    const onChangeSearchDate = (e: any) => {
        debugger;
        const newDate = e.target.value;
        setsearchDate(newDate);
        //let load: Loadermessage = {
        //    message: "",
        //    isLoader: true,
        //    isSuccess: false,
        //    isfailed: false
        //}
        //Setisloader(load);
        //GetInventoryentitylist(sku, locationid, accno, iruletype, e.target.value, searchEndDate, ivalidity, supplierkey, prdgrpname, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
        //    Setentylist(result);
        //    if (result.length > 0) {
        //        setTotalCount(result[0].totalRecord);
        //        setPage(result[0].index);
        //        setPageSize(result[0].recordPerPage);
        //        setActivePage(Page);
        //    }
        //    else {
        //        setTotalCount(result.length);
        //    }
        //    setisLoader(false);
        //});
    };

    const onChangeSearchEndDate = (e: any) => {
        const newDate = e.target.value;
        setsearchEndDate(newDate);
        //let load: Loadermessage = {
        //    message: "",
        //    isLoader: true,
        //    isSuccess: false,
        //    isfailed: false
        //}
        //Setisloader(load);
        //GetInventoryentitylist(sku, locationid, accno, iruletype, searchDate, e.target.value, ivalidity, supplierkey, prdgrpname, pricemaxname, priceminname, mfgname, Page, PageSize, function (result: any) {
        //    Setentylist(result);
        //    if (result.length > 0) {
        //        setTotalCount(result[0].totalRecord);
        //        setPage(result[0].index);
        //        setPageSize(result[0].recordPerPage);
        //        setActivePage(Page);
        //    }
        //    else {
        //        setTotalCount(result.length);
        //    }
        //    setisLoader(false);
        //});
    };
    const onChangeEndDate = (e: any) => {
        const newDate = e.target.value;
        var date = moment(new Date()).format('MM/DD/YYYY');
        var sldate = moment(new Date(newDate)).format('MM/DD/YYYY');
        let isdatevalid = true;
        if (sldate == date) {
            setendDate(newDate);
            setutcEndDate(moment.utc(moment(newDate).utc()).format())
            Setisloader(CreateinitialLoadermessage());
        }
        if (sldate < date) {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "End Date - Please don't select Past date"
            Setisloader(result);
            isdatevalid = false;
        } else {
            Setisloader(CreateinitialLoadermessage());
            setendDate(newDate);
            setutcEndDate(moment.utc(moment(newDate).utc()).format())


        }
    };
    const ClosePopup = () => {
        Setisloader(CreateinitialLoadermessage())
    }
    const resetPage = () => {
        history.go(0);
    }
    const resetEPage = () => {
        //history.go(0);
        debugger;
        setactivecatclass(false);
        setactiveprdclass(false);
        setactiveitemclass(true);
        setiscategoryorothers(3);
        setefsku("");
        setetsku("");
        setfilterlist(intfilterlist);
        ExistingInvProductLoad();
    }
    const changeCgroup = (e: any) => {
        let cgidv = e.target.value;
        setcgid(cgidv);
        debugger;
        GetAllMarketplacebyAccountId(cgidv, function (result: any) {
            setmpacclist(result);
        });
    }
    const DownloadInventoryRule = () => {
        if (filterobj1 != null) {
            try {
                let load: Loadermessage = {
                    message: "",
                    isLoader: true,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
                debugger;
                var data = new FormData();
                data.append("filters", JSON.stringify(filterobj1));
                const response = axios({
                    method: "post",
                    url: "api/MP/DownloadInventoryRule",
                    data: data,
                    headers: { "Content-Type": "multipart/form-data" },
                    responseType: 'blob',
                }).then((result) => {

                    let resultmes = CreateinitialLoadermessage();
                    if (result != null) {
                        debugger;
                        const url = window.URL.createObjectURL(new Blob([result.data]));
                        const a = document.createElement('a');
                        a.href = url;
                        a.setAttribute('download', "InventoryRuleProducts.csv");
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        URL.revokeObjectURL(url);

                    }
                    resultmes.isLoader = false;
                    Setisloader(resultmes);
                });
                //Setisloader(load);
            } catch (error) {
                console.log(error)
            }
        }
        else {
            let load: Loadermessage = {
                message: "Select Atleast one filter and download",
                isLoader: false,
                isSuccess: false,
                isfailed: true
            }
            Setisloader(load);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        }
    }
    const DownloadEInventoryRule = () => {
        if (filterobj != null) {
            try {
                let load: Loadermessage = {
                    message: "",
                    isLoader: true,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
                debugger;
                var data = new FormData();
                data.append("filters", JSON.stringify(filterobj));
                const response = axios({
                    method: "post",
                    url: "api/MP/DownloadEInventoryRule",
                    data: data,
                    headers: { "Content-Type": "multipart/form-data" },
                    responseType: 'blob',
                }).then((result) => {

                    let resultmes = CreateinitialLoadermessage();
                    if (result != null) {
                        debugger;
                        const url = window.URL.createObjectURL(new Blob([result.data]));
                        const a = document.createElement('a');
                        a.href = url;
                        a.setAttribute('download', "ExInventoryRuleProducts.csv");
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        URL.revokeObjectURL(url);

                    }
                    resultmes.isLoader = false;
                    Setisloader(resultmes);
                });
                //Setisloader(load);
            } catch (error) {
                console.log(error)
            }
        }
        else {
            let load: Loadermessage = {
                message: "Select Atleast one filter and download",
                isLoader: false,
                isSuccess: false,
                isfailed: true
            }
            Setisloader(load);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        }
    }
    const handleCheckAll = (value: any) => {
        setCheckAll(value);
        var merged: any[] = [];
        entylist.forEach((x: any) => {
            merged.push({ ...x, ischeckall: value });
        });
        Setentylist(merged);
        var checkedItems = merged.filter((item: any) => item.ischeckall == true)
        //console.log("checkedItems....", checkedItems)
        const productIdsArr = checkedItems.map((obj: any) => obj.id.toString());
        console.log("productIdsArr....", productIdsArr)
       // const productIdsArr = checkedItems.map((item:any) => ({ id: item.id }));
       // const result = productIdsArr.join(',');
        setSelectedProduct(productIdsArr);


    };
    const handleChangeCheckbox = (e: any, index: any) => {
        let arr = entylist;
        arr[index].ischeckall = e.target.checked;
        Setentylist([...arr]);
        console.log("arr....", arr)
        var checkedItems = arr.filter((item: any) => item.ischeckall == true)
        //console.log("checkedItems....", checkedItems)
        const productIdsArr = checkedItems.map((obj: any) => obj.id.toString());
        console.log("productIdsArr....", productIdsArr)
        //const result = productIdsArr.join(',');
        setSelectedProduct(productIdsArr);

    };
    const DeactivateAlert = () => {
        confirmAlert({
            title: 'Deactivate Rule',
            message: 'Are you sure you want to deactivate the Rule?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeactivateRule()
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const DeactivateRule=()=>{
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
      
        DeactivateRules(selectedProduct, function (result: any) {
            if (result==true) {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "Rule deactivated successfully";
                Setisloader(result);
                RefreshList();
               
            }else{
                let result = CreateinitialLoadermessage();
                result.isSuccess = false;
                result.message = "failed";
                result.isfailed=true;
                Setisloader(result);
                RefreshList();

            }
        })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const [currentEntity, setCurrentEntity] = useState(createinitialProducts);
    const [currentEntityExisting, setcurrentEntityExisting] = useState(createinitialjobDetails);
    const [isPopover1Open, setIsPopover1Open] = useState(false);
    const [isPopover2Open, setIsPopover2Open] = useState(false);

    const handleInfoClick = (event: any, data: any) => {
        setAnchorEl(event.currentTarget);
        setCurrentEntity(data);
        setIsPopover1Open(true);
        setIsPopover2Open(false);
        console.log("event....", data)

    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleInfoClickExisting = (event: any, data: any) => {
        setAnchorEl(event.currentTarget);
        setcurrentEntityExisting(data);
        setIsPopover2Open(true);
        setIsPopover1Open(false);
        console.log("handleInfoClickExisting....", data)

    }
    const handleSelect = (selectedList: any, selectedItem: any, filterId: any) => {
        console.log("selectedList....", selectedList)

        var ifilter = [...filterobj1];

        ifilter.forEach(element => {
            if (element.id === filterId) {

                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj1(ifilter);
        console.log("selectedItem....", ifilter);
    }
    const eventRef = React.useRef(null);
    const handleRemove = (selectedList: any, selectedItem: any, filterId: number) => {
        console.log('filterId', filterId);
        var ifilter = [...filterobj1];
        ifilter.forEach(element => {
            if (element.id === filterId) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });

        setfilterobj1(ifilter);
        const event: any = eventRef.current;
        event.stopPropagation();
        console.log("DeselectedItem....", ifilter);
    }

    const handleSelectExisting = (selectedList: any, selectedItem: any, filterId: any) => {
        console.log("selectedListExist....", selectedList)

        var ifilter = [...filterobj];

        ifilter.forEach(element => {
            if (element.id === filterId) {

                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj1(ifilter);
        console.log("selectedItemExist....", ifilter);
    }
    const eventRef1 = React.useRef(null);
    const handleRemoveExisting = (selectedList: any, selectedItem: any, filterId: any) => {

        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === filterId) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj1(ifilter);
        const event: any = eventRef1.current;
        event.stopPropagation();
        console.log("DeselectedItemExist....", ifilter);
    }

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;


    const [options, setOptions] = useState<string[]>([]);
    const [storeFilters, setStoreFilters] = useState<storeFilter[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const loadOptions = (filterId: number) => {
        setOptions([]);
        setIsLoading(true);
        let isFilterExist: storeFilter[] = storeFilters.filter(x => x.filterID == filterId);
        if (isFilterExist.length > 0) {
            console.log('loadOptions isFilterExist', isFilterExist);
            setOptions(isFilterExist[0].values);
            setIsLoading(false);
        } else {
            GetFilterDatabyModuleIdandFilterId(3, filterId, function (result: any) {
                const optionsList: string[] = result.map((item: any) => item.value);
                let makeFilteredStoredData: storeFilter = {
                    filterID: filterId,
                    values: optionsList
                };
                let sum: storeFilter[] = storeFilters;
                sum.push(makeFilteredStoredData);
                setStoreFilters(storeFilters);
                setOptions(optionsList);
                setIsLoading(false);
            });
        }
    }




    const loadOptionsExisting = (e: any) => {

        setOptions([]);
        let filterId: number = e.currentTarget.getAttribute('data-d-id');
        setIsLoading(true);
        let isFilterExist: storeFilter[] = storeFilters.filter(x => x.filterID == filterId);
        if (isFilterExist.length > 0) {
            setOptions(isFilterExist[0].values);
            setIsLoading(false);
        } else {
            GetFilterDatabyModuleIdandFilterId(4, filterId, function (result: any) {
                const optionsList: string[] = result.map((item: any) => item.value);
                let makeFilteredStoredData: storeFilter = {
                    filterID: filterId,
                    values: optionsList
                };
                let sum: storeFilter[] = storeFilters;
                sum.push(makeFilteredStoredData);
                setStoreFilters(storeFilters);
                setOptions(optionsList);
                setIsLoading(false);
            });
        }

    }

    function preventDefault(): { preventDefault: () => void; } {
        throw new Error("Function not implemented.");
    }
    console.log('options', options);
    const OpenFileExplore = () => {
        inputFile.current.click();
    };
    const handleFileUpload = (e: any) => {
        setisLoader(true);
        e.preventDefault();
        const { files } = e.target;
        if (files && files.length) {
            setFilename(files[0].name);
            setSelectedFile(files[0]);
            const formData = new FormData();
            formData.append('File', files[0]);

            try {
                const response = axios({
                    method: "post",
                    url: "api/MP/UploadInventoryRuleFile",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                }).then(result => {
                    if (result.data.isSuccess) {
                        let load: Loadermessage = {
                            message: "File Uploaded Successfully",
                            isLoader: false,
                            isSuccess: true,
                            isfailed: false
                        }
                        Setisloader(load);

                    }else{
                        let load: Loadermessage = {
                            message: "File Upload failed",
                            isLoader: false,
                            isSuccess: false,
                            isfailed: true
                        }
                        Setisloader(load);

                    }
                    setisLoader(false);
                    // setLoading(false);
                    // console.log(result);
                });



            } catch (error) {
                console.log(error)
                setisLoader(false);
            }
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }
    return (
        <React.Fragment>
            {isLoader ? (<Loader isActive={isLoader} />) : (null)}
            <div className="row">
                {/*<div className="col-md-3">*/}
                {/*    <SideBar />*/}
                {/*</div>*/}
                <div className="col-md-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Inventory Rules
                            </li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-section">
                                <h1>Inventory Rules</h1>

                                <div style={{ justifyContent: 'end', display: 'flex' }}>
                                    {/* <button type="button" className="btn btn-wh-1 btn-primary ms-2" onClick={backUserDB}>Back</button> */}
                                    <button type="button" className="btn btn-primary" onClick={backUserDB}>Back</button>

                                    <form>
                                        {/* <div className="col-12 mb-2 text-end"> */}
                                        {/* <button type="button" className="btn btn-wh-2 btn-primary" onClick={(e) => OpenFileExplore()}>Import Rule</button> */}
                                        {/* <button type="button" className="btn btn-primary ms-2" onClick={(e) => OpenFileExplore()}>Import Rule</button> */}
                                        {/* </div> */}
                                        {/* <input
                                            ref={inputFile}
                                            style={{ display: "none" }} onChange={handleFileUpload}
                                            type="file"
                                        /> */}
                                    </form>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="nav nav-pills">
                                        <li className="nav-item">
                                            <button className={activecatclass == true ? activeclassname : inactiveclassname} aria-current="page" id="1" onClick={handleChange} >New Rule</button>
                                        </li>
                                        <li className="nav-item hidden">
                                            <button className={activeprdclass == true ? activeclassname : inactiveclassname} id="2" onClick={handleChange}>Product</button>
                                        </li>
                                        <li className="nav-item">
                                            <button className={activeitemclass == true ? activeclassname : inactiveclassname} id="3" onClick={handleChange}>Existing Rules</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className={activecatclass == true ? "" : "hidden"}>
                                <div className="tab-content">
                                    <h2 className="pb-2 primarycolor">Inventory</h2>
                                    <div className="section-border-new">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <form>
                                                    {/* <div className="col-12 mb-2 text-end"> */}
                                                    {/* <button type="button" className="btn btn-wh-2 btn-primary" onClick={(e) => OpenFileExplore()}>Import Rule</button> */}
                                                    <button type="button" className="btn btn-primary float-end" onClick={(e) => OpenFileExplore()}>Import Rule</button>
                                                    {/* </div> */}
                                                    <input
                                                        ref={inputFile}
                                                        style={{ display: "none" }} onChange={handleFileUpload}
                                                        type="file"
                                                    />
                                                </form>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <h3 className="mb-3">Compute Inventory</h3>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="row g-2 align-items-center mb-2">
                                                            <div className="col-4">
                                                                <label className="form-label">Reserved Quantity</label>
                                                            </div>
                                                            <div className="col-8">
                                                                <input type="text" id="" className="form-control" aria-describedby="" disabled={isdisabled} value={minimumqty} onChange={changeMimQty} />
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 align-items-center mb-2">
                                                            <div className="col-4">
                                                                <label className="form-label">Level-up</label>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="intValue" onChange={onChangeValue} checked={ispervalue ? true : false} id="" />
                                                                    <label className="form-check-label">
                                                                        Percentage
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="intfixValue" onChange={onChangefixValue} checked={isfixvalue ? true : false} id="" />
                                                                    <label className="form-check-label">
                                                                        Fixed
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row g-2 align-items-center mb-3">
                                                            <div className="col-4">
                                                                <label className="form-label">Value</label>
                                                            </div>
                                                            <div className="col-8">
                                                                <input type="number" id="" className="form-control" aria-describedby="" disabled={isdisabled} value={invalue} onChange={changeinValue} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*<div className="col">*/}
                                                    {/*    <div className="row g-2 align-items-center mb-4 hidden">*/}
                                                    {/*        <div className="col-auto">*/}
                                                    {/*            <div className="form-check">*/}
                                                    {/*                <input className="form-check-input" type="checkbox" onChange={changeMarkdown} checked={IsMarkdown} />*/}
                                                    {/*                <label className="form-check-label">*/}
                                                    {/*                    Markdown*/}
                                                    {/*                </label>*/}
                                                    {/*            </div>*/}
                                                    {/*        </div>*/}
                                                    {/*        <div className="col-auto hidden">*/}
                                                    {/*            <div className="form-check">*/}
                                                    {/*                <input className="form-check-input" type="checkbox" onChange={changeAllMP} checked={IsallMP} />*/}
                                                    {/*                <label className="form-check-label">*/}
                                                    {/*                    Apply to all marketplaces*/}
                                                    {/*                </label>*/}
                                                    {/*            </div>*/}
                                                    {/*        </div>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="d-flex justify-content-between">
                                                    <h3 className="">Fixed Inventory</h3>
                                                </div>
                                                <div className="row g-2 align-items-center">
                                                    <div className="col-4">
                                                        <label className="col-form-label">Fixed Quantity</label>
                                                    </div>
                                                    <div className="col-8">
                                                        <input type="text" id="" className="form-control" aria-describedby="" disabled={isfdisabled} value={fixedinv} onChange={changeFixedQty} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="row mb-3 g-2 align-items-center">
                                                    <div className="col-4">
                                                        <label className="col-form-label">Effective Startdate</label>
                                                    </div>
                                                    <div className="col-8">
                                                        <input type="datetime-local" className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeStartDate} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="row mb-3 g-2 align-items-center">
                                                    <div className="col-4">
                                                        <label className="col-form-label">Effective Enddate</label>
                                                    </div>
                                                    <div className="col-8">
                                                        <input type="datetime-local" className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeEndDate} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className="pb-2 primarycolor">Customers</h2>
                                    <div className="section-border-new">
                                        <div className="row">
                                            <div className="col">
                                                <div className="row g-2 align-items-center mb-3">
                                                    <div className="col-5">
                                                        <label className="col-form-label">Inventory Rule Type</label>
                                                    </div>
                                                    <div className="col-7">
                                                        <select className="form-select" aria-label="Default select example" onChange={changeType}>
                                                            <option selected>Select Option</option>
                                                            <option value="1">Global-All</option>
                                                            <option value="2">Fulfillment-Accounts</option>
                                                            <option value="4">Fulfillment-Branches</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="row g-2 align-items-center mb-3">
                                                    <div className="col-5">
                                                        <label className="col-form-label">Customer Group</label>
                                                    </div>
                                                    <div className="col-7">
                                                        <select className="form-select" aria-label="Default select example" onChange={changeCgroup}>
                                                            <option selected value="0">All</option>
                                                            {
                                                                cglist != null ? (
                                                                    cglist.map((itemval, index) => (
                                                                        <option value={itemval.resultid}>{itemval.name}</option>
                                                                    ))
                                                                ) : (null)
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12">
                                                <table className="table table-bordered">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col">
                                                                <div className="form-check"><input className="form-check-input" type="checkbox" id="" value="" onChange={(e) => handlempAllClick(e)} /></div>
                                                            </th>
                                                            <th scope="col">
                                                                Name
                                                            </th>
                                                            <th scope="col">
                                                                Account Number
                                                            </th>
                                                            <th scope="col">
                                                                Customer Group
                                                            </th>
                                                            <th scope="col">
                                                                Branch
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            mpacclist != null ? (
                                                                mpacclist.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" name="entities" value={item.id} checked={item.isSuccess ? true : false} onChange={(e) => handleMPAccClick(item.customerId, e)} id={item.customerId.toString()} />
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {item.name}
                                                                        </td>
                                                                        <td>
                                                                            {item.message}
                                                                        </td>
                                                                        <td>
                                                                            {item.value}
                                                                        </td>
                                                                        <td>
                                                                            {item.statusdisplayname}
                                                                        </td>

                                                                    </tr>
                                                                ))) : (null)
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className="pb-2 primarycolor">Filters</h2>
                                    <div className="section-border-new">
                                        <div className="row">
                                            {
                                                filter1 != null ? (
                                                    filter1.map((item, index) => (

                                                        item.ftid == 3 ? <div className="col-6 mb-2">
                                                            <div className="row g-3 align-items-end">
                                                                <div className="col-6">
                                                                    <label className="form-label">From SKU</label>
                                                                    <input type="text" onChange={changeFilterSku} placeholder="" className="form-control" value={fsku} />
                                                                </div>
                                                                <div className="col-6">
                                                                    <label className="form-label">To SKU</label>
                                                                    <input type="text" onChange={changeFilterTSku} placeholder="" className="form-control" value={tsku} />
                                                                </div>

                                                            </div>
                                                        </div> :

                                                            // item.ftid == 1 ? <div className="col-3 mb-2">
                                                            //     <label className="form-label">
                                                            //         {item.name}
                                                            //     </label>
                                                            //     <select className="form-select" onChange={(e) => changeFilters(item.id, item.name, e)}>
                                                            //         <option selected value={""}>Alls</option>
                                                            //         {
                                                            //             item.filtersvalues != null ? (
                                                            //                 item.filtersvalues.map((itemval, index) => (
                                                            //                     <option value={itemval.key}>{itemval.value}</option>
                                                            //                 ))
                                                            //             ) : (null)
                                                            //         }
                                                            //     </select>
                                                            // </div> : ""



                                                            // item.ftid == 1 ? <div className="col-3 mb-2">
                                                            //     <label className="form-label">
                                                            //         {item.name}
                                                            //     </label>
                                                            //     <Multiselect
                                                            //         isObject={false}
                                                            //         displayValue="name"
                                                            //         placeholder="All"

                                                            //         onKeyPressFn={function noRefCheck() { }}
                                                            //         // onRemove={handleRemove}
                                                            //         onRemove={(selectedList, selectedItem) => handleRemove(selectedList, selectedItem, item.id)}
                                                            //         onSearch={function noRefCheck() { }}
                                                            //         // onSelect={handleSelect}
                                                            //         onSelect={(selectedList, selectedItem) => handleSelect(selectedList, selectedItem, item.id)}


                                                            //         options={item.filtersvalues.map(x => x.value)}
                                                            //     />
                                                            // </div> : ""

                                                            item.ftid == 1 ? <div className="col-3 mb-2">
                                                                <label className="form-label">
                                                                    {item.name}
                                                                </label>
                                                                <div onClick={() => loadOptions(item.id)}>
                                                                    <Multiselect
                                                                        isObject={false}
                                                                        displayValue="name"
                                                                        // placeholder="All"

                                                                        onKeyPressFn={function noRefCheck() { }}
                                                                        // onRemove={handleRemove}
                                                                        onRemove={(selectedList, selectedItem) => handleRemove(selectedList, selectedItem, item.id)}
                                                                        onSearch={function noRefCheck() { }}
                                                                        // onSelect={handleSelect}
                                                                        onSelect={(selectedList, selectedItem) => handleSelect(selectedList, selectedItem, item.id)}
                                                                        // onFocus={() => handleDropdownClick(item.id)} // Pass item.id when calling handleDropdownClick

                                                                        // options={item.filtersvalues.map(x => x.value)}
                                                                        options={options}
                                                                        loading={isLoading}
                                                                    />
                                                                </div>
                                                            </div> : ""

                                                    ))
                                                ) : (null)
                                            }
                                        </div>

                                        {/*    <div className="col-sm-12 col-md-12">*/}
                                        {/*        <div className="row">*/}
                                        {/*            <div className="col-sm-6 col-md-4">*/}
                                        {/*                <div className="row g-3 align-items-center mb-3">*/}
                                        {/*                    <div className="col-4">*/}
                                        {/*                        <label className="col-form-label">SKU</label>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="col-8">*/}
                                        {/*                        <input type="text" onChange={changeEntityName} placeholder="" className="form-control" value={entityname} />*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="col-sm-6 col-md-4">*/}
                                        {/*                <div className="row g-2 align-items-center mb-3">*/}
                                        {/*                    <div className="col-4">*/}
                                        {/*                        <label className="form-label mb-0">*/}
                                        {/*                            Web Category*/}
                                        {/*                        </label>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="col-8">*/}
                                        {/*                        <select className="form-select" onChange={(e) => changeCategoryId(e)}>*/}
                                        {/*                            <option selected value={0}>Select</option>*/}
                                        {/*                            {*/}
                                        {/*                                catList.map((item, index) => (*/}
                                        {/*                                    <option value={item.entityId}>{item.entityName}</option>*/}
                                        {/*                                ))*/}
                                        {/*                            }*/}
                                        {/*                        </select>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="col-sm-6 col-md-4">*/}
                                        {/*                <div className="row g-2 align-items-center mb-3">*/}
                                        {/*                    <div className="col-4">*/}
                                        {/*                        <label className="form-label mb-0">*/}
                                        {/*                            Supplier*/}
                                        {/*                        </label>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="col-8">*/}
                                        {/*                        <select className="form-select" onChange={(e) => changeSupplierId(e)}>*/}
                                        {/*                            <option selected value={""}>Select</option>*/}
                                        {/*                            {*/}
                                        {/*                                suppList.map((item, index) => (*/}
                                        {/*                                    <option value={item.entityId}>{item.entityName}</option>*/}
                                        {/*                                ))*/}
                                        {/*                            }*/}
                                        {/*                        </select>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="col-sm-6 col-md-4">*/}
                                        {/*                <div className="row g-2 align-items-center mb-3">*/}
                                        {/*                    <div className="col-4">*/}
                                        {/*                        <label className="form-label mb-0">*/}
                                        {/*                            Product Group*/}
                                        {/*                        </label>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="col-8">*/}
                                        {/*                        <select className="form-select" onChange={(e) => changeProductGroupId(e)}>*/}
                                        {/*                            <option selected value={""}>Select</option>*/}
                                        {/*                            {*/}
                                        {/*                                Prdgrplist.map((item, index) => (*/}
                                        {/*                                    <option value={item.name}>{item.name}</option>*/}
                                        {/*                                ))*/}
                                        {/*                            }*/}
                                        {/*                        </select>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="col-sm-6 col-md-4">*/}
                                        {/*                <div className="row g-2 align-items-center mb-3">*/}
                                        {/*                    <div className="col-4">*/}
                                        {/*                        <label className="form-label mb-0">*/}
                                        {/*                            Price Group Major*/}
                                        {/*                        </label>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="col-8">*/}
                                        {/*                        <select className="form-select" onChange={(e) => changePriceMax(e)}>*/}
                                        {/*                            <option selected value={""}>Select</option>*/}
                                        {/*                            {*/}
                                        {/*                                Pricemaxlist.map((item, index) => (*/}
                                        {/*                                    <option value={item.name}>{item.name}</option>*/}
                                        {/*                                ))*/}
                                        {/*                            }*/}
                                        {/*                        </select>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="col-sm-6 col-md-4">*/}
                                        {/*                <div className="row g-2 align-items-center mb-3">*/}
                                        {/*                    <div className="col-4">*/}
                                        {/*                        <label className="form-label mb-0">*/}
                                        {/*                            Price Group Minor*/}
                                        {/*                        </label>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="col-8">*/}
                                        {/*                        <select className="form-select" onChange={(e) => changePriceMin(e)}>*/}
                                        {/*                            <option selected value={""}>Select</option>*/}
                                        {/*                            {*/}
                                        {/*                                Priceminlist.map((item, index) => (*/}
                                        {/*                                    <option value={item.name}>{item.name}</option>*/}
                                        {/*                                ))*/}
                                        {/*                            }*/}
                                        {/*                        </select>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="col-sm-6 col-md-4">*/}
                                        {/*                <div className="row g-2 align-items-center mb-3">*/}
                                        {/*                    <div className="col-4">*/}
                                        {/*                        <label className="form-label mb-0">*/}
                                        {/*                            Manufacturer*/}
                                        {/*                        </label>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="col-8">*/}
                                        {/*                        <select className="form-select" onChange={(e) => changeMfg(e)}>*/}
                                        {/*                            <option selected value={""}>Select</option>*/}
                                        {/*                            {*/}
                                        {/*                                mfglist.map((item, index) => (*/}
                                        {/*                                    <option value={item.name}>{item.name}</option>*/}
                                        {/*                                ))*/}
                                        {/*                            }*/}
                                        {/*                        </select>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="col-sm-6 col-md-4">*/}
                                        {/*                <div className="row g-2 align-items-center mb-3">*/}
                                        {/*                    <div className="col-4">*/}
                                        {/*                        <label className="form-label mb-0">*/}
                                        {/*                            Product Group Major*/}
                                        {/*                        </label>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="col-8">*/}
                                        {/*                        <select className="form-select" onChange={(e) => changeMfg(e)}>*/}
                                        {/*                            <option selected value={""}>Select</option>*/}

                                        {/*                        </select>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}

                                        {/*            <div className="col-sm-6 col-md-4">*/}
                                        {/*                <div className="row g-2 align-items-center mb-3">*/}
                                        {/*                    <div className="col-4">*/}
                                        {/*                        <label className="form-label mb-0">*/}
                                        {/*                            Product Group Minor*/}
                                        {/*                        </label>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="col-8">*/}
                                        {/*                        <select className="form-select" onChange={(e) => changeMfg(e)}>*/}
                                        {/*                            <option selected value={""}>Select</option>*/}

                                        {/*                        </select>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}

                                        {/*            <div className="col-sm-6 col-md-4">*/}
                                        {/*                <div className="row g-2 align-items-center mb-3">*/}
                                        {/*                    <div className="col-4">*/}
                                        {/*                        <label className="form-label mb-0">*/}
                                        {/*                            Hayward W3*/}
                                        {/*                        </label>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="col-8">*/}
                                        {/*                        <select className="form-select" onChange={(e) => changeMfg(e)}>*/}
                                        {/*                            <option selected value={""}>Select</option>*/}

                                        {/*                        </select>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}


                                        {/*            <div className="col-sm-6 col-md-4">*/}
                                        {/*                <div className="row g-2 align-items-center mb-3">*/}
                                        {/*                    <div className="col-4">*/}
                                        {/*                        <label className="form-label mb-0">*/}
                                        {/*                            Pentair EC*/}
                                        {/*                        </label>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="col-8">*/}
                                        {/*                        <select className="form-select" onChange={(e) => changeMfg(e)}>*/}
                                        {/*                            <option selected value={""}>Select</option>*/}

                                        {/*                        </select>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}


                                        {/*            <div className="col-sm-6 col-md-4">*/}
                                        {/*                <div className="row g-2 align-items-center mb-3">*/}
                                        {/*                    <div className="col-4">*/}
                                        {/*                        <label className="form-label mb-0">*/}
                                        {/*                            LTL Only*/}
                                        {/*                        </label>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="col-8">*/}
                                        {/*                        <select className="form-select" onChange={(e) => changeMfg(e)}>*/}
                                        {/*                            <option selected value={""}>Select</option>*/}

                                        {/*                        </select>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}

                                        {/*            <div className="col-sm-6 col-md-4">*/}
                                        {/*                <div className="row g-2 align-items-center mb-3">*/}
                                        {/*                    <div className="col-4">*/}
                                        {/*                        <label className="form-label mb-0">*/}
                                        {/*                            Non Trade Itemflag*/}
                                        {/*                        </label>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="col-8">*/}
                                        {/*                        <select className="form-select" onChange={(e) => changeMfg(e)}>*/}
                                        {/*                            <option selected value={""}>Select</option>*/}

                                        {/*                        </select>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}

                                        {/*            <div className="col-sm-6 col-md-4">*/}
                                        {/*                <div className="row g-2 align-items-center mb-3">*/}
                                        {/*                    <div className="col-4">*/}
                                        {/*                        <label className="form-label mb-0">*/}
                                        {/*                            Chemical ApprovedFor Fulfillment*/}
                                        {/*                        </label>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="col-8">*/}
                                        {/*                        <select className="form-select" onChange={(e) => changeMfg(e)}>*/}
                                        {/*                            <option selected value={""}>Select</option>*/}

                                        {/*                        </select>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}

                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <div className="d-flex justify-content-end align-items-center">
                                                    <button type="button" className="btn btn-wh-1 btn-primary" disabled={AINVRules != true ? true : false} onClick={(e) => ApplyFilterRules(e)}>Filter</button>
                                                    <button type="button" className="btn btn-wh-1 btn-primary ms-2" onClick={resetPage}>Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <button type="button" disabled={AINVRules != true ? true : false} className="btn btn-wh-2 btn-primary mb-2" onClick={SaveRule}>Create Rule</button>
                                        </div>
                                        {/* <form>
                                            <div className="col-12 mb-2 text-end">
                                                <button type="button" className="btn btn-wh-2 btn-primary" onClick={(e) => OpenFileExplore()}>Import Rule</button>

                                            </div>
                                            <input
                                                ref={inputFile}
                                                style={{ display: "none" }} onChange={handleFileUpload}
                                                type="file"
                                            />
                                        </form> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <div className="d-flex justify-content-between" area-disabled={EINVRules != true ? true : false}>
                                                <h2 className="p-0 m-0 primarycolor">Inventory Product Listing</h2>
                                                <a target="_blank" type="button" className="btn btn-wh-1 btn-primary float-end" aria-disabled={EINVRules != true ? true : false} onClick={DownloadInventoryRule}>Download</a>
                                            </div>
                                        </div>
                                    </div>
                                    <Pagenation1 />
                                    <div className="row mb-3">
                                        <div className="col-sm-12 col-md-12">
                                            <div className={tablescrollsection == true ? "table-scroll-section-2" : ""}>
                                                <table className="table table-bordered">
                                                    <thead className="table-light sticky-top">
                                                        <tr>
                                                            <th>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" name="entities" checked={isAllChecked ? true : false} value="" onChange={(e) => handleAllClick(e)} />
                                                                </div>
                                                            </th>
                                                            <th>SKU</th>
                                                            <th>Agility UOM</th>
                                                            {/*<th>Customer ReferenceID</th>*/}
                                                            {/*<th>Customer UOM</th>                                                            */}
                                                            <th>Item Name </th>
                                                            <th>Brand</th>
                                                            <th>More Information </th>
                                                            {/* <th>Description</th>
                                                            <th>Brand</th>
                                                            <th>Price Group Major</th>
                                                            <th>Price Group Minor</th>
                                                            <th>Supplier</th>
                                                            <th>Product Group Major</th>
                                                            <th>Product Group Minor</th>
                                                            <th>Classification</th>
                                                            <th>LTL only</th>
                                                            <th>Channel</th> */}

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {

                                                            entitylist != null ? (
                                                                entitylist.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" checked={item.isselected ? true : false} type="checkbox" name="entities" value={item.sku} onChange={(e) => handleClick(item.sku.toString(), item.uom, e)} id={item.sku.toString()} />
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {item.sku}
                                                                        </td>
                                                                        <td>
                                                                            {item.uom}
                                                                        </td>
                                                                        {/*<td>*/}
                                                                        {/*    {item.asin}*/}
                                                                        {/*</td>*/}
                                                                        {/*<td>*/}
                                                                        {/*    {item.touom}*/}
                                                                        {/*</td>*/}
                                                                        <td>
                                                                            {item.displayDescription}
                                                                        </td>
                                                                        <td>
                                                                            {item.brand}
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                onClick={(e) => handleInfoClick(e, item)}
                                                                                className="attribute_icon"
                                                                            >
                                                                                <InfoIcon className="Icon_color_global" />
                                                                            </div>
                                                                        </td>
                                                                        {/* <td>
                                                                            {item.displayDescription}
                                                                        </td>
                                                                        <td>
                                                                            {item.brand}
                                                                        </td>
                                                                        <td>
                                                                            {item.pmax}
                                                                        </td>
                                                                        <td>
                                                                            {item.pmin}
                                                                        </td>
                                                                        <td>
                                                                            {item.supplierid}
                                                                        </td>
                                                                        <td>
                                                                            {item.productgrp}
                                                                        </td>
                                                                        <td>
                                                                            {item.pgroup}
                                                                        </td>
                                                                        <td>
                                                                            {item.classfication}
                                                                        </td>

                                                                        <td>
                                                                            {item.ltlonly}
                                                                        </td>
                                                                        <td>
                                                                            {item.nontradeitemflag}
                                                                        </td> */}
                                                                    </tr>
                                                                ))
                                                            ) : (<tr>
                                                                <td>
                                                                    <span>no records found</span>
                                                                </td>
                                                            </tr>)
                                                        }

                                                    </tbody>
                                                </table>
                                                {isPopover1Open && (<Popover
                                                    id={id}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "center",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center",
                                                    }}
                                                >

                                                    <div className="order_attribute_container">
                                                        <div>
                                                            {currentEntity != null && currentEntity.pmfg != null && (
                                                                <div>
                                                                    <span className="attribute_label">Mfg PartNumber:</span>
                                                                    <span className="attribute_Item">{currentEntity.pmfg}</span>
                                                                </div>
                                                            )}
                                                            {/*{currentEntity != null && currentEntity.brand != null && (*/}
                                                            {/*    <div>*/}
                                                            {/*        <span className="attribute_label">Brand:</span>*/}
                                                            {/*        <span className="attribute_Item">{currentEntity.brand}</span>*/}
                                                            {/*    </div>*/}
                                                            {/*)}*/}
                                                            {currentEntity != null && currentEntity.pmax != null && (
                                                                <div>
                                                                    <span className="attribute_label">Price Group Major:</span>
                                                                    <span className="attribute_Item">{currentEntity.pmax}</span>
                                                                </div>
                                                            )}
                                                            {currentEntity != null && currentEntity.pmin != null && (
                                                                <div>
                                                                    <span className="attribute_label">Price Group Minor:</span>
                                                                    <span className="attribute_Item">{currentEntity.pmin}</span>
                                                                </div>
                                                            )}
                                                            {currentEntity != null && currentEntity.supplierid != null && (
                                                                <div>
                                                                    <span className="attribute_label">Supplier:</span>
                                                                    <span className="attribute_Item">{currentEntity.supplierid}</span>
                                                                </div>
                                                            )}
                                                            {currentEntity != null && currentEntity.productgrp != null && (
                                                                <div>
                                                                    <span className="attribute_label">Product Group Major:</span>
                                                                    <span className="attribute_Item">{currentEntity.productgrp}</span>
                                                                </div>
                                                            )}
                                                            {currentEntity != null && currentEntity.pgroup != null && (
                                                                <div>
                                                                    <span className="attribute_label">Product Group Minor:</span>
                                                                    <span className="attribute_Item">{currentEntity.pgroup}</span>
                                                                </div>
                                                            )}
                                                            {currentEntity != null && currentEntity.classfication != null && (
                                                                <div>
                                                                    <span className="attribute_label">Classification:</span>
                                                                    <span className="attribute_Item">{currentEntity.classfication}</span>
                                                                </div>
                                                            )}
                                                            {currentEntity != null && currentEntity.ltlonly != null && (
                                                                <div>
                                                                    <span className="attribute_label">LTL only:</span>
                                                                    <span className="attribute_Item">{currentEntity.ltlonly}</span>
                                                                </div>
                                                            )}
                                                            {currentEntity != null && currentEntity.nontradeitemflag != null && (
                                                                <div>
                                                                    <span className="attribute_label">Channel:</span>
                                                                    <span className="attribute_Item">{currentEntity.nontradeitemflag}</span>
                                                                </div>
                                                            )}


                                                        </div>
                                                    </div>
                                                    {/* <MyAttributes attributes={attributes} /> */}
                                                </Popover>)}

                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="row">*/}
                                    {/*    <div className="col">*/}
                                    {/*        <div className="">*/}
                                    {/*            <button type="button" className="btn btn-primary" disabled={EInventoryRules != true ? true : false} onClick={SaveRule}>Add Rule</button>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}


                                    {/*<div className="row g-2 align-items-center mb-4" style={stateval == 'clicked' ? { display: 'block' } : { display: 'none' }}>*/}
                                    {/*    <div className="col-3">*/}
                                    {/*        <label className="col-form-label">AccountNumber</label>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="col-3">*/}
                                    {/*        {*/}
                                    {/*            mpacclist != null ? (*/}
                                    {/*                mpacclist.map((item, index) => (*/}
                                    {/*                    <div className="form-check">*/}
                                    {/*                        <input className="form-check-input" type="checkbox" name="entities" value={item.id} onChange={(e) => handleMPAccClick(item.customerId, e)} id={item.customerId.toString()} />*/}
                                    {/*                        <span>{item.message}</span>*/}
                                    {/*                    </div>*/}
                                    {/*                ))) : (null)*/}
                                    {/*        }*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className={activecatclass == false ? "" : "hidden"}>
                                <div className="tab-content">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12">
                                            <h2 className="pb-2 primarycolor">Rule Filters</h2>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="section-border-new">
                                                        <div className="row">
                                                            {
                                                                filter != null ? (
                                                                    filter.map((item, index) => (

                                                                        item.ftid == 3 ? <div className="col-6 mb-2">
                                                                            <div className="row g-3 align-items-end">
                                                                                <div className="col-6">
                                                                                    <label className="form-label">From SKU</label>
                                                                                    <input type="text" onChange={changeEFilterSku} placeholder="" className="form-control" value={efsku} />
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <label className="form-label">To SKU</label>
                                                                                    <input type="text" onChange={changeEFilterTSku} placeholder="" className="form-control" value={etsku} />
                                                                                </div>

                                                                            </div>
                                                                        </div> :
                                                                            item.ftid == 4 ? <div className="col-6 mb-2">
                                                                                <div className="row g-3 align-items-end">
                                                                                    <div className="col-6">
                                                                                        <label className="form-label">Start Date</label>
                                                                                        <input type="datetime-local" className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeSearchDate} />

                                                                                    </div>
                                                                                    <div className="col-6">
                                                                                        <label className="form-label">End Date</label>
                                                                                        <input type="datetime-local" className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeSearchEndDate} />
                                                                                    </div>

                                                                                </div>
                                                                            </div> :

                                                                                // item.ftid == 1 ? <div className="col-3 mb-2">
                                                                                //     <label className="form-label">
                                                                                //         {item.name}
                                                                                //     </label>
                                                                                //     <select className="form-select" onChange={(e) => changeEFilters(item.id, item.name, e)}>
                                                                                //         <option selected value={""}>Allss</option>
                                                                                //         {
                                                                                //             item.filtersvalues != null ? (
                                                                                //                 item.filtersvalues.map((itemval, index) => (
                                                                                //                     <option value={itemval.key}>{itemval.value}</option>
                                                                                //                 ))
                                                                                //             ) : (null)
                                                                                //         }
                                                                                //     </select>
                                                                                // </div> : ""

                                                                                // item.ftid == 1 ? <div className="col-3 mb-2">
                                                                                //     <label className="form-label">
                                                                                //         {item.name}
                                                                                //     </label>
                                                                                //     <Multiselect
                                                                                //         isObject={false}
                                                                                //         displayValue="name"
                                                                                //         placeholder="All"

                                                                                //         onKeyPressFn={function noRefCheck() { }}
                                                                                //         // onRemove={handleRemove}
                                                                                //         onRemove={(selectedList, selectedItem) => handleRemoveExisting(selectedList, selectedItem, item.id)}
                                                                                //         onSearch={function noRefCheck() { }}
                                                                                //         // onSelect={handleSelect}
                                                                                //         onSelect={(selectedList, selectedItem) => handleSelectExisting(selectedList, selectedItem, item.id)}


                                                                                //         options={item.filtersvalues.map(x => x.value)}
                                                                                //     />
                                                                                // </div> : ""
                                                                                item.ftid == 1 ? <div className="col-3 mb-2">
                                                                                    <label className="form-label">
                                                                                        {item.name}
                                                                                    </label>
                                                                                    <div onClick={loadOptionsExisting} data-d-id={item.id}>
                                                                                        <Multiselect
                                                                                            isObject={false}
                                                                                            displayValue="name"
                                                                                            // placeholder="All"

                                                                                            onKeyPressFn={function noRefCheck() { }}
                                                                                            // onRemove={handleRemove}
                                                                                            onRemove={(selectedList, selectedItem) => handleRemoveExisting(selectedList, selectedItem, item.id)}
                                                                                            onSearch={function noRefCheck() { }}
                                                                                            // onSelect={handleSelect}
                                                                                            onSelect={(selectedList, selectedItem) => handleSelectExisting(selectedList, selectedItem, item.id)}

                                                                                            options={options}
                                                                                            loading={isLoading}
                                                                                        // options={item.filtersvalues.map(x => x.value)}
                                                                                        />
                                                                                    </div>
                                                                                </div> : ""

                                                                    ))
                                                                ) : (null)
                                                            }
                                                        </div>

                                                        {/*<div className="row">*/}
                                                        {/*    <div className="col-6 mb-3">*/}
                                                        {/*        <div className="row g-3 align-items-center">*/}
                                                        {/*            <div className="col-5">*/}
                                                        {/*                <label className="form-label">SKU /Customer ReferenceID</label>*/}
                                                        {/*            </div>*/}
                                                        {/*            <div className="col-5">*/}
                                                        {/*                <input type="text" onChange={changeSearchSku} placeholder="" className="form-control" value={sku} />*/}
                                                        {/*            </div>*/}
                                                        {/*            <div className="col-2">*/}
                                                        {/*                <button type="button" className="btn btn-outline-primary" onClick={SearchInventory}>Search</button>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="col-3 mb-3">*/}
                                                        {/*        <div className="row g-2 align-items-center">*/}
                                                        {/*            <div className="col-4">*/}
                                                        {/*                <label className="col-form-label">Start Date</label>*/}
                                                        {/*            </div>*/}
                                                        {/*            <div className="col-8">*/}
                                                        {/*                <input type="datetime-local" className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeSearchDate} />*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="col-3 mb-3">*/}
                                                        {/*        <div className="row g-2 align-items-center">*/}
                                                        {/*            <div className="col-4">*/}
                                                        {/*                <label className="col-form-label">End Date</label>*/}
                                                        {/*            </div>*/}
                                                        {/*            <div className="col-8">*/}
                                                        {/*                <input type="datetime-local" className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeSearchEndDate} />*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="row">*/}
                                                        {/*    <div className="col-3 mb-2">*/}
                                                        {/*        <label className="form-label">Rule Type</label>*/}
                                                        {/*        <select className="form-select" aria-label="Default select example" onChange={changerule}>*/}
                                                        {/*            <option selected value="">Select</option>*/}
                                                        {/*            <option value="1">Global-All</option>*/}
                                                        {/*            <option value="2">Marketplace-Accounts</option>*/}
                                                        {/*            <option value="4">Marketplace Warehouses</option>*/}
                                                        {/*        </select>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="col-3 mb-2">*/}
                                                        {/*        <label className="form-label">*/}
                                                        {/*            Account number*/}
                                                        {/*        </label>*/}
                                                        {/*        <select className="form-select" onChange={(e) => changeacc(e)}>*/}
                                                        {/*            <option selected value="">Select</option>*/}
                                                        {/*            {*/}
                                                        {/*                acclist.map((item, index) => (*/}
                                                        {/*                    <option value={item.customerId}>{item.message}</option>*/}
                                                        {/*                ))*/}
                                                        {/*            }*/}
                                                        {/*        </select>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="col-3 mb-2">*/}
                                                        {/*        <label className="form-label">*/}
                                                        {/*            Warehouse*/}
                                                        {/*        </label>*/}
                                                        {/*        <select className="form-select" onChange={(e) => changelocation(e)}>*/}
                                                        {/*            <option selected value="">Select</option>*/}
                                                        {/*            {*/}
                                                        {/*                loclist.map((item, index) => (*/}
                                                        {/*                    <option value={item.resultid}>{item.name}</option>*/}
                                                        {/*                ))*/}
                                                        {/*            }*/}
                                                        {/*        </select>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="col-3 mb-2">*/}
                                                        {/*        <label className="form-label">*/}
                                                        {/*            Supplier*/}
                                                        {/*        </label>*/}
                                                        {/*        <select className="form-select" onChange={(e) => changesupplier(e)}>*/}
                                                        {/*            <option selected value="">Select</option>*/}
                                                        {/*            {*/}
                                                        {/*                suppList.map((item, index) => (*/}
                                                        {/*                    <option value={item.entityId}>{item.entityName}</option>*/}
                                                        {/*                ))*/}
                                                        {/*            }*/}
                                                        {/*        </select>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="col-3 mb-2">*/}
                                                        {/*        <label className="form-label">*/}
                                                        {/*            Product Group*/}
                                                        {/*        </label>*/}
                                                        {/*        <select className="form-select" onChange={(e) => changeprdgrp(e)}>*/}
                                                        {/*            <option selected value="">Select</option>*/}
                                                        {/*            {*/}
                                                        {/*                Prdgrplist.map((item, index) => (*/}
                                                        {/*                    <option value={item.name}>{item.name}</option>*/}
                                                        {/*                ))*/}
                                                        {/*            }*/}
                                                        {/*        </select>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="col-3 mb-2">*/}
                                                        {/*        <label className="form-label">*/}
                                                        {/*            Price Max*/}
                                                        {/*        </label>*/}
                                                        {/*        <select className="form-select" onChange={(e) => changeprimax(e)}>*/}
                                                        {/*            <option selected value="">Select</option>*/}
                                                        {/*            {*/}
                                                        {/*                Pricemaxlist.map((item, index) => (*/}
                                                        {/*                    <option value={item.name}>{item.name}</option>*/}
                                                        {/*                ))*/}
                                                        {/*            }*/}
                                                        {/*        </select>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="col-3 mb-2">*/}
                                                        {/*        <label className="form-label">*/}
                                                        {/*            Price Min*/}
                                                        {/*        </label>*/}
                                                        {/*        <select className="form-select" onChange={(e) => changeprimin(e)}>*/}
                                                        {/*            <option selected value="">Select</option>*/}
                                                        {/*            {*/}
                                                        {/*                Priceminlist.map((item, index) => (*/}
                                                        {/*                    <option value={item.name}>{item.name}</option>*/}
                                                        {/*                ))*/}
                                                        {/*            }*/}
                                                        {/*        </select>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="col-3 mb-2">*/}
                                                        {/*        <label className="form-label">*/}
                                                        {/*            Manufacture*/}
                                                        {/*        </label>*/}
                                                        {/*        <select className="form-select" onChange={(e) => changemfgname(e)}>*/}
                                                        {/*            <option selected value="">Select</option>*/}
                                                        {/*            {*/}
                                                        {/*                mfglist.map((item, index) => (*/}
                                                        {/*                    <option value={item.name}>{item.name}</option>*/}
                                                        {/*                ))*/}
                                                        {/*            }*/}
                                                        {/*        </select>*/}
                                                        {/*    </div>*/}
                                                        {/*    <div className="col-3 mb-2">*/}
                                                        {/*        <label className="form-label">Validity</label>*/}
                                                        {/*        <select className="form-select" aria-label="Default select example" onChange={changevalidity}>*/}
                                                        {/*            <option selected value="">Select</option>*/}
                                                        {/*            <option value="0">Active</option>*/}
                                                        {/*            <option value="1">1-Week </option>*/}
                                                        {/*            <option value="2">Expired</option>*/}
                                                        {/*        </select>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        <div className="row">
                                                            <div className="col-12 mb-3">
                                                                <div className="d-flex justify-content-end align-items-center">
                                                                    <button type="button" disabled={AINVRules != true ? true : false} className="btn btn-wh-1 btn-primary" onClick={(e) => ApplyRules(e)}>Filter</button>
                                                                    <button type="button" className="btn btn-wh-1 btn-primary ms-2" onClick={resetEPage}>Reset</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 mb-3">
                                                    <div className="d-flex justify-content-end align-items-center" area-disabled={AINVRules != true ? true : false}>                                                        
                                                        <a target="_blank" type="button" className="btn btn-wh-1 btn-primary float-end" aria-disabled={EINVRules != true ? true : false} onClick={DownloadEInventoryRule}>Download</a>

                                                        {/*    <a target='_blank' href={`api/MP/ExPInvExport?json=${JSON.stringify(filterobj)}`} type="button" className="btn btn-wh-1 btn-primary float-end">Download</a>*/}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                            <h2 className="primarycolor">Products</h2>
                                                <button type="button" className="btn btn-sm btn-danger" onClick={DeactivateAlert}>Deactivate Rule</button>
                                            </div>
                                            <Pagenation />
                                            <div className="table-scroll-section-2" ref={scrollRef} >
                                                <table className="table table-bordered">
                                                    <thead className="table-light sticky-top">
                                                        <tr>
                                                            <th>
                                                                <div>
                                                                    <Checkbox
                                                                        onChange={(e) => handleCheckAll(e.target.checked)}
                                                                        {...label}
                                                                        checked={checkAll}
                                                                    ></Checkbox>

                                                                </div>
                                                            </th>
                                                            <th scope="col">
                                                                ID
                                                            </th>
                                                            <th scope="col">
                                                                Validity
                                                            </th>
                                                            {/*<th scope="col">*/}
                                                            {/*    EntityId*/}
                                                            {/*</th>*/}
                                                            <th scope="col">
                                                                SKU
                                                            </th>
                                                            <th scope="col">
                                                                Customer Reference ID
                                                            </th>
                                                            <th scope="col">
                                                                Reserved Qty
                                                            </th>
                                                            <th scope="col">
                                                                Fixed Quantity
                                                            </th>
                                                            <th scope="col">
                                                                Ruletype
                                                            </th>
                                                            <th scope="col">
                                                                Account Number
                                                            </th>
                                                            <th scope="col">
                                                                Branch
                                                            </th>
                                                            <th scope="col">
                                                                (%)
                                                            </th>
                                                            <th scope="col">
                                                                Value
                                                            </th>
                                                            <th scope="col">
                                                                Agility UOM
                                                            </th>
                                                            <th scope="col">
                                                                More Information
                                                            </th>

                                                            {/* <th scope="col">
                                                                Brand
                                                            </th>
                                                            <th scope="col">Product Group Major</th>
                                                            <th scope="col">Product Group Minor</th>
                                                            <th scope="col">Price Group Major</th>
                                                            <th scope="col">Price Group Miror</th>
                                                            <th scope="col">Supplier</th>
                                                            <th scope="col">
                                                                StartDate
                                                            </th>
                                                            <th scope="col">
                                                                EndDate
                                                            </th> */}

                                                            {/*<th scope="col">*/}
                                                            {/*    Date*/}
                                                            {/*</th>*/}
                                                            <th scope="col">
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            entylist != null ? (
                                                                entylist.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <div>
                                                                                <Checkbox
                                                                                    checked={item.ischeckall}
                                                                                    {...label}
                                                                                    onChange={(e) => handleChangeCheckbox(e, index)}
                                                                                ></Checkbox>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {item.id}
                                                                        </td>
                                                                        <td>
                                                                            {item.validity == "0" ? <span className="dot green" title="Active"></span> : item.validity == "1" ? <span className="dot orange" title="1-Week"></span> : item.validity == "2" ? <span className="dot gray" title="Expired"></span> : ""}
                                                                        </td>
                                                                        <td>
                                                                            {item.entityName}
                                                                        </td>
                                                                        <td>
                                                                            {item.type}
                                                                        </td>
                                                                        <td>
                                                                            {item.reservedqty}
                                                                        </td>
                                                                        <td>
                                                                            {item.stage}
                                                                        </td>
                                                                        <td>
                                                                            {item.jobName}
                                                                        </td>
                                                                        <td>
                                                                            {item.accountname}
                                                                        </td>
                                                                        <td>
                                                                            {item.locationname}
                                                                        </td>
                                                                        <td>
                                                                            {item.isActive == true ? (<span>true</span>) : (<span>false</span>)}
                                                                        </td>
                                                                        <td>
                                                                            {item.status}
                                                                        </td>
                                                                        <td>
                                                                            {item.uom}
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                onClick={(e) => handleInfoClickExisting(e, item)}
                                                                                className="attribute_icon"
                                                                            >
                                                                                <InfoIcon className="Icon_color_global" />
                                                                            </div>
                                                                        </td>
                                                                        {/* <td>
                                                                            {item.brand}
                                                                        </td>
                                                                        <td>
                                                                            {item.pgroup}
                                                                        </td>
                                                                        <td>
                                                                            {item.productgrp}
                                                                        </td>
                                                                        <td>
                                                                            {item.pmax}
                                                                        </td>
                                                                        <td>
                                                                            {item.pmin}
                                                                        </td>
                                                                        <td>
                                                                            {item.pmfg}
                                                                        </td>
                                                                        <td>
                                                                            {item.startdate != null ? moment(item.startdate).format('MM/DD/YYYY H:mm a') : ""}
                                                                        </td>
                                                                        <td>
                                                                            {item.enddate != null ? moment(item.enddate).format('MM/DD/YYYY H:mm a') : ""}
                                                                        </td> */}

                                                                        {/*<td>*/}
                                                                        {/*    {item.createdDate}*/}
                                                                        {/*</td>*/}

                                                                        <td>
                                                                            <button className="btn btn-sm btn-danger" disabled={DINVRules != true ? true : false} data-bs-toggle="tooltip" data-bs-placement="top" title="DeActivate Rule" onClick={(e) => DeleteAlert(item.id)} ><i className="bi-archive"></i></button>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (null)
                                                        }
                                                    </tbody>
                                                </table>
                                                {isPopover2Open && (<Popover
                                                    id={id}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "center",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center",
                                                    }}
                                                >
                                                    <div className="order_attribute_container">
                                                        <div>
                                                            {currentEntityExisting != null && currentEntityExisting.brand != null && (
                                                                <div>
                                                                    <span className="attribute_label">Brand:</span>
                                                                    <span className="attribute_Item">{currentEntityExisting.brand}</span>
                                                                </div>
                                                            )}
                                                            {currentEntityExisting != null && currentEntityExisting.pgroup != null && (
                                                                <div>
                                                                    <span className="attribute_label">Product Group Major:</span>
                                                                    <span className="attribute_Item">{currentEntityExisting.pgroup}</span>
                                                                </div>
                                                            )}
                                                            {currentEntityExisting != null && currentEntityExisting.productgrp != null && (
                                                                <div>
                                                                    <span className="attribute_label">Product Group Minor:</span>
                                                                    <span className="attribute_Item">{currentEntityExisting.productgrp}</span>
                                                                </div>
                                                            )}
                                                            {currentEntityExisting != null && currentEntityExisting.pmax != null && (
                                                                <div>
                                                                    <span className="attribute_label">Price Group Major:</span>
                                                                    <span className="attribute_Item">{currentEntityExisting.pmax}</span>
                                                                </div>
                                                            )}
                                                            {currentEntityExisting != null && currentEntityExisting.pmin != null && (
                                                                <div>
                                                                    <span className="attribute_label">Price Group Minor:</span>
                                                                    <span className="attribute_Item">{currentEntityExisting.pmin}</span>
                                                                </div>
                                                            )}
                                                            {currentEntityExisting != null && currentEntityExisting.pmfg != null && (
                                                                <div>
                                                                    <span className="attribute_label">Supplier:</span>
                                                                    <span className="attribute_Item">{currentEntityExisting.pmfg}</span>
                                                                </div>
                                                            )}
                                                            {currentEntityExisting != null && currentEntityExisting.startdate != null && (
                                                                <div>
                                                                    <span className="attribute_label">StartDate:</span>
                                                                    <span className="attribute_Item">{moment(currentEntityExisting.startdate).format('MM/DD/YYYY H:mm a')}</span>
                                                                </div>
                                                            )}
                                                            {currentEntityExisting != null && currentEntityExisting.enddate != null && (
                                                                <div>
                                                                    <span className="attribute_label">EndDate:</span>
                                                                    <span className="attribute_Item">{moment(currentEntityExisting.enddate).format('MM/DD/YYYY H:mm a')}</span>
                                                                </div>
                                                            )}







                                                        </div>
                                                    </div>

                                                </Popover>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div onClick={ClosePopup}>
                        {
                            isloader.isfailed ? (
                                <AFailed message={isloader.message} />
                            ) : (null)
                        }
                    </div>
                    <div onClick={ClosePopup}>
                        {
                            isloader.isSuccess ? (
                                <ASuccess message={isloader.message} />
                            ) : (null)
                        }
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
export default inventoryRules;


