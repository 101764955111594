import { fail } from "assert"


export interface user {
    id: number,
    userName: string,
    password: string,
    emailId: string,
    isActive: boolean,
    passwordhash: string,
    isSuccess: boolean,
    role: boolean,
    roleId:number
    createdon: Date,
    customerId: number
    index: number;
    recordPerPage: number;
    searchValue: string;
    totalRecord: number;
    moduleinfo: Array<moduleinfo>;
    apitoken: string;
    roleName:string;
}

export interface moduleinfo {
    moduleid: number,
    maid:number,
    appname: string,
    modulename: string,
    permission: string   
}
export const createinitialUser = (): user => {
    return ({
        id: 0,
        userName: "",
        password: "",
        emailId: "",
        isActive: false,
        passwordhash: "",
        isSuccess: false,
        role: false,
        roleId:0,
        createdon: new Date(),
        customerId: 0,
        index: 1,
        recordPerPage: 50,
        searchValue: "",
        totalRecord: 0,
        moduleinfo: [],
        apitoken :"",
        roleName:""
    })
}

