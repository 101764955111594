import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import Globals from '../dataservices/global';
import SideBar from './sidebar';
export default (props: { children?: React.ReactNode }) => (


    <React.Fragment>
        <NavMenu />
        <Container>
            <div className="main-section">
                {props.children}
            </div>
        </Container>
        <footer className="py-1 footer-bg text-center">
            <p className="m-0">Version: 1.2.0</p>
        </footer>
    </React.Fragment>
);
