import React, { useEffect, useState } from "react";

import { generatePath, Link } from "react-router-dom";
import { NavLink } from "reactstrap";
import { useHistory } from 'react-router-dom';
import Globals from "../dataservices/global";
import { moduleinfo } from "../dataservices/model/user";
import { GetViewPermission } from "../dataservices/action/account/register";
import { channellist } from "../dataservices/model/channellist";
import { GetAllMarketplaces, Getrolepermissionbyuserid } from "../dataservices/action/marketplace/marketplace";

const SideBar = (props: any) => {
    const [isSyncSubMenu, setisSyncSubMenu] = useState(false);
    const history = useHistory();

    const [magentoConnect, setmagentoConnect] = useState(false);
    const [amazonMp, setamazonMp] = useState(false);

    var intAcclist: channellist[] = new Array<channellist>();
    const [acclist, setacclist] = useState(intAcclist);

    var vperList: moduleinfo[] = new Array<moduleinfo>();
    const [VPList, setVPList] = useState(vperList);
    const [isLoader, setisLoader] = useState(false);
    const accid = Globals.accountid;
    const [setup, setissetup] = useState("/setup/" + accid);
    const [product, setisproduct] = useState("/productsindex/" + accid);
    const [logs, setislogs] = useState("/mpjoblist/" + accid);
    const [activeclass, setactiveclass] = useState("nav-link active");
    const [deactiveclass, setdeactiveclass] = useState("nav-link");
    const [issetup, setsetup] = useState(false);
    const [isproduct, setproduct] = useState(false);
    const [islogs, setlogs] = useState(false);

    //view
    const [VDashboard, setVDashboard] = useState(false);
    const [VCategoryAttributes, setVCategoryAttributes] = useState(false);
    const [VAPIsettings, setVAPIsettings] = useState(false);
    const [VMagentoSync, setVMagentoSync] = useState(false);
    const [VInriverSync, setVInriverSync] = useState(false);
    const [VProducts, setVProducts] = useState(false);
    const [VInventoryRules, setVInventoryRules] = useState(false);
    const [Vlogs, setVlogs] = useState(false);
    const [VSettings, setVSettings] = useState(false);
    const [accname, setaccname] = useState("");




    let magentoCnt = true;

    useEffect(() => {
        let expiresAt = localStorage.getItem('IsAuthendicated');
        if (expiresAt !== "true") {
            history.push(`/joblist`);
        }
    }, [])

    useEffect(() => {
        setisLoader(true);
        GetAllMarketplaces(function (result: any) {
            if (result.length > 0) {
                setacclist(result);
                for (var i = 0; i < result.length; i++) {
                    if (result[i].customerId == Number(accid)) {
                        setaccname(result[i].message);
                    }
                }
                setisLoader(false);
            }
        })
    }, [])

    useEffect(() => {
        setisLoader(true);
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 2:
                                if (result[i].canview == "1") {
                                    setVDashboard(true);
                                }
                                break;

                            case 3:
                                if (result[i].canview == "1") {
                                    setVCategoryAttributes(true);
                                } break;

                            case 4:
                                if (result[i].canview == "1") {
                                    setVAPIsettings(true);
                                } break;
                            case 5:
                                if (result[i].canview == "1") {
                                    setVMagentoSync(true);
                                } break;
                            case 6:
                                if (result[i].canview == "1") {
                                    setVInriverSync(true);
                                } break;


                        }
                    }
                }
            }
        })
    }, [])




    const Showhidesubmenu = () => {
        setisSyncSubMenu(!isSyncSubMenu);
    }

    const IsActiveMenu = () => {
        const allWithClass = Array.from(
            document.querySelectorAll('nav-link')
        );

        allWithClass.forEach(element => {
            element.classList.remove("active");
        });
    }
    const handleProceed = () => {
        history.push(generatePath("/setup/:accid", { accid }));
    };

    return (
        <div>
            <div className="sidebar-section">
                <ul className="nav flex-column">
                    {

                        magentoCnt == true ? (
                            <>
                                {
                                    VDashboard == true ? (
                                        <>
                                            <li className="nav-item">
                                                <Link to="/dashboard" className="nav-link active">
                                                    Dashboard
                                                </Link>
                                            </li>
                                        </>
                                    ) : (null)
                                }
                                {
                                    VCategoryAttributes == true ? (
                                        <>
                                            <li className="nav-item">
                                                <Link to="addattribute">
                                                    <a className="nav-link" href="/addattribute">
                                                        Category Attributes
                                                    </a>
                                                </Link>

                                            </li>
                                        </>
                                    ) : (null)
                                }
                                {
                                    VAPIsettings == true ? (
                                        <>
                                            <li className="nav-item">
                                                <Link to="apisettings">
                                                    <a className="nav-link" href="/apiSettings">
                                                        API Settings
                                                    </a>
                                                </Link>
                                            </li>
                                        </>
                                    ) : (null)
                                }
                                {
                                    VInriverSync == true || VMagentoSync == true ?

                                        <li className="nav-item">
                                            <a className="nav-link" onClick={Showhidesubmenu}>
                                                <i className="bi bi-caret-right-fill"></i> Sync
                                            </a>
                                            {
                                                isSyncSubMenu ? (<ul>
                                                    {
                                                        VInriverSync == true ? (
                                                            <>
                                                                <li>
                                                                    <Link to="/synchistory" className="nav-link">InRiver Sync</Link>
                                                                </li>
                                                            </>
                                                        ) : (null)
                                                    }
                                                    {
                                                        VMagentoSync == true ? (
                                                            <>
                                                                <li>
                                                                    <Link to="/magentosynchistory" className="nav-link">Magento Sync</Link>
                                                                </li>
                                                            </>
                                                        ) : (null)
                                                    }
                                                </ul>) : (null)
                                            }
                                        </li> : (null)
                                }
                            </>
                        ) : (null)
                    }
                    {/*{*/}

                    {/*    magentoCnt != true ? (*/}
                    {/*        <>*/}
                    {/*            {*/}
                    {/*                <li className="nav-item b-l-0">*/}
                    {/*                    <div className="head-accno">*/}
                    {/*                        Account No: <span>{accname}</span>*/}
                    {/*                    </div>*/}
                    {/*                </li>*/}
                    {/*            }*/}
                    {/*            {*/}
                    {/*                VProducts == true ? (*/}
                    {/*                    <>*/}
                    {/*                        <li className="nav-item">*/}
                    {/*                            <Link to={product} className={activeclass}>*/}
                    {/*                                <i className="bi bi-file-earmark-ppt-fill"></i>Product Listing*/}
                    {/*                            </Link>*/}
                    {/*                        </li>*/}
                    {/*                    </>*/}
                    {/*                ) : (null)*/}
                    {/*            }*/}
                    {/*            {*/}

                    {/*                Vlogs == true ? (*/}
                    {/*                    <>*/}
                    {/*                        <li className="nav-item">*/}
                    {/*                            <Link to={logs} className={activeclass}>*/}
                    {/*                                <i className="bi bi-person-lines-fill"></i>Logs*/}
                    {/*                            </Link>*/}
                    {/*                        </li>*/}
                    {/*                    </>*/}
                    {/*                ) : (null)*/}
                    {/*            }*/}
                    {/*            {*/}

                    {/*                VSettings == true ? (*/}
                    {/*                    <>*/}
                    {/*                        <li className="nav-item">*/}
                    {/*                            <Link to={setup} className={activeclass}>*/}
                    {/*                                <i className="bi bi-gear-fill"></i>Settings*/}
                    {/*                            </Link>*/}
                    {/*                        </li>*/}
                    {/*                    </>*/}
                    {/*                ) : (null)*/}
                    {/*            }*/}
                    {/*        </>*/}
                    {/*    ) : (null)*/}
                    {/*}*/}
                    {/*{*/}

                    {/*    magentoCnt != true ? (*/}
                    {/*        <>*/}
                    {/*            {*/}

                    {/*                VSettings == true ? (*/}
                    {/*                    <>*/}
                    {/*                        <li className="nav-item">*/}
                    {/*                            <Link to={setup} className="nav-link active">*/}
                    {/*                                Settings*/}
                    {/*                            </Link>*/}
                    {/*                        </li>*/}
                    {/*                    </>*/}
                    {/*                ) : (null)*/}
                    {/*            }*/}
                    {/*            {*/}
                    {/*                VProducts == true ? (*/}
                    {/*                    <>*/}
                    {/*                        <li className="nav-item">*/}
                    {/*                            <Link to={product} className="nav-link active">*/}
                    {/*                                Product Listing*/}
                    {/*                            </Link>*/}
                    {/*                        </li>*/}
                    {/*                    </>*/}
                    {/*                ) : (null)*/}
                    {/*            }*/}
                    {/*            {*/}

                    {/*                Vlogs == true ? (*/}
                    {/*                    <>*/}
                    {/*                        <li className="nav-item">*/}
                    {/*                            <Link to={logs} className="nav-link active">*/}
                    {/*                                Logs*/}
                    {/*                            </Link>*/}
                    {/*                        </li>*/}
                    {/*                    </>*/}
                    {/*                ) : (null)*/}
                    {/*            }*/}

                    {/*        </>*/}
                    {/*    ) : (null)*/}
                    {/*}*/}
                </ul>
            </div>
        </div>
    )
}



export default SideBar;