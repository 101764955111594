import React, { useEffect, useState } from "react";
import { job, createinitialjob } from "../../dataservices/model/joblist";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../sidebar";
import { PaginationValues } from "../pagenation/pageflow";
import { useHistory } from 'react-router-dom';
import Loader from "../loader";
import { debug } from "console";
import { AgilitySyncDate, GetagilityJobList, GetAgilityLatestSyncDate, GetAgilityscheduledSync, GetAgilitySyncPauseval, GetAllMarketplaces, GetMPJobList, GetMPLatestSyncDate, GetMpSyncPauseval, Getrolepermissionbyuserid, PauseagilitySync, PauseMPSync, SyncMPDate } from "../../dataservices/action/marketplace/marketplace";
import { CreateinitialLoadermessage, Loadermessage } from "../../dataservices/model/loadermessage";
import moment from "moment";
import AFailed from "../alretbox/failed";
import ASuccess from "../alretbox/success";
import { channellist } from "../../dataservices/model/channellist";
import { jobDetails } from "../../dataservices/model/jobDetails";
import { scheduledSync } from "../../dataservices/model/scheduledSync";
import { moduleinfo } from "../../dataservices/model/user";
import { GetEditPermission } from "../../dataservices/action/account/register";


const agilityjobList = (props: any) => {
    var intjob: job[] = new Array<job>();
    const [joblist, SetJoblist] = useState(intjob);
    const storeJob = useSelector((state: any) => { return state.JobList });
    const dispatch = useDispatch();
    const [TotalCount, setTotalCount] = useState(0);
    const history = useHistory();
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [PageSizeList, setPageSizeList] = useState([50, 100, 200]);
    const [SearchValue, setSearchValue] = useState("");
    const [isloader, setisloader] = useState(false);
    const [activePage, setActivePage] = useState(0);
    const [ECategoryAttributes, setVCategoryAttributes] = useState(false);
    const [EAPIsettings, setVAPIsettings] = useState(false);
    const [EMagentoSync, setVMagentoSync] = useState(false);
    const [EInriverSync, setVInriverSync] = useState(false);
    const [EProducts, setVProducts] = useState(false);
    const [EInventoryRules, setVInventoryRules] = useState(false);
    const [Elogs, setVlogs] = useState(false);
    const [ESettings, setVSettings] = useState(false);
    const [isDisabled, setisDisabled] = useState("disabled");
    var vperList: moduleinfo[] = new Array<moduleinfo>();
    const [VPList, setVPList] = useState(vperList);

    const [AAginv, setAAginv] = useState(false);
    const [EAginv, setEAginv] = useState(false);
    const [DAginv, setDAginv] = useState(false);
    const [CSAginv, setCSAginv] = useState(false);
    useEffect(() => {
        debugger;
        Getrolepermissionbyuserid(1, function (result: any) {            
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 21:                                
                                if (result[i].canadd == "1") {
                                    setAAginv(true);
                                }
                                if (result[i].canedit == "1") {
                                    setEAginv(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDAginv(true);
                                }
                                if (result[i].cansync == "1") {
                                    setCSAginv(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])

    useEffect(() => {
        setisloader(true);
        GetagilityJobList(Page, PageSize, function (result: job[]) {
            if (result.length != 0) {
                dispatch(
                    {
                        type: "GET_JOBLIST_COMPLETED",
                        payload: result
                    })
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                SetJoblist(result);
                setActivePage(Page)
                setisloader(false);
            } else {
                setisloader(false);
            }
        })
    }, [])

    const Details = (id: number) => {
        props.history.push({
            pathname: '/agilitytaskdetails',
            state: id
        });

    }
    const Pagenation = () => {
        let Pagination = new PaginationValues();
        Pagination.TotalItemCount = TotalCount;
        Pagination.CurrentPage = Page;
        Pagination.PageSlide = 2;
        Pagination.IsSort = true;
        Pagination.PageSize = PageSize;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page - 1) * PageSize) + 1}    - {((((Page - 1) * PageSize) + 1) + PageSize) > TotalCount ? TotalCount : ((((Page - 1) * PageSize)) + PageSize)} of {TotalCount} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                                {
                                    Pagination.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination.Pages().map((x, i) =>
                                        x == Page ?
                                            (
                                                <li className={`page-item  ${activePage == x ? 'active' : null}`} key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className="page-link" onClick={() => SetPage(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
    const SetPageSize = (size: number) => {
        setPageSize(size);
        GetagilityJobList(Page, size, function (result: job[]) {
            if (result != null) {
                SetJoblist(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
            }
        })
    }

    const SetPage = (page: number) => {
        setPage(page);
        GetagilityJobList(page, PageSize, function (result: job[]) {
            if (result != null) {
                SetJoblist(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(page)
            }
        })
    }

    const Refresh = () => {
        let PoNumber: string = "";
        let OrderNumber: number = 0;
        let InvoiceNumber: string = "";
        if (SearchValue != "") {
        }
        GetagilityJobList(Page, PageSize, function (result: job[]) {
            if (result != null) {
                SetJoblist(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
            }
        })
    }
    const Back = () => {
        history.push(`/userdashboard`);
    }
    const [isDate, SetisDate] = useState(false);
    const [isVendorDate, SetisVendorDate] = useState(true);

    const [jobType, SetselectjobType] = useState(1);
    const [isLoader, SetisLoader] = useState(CreateinitialLoadermessage());
    const [isBindTime, setBindTime] = useState(false);
    const [selectedTime, setSelectedTime] = useState("");
    const [deltaDate, SetselectdeltaDate] = useState(new Date());
    const [isBindDeltaDate, setBindDeltaDate] = useState(false);
    const [isShowpopup, SetisShowpopup] = useState(false);
    const [accountid, Setaccountid] = useState("0");
    var intAcclist: channellist[] = new Array<channellist>();
    const [acclist, setacclist] = useState(intAcclist);
    useEffect(() => {
        setisloader(true);
        GetAllMarketplaces(function (result: any) {
            setacclist(result);
            setisloader(false);

        })
    }, [])
    const SyncAllOpenClosePopUp = (id: number) => {
        GetAgilityLatestSyncDate(function (result: jobDetails) {
            if (result != null) {
                debugger;
                var momentDate = moment(result.createdDate);
                // alert(new Date(result));
                SetselectdeltaDate(new Date(result.createdDate));
                let syncTime = moment(result.createdDate).format("HH:mm");
                setSelectedTime(syncTime);
                setBindDeltaDate(true);
                //Setaccountid(result.accountname);
            }
            else {
                setBindDeltaDate(false);
            }

        })

        SetisShowpopup(!isShowpopup);
    }
    const HandleSync = () => {
        debugger;
        history.push(`/agilityschedule`);
    }
    const ClosePopup = () => {
        SetisLoader(CreateinitialLoadermessage())
    }
    const OpenClosePopUp = (id: number) => {
        SetisShowpopup(!isShowpopup);
    }

    const changeAccNo = (e: any) => {
        debugger;
        Setaccountid(e.target.value);
    }

    const ChnageJobType = (jobType: number) => {
        if (jobType == 1) {
            SetisDate(false);
            SetisVendorDate(true);
        }
        else if (jobType == 2) {
            SetisDate(true);
            SetisVendorDate(false);
        }
        //else if (jobType == 3) {
        //    SetisDate(true);
        //    SetisVendorDate(true);
        //}
        SetselectjobType(jobType)
    }
    const onChangeDate = (e: any) => {
        const newDate = e.target.value;
        //var ndate = moment(newDate).format("MM/DD/YYYY")
        //var date = moment(new Date()).format("MM/DD/YYYY")
        var ndate = moment(new Date(newDate).getTime())
        var date = moment(new Date().getTime())
        if (ndate > date) {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please don't select future date"
            SetisLoader(result);
        }
        else {
            SetselectdeltaDate(newDate)
        }
    };
    const OnChangeTime = (e: any) => {
        var newTime = e.target.value;
        setBindTime(true);
        setSelectedTime(newTime);
        var sldate = new Date(moment(deltaDate).format("MM/DD/YYYY"));

        SetselectdeltaDate(sldate);
    }

    const AgilitySyncAll = () => {
        debugger;
        var date = moment(new Date().getTime());
        var sldate = moment(new Date(deltaDate).getTime());
        var Date1 = moment(deltaDate).format("YYYY/MM/DD") + " " + selectedTime;

        var utcDate = moment.utc(moment(Date1).utc()).format();

        //var utcDate = moment.utc(moment(deltaDate).utc()).format();
        console.log(moment.utc(moment(deltaDate).utc()).format());
        let isdatevalid = true;
        if (sldate > date) {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please don't select future date"
            SetisLoader(result);
            isdatevalid = false;
        }
        if (jobType !== 0) {
            let load: Loadermessage = {
                message: "",
                isLoader: true,
                isSuccess: false,
                isfailed: false
            }
            //SetisLoader(load);
            setisloader(true);
            let dt = deltaDate
            AgilitySyncDate(jobType, utcDate, selectedTime, accountid, function (res: any) {
                let result = CreateinitialLoadermessage()
                if (res) {
                    result.isSuccess = true;
                    result.message = "Sync Started Successfully";
                    SetisLoader(result);
                    history.go(0);
                }
                else {
                    result.isfailed = true;
                    result.message = "Sync not started";
                    SetisLoader(result);
                }
                setisloader(false);
                SetisShowpopup(!isShowpopup);
            })
        } else {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please select jobtype ";
            SetisLoader(result);
        }
    }
    const [isPause, setisPause] = useState(false);
    const Pause = (id: number) => {
        setisloader(true);

        GetAgilitySyncPauseval(id, function (result: scheduledSync) {
            refreshPage();
            setisloader(false);
            //if (result.isPauseSync) {
            //    let result = CreateinitialLoadermessage()
            //    result.isfailed = true;
            //    result.isLoader = false;
            //    result.message = "Paused";
            //    SetisLoader(result);
            //    refreshPage();
            //}
            //else {
            //    //alert("Resumed Successfully");
            //    let result = CreateinitialLoadermessage()
            //    result.isfailed = true;
            //    result.isLoader = false;
            //    result.message = "Resumed";
            //    SetisLoader(result);
            //    refreshPage();
            //}
        })
    }
    function refreshPage() {
        window.location.reload();
    }
    useEffect(() => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        SetisLoader(load);
        PauseagilitySync(function (result: any) {
            setisPause(result)
            let resultmes = CreateinitialLoadermessage();
            resultmes.isLoader = false;
            SetisLoader(resultmes);
        })
    }, [])
    return (
        <React.Fragment>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Agility Jobs List
                    </li>
                </ol>
            </nav>
            <div className="row">
                {
                    isloader ? (<Loader isActive={isloader} />) : (null)
                }
                <div className="col-md-12">
                    <div className="heading-section">
                        <h1>Agility Jobs List</h1>
                        <div>
                            <input className="btn btn-primary" type="button" value="Back" onClick={Back} />
                            <input className="btn btn-primary ms-2" type="button" disabled={CSAginv != true ? true : false} onClick={HandleSync} value="Sync Schedule" />
                            <input className="btn btn-primary ms-2" type="button" disabled={CSAginv != true ? true : false} onClick={(e) => SyncAllOpenClosePopUp(0)} value="Sync All" />
                            {!isPause ? <input className="btn btn-primary ms-2" disabled={CSAginv != true ? true : false} type="button" value="Pause Sync" onClick={(e) => Pause(1)} /> : <input className="btn btn-primary ms-2" disabled={CSAginv != true ? true : false} onClick={(e) => Pause(0)} type="button" value="Resume Sync" />}

                        </div>
                    </div>


                    <div>
                        <table className="table table-bordered">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">
                                        Job Id
                                    </th>
                                    <th scope="col">
                                        Job date
                                    </th>
                                    <th scope="col">
                                        Job Type
                                    </th>
                                    <th scope="col">
                                        Vendor Code
                                    </th>
                                    <th scope="col">
                                        Status
                                    </th>
                                    <th scope="col">
                                        Details
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    joblist != null ? (
                                        joblist.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {item.id}
                                                </td>
                                                <td>

                                                    {item.createdDate != null ? moment(item.createdDate).format('MM/DD/YYYY H:mm a') : ""}
                                                </td>
                                                <td>
                                                    {item.createdType}
                                                </td>
                                                <td>
                                                    {item.scope}
                                                </td>
                                                <td>
                                                    {item.status}
                                                </td>

                                                <td>
                                                    <button className="btn btn-sm btn-success" data-toggle="tooltip" data-placement="top" title="Details" onClick={(e) => Details(item.id)} ><i className="bi bi-eye-fill"></i></button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (

                                        <tr>
                                            <td>
                                                No Records found
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        <Pagenation />
                    </div>
                    {
                        isShowpopup ? (
                            <div id="popup1" className="overlay">
                                <div className="popup">
                                    <h4> Sync Options</h4>
                                    <a className="close" onClick={(e) => OpenClosePopUp(0)}>&times;</a>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" defaultChecked name="isSyncetype" id="" onClick={(e) => ChnageJobType(1)} />
                                                <label className="form-check-label">
                                                    Full Sync
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="isSyncetype" id="" onClick={(e) => ChnageJobType(2)} />
                                                <label className="form-check-label">
                                                    Delta Sync
                                                </label>
                                            </div>
                                            {/*<div className="form-check">*/}
                                            {/*    <input className="form-check-input" type="radio" name="isSyncetype" id="" onClick={(e) => ChnageJobType(3)} />*/}
                                            {/*    <label className="form-check-label">*/}
                                            {/*        Vendor*/}
                                            {/*    </label>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    {
                                        isDate ? (<div className="row">

                                            <div className="col-12 mb-3">
                                                <label className="form-label">Last Sync Date & Time</label>
                                                {isBindDeltaDate ?
                                                    (<div className="box-results-col mb-2">
                                                        <input type="date" className="form-control" id="datetime" value={new Date(deltaDate).toLocaleDateString('en-CA')} aria-describedby="datetimehelp" onChange={onChangeDate} />

                                                    </div>) : (<div className="box-results-col mb-2">
                                                        <input type="date" className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeDate} required />
                                                    </div>)
                                                }
                                                {
                                                    isBindTime ? (<div className="box-results-col">
                                                        <input type="time" value={selectedTime} className="form-control" id="datetime" onChange={OnChangeTime} aria-describedby="datetimehelp" required />
                                                    </div>) : (<div className="box-results-col">
                                                        <input type="time" value={selectedTime} className="form-control" id="datetime" onChange={OnChangeTime} aria-describedby="datetimehelp" />
                                                    </div>)
                                                }
                                            </div>
                                        </div>) : (<div className="row">{
                                            isVendorDate ?
                                                (<div className="col-12 mb-3" >
                                                    <label className="form-label">
                                                        Account number
                                                    </label>
                                                    <select className="form-select" onChange={(e) => changeAccNo(e)} >
                                                        <option value="0">All</option>
                                                        {
                                                            acclist.map((item, index) => (

                                                                <option value={item.customerId}>{item.message}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>) : (null)

                                        }</div>)
                                    }

                                    <div className="d-flex justify-content-center align-items-center">
                                        <button type="button" className="btn btn-sm btn-primary me-2" onClick={(e) => AgilitySyncAll()}>Sync</button>
                                    </div>
                                </div>
                            </div>
                        ) : (null)
                    }
                    <div onClick={ClosePopup}>
                        {
                            isLoader.isfailed ? (
                                <AFailed message={isLoader.message} />
                            ) : (null)
                        }
                    </div>
                    <div onClick={ClosePopup}>
                        {
                            isLoader.isSuccess ? (
                                <ASuccess message={isLoader.message} />
                            ) : (null)
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default agilityjobList;