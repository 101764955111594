export interface json {
    message: string,
    isSuccess: boolean
}



export const Createinitialjson = (): json => {
    return ({        
        message: "",
        isSuccess: false
    })
}
