import React from "react";
import axios from "axios";
import { APISettings } from "../../model/apisettings";
import { RoleModel, ApplyPermissionModel } from "../../model/permission";
import { role, roleSave } from "../../model/role";
import { shippingMethodModel } from "../../model/shippingmethods";
import { channellist } from "../../model/channellist";
// export const GetAPISettings = async (roleid: number, callback: any) => {
//     await axios.post("api/Account/SaveandGetApiSettings?userId=" + roleid)
//     .then(res => {
//         callback(res.data)
//     })
//         .catch(err => console.log(err))
// }


export const GetAPISettings = async (id: number, callback: any) => {
    await axios.post("api/Account/SaveandGetApiSettings?userId=" + 10)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const GetSettingsByID = async (id: string, callback: any) => {
    await axios.post("api/MP/GetSettingsByID?accid=" + Number(id))
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const GetNewApiKey = async (id: number, callback: any) => {
    await axios.get("api/Account/GenerateKey?userId=" + 10)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const SaveApiSettings = async (setiings: APISettings, callback: any) => {
    await axios.post("api/Account/SaveApiSettings", {
        id: setiings.id,
        name: setiings.name,
        Url: setiings.url,
        token: setiings.token
    })
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const SaveMPApiSettings = async (settings: APISettings[], callback: any) => {
    await axios.post("api/MP/SaveMPSettingsByID", settings)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const TestAPiConncetion = async (setiings: APISettings, callback: any) => {
    await axios.post("api/Account/TestConnection", {
        Url: setiings.url,
        token: setiings.token
    })
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const GetRoleLoad = async (page: number, size: number, callback: any) => {

    await axios.post("api/MP/GetRoles", {
        index: page,
        recordPerPage: size
    })
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))

};

export const GetRoleById = async (id: number, callback: any) => {

    await axios.post("api/MP/GetRolebyId?roleid=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
};

export const GetTree = async (id: number, callback: any) => {

    await axios.post("api/MP/GetTree?roleid=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
};

export const Getrolepermissionbyuserid = async (id: number, callback: any) => {

    await axios.post("api/MP/Getrolepermissionbyuserid?permissionid=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
};
export const Getrolepermissionbyroleid = async (id: number, callback: any) => {

    await axios.post("api/MP/Getrolepermissionbyroleid?roleid=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
};

export const SavePermission = async (roleModel: role, callback: any) => {
    await axios.post("api/MP/SavePermission", {
        id: roleModel.id,
        Name: roleModel.name,
        Index: roleModel.index,
        RecordPerPage: roleModel.recordPerPage,
        totalRecord: roleModel.totalRecord,
        ApplyPermission: roleModel.applypermission

    })
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const SaveRole = async (roleModel: roleSave, callback: any) => {
    await axios.post("api/MP/SaveRole", {
        id: roleModel.id,
        Name: roleModel.Name,
        IsActive: roleModel.IsActive

    })
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const CheckIsRoleAssigned = async (id: number, callback: any) => {

    await axios.post("api/MP/CheckIsRoleAssignedToUser?roleId="+id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
};

export const DeleteUserRule = async (role: any, callback: any) => {

    await axios.post("api/MP/DeleteUserRule", {
        id: Number(role.id),
        existingId: role.existingId

    })
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
};

export const GetShippingMethods = async (page: number, size: number, accid: number, callback: any) => {

    await axios.post("api/MP/GetShippingMethodsByMAID", {
        index: page,
        recordPerPage: size,
        MAID: accid
    })
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))

};

export const EditandSaveShippingMethod = async (shippingMethod: shippingMethodModel[], callback: any) => {
    await axios.post("api/MP/SaveShippingMehods",shippingMethod)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}

export const GetMpAccountSettingValue = async (id: number, callback: any) => {

    await axios.post("api/MP/GetMpAccountSettingValue?accid=" + id)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
};
export const SaveNFApiSettings = async (settings: channellist[], callback: any) => {
    await axios.post("api/MP/SaveNFApiSettings", settings)
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}