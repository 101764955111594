import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { UserRegister } from "../../dataservices/action/account/register";
import { user, createinitialUser, moduleinfo } from "../../dataservices/model/user";
import { useSelector } from 'react-redux'
import { GetUserLoad, DeleteUser, SaveEditUser, GetModuleList } from "../../dataservices/action/users/usersload";
import SideBar from "../sidebar";
import { ValidateEmail } from '../../dataservices/helper/componenthelpers';
import ASuccess from "../alretbox/success";
import { PaginationValues } from "../pagenation/pageflow";
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
import Loader from "../loader";
import AFailed from "../alretbox/failed";
import { EALREADY } from "constants";
import { debug } from "console";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { settings } from "cluster";
import { Checkbox } from "@material-ui/core";
import { GetNewApiKey } from "../../dataservices/action/users/getUserApiSettins";
import { GetNewApiKeybyUserID, Getrolepermissionbyuserid } from "../../dataservices/action/marketplace/marketplace";
import { GetRoleLoad } from "../../dataservices/action/users/getUserApiSettins";
import { role, createinitialRole } from "../../dataservices/model/role";
import UserSideBar from "../usersidebar";
const userList = () => {
    var intusers: user[] = new Array<user>();
    var introles: role[] = new Array<role>()
    const history = useHistory();
    const [users, setUsers] = useState(intusers);
    const [neusers, SetNEUser] = useState(createinitialUser());
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const [showdownloadPopup, SetuserEditadd] = useState(false);
    const [islogin, setislogin] = useState(false);
    const [isLoader, SetisLoader] = useState(false);
    const store = useSelector((state: any) => { return state });
    const [uname, setuname] = useState("");
    const [uemail, setemail] = useState("");
    const [upwd, setpwd] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [activePage, setActivePage] = useState(0)
    const [TotalCount, setTotalCount] = useState(0);
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [PageSizeList, setPageSizeList] = useState([50, 100, 200]);
    const [SearchValue, setSearchValue] = useState("");
    const [userid, setuserid] = useState(0);
    const [roles, setRoles] = useState(introles);
    const [selectedRoleValue, setSelectedRoleValue] = useState('');
    const [selectedRoleId, setSelectedRoleId] = useState(0);

    const [AUser, setAUser] = useState(false);
    const [EUser, setEUser] = useState(false);
    const [DUser, setDUser] = useState(false);
    //const [CSIMsync, setCSIMsync] = useState(false);
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                      //  console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 23:
                                if (result[i].canadd == "1") {
                                    setAUser(true);
                                }
                                if (result[i].canedit == "1") {
                                    setEUser(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDUser(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])


    useEffect(() => {
        SetisLoader(true);
        GetRoleLoad(Page, PageSize, function (result: role[]) {
            if (result != null) {
                setRoles(result);

            }
            //console.log("roles----", result)
        }),

            GetUserLoad(Page, PageSize, function (result: user[]) {
                if (result != null) {
                    setUsers(result);
                    setTotalCount(result[0].totalRecord)
                    setPage(result[0].index);
                    setPageSize(result[0].recordPerPage)
                    setActivePage(Page);
                    SetisLoader(false);
                }
               // console.log("userslist----", result)
            })


    }, [])


    const Pagenation = () => {
        let Pagination = new PaginationValues();
        Pagination.TotalItemCount = TotalCount;
        Pagination.CurrentPage = Page;
        Pagination.PageSlide = 2;
        Pagination.IsSort = true;
        Pagination.PageSize = PageSize;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page - 1) * PageSize) + 1}    - {((((Page - 1) * PageSize) + 1) + PageSize) > TotalCount ? TotalCount : ((((Page - 1) * PageSize)) + PageSize)} of {TotalCount} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">

                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                                {
                                    Pagination.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination.Pages().map((x, i) =>
                                        x == Page ?
                                            (
                                                <li className={`page-item  ${activePage == x ? 'active' : null}`} key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className="page-link" onClick={() => SetPage(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }

    const SetPageSize = (size: number) => {
        setPageSize(size);
        RefreshQuote();
    }

    const SetPage = (page: number) => {
        setPage(page);
        setActivePage(page)
        RefreshQuote();
    }

    const RefreshQuote = () => {
        let PoNumber: string = "";
        let OrderNumber: number = 0;
        let InvoiceNumber: string = "";
        if (SearchValue != "") {
        }
        GetUserLoad(Page, PageSize, function (result: user[]) {
            if (result != null) {
                setUsers(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
            }
        })
    }


    const CloseLOginPopup = () => {
        setislogin(false);
    }
    const DeleteAlert = (id: number, emailId: string) => {
        confirmAlert({
            title: 'Delete User',
            message: 'Are you sure you want to delete the user ' + emailId + '?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Delete(id)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const Delete = (id: number) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        DeleteUser(id, function (result: user) {
            if (result.isSuccess) {
                //alert("User deleted successfully")
                //let updateduser = users.filter((user: { id: number; }) => user.id != id);
                //setUsers(updateduser);
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "User deleted successfully"
                Setisloader(result);
                let updateduser = users.filter((user: { id: number; }) => user.id != id);
                setUsers(updateduser);
                history.go(0);
            }
        })
    }


    const CloseUserPopup = () => {
        SetuserEditadd(false);
        SetNEUser(createinitialUser());
        Setisloader(CreateinitialLoadermessage);
    }

    const ChangEmail = (e: any) => {
        neusers.emailId = e.target.value;
        setemail(e.target.value);
        SetNEUser(neusers);
        //SetNEUser((prev: any) => ({ ...prev, emailId: e.target.value }))
        //alert(e.target.value);
    };
    const ChangeName = (e: any) => {
        neusers.userName = e.target.value;
        setuname(e.target.value);
        SetNEUser(neusers);
        //SetNEUser((prev: any) => ({ ...prev, name: e.target.value }))
        //alert(target.value);
    };
    const ChangePassword = (e: any) => {
        //alert(e.target.value);
        neusers.password = e.target.value;
        setpwd(e.target.value);
        SetNEUser(neusers);
        //SetNEUser((prev: any) => ({ ...prev, password: e.target.value }))       
    };

    const [allBox, changeAllBox] = useState(false);
    const [allchkBox, changeAllchkBox] = useState(false);
    //view
    const [CategoryAttributes, changeCategoryAttributes] = useState(false);
    const [APIsettings, changeAPIsettings] = useState(false);
    const [MagentoSync, changeMagentoSync] = useState(false);
    const [InriverSync, changeInriverSync] = useState(false);
    const [Products, changeProducts] = useState(false);
    const [InventoryRules, changeInventoryRules] = useState(false);
    const [logs, changelogs] = useState(false);
    const [Settings, changeSettings] = useState(false);
    //Edit
    const [ECategoryAttributes, changeECategoryAttributes] = useState(false);
    const [EAPIsettings, changeEAPIsettings] = useState(false);
    const [EMagentoSync, changeEMagentoSync] = useState(false);
    const [EInriverSync, changeEInriverSync] = useState(false);
    const [EProducts, changeEProducts] = useState(false);
    const [EInventoryRules, changeEInventoryRules] = useState(false);
    const [Elogs, changeElogs] = useState(false);
    const [ESettings, changeESettings] = useState(false);
    //ModuleId

    const [CategoryAttributeID, changeCategoryAttributeID] = useState(1);
    const [APIsettingID, changeAPIsettingID] = useState(2);
    const [MagentoSyncID, changeMagentoSyncID] = useState(3);
    const [InriverSyncID, changeInriverSyncID] = useState(4);
    const [ProductID, changeProductID] = useState(5);
    const [InventoryRuleID, changeInventoryRuleID] = useState(6);
    const [logID, changelogID] = useState(7);
    const [SettingID, changeSettingID] = useState(8);
    let initialrole: moduleinfo[] = new Array<moduleinfo>();
    const [intRole, setintRole] = useState(initialrole);
    const [apiKey, SetApiKey] = useState("");
    const [inturole, setintuRole] = useState(0);

    const backUserDB = () => {

        history.push('/userdashboard');
    }
    const Saveuser = () => {
        debugger;
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        SetisLoader(true);
        let permissionval = "";
        let initialAttribute: moduleinfo[] = new Array<moduleinfo>();
        var iroles = [...intRole];
        debugger;
        if (CategoryAttributeID == 1) {
            permissionval = (CategoryAttributes == true ? "1" : "0") + (ECategoryAttributes == true ? "1" : "0");
            //var val = intRole.filter(x => x.moduleid == CategoryAttributeID)[0];
            iroles.forEach(element => {
                if (element.moduleid === CategoryAttributeID) {
                    element.permission = permissionval;
                }
            });
            setintRole(iroles);
        }
        if (APIsettingID == 2) {
            permissionval = (APIsettings == true ? "1" : "0") + (EAPIsettings == true ? "1" : "0");
            iroles.forEach(element => {
                if (element.moduleid === APIsettingID) {
                    element.permission = permissionval;
                }
            });
            setintRole(iroles);
        }
        if (MagentoSyncID == 3) {
            permissionval = (MagentoSync == true ? "1" : "0") + (EMagentoSync == true ? "1" : "0");
            iroles.forEach(element => {
                if (element.moduleid === MagentoSyncID) {
                    element.permission = permissionval;
                }
            });
            setintRole(iroles);
        }
        if (InriverSyncID == 4) {
            permissionval = (InriverSync == true ? "1" : "0") + (EInriverSync == true ? "1" : "0");
            iroles.forEach(element => {
                if (element.moduleid === InriverSyncID) {
                    element.permission = permissionval;
                }
            });
            setintRole(iroles);
        }
        if (ProductID == 5) {
            permissionval = (Products == true ? "1" : "0") + (EProducts == true ? "1" : "0");
            iroles.forEach(element => {
                if (element.moduleid === ProductID) {
                    element.permission = permissionval;
                }
            });
            setintRole(iroles);
        }
        if (InventoryRuleID == 6) {
            permissionval = (InventoryRules == true ? "1" : "0") + (EInventoryRules == true ? "1" : "0");
            iroles.forEach(element => {
                if (element.moduleid === InventoryRuleID) {
                    element.permission = permissionval;
                }
            });
            setintRole(iroles);
        }
        if (logID == 7) {
            permissionval = (logs == true ? "1" : "0") + (Elogs == true ? "1" : "0");
            iroles.forEach(element => {
                if (element.moduleid === logID) {
                    element.permission = permissionval;
                }
            });
            setintRole(iroles);
        }
        if (SettingID == 8) {
            permissionval = (Settings == true ? "1" : "0") + (ESettings == true ? "1" : "0");
            iroles.forEach(element => {
                if (element.moduleid === SettingID) {
                    element.permission = permissionval;
                }
            });
            setintRole(iroles);
        }
        //    let item: moduleinfo = {
        //        moduleid: cha,
        //        id: 0,
        //        attributeCode: "",
        //        magentoAttributeId: 0,
        //        magentoAttributecode: "",
        //        magentoAttributeName: "",
        //        isSync: false,
        //        fieldTypeDisplayName: "",
        //        selectedattrbute: "",
        //        selecteMegentodattrbute: ""
        //    };


        if (neusers.emailId != "" && neusers.password != "" && selectedRoleId > 0) {
            neusers.role = isAdmin;
            neusers.roleId = selectedRoleId;
            if (ValidateEmail(neusers.emailId)) {
                SaveEditUser(store.user.customerId, neusers, iroles, allBox, function (result: user) {
                    if (result.isSuccess) {
                        //alert("User saved successfully")
                        let result = CreateinitialLoadermessage();
                        result.isSuccess = true;
                        result.message = "Saved Successfully"
                        Setisloader(result);
                        //history.push(`/userindex`);
                        history.go(0);

                        SetisLoader(false);
                    }
                    else{
                        let result = CreateinitialLoadermessage();
                        result.isSuccess = false;
                        result.isfailed=true;
                        result.message = "This user already exists"
                        Setisloader(result);
                        SetisLoader(false);
                    }
                })
            } else {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "please enter correct email Id"
                Setisloader(result);
                SetisLoader(false);
            }
        }
        else if (selectedRoleId == 0) {
            let result = CreateinitialLoadermessage();
            result.isSuccess = true;
            result.message = "Please Select Role"
            Setisloader(result);
            SetisLoader(false);
        }
        else {
            let result = CreateinitialLoadermessage();
            result.isSuccess = true;
            result.message = "*Please fill required field"
            Setisloader(result);
            SetisLoader(false);
        }

    }
    const selectAll = (e: any) => {
        debugger;
        let newVal = false;
        setIsAdmin(e.target.checked);
        if (e.target.checked) {
            newVal = true;
        }
        changeCategoryAttributes(newVal);
        changeAPIsettings(newVal);
        changeMagentoSync(newVal);
        changeInriverSync(newVal);
        changeProducts(newVal);
        changeInventoryRules(newVal);
        changelogs(newVal);
        changeSettings(newVal);
        changeECategoryAttributes(newVal);
        changeEAPIsettings(newVal);
        changeEMagentoSync(newVal);
        changeEInriverSync(newVal);
        changeEProducts(newVal);
        changeEInventoryRules(newVal);
        changeElogs(newVal);
        changeESettings(newVal);
        changeAllBox(newVal);
        changeAllchkBox(newVal)
    }

    const EnableuserEditAdd = (id: number) => {

        if (id != 0) {
            let user = users.filter(x => x.id == id)[0];
            SetApiKey(user.apitoken);
            setuserid(id);
            setuname(user.userName);
            setemail(user.emailId);
            setpwd(user.password);
            setintRole(user.moduleinfo);
            setintuRole(user.roleId);
            setSelectedRoleId(user.roleId)
            if (user.moduleinfo == null) {
                GetModuleList(function (result: moduleinfo[]) {
                    if (result != null) {
                        setintRole(result);
                    }
                })
            }

            changeAllBox(user.role);
            if (user.moduleinfo != null) {
                for (var i = 0; i < user.moduleinfo.length; i++) {
//console.log(user.moduleinfo[i].modulename);
                    switch (user.moduleinfo[i].moduleid) {
                        case 1:
                            if (user.moduleinfo[i].permission.substring(0, 1) == "1") {
                                changeCategoryAttributes(true);
                            }
                            if (user.moduleinfo[i].permission.substring(1, 2) == "1") {
                                changeECategoryAttributes(true);
                            }
                            break;
                        case 2:
                            if (user.moduleinfo[i].permission.substring(0, 1) == "1") {
                                changeAPIsettings(true);
                            }
                            if (user.moduleinfo[i].permission.substring(1, 2) == "1") {
                                changeEAPIsettings(true);
                            }
                            break;

                        case 3:
                            if (user.moduleinfo[i].permission.substring(0, 1) == "1") {
                                changeMagentoSync(true);
                            }
                            if (user.moduleinfo[i].permission.substring(1, 2) == "1") {
                                changeEMagentoSync(true);
                            }
                            break;

                        case 4:
                            if (user.moduleinfo[i].permission.substring(0, 1) == "1") {
                                changeInriverSync(true);
                            }
                            if (user.moduleinfo[i].permission.substring(1, 2) == "1") {
                                changeEInriverSync(true);
                            }
                            break;

                        case 5:
                            if (user.moduleinfo[i].permission.substring(0, 1) == "1") {
                                changeProducts(true);
                            }
                            if (user.moduleinfo[i].permission.substring(1, 2) == "1") {
                                changeEProducts(true);
                            }
                            break;

                        case 6:
                            if (user.moduleinfo[i].permission.substring(0, 1) == "1") {
                                changeInventoryRules(true);
                            }
                            if (user.moduleinfo[i].permission.substring(1, 2) == "1") {
                                changeEInventoryRules(true);
                            }
                            break;

                        case 7:
                            if (user.moduleinfo[i].permission.substring(0, 1) == "1") {
                                changelogs(true);
                            }
                            if (user.moduleinfo[i].permission.substring(1, 2) == "1") {
                                changeElogs(true);
                            }
                            break;

                        case 8:
                            if (user.moduleinfo[i].permission.substring(0, 1) == "1") {
                                changeSettings(true);
                            }
                            if (user.moduleinfo[i].permission.substring(1, 2) == "1") {
                                changeESettings(true);
                            }
                            break;


                    }

                }
            }
            SetNEUser(user);
        }
        else {
            setuserid(id);
            setuname("");
            setemail("");
            setpwd("");
            GetModuleList(function (result: moduleinfo[]) {
                if (result != null) {
                    setintRole(result);
                }
            })
        }
        SetuserEditadd(true)
    }

    const handleChange = (e: any, id: number, moduleid: number) => {



        switch (moduleid) {
            case 1:
                if (id == 0) {
                    changeCategoryAttributes(e.target.checked);

                } else {
                    changeECategoryAttributes(e.target.checked);

                }
                break;
            case 2:
                if (id == 0) {
                    changeAPIsettings(e.target.checked);

                } else {
                    changeEAPIsettings(e.target.checked);

                }
                break;

            case 3:
                if (id == 0) {
                    changeMagentoSync(e.target.checked);

                } else {
                    changeEMagentoSync(e.target.checked);

                }
                break;

            case 4:
                if (id == 0) {
                    changeInriverSync(e.target.checked);

                } else {
                    changeEInriverSync(e.target.checked);

                }
                break;

            case 5:
                if (id == 0) {
                    changeProducts(e.target.checked);

                } else {
                    changeEProducts(e.target.checked);

                }
                break;

            case 6:
                if (id == 0) {
                    changeInventoryRules(e.target.checked);

                } else {
                    changeEInventoryRules(e.target.checked);

                }
                break;

            case 7:
                if (id == 0) {
                    changelogs(e.target.checked);

                } else {
                    changeElogs(e.target.checked);

                }
                break;

            case 8:
                if (id == 0) {
                    changeSettings(e.target.checked);

                } else {
                    changeESettings(e.target.checked);

                }
                break;

        }

    }

    const GetNewApi = () => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetNewApiKeybyUserID(userid, function (result: string) {
            if (result != null) {
                SetApiKey(result);
            }
            let loadc = isloader;
            loadc.isLoader = false;
            Setisloader(loadc);
        })

    }
    const handleSelectChange = (event: any) => {
        setSelectedRoleValue(event.target.value);
        setSelectedRoleId(event.target.value);
        setintuRole(event.target.value);
        console.log("event.target..", event.target);
    };
    return (

        <React.Fragment>


            {/*<nav aria-label="breadcrumb">*/}
            {/*    <ol className="breadcrumb">*/}
            {/*        <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Home</a></li>*/}
            {/*        <li className="breadcrumb-item active" aria-current="page">*/}
            {/*            Manage Users*/}
            {/*        </li>*/}
            {/*    </ol>*/}
            {/*</nav>*/}
            <div className="row">
                <div className="col-md-3">
                    <UserSideBar />
                </div>
                {
                    isLoader ? (<Loader isActive={isLoader} />) : (null)
                }
                {
                    showdownloadPopup != true ? (
                        <div className="col-sm-9 col-md-9">
                            <div className="heading-section">
                                <h1>Manage Users</h1>
                                <div>
                                    <button type="button" className="btn btn-primary" disabled={AUser != true ? true : false} onClick={(e) => EnableuserEditAdd(0)}>Add User</button>
                                    <button type="button" className="btn btn-primary ms-2" onClick={backUserDB}>Back</button>
                                </div>
                            </div>

                            <table className="table table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        {/* <th scope="col">
                                            User Name
                                        </th> */}
                                        <th scope="col">
                                            Email Id
                                        </th>
                                        <th scope="col">
                                            Role
                                        </th>
                                        <th scope="col">
                                            Is Active
                                        </th>
                                        <th scope="col">
                                            Created On
                                        </th>
                                        <th scope="col">
                                            Edit
                                        </th>
                                        <th scope="col">
                                            Delete
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        users != null ? (
                                            users.map((item, index) => (
                                                <tr key={index}>
                                                    {/* <td>
                                                        {item.userName}
                                                    </td> */}
                                                    <td>
                                                        {item.emailId}
                                                    </td>
                                                    <td>
                                                        {item.roleName}
                                                    </td>
                                                    <td>
                                                        {item.isActive == true ? (<span>Active</span>) : (<span>In Active</span>)}
                                                    </td>
                                                    <td>
                                                        {item.createdon}
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-sm btn-primary" disabled={EUser != true ? true : false} onClick={(e) => EnableuserEditAdd(item.id)}><i className="bi bi-pencil-square"></i></button>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-sm btn-danger" disabled={DUser != true ? true : false} data-toggle="tooltip" data-placement="top" title="Delete User" onClick={(e) => DeleteAlert(item.id, item.emailId)} ><i className="bi bi-trash-fill"></i></button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (null)

                                    }
                                </tbody>
                            </table>
                            <Pagenation />
                        </div>
                    )
                        : (
                            <div className="col-md-6">
                                <div className="heading-section">
                                    <h1>User details</h1>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <div className="form-group mb-3" hidden>
                                            <label className="form-label">User Name</label>
                                            <input className="form-control" type="text" onChange={ChangeName} value={uname} />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Email Id</label>
                                            <input className="form-control" type="text" onChange={ChangEmail} value={uemail} />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Password</label>
                                            <input className="form-control" type="password" onChange={ChangePassword} value={upwd} />
                                        </div>
                                        <div className="row align-items-end mt-4 mb-4">
                                            <div className="col">
                                                <label className="form-label">Access Key</label>
                                                <input className="form-control" type="text" value={apiKey} />
                                            </div>
                                            <div className="col">
                                                <input className="btn btn-primary" type="button" value="Generate key" onClick={GetNewApi} />
                                            </div>
                                        </div>
                                        {/* <div className="form-check mb-3">
                                            <input className="form-check-input" type="checkbox" onChange={selectAll} checked={allBox} />
                                            <label className="form-check-label">
                                                Admin
                                            </label>
                                        </div> */}
                                        <div className="row align-items-end mt-4 mb-4">
                                            <div className="col">
                                                <label htmlFor="dropdown">Select Role</label>
                                            </div>
                                            {console.log("inturole..", inturole)}
                                            <select id="dropdown" className="form-select" value={inturole} onChange={handleSelectChange}>
                                                <option value={0}>Select</option>
                                                {
                                                    roles.map((option, index) => (
                                                        <option key={index} value={option.id}>{option.name}</option>
                                                    ))}
                                            </select>


                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <table className="table table-bordered">
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col">
                                                    </th>
                                                    <th scope="col">
                                                        View
                                                    </th>
                                                    <th scope="col">
                                                        Edit
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/*<tr>*/}
                                {/*    <td colspan='3'>*/}
                                {/*        Title1*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                                {/* <tr className="table-active">
                                                    <th scope="row">MagentoConnector</th>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">Category Attributes</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" onChange={(e) => handleChange(e, 0, 1)} checked={CategoryAttributes} value="" id="" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" onChange={(e) => handleChange(e, 1, 1)} checked={ECategoryAttributes} value="" id="" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">APIsettings</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" onChange={(e) => handleChange(e, 0, 2)} checked={APIsettings} id="" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" onChange={(e) => handleChange(e, 1, 2)} checked={EAPIsettings} id="" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">Magento Sync</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" onChange={(e) => handleChange(e, 0, 3)} checked={MagentoSync} id="" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" onChange={(e) => handleChange(e, 1, 3)} checked={EMagentoSync} id="" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">Inriver Sync</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" onChange={(e) => handleChange(e, 0, 4)} checked={InriverSync} id="" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" onChange={(e) => handleChange(e, 1, 4)} checked={EInriverSync} id="" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="table-active">
                                                    <th scope="row">Marketplace Connector</th>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">Products</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" onChange={(e) => handleChange(e, 0, 5)} checked={Products} id="" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" onChange={(e) => handleChange(e, 1, 5)} checked={EProducts} id="" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">Inventory Rules</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" onChange={(e) => handleChange(e, 0, 6)} checked={InventoryRules} id="" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" onChange={(e) => handleChange(e, 1, 6)} checked={EInventoryRules} id="" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">Logs</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" onChange={(e) => handleChange(e, 0, 7)} checked={logs} id="" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" onChange={(e) => handleChange(e, 1, 7)} checked={Elogs} id="" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td scope="row">Settings</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" onChange={(e) => handleChange(e, 0, 8)} checked={Settings} id="" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" onChange={(e) => handleChange(e, 1, 8)} checked={ESettings} id="" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div> */}



                                <div>
                                    <button type="button" className="btn btn-primary me-2" disabled={EUser != true ? true : false} onClick={Saveuser}>Save</button>
                                    <button type="button" className="btn btn-primary" onClick={CloseUserPopup} >Close</button>
                                </div>
                            </div>)
                }

                <div onClick={CloseUserPopup}>
                    {
                        isloader.isfailed ? (
                            <AFailed message={isloader.message} />
                        ) : (null)
                    }
                </div>
                <div onClick={CloseUserPopup}>
                    {
                        isloader.isSuccess ? (
                            <ASuccess message={isloader.message} />
                        ) : (null)
                    }
                </div>
            </div>
        </React.Fragment>
    )

}

export default userList;