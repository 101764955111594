import React, { useState, useEffect } from "react";
import Loader from "../loader";
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import UserSideBar from "../usersidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { GetRoleById } from "../../dataservices/action/users/getUserApiSettins";
import { GetTree } from "../../dataservices/action/users/getUserApiSettins";
import { Getrolepermissionbyroleid } from "../../dataservices/action/users/getUserApiSettins";
import { RoleModel, ApplyPermissionModel } from '../../dataservices/model/permission';
import { role, roleSave } from "../../dataservices/model/role";
import { SavePermission, SaveRole } from "../../dataservices/action/users/getUserApiSettins";
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
import AFailed from "../alretbox/failed";
import ASuccess from "../alretbox/success";
// const treeData = [
//     {
//         id: 1,
//         name: 'root',
//         children: [
//             {
//                 id: 2,
//                 name: 'item 1',
//                 children: []
//             },
//             {
//                 id: 3,
//                 name: 'Item 2',
//                 children: [
//                     {
//                         id: 4,
//                         name: 'Item 2.1',
//                         children: []
//                     },
//                     {
//                         id: 5,
//                         name: 'Item 2.2',
//                         children: []
//                     }
//                 ]
//             },
//             {
//                 id: 6,
//                 name: 'item 3',
//                 children: []
//             },
//             {
//                 id: 7,
//                 name: 'item 4',
//                 children: [
//                     {
//                         id: 6,
//                         name: 'item 4.1',
//                         children: []
//                     },
//                 ]
//             },
//         ]
//     },
// ];
//const treeData = [{ "id": 1, "name": "Magento Connector", "children": [{ "id": 2, "name": "Website Configuration (Dashboard)", "children": [] }, { "id": 3, "name": "Category Attributes", "children": [{ "id": null, "name": null }] }, { "id": 4, "name": "APIsettings", "children": [{ "id": null, "name": null }] }, { "id": 5, "name": "Magento Sync", "children": [{ "id": null, "name": null }] }, { "id": 6, "name": "Inriver Sync", "children": [{ "id": null, "name": null }] }] }, { "id": 7, "name": "Fulfillment Customer", "children": [{ "id": 8, "name": "Product Rules", "children": [{ "id": null, "name": null }] }, { "id": 9, "name": "Inventory Rules", "children": [{ "id": null, "name": null }] }, { "id": 10, "name": "Order Listing Page", "children": [{ "id": null, "name": null }] }] }, { "id": 11, "name": "Customer Accounts", "children": [{ "id": 12, "name": "Customer Product Listing ", "children": [{ "id": null, "name": null }] }, { "id": 13, "name": "Settings", "children": [{ "id": 17, "name": "API Settings" }, { "id": 16, "name": "UOM Mapping" }, { "id": 15, "name": "Warehouse Mapping" }, { "id": 14, "name": "Associated Products" }] }, { "id": 18, "name": "Logs", "children": [{ "id": null, "name": null }] }, { "id": 19, "name": "Sync Schedule", "children": [{ "id": null, "name": null }] }, { "id": 20, "name": "Sync Options", "children": [{ "id": null, "name": null }] }] }, { "id": 21, "name": "Aligilty Inventory", "children": [{ "id": null, "name": null, "children": null }] }, { "id": 22, "name": "Webhooks", "children": [{ "id": null, "name": null, "children": null }] }, { "id": 23, "name": "Users", "children": [{ "id": null, "name": null, "children": null }] }, { "id": 24, "name": "User Roles", "children": [{ "id": null, "name": null, "children": null }] }]
const TreeNode = ({ node, handleData, roleIdValue }: any) => {
   // console.log("roleIdValue....", roleIdValue)
    const [isExpanded, setIsExpanded] = useState(false);
    const [clickedData, setClickedData] = useState(null);
    const [filteredValue, setFilteredValue] = useState(null);
    const handleToggle = () => {
        setIsExpanded(!isExpanded);
        //alert(node.id)
        Getrolepermissionbyroleid(roleIdValue, function (result: any) {
            const filteredData = result.filter((item: any) => item.permissionid == node.id);
          //  console.log("Getrolepermissionbyroleid_res....", result)
            // setClickedData(result);
            setFilteredValue(filteredData);
            handleData(filteredData);
        })

    };

    return (
        <div className="tree-node">
            <div onClick={handleToggle} className={`node-toggle ${isExpanded ? 'expanded' : ''}`} >
                {/* {isExpanded && node.children.length > 0 ? <FontAwesomeIcon icon={faCaretDown} /> : node.children.length == "" ? '' : <FontAwesomeIcon icon={faCaretRight} />} {node.name} */}
                {isExpanded && node.children!=null && node.children.length > 0 ? 
                (<FontAwesomeIcon icon={faCaretDown} /> 
                ): (node.children==null || node.children.length === 0 )? ''
                 : <FontAwesomeIcon icon={faCaretRight} />} {node.name}

            </div>
            {isExpanded && node.children != null &&(
                <ul className="child-nodes">
                    {node.children.map((childNode: any) => (
                        <li key={childNode.id}>
                            <TreeNode node={childNode} handleData={handleData} roleIdValue={roleIdValue} />
                        </li>
                    ))}
                </ul>
            )}  
        </div>
    );
};

const roleEdit = () => {
    const history = useHistory();
    const [isLoader, SetisLoader] = useState(false);
    const [checkActive, setcheckActive] = useState(false);
    const [roleId, setRoleId] = useState(0);
    const [roleName, setRoleName] = useState("");
    const [treeData, setTreeData] = useState([]);
    const [clickedName, setClickedName] = useState("");
    const [clickedData, setClickedData] = useState([]);
    const [filteredValue, setFilteredValue] = useState([] || null);
    const [AddPermit, setAddPermit] = useState("");
    const [ViewPermit, setViewPermit] = useState("");
    const [SyncPermit, setSyncPermit] = useState("");
    const [EditPermit, setEditPermit] = useState("");
    const [DeletePermit, setDeletePermit] = useState("");
    const [permissionId, setPermissionId] = useState(0);
    const [permissionName, setPermissionName] = useState("");
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const [showdownloadPopup, SetuserEditadd] = useState(false);

    // const [viewData, setViewData] = useState([]);
    const location: any = useLocation();
    let receivedRoleId: number = location.state;

   // console.log("receivedRoleId----", receivedRoleId);


    useEffect(() => {
        SetisLoader(true);
        GetRoleById(receivedRoleId, function (result: any) {
          //  console.log("GetRoleById..", result)
            setRoleId(result.id)
            setRoleName(result.name);
            setcheckActive(result.isActive);
        }),
            GetTree(receivedRoleId, function (result: any) {
                console.log("treedata....",result)
                const sortedList = result.sort((a:any, b:any) => a.sortorder - b.sortorder);

                setTreeData(sortedList);
            })

    }, [])

   // console.log("tree....", treeData)
    const OnChangeRoleName = (e: any) => {
        // console.log(e.target.value);
        setRoleName(e.target.value)


    };
    // const RoleActiveclick = (e: any) => {
    //     setcheckActive(e.target.checked)
    //     console.log("checkActivecheckActive", checkActive);
    // }

    const ChangeRoleButtonClick = () => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        const roleSave: roleSave = {
            id: receivedRoleId,
            Name: roleName,
            IsActive: checkActive

        }
        SaveRole(roleSave, function (result: any) {
            console.log("res..", result)
            if (result != null || result != "") {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "Role saved successfully"
                Setisloader(result);
                history.go(0);
            }
        })
    }
    const CloseUserPopup = () => {
        SetuserEditadd(false)
        //console.log("pop")

    }
    const backRoleDB = () => {

        history.goBack();
    }


    //CanAddClick
    const canAddClick = (e: any) => {
        setAddPermit(e.target.checked ? "1" : "0");
        let viewValue = e.target.checked;
        console.log('e.target.checked....', e.target.checked);
        e.target.checked ? viewValue = "1" : viewValue = "0"
        const applyPermission: ApplyPermissionModel = {
            permissionid: permissionId,
            name: permissionName,
            canview: ViewPermit,
            canadd: viewValue,
            canedit: EditPermit,
            candelete: DeletePermit,
            cansync: SyncPermit
        }

        const roleMod: role = {
            id: receivedRoleId,
            name: roleName,
            index: 0,
            recordPerPage: 50,
            totalRecord: 500,
            applypermission: applyPermission,
            createdOn: new Date(),
            existingId: 0
        };
        SavePermission(roleMod, function (result: any) {
            console.log("res..", result)
        })
    }

    //CanViewClick
    const canViewClick = (e: any) => {
        // console.log("tar..",e.target.checked)
        setViewPermit(e.target.checked ? "1" : "0")
        let viewValue = e.target.checked;
        console.log('e.target.checked....', e.target.checked);
        e.target.checked ? viewValue = "1" : viewValue = "0"
        const applyPermission: ApplyPermissionModel = {
            permissionid: permissionId,
            name: permissionName,
            canview: viewValue,
            canadd: AddPermit,
            canedit: EditPermit,
            candelete: DeletePermit,
            cansync: SyncPermit
        }

        const roleMod: role = {
            id: receivedRoleId,
            name: roleName,
            index: 0,
            recordPerPage: 50,
            totalRecord: 500,
            applypermission: applyPermission,
            createdOn: new Date(),
            existingId: 0
        };
        SavePermission(roleMod, function (result: any) {
            console.log("res..", result)
        })
    }
    // console.log("tar.2.", ViewPermit)

    //CanSyncClick
    const canSyncClick = (e: any) => {
        setSyncPermit(e.target.checked ? "1" : "0")
        let viewValue = e.target.checked;
        console.log('e.target.checked....', e.target.checked);
        e.target.checked ? viewValue = "1" : viewValue = "0"
        const applyPermission: ApplyPermissionModel = {
            permissionid: permissionId,
            name: permissionName,
            canview: ViewPermit,
            canadd: AddPermit,
            canedit: EditPermit,
            candelete: DeletePermit,
            cansync: viewValue
        }

        const roleMod: role = {
            id: receivedRoleId,
            name: roleName,
            index: 0,
            recordPerPage: 50,
            totalRecord: 500,
            applypermission: applyPermission,
            createdOn: new Date(),
            existingId: 0
        };
        SavePermission(roleMod, function (result: any) {
            console.log("res..", result)
        })
    }

    //CanEditClick
    const canEditClick = (e: any) => {
        setEditPermit(e.target.checked ? "1" : "0");
        let viewValue = e.target.checked;
        console.log('e.target.checked....', e.target.checked);
        e.target.checked ? viewValue = "1" : viewValue = "0"
        const applyPermission: ApplyPermissionModel = {
            permissionid: permissionId,
            name: permissionName,
            canview: ViewPermit,
            canadd: AddPermit,
            canedit: viewValue,
            candelete: DeletePermit,
            cansync: SyncPermit,

        }

        const roleMod: role = {
            id: receivedRoleId,
            name: roleName,
            index: 0,
            recordPerPage: 50,
            totalRecord: 500,
            applypermission: applyPermission,
            createdOn: new Date(),
            existingId: 0
        };
        SavePermission(roleMod, function (result: any) {
            console.log("res..", result)
        })
    }

    //CanDeleteClick
    const canDeleteClick = (e: any) => {
        setDeletePermit(e.target.checked ? "1" : "0");
        let viewValue = e.target.checked;
        //console.log('e.target.checked....', e.target.checked);
        e.target.checked ? viewValue = "1" : viewValue = "0"
        const applyPermission: ApplyPermissionModel = {
            permissionid: permissionId,
            name: permissionName,
            canview: ViewPermit,
            canadd: AddPermit,
            canedit: EditPermit,
            candelete: viewValue,
            cansync: SyncPermit
        }

        const roleMod: role = {
            id: receivedRoleId,
            name: roleName,
            index: 0,
            recordPerPage: 50,
            totalRecord: 500,
            applypermission: applyPermission,
            createdOn: new Date(),
            existingId: 0
        };
        SavePermission(roleMod, function (result: any) {
           // console.log("res..", result)
        })
    }


    const handleData = (data: any) => {
        setFilteredValue(data);
        // setAddPermit(data[0].canadd == "1" ? "1" : "");
        // setViewPermit(data[0].canview == "1" ? "1" : "");
        // setSyncPermit(data[0].cansync == "1" ? "1" : "");
        // setEditPermit(data[0].canedit == "1" ? "1" : "");
        // setDeletePermit(data[0].candelete == "1" ? "1" : "");
        setAddPermit(data[0].canadd == "1" ? "1" : data[0].canadd == "0" ? "0" : "");
        setViewPermit(data[0].canview == "1" ? "1" : data[0].canview == "0" ? "0" : "");
        setSyncPermit(data[0].cansync == "1" ? "1" : data[0].cansync == "0" ? "0" : "");
        setEditPermit(data[0].canedit == "1" ? "1" : data[0].canedit == "0" ? "0" : "");
        setDeletePermit(data[0].candelete == "1" ? "1" : data[0].candelete == "0" ? "0" : "");
        setPermissionId(data[0].permissionid);
        setPermissionName(data[0].name);


    }
   // console.log('setFilteredValue....', filteredValue);
   // console.log('add....', AddPermit);
  //  console.log('delete....', DeletePermit);
  //  console.log('edit....', EditPermit);
   // console.log('sync....', SyncPermit);
   // console.log('view....', ViewPermit);




    // console.log('permissionId....', permissionId);


    //CanAdd
    // if(filteredValue && filteredValue.length > 0){
    //     const addval=filteredValue[0].canview;
    //     console.log("addval..",addval)
    //    // setViewPermit(addval);

    // }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <UserSideBar />
                </div>
                <div className="col-sm-9 col-md-9">
                    <div className="heading-section">
                        <h1>Edit Role</h1>
                        <div>
                            <button type="button" className="btn btn-primary" onClick={ChangeRoleButtonClick}>Save</button>
                            <button type="button" className="btn btn-primary ms-2" onClick={backRoleDB}>Back</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Name</label>
                                <input className="form-control" type="text" onChange={OnChangeRoleName} value={roleName} />
                            </div>

                            {/* <div className="mb-3 form-check">
                                <input className="form-check-input" type="checkbox" onChange={RoleActiveclick} checked={checkActive} />
                                <label className="form-check-label">
                                    Active
                                </label>
                            </div> */}

                        </div>
                    </div>
                    <h6 className="fw-bold">Permissions</h6>
                    <div className="row">
                        <div className="col-sm-6 col-md-6">
                            <header className="App-header">
                                {treeData.map((rootNode: any) => (
                                    <TreeNode key={rootNode.id} node={rootNode} handleData={handleData} roleIdValue={roleId} />
                                ))}
                            </header>
                        </div>
                        <div className="col-sm-6 col-md-6">
                            <div className="app-header-right">                            {
                                filteredValue && filteredValue.map((item: any, index) => (
                                    <h6 className="fw-bold" key={index}>{item.name}</h6>
                                ))
                            }
                                {
                                    filteredValue && filteredValue.map((item: any, index) => (
                                        <table className="table tbl-text-align">
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col">
                                                        {item.canview != "" ? "View" : null}
                                                    </th>
                                                    <th scope="col">
                                                        {item.canadd != "" ? "Add" : null}
                                                    </th>
                                                    <th scope="col">
                                                        {item.canedit != "" ? "Edit" : null}
                                                    </th>
                                                    <th scope="col">
                                                        {item.candelete != "" ? "Delete" : null}
                                                    </th>
                                                    <th scope="col">
                                                        {item.cansync != "" ? "Run" : null}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    filteredValue && filteredValue.map((item: any, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {/* {item.canview != "" ? item.canview == '1' ? <input className="form-check-input" type="checkbox" onChange={CanViewClick} checked={true} /> : <input className="form-check-input" type="checkbox" onChange={CanViewClick} checked={false} /> : null} */}
                                                                {item.canview != "" ? <input className="form-check-input" type="checkbox" onChange={canViewClick} checked={ViewPermit == "1" ? true : false} /> : null}
                                                            </td>
                                                            <td>
                                                                {/* {item.canadd != "" ? item.canadd == '1' ? <input className="form-check-input" type="checkbox" onChange={CanAddClick} checked={true} /> : <input className="form-check-input" type="checkbox" onChange={CanAddClick} checked={false} /> : null} */}
                                                                {item.canadd != "" ? <input className="form-check-input" type="checkbox" onChange={canAddClick} checked={AddPermit == "1" ? true : false} /> : null}
                                                            </td>
                                                            <td>
                                                                {/* {item.canedit != "" ? item.canedit == '1' ? <input className="form-check-input" type="checkbox" onChange={CanEditClick} checked={true} /> : <input className="form-check-input" type="checkbox" onChange={CanEditClick} checked={false} /> : null} */}
                                                                {item.canedit != "" ? <input className="form-check-input" type="checkbox" onChange={canEditClick} checked={EditPermit == "1" ? true : false} /> : null}
                                                            </td>
                                                            <td>
                                                                {/* {item.candelete != "" ? item.candelete == '1' ? <input className="form-check-input" type="checkbox" onChange={CanDeleteClick} checked={true} /> : <input className="form-check-input" type="checkbox" onChange={CanDeleteClick} checked={false} /> : null} */}
                                                                {item.candelete != "" ? <input className="form-check-input" type="checkbox" onChange={canDeleteClick} checked={DeletePermit == "1" ? true : false} /> : null}
                                                            </td>
                                                            <td>
                                                                {/* {item.cansync != "" ? item.cansync == '1' ? <input className="form-check-input" type="checkbox" onChange={CanSyncClick} checked={true} /> : <input className="form-check-input" type="checkbox" onChange={CanSyncClick} checked={false} /> : null} */}
                                                                {item.cansync != "" ? <input className="form-check-input" type="checkbox" onChange={canSyncClick} checked={SyncPermit == "1" ? true : false} /> : null}
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    ))
                                }
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={CloseUserPopup}>
                    {
                        isloader.isfailed ? (
                            <AFailed message={isloader.message} />
                        ) : (null)
                    }
                </div>
                <div onClick={CloseUserPopup}>
                    {
                        isloader.isSuccess ? (
                            <ASuccess message={isloader.message} />
                        ) : (null)
                    }
                </div>
            </div>
        </React.Fragment >
    )



}
export default roleEdit;