import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { GetEditPermission, UserRegister } from "../../dataservices/action/account/register";
import { user, createinitialUser, moduleinfo } from "../../dataservices/model/user";
import { useSelector } from 'react-redux'
import { GetUserLoad, DeleteUser, SaveEditUser } from "../../dataservices/action/users/usersload";
import SideBar from "../sidebar";
import { ValidateEmail } from '../../dataservices/helper/componenthelpers';
import ASuccess from "../alretbox/success";
import { PaginationValues } from "../pagenation/pageflow";
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
import Loader from "../loader";
import AFailed from "../alretbox/failed";
import { EALREADY } from "constants";
import { debug, group } from "console";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { settings } from "cluster";
import { filterentities, products } from "../../dataservices/model/mpproducts";
import { DeleteEntity, DeletePRule, GetMarketplaceAccountNumberForRelationshipType, GetFilterDatabyModuleIdandFilterId, GetAllCustomerGroup, GetAllcustomersbyCusGroupId, GetAllMarketplacebyAccountId, GetFilfillmentTypebyRuleType, GetAllMarketplaces, GetFilters, GetFiltersjson, GetmpLocationsANDagilityBranch, GetProductRulesLoad, GetProductsLoad, GetProductsLoadbyFilter, GetProductsLoadbyRules, Getrolepermissionbyuserid, GetSuppliers, GetValuesFromMpProducts, Inactivempproduct, SaveProductRules, UpdateActiveProduct, GetAllRuleType } from "../../dataservices/action/marketplace/marketplace";
import Globals from "../../dataservices/global";
import { channellist } from "../../dataservices/model/channellist";
import moment from "moment";
import MPSideBar from "../mpsidebar";
import { jobDetails } from "../../dataservices/model/jobDetails";
import { filters, filtersobjs, storeFilter } from "../../dataservices/model/filters";
import Atable from "../alretbox/table";
import { Createinitialjson, json } from "../../dataservices/model/json";
import Multiselect from "multiselect-react-dropdown";
import { Select } from "@material-ui/core";
//import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import { Pagination } from "reactstrap";
const ProductRules = (props: any) => {
    var intproducts: products[] = new Array<products>()
    const history = useHistory();
    const [products, setProducts] = useState(intproducts);
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const [isjsonv, setisjsonv] = useState(Createinitialjson());
    const [activestatus, setactivestatus] = useState("2");
    const accountid = props.location.state;
    const { accid } = useParams();
    var intallruletype: channellist[] = new Array<channellist>();
    const [ruleType, setruleType] = useState(intallruletype);
    const [ruleTypeId, setRuleTypeId] = useState(0);
    var intfulfillmenttype: channellist[] = new Array<channellist>();
    const [fulfillmentType, setFulfillmentType] = useState(intfulfillmenttype);
    const [fulfillmentId, setFulfillmentId] = useState(0);
    const store = useSelector((state: any) => { return state });
    const [TotalCount, setTotalCount] = useState(0);
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [PageSizeList, setPageSizeList] = useState([50, 100, 200]);
    const [activePage, setActivePage] = useState(0)
    const [magentoConnect, setmagentoConnect] = useState(false);
    const [amazonMp, setamazonMp] = useState(false);
    const [isLoader, setisLoader] = useState(false);
    const [sku, setsku] = useState("");
    const [fsku, setfsku] = useState("");
    const [tsku, settsku] = useState("");
    var intlocationlist: channellist[] = new Array<channellist>();
    const [loclist, setloclist] = useState(intlocationlist);
    const [showCustomer, setShowCustomer] = useState(false);
    const [customerName, setCustomerName] = useState("");

    var intbranchlist: channellist[] = new Array<channellist>();
    const [branchlist, setbranchlist] = useState(intbranchlist);

    var intcgrouplist: channellist[] = new Array<channellist>();
    const [cglist, setcglist] = useState(intcgrouplist);

    const [locationid, setlocationid] = useState(0);
    const [branchid, setbranchid] = useState(0);
    const [issearchcheck, setissearchcheck] = useState(false);

    const [accname, setaccname] = useState("");
    var intbAcclist: channellist[] = new Array<channellist>();
    const [bacclist, setbacclist] = useState(intbAcclist);
    const [ECategoryAttributes, setVCategoryAttributes] = useState(false);
    const [EAPIsettings, setVAPIsettings] = useState(false);
    const [EMagentoSync, setVMagentoSync] = useState(false);
    const [EInriverSync, setVInriverSync] = useState(false);
    const [EProducts, setVProducts] = useState(false);
    const [EInventoryRules, setVInventoryRules] = useState(false);
    const [Elogs, setVlogs] = useState(false);
    const [ESettings, setVSettings] = useState(false);
    const [isDisabled, setisDisabled] = useState("disabled");
    var vperList: moduleinfo[] = new Array<moduleinfo>();
    const [VPList, setVPList] = useState(vperList);
    var intsupplist: jobDetails[] = new Array<jobDetails>();
    const [suppList, setsuppList] = useState(intsupplist);
    const [supplierId, setsupplierId] = useState("");
    var intPrdgrplist: channellist[] = new Array<channellist>();
    const [Prdgrplist, setPrdgrplist] = useState(intPrdgrplist);

    var intPricemaxlist: channellist[] = new Array<channellist>();
    const [Pricemaxlist, setPricemaxlist] = useState(intPricemaxlist);

    var intPriceminlist: channellist[] = new Array<channellist>();
    const [Priceminlist, setPriceminlist] = useState(intPriceminlist);


    var intmfglist: channellist[] = new Array<channellist>();
    const [mfglist, setmfglist] = useState(intmfglist);


    var intfilterlist: filters[] = new Array<filters>();
    const [filter, setfilterlist] = useState(intfilterlist);

    var intfilterobj: filtersobjs[] = new Array<filtersobjs>();
    const [filterobj, setfilterobj] = useState(intfilterobj);

    var initialfil: filtersobjs[] = new Array<filtersobjs>();

    const [prdgrp, setprdgrp] = useState("");
    const [pricemax, setpricemax] = useState("");
    const [pricemin, setpricemin] = useState("");
    const [mfg, setmfg] = useState("");


    var intMPAcclist: channellist[] = new Array<channellist>();
    const [mpacclist, setmpacclist] = useState(intMPAcclist);
    const [displayList, setDisplayList] = useState(intMPAcclist);

    var etylist: string[] = new Array<string>();
    const [enlist, setenlist] = useState(etylist);
    const [invtypeValue, setinvtypeValue] = useState(4);

    var filterlist: string[] = new Array<string>();
    const [flist, setflist] = useState(filterlist);
    const [ischange, setischange] = useState(false);

    var listofprod: object[] = new Array<object>();
    const [lstprod, setlstprod] = useState(listofprod);

    var listofprodrules: products[] = new Array<products>();
    const [lstprodrules, setlstprodrules] = useState(listofprodrules);

    const [pjson, setpjson] = useState("");
    var mpfilter: filterentities[] = new Array<filterentities>();
    const [mpfilters, setmpfilters] = useState(mpfilter);
    const [cgid, setcgid] = useState(0);
    const [etid, setetid] = useState(2);
    const [ecgid, setecgid] = useState(0);
    const [productcount, setproductcount] = useState(0);
    const [filteravl, setfilteravl] = useState(false);
    var intAcclist: channellist[] = new Array<channellist>();
    const [acclist, setacclist] = useState(intAcclist);
    const [accno, setaccno] = useState(0);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [ruleTypeIdExist, setRuleTypeIdExist] = useState(0);
    const [fulfillmentIdExist, setFulfillmentIdExist] = useState(0);
    const [filename, setFilename] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [nameAccNoList, setNameAccNoList] = useState([]);
    const [selectedValues, setSelectedValues] = useState<string[]>([])
    const inputFile: any = useRef();
    useEffect(() => {
        GetAllRuleType("F", function (result: any) {
            // console.log("res..GetAllRuleType..", result);
            setruleType(result);

        })
    }, [])
    useEffect(() => {
        setisLoader(true);
        GetValuesFromMpProducts(1, function (result: any) {
            setPrdgrplist(result);
            setisLoader(false);

        })
    }, [])
    useEffect(() => {
        setisLoader(true);
        GetValuesFromMpProducts(2, function (result: any) {
            setPricemaxlist(result);
            setisLoader(false);

        })
    }, [])
    useEffect(() => {
        setisLoader(true);
        GetValuesFromMpProducts(3, function (result: any) {
            setPriceminlist(result);
            setisLoader(false);

        })
    }, [])
    useEffect(() => {
        setisLoader(true);
        GetValuesFromMpProducts(4, function (result: any) {
            setmfglist(result);
            setisLoader(false);

        })
    }, [])
    //useEffect(() => {

    //    setisLoader(true);
    //    GetFilters(5, function (result: any) {

    //        setfilterlist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    useEffect(() => {

        setisLoader(true);
        GetFiltersjson(5, function (result: any) {

            setfilterobj(result);
            setisLoader(false);

        })
    }, [])
    //useEffect(() => {
    //    setisLoader(true);
    //    //supplierList
    //    GetSuppliers(function (result: any) {
    //        setsuppList(result);
    //        setisLoader(false);
    //    })
    //}, [])
    const changeAccNo = (e: any) => {
        setaccno(e.target.value);
        GetProductRulesLoad(0, 100, ruleTypeIdExist.toString(), fulfillmentIdExist.toString(), String(ecgid), e.target.value, function (result: any) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            setlstprodrules(result);
            setTotalCount(result[0].totalRecord)
            setPage(result[0].index);
            setPageSize(result[0].recordPerPage)
            setActivePage(Page);
            setisLoader(false);
        })
    }
    useEffect(() => {
        setisLoader(true);
        GetAllMarketplaces(function (result: any) {
            if (result.length > 0) {
                setbacclist(result);
                for (var i = 0; i < result.length; i++) {
                    if (result[i].customerId == Number(accid)) {
                        setaccname(result[i].message);
                    }
                }
                setacclist(result);
                setisLoader(false);
            }
        })
    }, [])

    useEffect(() => {
        setisLoader(true);
        GetmpLocationsANDagilityBranch(1, function (result: any) {
            setloclist(result);
            setisLoader(false);

        })
    }, [])
    useEffect(() => {
        setisLoader(true);
        GetmpLocationsANDagilityBranch(2, function (result: any) {
            setbranchlist(result);
            setisLoader(false);

        })
    }, [])

    useEffect(() => {
        setisLoader(true);
        GetAllCustomerGroup(function (result: any) {
            setcglist(result);
            setisLoader(false);
        })
    }, [])

    const [APRules, setAPRules] = useState(false);
    const [EPRules, setEPRules] = useState(false);
    const [DPRules, setDPRules] = useState(false);
    //const [CSIMsync, setCSIMsync] = useState(false);
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        // console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 8:
                                if (result[i].canadd == "1") {
                                    setAPRules(true);
                                }
                                if (result[i].canedit == "1") {
                                    setEPRules(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDPRules(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])

    useEffect(() => {
        setisLoader(true);
        GetProductRulesLoad(0, 100, ruleTypeIdExist.toString(), fulfillmentIdExist.toString(), String(ecgid), String(accno), function (result: any) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setlstprodrules(result);
                setTotalCount(result.length);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page);
                setisLoader(false);
            }
            else {
                setlstprodrules(result);
                setTotalCount(0);
            }
        })
    }, [])

    useEffect(() => {

        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetAllcustomersbyCusGroupId(0, function (result: any) {

            setmpacclist(result);
            setDisplayList(result);
        });
        GetFilters(5, function (result: any) {

            setfilterlist(result);
            GetFiltersjson(5, function (result: any) {

                setfilterobj(result);
                GetProductsLoadbyRules("1", result, Page, PageSize, flist, false, function (result: any) {

                    let load: Loadermessage = {
                        message: "",
                        isLoader: false,
                        isSuccess: false,
                        isfailed: false
                    }
                    if (result != null && result.length > 0) {
                        debugger;
                        setlstprod(result);
                        let jon: json = {
                            message: JSON.stringify(result),
                            isSuccess: true

                        }
                        setisjsonv(jon);

                        setpjson(JSON.stringify(result));
                        setProducts(result);
                        setTotalCount(result[0].totalRecord)
                        setproductcount(result.length)
                        setPage(result[0].index);
                        setPageSize(result[0].recordPerPage)
                        setActivePage(Page)


                    }
                    else {
                        let jon: json = {
                            message: JSON.stringify(result),
                            isSuccess: true

                        }
                        setisjsonv(jon);

                        setpjson(JSON.stringify(result));
                    }
                    Setisloader(load);
                })

            })


        })
        //GetProductsLoad(accid, sku, locationid, branchid, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, Page, PageSize, function (result: products[]) {
        //    let load: Loadermessage = {
        //        message: "",
        //        isLoader: false,
        //        isSuccess: false,
        //        isfailed: false
        //    }
        //    if (result != null && result.length > 0) {
        //        setProducts(result);
        //        setTotalCount(result[0].totalRecord)
        //        setPage(result[0].index);
        //        setPageSize(result[0].recordPerPage)
        //        setActivePage(Page)


        //    }
        //    Setisloader(load);
        //})
    }, [])

    const schedule = [
        {
            Sno: "1",
            "First Name": "name",
            "Last Name": "las2t name",
            Email: "test@gmail.com",
            Amount: "2000"
        },
        {
            Sno: "2",
            "First Name": "first name",
            "Last Name": "last name",
            Email: "test2@gmail.com",
            Amount: "2000"
        }
    ];
    const Pagenation = () => {
        let Pagination = new PaginationValues();
        Pagination.TotalItemCount = TotalCount;
        Pagination.CurrentPage = Page;
        Pagination.PageSlide = 2;
        Pagination.IsSort = true;
        Pagination.PageSize = PageSize;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page - 1) * PageSize) + 1}    - {((((Page - 1) * PageSize) + 1) + PageSize) > TotalCount ? TotalCount : ((((Page - 1) * PageSize)) + PageSize)} of {TotalCount} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">

                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                                {
                                    Pagination.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination.Pages().map((x, i) =>
                                        x == Page ?
                                            (
                                                <li className={`page-item  ${activePage == x ? 'active' : null}`} key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className="page-link" onClick={() => SetPage(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }

    const SetPageSize = (size: number) => {
        setPageSize(size);
        //RefreshProducts();
        setisLoader(true);
        GetProductsLoad(accid, sku, locationid, branchid, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, Page, size, function (result: products[]) {

            if (result != null) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                setisLoader(false);

            }
        })
    }

    const SetPage = (page: number) => {
        setPage(page);
        //setActivePage(page)
        //RefreshProducts();
        //setisLoader(true);
        GetProductsLoad(accid, sku, locationid, branchid, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, page, PageSize, function (result: products[]) {
            if (result != null) {
                setProducts(result);
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(page);
                // setisLoader(false);

            }
        })
    }

    const RefreshProducts = () => {

        setisLoader(true);
        GetProductsLoad(accid, sku, locationid, branchid, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, 1, 50, function (result: products[]) {
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page);
                setisLoader(false);
            }
            setisLoader(false);
        })
    }


    const backUserDB = () => {
        setmagentoConnect(false);
        setamazonMp(false);
        Globals.IsAmazon = false;
        Globals.IsMagento = false;
        history.push('/userdashboard');
    }
    const changeSearchSku = (e: any) => {
        setsku(e.target.value);
        // console.log(sku);

    }
    const changelocation = (e: any) => {
        let locid = e.target.value;
        setlocationid(locid);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoad(accid, sku, e.target.value, branchid, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, Page, PageSize, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page);
            }
            else {
                setProducts(result);
                setTotalCount(0);
            }

            Setisloader(load);
        })

    }

    const changeCgroup = (e: any) => {
        let cgidv = e.target.value;
        setcgid(cgidv);
        debugger;
        GetAllcustomersbyCusGroupId(cgidv, function (result: any) {
            setmpacclist(result);
            setDisplayList(result);
        });
    }

    setcgid

    const changebranch = (e: any) => {
        let branchid = e.target.value;
        setbranchid(branchid);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoad(accid, sku, locationid, e.target.value, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, Page, PageSize, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            else {
                setProducts(result);
                setTotalCount(0);
            }
            Setisloader(load);
        })

    }
    const changeSupplierId = (e: any) => {
        let supplierid = e.target.value;
        setsupplierId(supplierid);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoad(accid, sku, locationid, branchid, activestatus, e.target.value, prdgrp, pricemax, pricemin, mfg, Page, PageSize, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            else {
                setProducts(result);
                setTotalCount(0);
            }
            Setisloader(load);
        })
    }
    const changeprdgrp = (e: any) => {
        setprdgrp(e.target.value);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoad(accid, sku, locationid, branchid, activestatus, supplierId, e.target.value, pricemax, pricemin, mfg, Page, PageSize, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            else {
                setProducts(result);
                setTotalCount(0);
            }
            Setisloader(load);
        })
    }
    const changeprimax = (e: any) => {
        setpricemax(e.target.value);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoad(accid, sku, locationid, branchid, activestatus, supplierId, prdgrp, e.target.value, pricemin, mfg, Page, PageSize, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            else {
                setProducts(result);
                setTotalCount(0);
            }
            Setisloader(load);
        })
    }
    const changeprimin = (e: any) => {
        setpricemin(e.target.value);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoad(accid, sku, locationid, branchid, activestatus, supplierId, prdgrp, pricemax, e.target.value, mfg, Page, PageSize, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            else {
                setProducts(result);
                setTotalCount(0);
            }
            Setisloader(load);
        })
    }
    const changemfgname = (e: any) => {
        setmfg(e.target.value);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoad(accid, sku, locationid, branchid, activestatus, supplierId, prdgrp, pricemax, pricemin, e.target.value, Page, PageSize, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            else {
                setProducts(result);
                setTotalCount(0);
            }
            Setisloader(load);
        })
    }

    const SearchValues = (e: any) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoad(accid, sku, locationid, branchid, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, Page, PageSize, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)

            }
            else {
                setProducts(result);
                setTotalCount(0);
            }
            Setisloader(load);
        })
    }

    const handleActiveClick = (id: number, e: any) => {
        const { value, checked } = e.target;
        confirmAlert({
            title: 'Update Product Status',
            message: 'Pls confirm to Update Status ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Inactiveproduct(id, checked)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const Inactiveproduct = (id: number, ischk: boolean) => {

        let tmpproduct = products.map((mp) => mp.id === Number(id) ? { ...mp, isActive: ischk } : mp);
        setProducts(tmpproduct);

        debugger;
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        Inactivempproduct(id, ischk, function (result: any) {
            if (result.isSuccess) {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "product active status updated successfully"
                Setisloader(result);
                RefreshProducts();
            }
        })
    }
    const handleAllClick = (e: any) => {
        debugger;
        const { value, checked } = e.target;
        //let tmpproduct = mpacclist.map((mp) => {
            let tmpproduct = displayList.map((mp) => {

            return { ...mp, isSuccess: checked };
        });

        var ifilter = [...tmpproduct];
        ifilter.forEach(element => {
            let entityval = element.customerId;
            if (element.isSuccess) {
                setenlist(enlist => [...enlist, String(entityval)]);
            }
            else {
                setenlist(enlist.filter((e) => e !== String(entityval)));
            }

        });
        // console.log(enlist);


        setmpacclist(tmpproduct);
        setDisplayList(tmpproduct);
        //confirmAlert({
        //    title: 'Update Product Status',
        //    message: 'Pls confirm to Update Status ?',
        //    buttons: [
        //        {
        //            label: 'Yes',
        //            onClick: () => Inactiveallproduct(checked)
        //        },
        //        {
        //            label: 'No',
        //            onClick: () => { }
        //        }
        //    ]
        //});

    }
    const ClosePopup = () => {
        Setisloader(CreateinitialLoadermessage())
    }

    const Inactiveallproduct = (ischk: boolean) => {
        debugger;
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        UpdateActiveProduct(accid, sku, locationid, branchid, activestatus, ischk, Page, PageSize, function (result: any) {
            let result1 = CreateinitialLoadermessage();
            result1.isSuccess = true;
            result1.message = "product active status updated successfully"
            Setisloader(result1);
            RefreshProducts();
        })
    }

    const AddProductRules = (e: any) => {
        debugger;
        setisLoader(true);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        var accid = enlist.join();
        if (customerName.includes("ALL")) {  //If it is ALL
            //customerId = "0";
            enlist.length = 1;
        }
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.code === "SKU") {
                element.value1 = fsku;
                element.value2 = tsku;
            }
        });

        setfilterobj(ifilter);
        // setfilteravl(false);
        // var ifilterval = [...filterobj];
        // console.log("ifilterval....",ifilterval)
        // ifilterval.forEach(element => {
        //     if (element.value1 !== "") {
        //         setfilteravl(true);
        //     }
        // });



        //console.log("filteravl....", filteravl)
        if (ruleTypeId != 0) {
            if (enlist.length != 0 && filteravl) { // here filterval is added instead of ischange.
                SaveProductRules(ruleTypeId, fulfillmentId, accid, filterobj, invtypeValue, function (result: any) {
                    let load: Loadermessage = {
                        message: "Rule Activated Successfully",
                        isLoader: false,
                        isSuccess: true,
                        isfailed: false
                    }
                    Setisloader(load);
                    // setisLoader(false);
                    //RefreshProductRules();
                    history.go(0);
                })
            }
            else {
                if (enlist.length == 0) {
                    let result1 = CreateinitialLoadermessage();
                    result1.isfailed = true;
                    result1.message = "please select atleast one customer"
                    Setisloader(result1);
                    setisLoader(false);
                }
                else if (filteravl !== true) {
                    let result1 = CreateinitialLoadermessage();
                    result1.isfailed = true;
                    result1.message = "please select atleast one filter"
                    Setisloader(result1);
                    setisLoader(false);
                }
            }

        }
        else {
            let result1 = CreateinitialLoadermessage();
            result1.isfailed = true;
            result1.message = "please select Rule Type"
            Setisloader(result1);
            setisLoader(false);
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    // const OpenFileExplore = () => {
    //     inputFile.current.click();
    // };
    // const handleFileUpload = (e: any) => {
    //     e.preventDefault();
    //     const { files } = e.target;
    //     if (files && files.length) {
    //         setFilename(files[0].name);
    //         setSelectedFile(files[0]);
    //         const formData = new FormData();
    //         formData.append('File', files[0]);
    //         let load: Loadermessage = {
    //             message: "",
    //             isLoader: true,
    //             isSuccess: false,
    //             isfailed: false
    //         }
    //         Setisloader(load);
    //         // setLoading(true);
    //         try {
    //             const response = axios({
    //                 method: "post",
    //                 url: "api/MP/UploadProductRuleFile",
    //                 data: formData,
    //                 headers: { "Content-Type": "multipart/form-data" },
    //             }).then(result => {
    //                 let load: Loadermessage = {
    //                     message: "File Uploaded Successfully",
    //                     isLoader: false,
    //                     isSuccess: true,
    //                     isfailed: false
    //                 }
    //                 Setisloader(load);
    //                 // setLoading(false);
    //                 //console.log(result);
    //             });



    //         } catch (error) {
    //             console.log(error)
    //         }
    //     }
    // }

    const RefreshProductRules = () => {
        setisLoader(true);
        GetProductRulesLoad(0, 100, ruleTypeIdExist.toString(), fulfillmentIdExist.toString(), String(ecgid), String(accno), function (result: any) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            setlstprodrules(result);
            setTotalCount(result[0].totalRecord)
            setPage(result[0].index);
            setPageSize(result[0].recordPerPage)
            setActivePage(Page);
            setisLoader(false);
        })
    }
    const ApplyRules = (e: any) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);


        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.code === "SKU") {
                element.value1 = fsku;
                element.value2 = tsku;
            }
        });
        setfilterobj(ifilter);
        GetProductsLoadbyRules("1", filterobj, Page, PageSize, mpfilters, true, function (result: any) {

            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                let jon: json = {
                    message: JSON.stringify(result),
                    isSuccess: true

                }
                setisjsonv(jon);

                setpjson(JSON.stringify(result));
                setProducts(result);
                setTotalCount(result.length);
                setproductcount(result.length);
                // console.log(result.length);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
            }
            else {
                setProducts(result);
                let jon: json = {
                    message: JSON.stringify(result),
                    isSuccess: true

                }
                setisjsonv(jon);
                setPage(1),
                    setPageSize(50),
                    setTotalCount(0);
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            Setisloader(load);
        })
    }


    const changeFilters = (id: any, name: string, e: any) => {
        debugger;
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);


        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.code === name) {
                if (name == "SKU") {
                    element.value1 = fsku

                } else {
                    element.value1 = e.target.value;
                }
            }
        });

        const { value, checked } = e.target;
        let entityval = e.target.id;

        let mplistval: filterentities = {
            id: id
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        //setflist(flist => [...flist, id]);

        //setflist([...flist, id])

        //setflist(flist.filter((e) => e !== id));


        setischange(true);



        setfilterobj(ifilter);
        //GetProductsLoadbyRules("1", filterobj, Page, PageSize, mpfilters, true, function (result: any) {
        //    debugger;
        //    let load: Loadermessage = {
        //        message: "",
        //        isLoader: false,
        //        isSuccess: false,
        //        isfailed: false
        //    }
        //    if (result != null && result.length > 0) {
        //        let jon: json = {
        //            message: JSON.stringify(result),
        //            isSuccess: true

        //        }
        //        setisjsonv(jon);

        //        setpjson(JSON.stringify(result));
        //        setProducts(result);
        //        setTotalCount(result[0].totalRecord)
        //        setPage(result[0].index);
        //        setPageSize(result[0].recordPerPage)
        //        setActivePage(Page)
        //    }
        //    else {
        //        setProducts(result);
        //        let jon: json = {
        //            message: JSON.stringify(result),
        //            isSuccess: true

        //        }
        //        setisjsonv(jon);
        //        setTotalCount(0);
        //        let load: Loadermessage = {
        //            message: "",
        //            isLoader: false,
        //            isSuccess: false,
        //            isfailed: false
        //        }
        //        Setisloader(load);
        //    }
        //    Setisloader(load);
        //})
        let load1: Loadermessage = {
            message: "",
            isLoader: false,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load1);
    }
    const changeFilterSku = (e: any) => {
        setfsku(e.target.value);
        setischange(true);
        setfilteravl(true)


    }
    const changeFilterTSku = (e: any) => {
        settsku(e.target.value);
        setischange(true);
        setfilteravl(true);

    }
    const searchisactive = (e: any) => {
        const { value, checked } = e.target;
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        setactivestatus(e.target.value);
        GetProductsLoad(accid, sku, locationid, branchid, e.target.value, supplierId, prdgrp, pricemax, pricemin, mfg, Page, PageSize, function (result: products[]) {
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page);
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            else {
                setProducts(result);
                setTotalCount(0);
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
        })

    }


    const handleMPAccClick = (id: number, e: any) => {
        const { value, checked } = e.target;
        let entityval = e.target.id;

        if (e.target.checked) {
            setenlist(enlist => [...enlist, entityval]);
        }
        else {
            setenlist(enlist.filter((e) => e !== entityval));
        }
        // var ifilter1 = [...mpacclist];
        var ifilter1 = [...displayList];
        ifilter1.forEach(element => {
            let entityval = e.target.id;
            if (element.customerId == entityval) {
                element.isSuccess = e.target.checked;
            }


        });

        //console.log(enlist);
        //setisaccchecked(false);

    }
    const changeType = (e: any) => {
        setinvtypeValue(e.target.value);
    }

    // const changeEType = (e: any) => {
    //     debugger;
    //     setetid(e.target.value);
    //     GetProductRulesLoad(0, 100, e.target.value, String(ecgid), String(accno), function (result: any) {
    //         let load: Loadermessage = {
    //             message: "",
    //             isLoader: false,
    //             isSuccess: false,
    //             isfailed: false
    //         }
    //         setlstprodrules(result);
    //         setTotalCount(result[0].totalRecord)
    //         setPage(result[0].index);
    //         setPageSize(result[0].recordPerPage)
    //         setActivePage(Page);
    //         setisLoader(false);
    //     })
    // }

    const [activeprodclass, setactiveprodclass] = useState(true);
    const [activeEprdclass, setactiveEprdclass] = useState(false);

    var activeclassname = "nav-link active";
    var inactiveclassname = "nav-link";
    const handleChange = (e: any) => {
        if (e.target.id == 1) {
            setactiveprodclass(true);
            setactiveEprdclass(false);

        }
        if (e.target.id == 2) {
            setactiveprodclass(false);
            setactiveEprdclass(true);
            //RefreshProductRules();
        }

    }
    function refreshPage() {
        window.location.reload();
    }

    const changeEcustpmergroup = (e: any) => {
        let cgidv = e.target.value;
        setecgid(cgidv);
        debugger;
        GetProductRulesLoad(0, 100, ruleTypeIdExist.toString(), fulfillmentIdExist.toString(), e.target.value, String(accno), function (result: any) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            setlstprodrules(result);
            setTotalCount(result[0].totalRecord)
            setPage(result[0].index);
            setPageSize(result[0].recordPerPage)
            setActivePage(Page);
            setisLoader(false);
        })
    }
    const [selectedOptions, setSelectedOptions] = useState();
    const options = [
        { label: "Grapes 🍇", value: "brand$GrapsSS" },
        { label: "Mango 🥭", value: "mango" },
        { label: "Strawberry 🍓", value: "strawberry", disabled: true },
    ];
    const [selected, setSelected] = useState([]);
    const handleRemove2 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 2) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }
    const handleSelect2 = (selectedList: any, selectedItem: any) => {

        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 2) {
                element.value1 = selectedList.join(',');
            }
        });

        let mplistval: filterentities = {
            id: "2"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);
        setischange(true);
        setfilterobj(ifilter);
        // console.log(selectedItem);
    }
    const handleRemove4 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 4) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }
    const handleSelect4 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 4) {

                element.value1 = selectedList.join(',');

            }
        });

        // const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "4"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        //console.log(selectedItem);
    }
    const handleRemove5 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 5) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }
    const handleSelect5 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 5) {

                element.value1 = selectedList.join(',');

            }
        });

        //const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "5"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        // console.log(selectedItem);
    }
    const handleRemove6 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 6) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }
    const handleSelect6 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 6) {

                element.value1 = selectedList.join(',');

            }
        });

        //const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "6"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        //console.log(selectedItem);
    }
    const handleRemove11 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 11) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }
    const handleSelect11 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 11) {

                element.value1 = selectedList.join(',');

            }
        });

        //const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "11"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        //console.log(selectedItem);
    }
    const handleRemove12 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 12) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }

    const handleSelect12 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 12) {

                element.value1 = selectedList.join(',');

            }
        });

        //const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "12"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        //console.log(selectedItem);
    }
    const handleRemove13 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 13) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }
    const handleSelect13 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 13) {

                element.value1 = selectedList.join(',');

            }
        });

        //const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "13"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        // console.log(selectedItem);
    }
    const handleRemove14 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 14) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }

    const handleSelect14 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 14) {

                element.value1 = selectedList.join(',');

            }
        });

        //const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "14"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        //console.log(selectedItem);
    }
    const handleRemove15 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 15) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }

    const handleSelect15 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 15) {

                element.value1 = selectedList.join(',');

            }
        });

        //const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "15"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        // console.log(selectedItem);
    }
    const handleRemove21 = (selectedList: any, selectedItem: any) => {
        debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 21) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
    }

    const handleSelect21 = (selectedList: any, selectedItem: any) => {
        debugger;
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === 21) {

                element.value1 = selectedList.join(',');

            }
        });

        //const { value, checked } = e.target;
        //let entityval = e.target.id;

        let mplistval: filterentities = {
            id: "21"
        }
        setmpfilters(mpfilters => [...mpfilters, mplistval]);


        setischange(true);



        setfilterobj(ifilter);
        //console.log(selectedItem);
    }

    const onSelect = (selectedList: any, selectedItem: any) => {
        debugger;
        alert(selectedItem);
        //updateListObj([...listObj, selectedItem])
    }
    const DeleteAlert = (id: number) => {
        confirmAlert({
            title: 'Delete Rule',
            message: 'Are you sure you want to deactivate the Rule?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Delete(id)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const Delete = (id: number) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        DeletePRule(id, function (result: any) {
            if (result.isSuccess) {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "Rule deactivated successfully"
                Setisloader(result);
                RefreshProductRules();
            }
        })
    }
    const DownloadProductRule = () => {
        if (filterobj != null) {
            try {
                let load: Loadermessage = {
                    message: "",
                    isLoader: true,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
                debugger;
                var data = new FormData();
                data.append("filters", JSON.stringify(filterobj));
                const response = axios({
                    method: "post",
                    url: "api/MP/DownloadProductRule",
                    data: data,
                    headers: { "Content-Type": "multipart/form-data" },
                    responseType: 'blob',
                }).then((result) => {

                    let resultmes = CreateinitialLoadermessage();
                    if (result != null) {
                        debugger;
                        const url = window.URL.createObjectURL(new Blob([result.data]));
                        const a = document.createElement('a');
                        a.href = url;
                        a.setAttribute('download', "ProductRule.csv");
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        URL.revokeObjectURL(url);

                    }
                    resultmes.isLoader = false;
                    Setisloader(resultmes);
                });
                //Setisloader(load);
            } catch (error) {
                console.log(error)
            }
        }
        else {
            let load: Loadermessage = {
                message: "Select Atleast one filter and download",
                isLoader: false,
                isSuccess: false,
                isfailed: true
            }
            Setisloader(load);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        }
    }
    useEffect(() => {
        GetFilfillmentTypebyRuleType(ruleTypeId, function (result: any) {
            //console.log("res..fulfill..", result);
            setFulfillmentType(result);

        })

    }, [ruleTypeId])
    useEffect(() => {
        GetFilfillmentTypebyRuleType(ruleTypeIdExist, function (result: any) {
            // console.log("res..fulfill..", result);
            setFulfillmentType(result);

        })

    }, [ruleTypeIdExist])

    const changeRuleType = (e: any) => {
        //console.log("rulechanged.....", e.target.value)
        setRuleTypeId(e.target.value);
        setOpenDropdown(true);
    }
    const changeRuleTypeExisting = (e: any) => {

        setRuleTypeIdExist(e.target.value);
        GetProductRulesLoad(0, 100, e.target.value, fulfillmentIdExist.toString(), String(ecgid), String(accno), function (result: any) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setlstprodrules(result);
                setTotalCount(result.length);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page);
                setisLoader(false);
            }
            else {
                setlstprodrules(result);
                setTotalCount(0);
            }
        })
        //setOpenDropdown(true);
    }


    const changeFulFillmentType = (e: any) => {
        setFulfillmentId(e.target.value);
        // console.log("tar....", e.target);
        const selectedOption = e.target.selectedOptions[0];
        const customAttribute = selectedOption.getAttribute('data-custom-attribute');
        setCustomerName(customAttribute);
        // console.log("customAttribute ..", customAttribute)
        if (customAttribute == "CustomerList(NF)") {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            setNameAccNoList([])
            GetMarketplaceAccountNumberForRelationshipType("D", function (result: any) {
                //console.log("cust....", result);
                setmpacclist(result);
                setDisplayList(result);
                const resultArray = result.reduce((acc: any, item: any) => {
                    acc.push(item.message, item.name);
                    return acc;
                }, []);
                const options = resultArray.map((item: any, index: any) => ({
                    id: index,
                    name: item
                }));
                setNameAccNoList(options);
                console.log("options..D..", options);
                setisLoader(false);
            })
            setShowCustomer(true);
        } else if (customAttribute == "CustomerList(F)") {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            setNameAccNoList([])
            GetAllcustomersbyCusGroupId(0, function (result: any) {

                setmpacclist(result);
                setDisplayList(result);
                const resultArray = result.reduce((acc: any, item: any) => {
                    acc.push(item.message, item.name);
                    return acc;
                }, []);
                const options = resultArray.map((item: any, index: any) => ({
                    id: index,
                    name: item
                }));
                setNameAccNoList(options);
                console.log("options....", options);
                setisLoader(false);
            });
            setShowCustomer(true);
        } else {
            setShowCustomer(false);

        }

        // if (e.target.value == 4 || e.target.value == 5) {

        //     setShowCustomer(true);
        // }
        // else {
        //     setShowCustomer(false);

        // }

    }
    const changeFulFillmentTypeExisting = (e: any) => {
        setFulfillmentId(e.target.value);
        // console.log("tar....", e.target);
        GetProductRulesLoad(0, 100, ruleTypeIdExist.toString(), e.target.value, String(ecgid), String(accno), function (result: any) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setlstprodrules(result);
                setTotalCount(result.length);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page);
                setisLoader(false);
            }
            else {
                setlstprodrules(result);
                setTotalCount(0);
            }
        })

    }

    const handleSelect = (selectedList: any, selectedItem: any, filterId: any) => {
        //console.log("sele....", filterId)
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === filterId) {
                //  console.log("coming....")
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
        setischange(true);
        setfilteravl(true);
        // console.log("selectedItem....",ifilter);
    }
    const eventRef = React.useRef(null);
    const handleRemove = (selectedList: any, selectedItem: any, filterId: any) => {
        // debugger;

        var ifilter = [...filterobj];
        // console.log('filterId', filterId);
        ////console.log('selectedList', selectedList);
        //console.log('selectedItem', selectedItem);
        ifilter.forEach(element => {
            if (element.id === filterId) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
        //setfilteravl(false);
        const event: any = eventRef.current;
        event.stopPropagation();
        //console.log("DeselectedItem....",ifilter);
    }
    // var intfilterlist1: filters[] = new Array<filters>();
    // const [options1, setOptions1] = useState(intfilterlist1);
    // const [isLoading, setIsLoading] = useState(false);
    // const loadOptions = (e: any) => {
    //     console.log("calling")
    //     console.log("DropDownClicked....", e.currentTarget.getAttribute('data-d-id'));
    //     let filterId = e.currentTarget.getAttribute('data-d-id');
    //     // setOptions([]);
    //     setIsLoading(true);
    //     GetFilterDatabyModuleIdandFilterId(1, filterId, function (result: any) {
    //         const optionsList = result.map((item: any) => item.value);
    //         setOptions1(optionsList);
    //         setIsLoading(false);
    //     });

    // }
    const [options1, setOptions1] = useState<string[]>([]);
    const [storeFilters, setStoreFilters] = useState<storeFilter[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const loadOptions = (e: any) => {
        setOptions1([]);
        let filterId: number = e.currentTarget.getAttribute('data-d-id');
        console.log('loadOptions filterId', filterId);
        setIsLoading(true);
        let isFilterExist: storeFilter[] = storeFilters.filter(x => x.filterID == filterId);
        if (isFilterExist.length > 0) {
            setOptions1(isFilterExist[0].values);
            setIsLoading(false);
        } else {
            GetFilterDatabyModuleIdandFilterId(1, filterId, function (result: any) {
                const optionsList: string[] = result.map((item: any) => item.value);
                let makeFilteredStoredData: storeFilter = {
                    filterID: filterId,
                    values: optionsList
                };
                let sum: storeFilter[] = storeFilters;
                sum.push(makeFilteredStoredData);
                setStoreFilters(storeFilters);
                setOptions1(optionsList);
                setIsLoading(false);
            });
        }
    }
    //console.log('options', options1);
    const handleSelectCustomer = (selectedList: any, selectedItem: any) => {

    }
    const onSelectAcc = (selectedList: any, selectedItem: any) => {
        console.log("Selected item:", selectedList);

        setSelectedValues(selectedList);
        // Update your state or handle the selected item

        //const filteredResults = mpacclist.filter(item => selectedList.includes(item.message) || selectedList.includes(item.name));
        //setmpacclist(filteredResults);

        const namesSet = new Set(selectedList.map((item: any) => item.name));
        const filteredJsonData = mpacclist.filter((item: any) => namesSet.has(item.name) || namesSet.has(item.message));
        setDisplayList(filteredJsonData);
    };

    const onRemoveAcc = (selectedList: any, removedItem: any) => {
        console.log("Removed item:", selectedList);
        setSelectedValues(selectedList);
        if (selectedList.length != 0) {
            const namesSet = new Set(selectedList.map((item: any) => item.name));
            const filteredJsonData = mpacclist.filter((item: any) => namesSet.has(item.name) || namesSet.has(item.message));
            setDisplayList(filteredJsonData);

        } else {
            setDisplayList(mpacclist);

        }


    };
    return (
        <React.Fragment>
            {/*<div className="row">*/}
            {/*    <div className="col-md-12">*/}
            {/*        <div className="mpc-menu">*/}
            {/*            <nav aria-label="breadcrumb">*/}
            {/*                <ol className="breadcrumb">*/}
            {/*                    <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Home</a></li>*/}
            {/*                    <li className="breadcrumb-item" aria-current="page">*/}
            {/*                        <span>Product Listing</span>*/}
            {/*                    </li>*/}
            {/*                    <li className="breadcrumb-item active" aria-current="page">*/}
            {/*                        <div className="head-accno">*/}
            {/*                            Account No: <span>{accname}</span>*/}
            {/*                        </div>*/}
            {/*                    </li>*/}
            {/*                </ol>*/}
            {/*            </nav>*/}
            {/*            <MPSideBar id={accid} />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="row">
                {

                    isloader.isLoader ? (<Loader isActive={isloader} />) : (null)
                }

                <div className="col-md-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Product Rules
                            </li>
                        </ol>
                    </nav>
                    <div className="heading-section">
                        <h1>Product Rules</h1>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <button className={activeprodclass == true ? activeclassname : inactiveclassname} aria-current="page" id="1" onClick={handleChange} >New Product Rule</button>
                                </li>
                                <li className="nav-item">
                                    <button className={activeEprdclass == true ? activeclassname : inactiveclassname} id="2" onClick={handleChange}>Existing Product Rules</button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className={activeprodclass == true ? "" : "hidden"}>
                        <div className="tab-content">
                            <div className="row">
                                <div className="col-6">
                                    <div className="row g-2 mb-3 align-items-center">
                                        <div className="col-4">
                                            <label className="form-label">
                                                Rule Type
                                            </label>
                                        </div>
                                        <div className="col-8">
                                            <div className="form-check">
                                                <select className="form-select" onChange={(e) => changeRuleType(e)} >
                                                    <option value="0">Select</option>
                                                    {
                                                        ruleType.map((item, index) => (

                                                            <option value={item.resultid}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {
                                            openDropdown ? (
                                                <>

                                                    <div className="col-4">
                                                        <label className="form-label">
                                                            Customer Type
                                                        </label>
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="form-check">
                                                            <select className="form-select" onChange={(e) => changeFulFillmentType(e)} >
                                                                <option value="0">Select</option>
                                                                {
                                                                    fulfillmentType.map((item, index) => (

                                                                        <option value={item.resultid} data-custom-attribute={item.name}>{item.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (null)

                                        }

                                    </div>
                                </div>
                            </div>
                            {showCustomer == true ? (
                                <>
                                    <h2 className="pb-2 primarycolor">Customers</h2>
                                    <div className="section-border-new">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="row g-2 mb-3 align-items-center">
                                                    <div className="col-4">
                                                        <label className="form-label">
                                                            Customer Group
                                                        </label>
                                                    </div>
                                                    <div className="col-8">
                                                        <select className="form-select" aria-label="Default select example" onChange={(e) => changeCgroup(e)}>
                                                            <option selected value="0">All</option>
                                                            {
                                                                cglist != null ? (
                                                                    cglist.map((itemval, index) => (
                                                                        <option value={itemval.resultid}>{itemval.name}</option>
                                                                    ))
                                                                ) : (null)
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="row g-2 mb-3 align-items-center">
                                                    {
                                                        console.log("nameAccNoList....", nameAccNoList)
                                                    }
                                                    <div className="col-4">
                                                        <label className="form-label">
                                                            Search Name / Account No.
                                                        </label>
                                                    </div>
                                                    <div className="col-8">
                                                        <Multiselect
                                                            options={nameAccNoList}
                                                            selectedValues={selectedValues} // Preselected value to persist in dropdown
                                                            onSelect={onSelectAcc} // Function will trigger on select event
                                                            onRemove={onRemoveAcc} // Function will trigger on remove event
                                                            // onSelect={(selectedList, selectedItem) => handleSelectCustomer(selectedList, selectedItem)}
                                                            displayValue="name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12">
                                                <table className="table table-bordered">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col">
                                                                <div className="form-check"><input className="form-check-input" type="checkbox" id="" onChange={(e) => handleAllClick(e)} value="" /></div>
                                                            </th>
                                                            <th scope="col">
                                                                Name
                                                            </th>
                                                            <th scope="col">
                                                                Account Number
                                                            </th>
                                                            <th scope="col">
                                                                Customer Group
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            displayList != null ? (
                                                                displayList.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" name="entities" value={item.id} onChange={(e) => handleMPAccClick(item.customerId, e)} checked={item.isSuccess ? true : false} id={item.customerId.toString()} />
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {item.name}
                                                                        </td>
                                                                        <td>
                                                                            {item.message}
                                                                        </td>
                                                                        <td>
                                                                            {item.statusdisplayname}
                                                                        </td>
                                                                    </tr>
                                                                ))) : (null)
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (null)
                            }
                            <h2 className="pb-2 primarycolor">Filters</h2>
                            <div className="section-border-new">
                                <div className="row">
                                    {/* <div className="col-6">
                            {/*     <div className="row g-3 align-items-end">*/}
                                    {/*         <div className="col-6">*/}
                                    {/*             <label className="form-label">SKU/ Customer ReferenceID/ Mfg</label>*/}
                                    {/*             <input type="text" onChange={changeSearchSku} placeholder="" className="form-control" value={sku} />*/}
                                    {/*         </div>*/}
                                    {/*         <div className="col-2">*/}
                                    {/*             <button type="button" className="btn btn-outline-primary" onClick={SearchValues}>Search</button>*/}
                                    {/*         </div>*/}
                                    {/*         */}{/*<div className="col-4">*/}
                                    {/*         */}{/*    <div className="form-check">*/}
                                    {/*         */}{/*        <label className="form-label">*/}
                                    {/*         */}{/*            Is Active*/}
                                    {/*         */}{/*        </label>*/}
                                    {/*         */}{/*        <select className="form-select" aria-label="Default select example" onChange={searchisactive}>*/}
                                    {/*         */}{/*            <option selected value="2">Select</option>*/}
                                    {/*         */}{/*            <option value="2">All</option>*/}
                                    {/*         */}{/*            <option value="1">Active</option>*/}
                                    {/*         */}{/*            <option value="0">InActive</option>*/}
                                    {/*         */}{/*        </select>*/}
                                    {/*         */}{/*        */}{/*    <input className="form-check-input" type="checkbox" name="entities" value="" onChange={(e) => searchisactive(e)} />*/}
                                    {/*         */}{/*    </div>*/}
                                    {/*         */}{/*</div>*/}
                                    {/*     </div>*/}
                                    {/*</div>*/}
                                    {
                                        filter != null ? (
                                            filter.map((item, index) => (

                                                item.ftid == 3 ? <div className="col-6 mb-2">
                                                    <div className="row g-3 align-items-end">
                                                        <div className="col-6">
                                                            <label className="form-label">From SKU</label>
                                                            <input type="text" onChange={changeFilterSku} placeholder="" className="form-control" value={fsku} />
                                                        </div>
                                                        <div className="col-6">
                                                            <label className="form-label">To SKU</label>
                                                            <input type="text" onChange={changeFilterTSku} placeholder="" className="form-control" value={tsku} />
                                                        </div>

                                                    </div>
                                                </div> :

                                                    item.ftid == 1 && item.name != "Override" ? <div className="col-3 mb-2">
                                                        <label className="form-label">
                                                            {item.name}
                                                        </label>
                                                        <div onClick={loadOptions} data-d-id={item.id}>
                                                            <Multiselect
                                                                isObject={false}
                                                                displayValue="name"
                                                                // placeholder="All"
                                                                // onDropdownToggle={handleDropdownToggle}

                                                                onKeyPressFn={function noRefCheck() { }}
                                                                // onRemove={handleRemove}
                                                                onRemove={(selectedList, selectedItem) => handleRemove(selectedList, selectedItem, item.id)}
                                                                onSearch={function noRefCheck() { }}
                                                                // onSelect={handleSelect}
                                                                onSelect={(selectedList, selectedItem) => handleSelect(selectedList, selectedItem, item.id)}
                                                                // options={item.filtersvalues.map(x => x.value)}
                                                                options={options1}
                                                                loading={isLoading}

                                                            />
                                                        </div>
                                                    </div> : ""

                                                // item.id == 2 ? <div className="col-3 mb-2">
                                                //     <label className="form-label">
                                                //         {item.name}
                                                //     </label>
                                                //     <Multiselect
                                                //         isObject={false}
                                                //         displayValue="name"
                                                //         placeholder="All"
                                                //         onKeyPressFn={function noRefCheck() { }}
                                                //         onRemove={handleRemove2}
                                                //         onSearch={function noRefCheck() { }}
                                                //         onSelect={handleSelect2}

                                                //         options={item.filtersvalues.map(x => x.value)}
                                                //     />
                                                //     {/*<MultiSelect*/}
                                                //     {/*    options={item.filtersvalues}*/}
                                                //     {/*    value={selected}*/}
                                                //     {/*    onChange={handleSelect}*/}
                                                //     {/*    labelledBy="Select"*/}
                                                //     {/*/>*/}
                                                //     {/*<Select*/}
                                                //     {/*    options={optionList}*/}
                                                //     {/*    placeholder="Select color"*/}
                                                //     {/*    value={selectedOptions}*/}
                                                //     {/*    onChange={handleSelect}*/}
                                                //     {/*/>*/}
                                                //     {/*<select className="form-select" multiple onChange={(e) => changeFilters(item.id, item.name, e)}>*/}
                                                //     {/*    <option selected value={""}>Select</option>*/}
                                                //     {/*    {*/}
                                                //     {/*        item.filtersvalues != null ? (*/}
                                                //     {/*            item.filtersvalues.map((itemval, index) => (*/}
                                                //     {/*                <>*/}
                                                //     {/*                    <option value={itemval.key}>{itemval.value}</option>*/}
                                                //     {/*                </>*/}

                                                //     {/*            ))*/}
                                                //     {/*        ) : (null)*/}
                                                //     {/*    }*/}
                                                //     {/*</select>*/}
                                                // </div> : item.id == 4 ? <div className="col-3 mb-2">
                                                //     <label className="form-label">
                                                //         {item.name}
                                                //     </label>
                                                //     <Multiselect
                                                //         isObject={false}
                                                //         displayValue="name"
                                                //         placeholder="All"

                                                //         onKeyPressFn={function noRefCheck() { }}
                                                //         onRemove={handleRemove4}
                                                //         onSearch={function noRefCheck() { }}
                                                //         onSelect={handleSelect4}

                                                //         options={item.filtersvalues.map(x => x.value)}
                                                //     />
                                                // </div> : item.id == 5 ? <div className="col-3 mb-2">
                                                //     <label className="form-label">
                                                //         {item.name}
                                                //     </label>
                                                //     <Multiselect
                                                //         isObject={false}
                                                //         displayValue="name"
                                                //         placeholder="All"

                                                //         onKeyPressFn={function noRefCheck() { }}
                                                //         onRemove={handleRemove5}
                                                //         onSearch={function noRefCheck() { }}
                                                //         onSelect={handleSelect5}

                                                //         options={item.filtersvalues.map(x => x.value)}
                                                //     />
                                                // </div> : item.id == 6 ? <div className="col-3 mb-2">
                                                //     <label className="form-label">
                                                //         {item.name}
                                                //     </label>
                                                //     <Multiselect
                                                //         isObject={false}
                                                //         displayValue="name"
                                                //         placeholder="All"

                                                //         onKeyPressFn={function noRefCheck() { }}
                                                //         onRemove={handleRemove6}
                                                //         onSearch={function noRefCheck() { }}
                                                //         onSelect={handleSelect6}

                                                //         options={item.filtersvalues.map(x => x.value)}
                                                //     />
                                                // </div> : item.id == 11 ? <div className="col-3 mb-2">
                                                //     <label className="form-label">
                                                //         {item.name}
                                                //     </label>
                                                //     <Multiselect
                                                //         isObject={false}
                                                //         displayValue="name"
                                                //         placeholder="All"

                                                //         onKeyPressFn={function noRefCheck() { }}
                                                //         onRemove={handleRemove11}
                                                //         onSearch={function noRefCheck() { }}
                                                //         onSelect={handleSelect11}

                                                //         options={item.filtersvalues.map(x => x.value)}
                                                //     />
                                                // </div> : item.id == 12 ? <div className="col-3 mb-2">
                                                //     <label className="form-label">
                                                //         {item.name}
                                                //     </label>
                                                //     <Multiselect
                                                //         isObject={false}
                                                //         displayValue="name"
                                                //         placeholder="All"

                                                //         onKeyPressFn={function noRefCheck() { }}
                                                //         onRemove={handleRemove12}
                                                //         onSearch={function noRefCheck() { }}
                                                //         onSelect={handleSelect12}

                                                //         options={item.filtersvalues.map(x => x.value)}
                                                //     />
                                                // </div> : item.id == 13 ? <div className="col-3 mb-2">
                                                //     <label className="form-label">
                                                //         {item.name}
                                                //     </label>
                                                //     <Multiselect
                                                //         isObject={false}
                                                //         displayValue="name"
                                                //         placeholder="All"

                                                //         onKeyPressFn={function noRefCheck() { }}
                                                //         onRemove={handleRemove13}
                                                //         onSearch={function noRefCheck() { }}
                                                //         onSelect={handleSelect13}

                                                //         options={item.filtersvalues.map(x => x.value)}
                                                //     />
                                                // </div> : item.id == 14 ? <div className="col-3 mb-2">
                                                //     <label className="form-label">
                                                //         {item.name}
                                                //     </label>
                                                //     <Multiselect
                                                //         isObject={false}
                                                //         displayValue="name"
                                                //         placeholder="All"

                                                //         onKeyPressFn={function noRefCheck() { }}
                                                //         onRemove={handleRemove14}
                                                //         onSearch={function noRefCheck() { }}
                                                //         onSelect={handleSelect14}

                                                //         options={item.filtersvalues.map(x => x.value)}
                                                //     />
                                                // </div> : item.id == 15 ? <div className="col-3 mb-2">
                                                //     <label className="form-label">
                                                //         {item.name}
                                                //     </label>
                                                //     <Multiselect
                                                //         isObject={false}
                                                //         displayValue="name"
                                                //         placeholder="All"

                                                //         onKeyPressFn={function noRefCheck() { }}
                                                //         onRemove={handleRemove15}
                                                //         onSearch={function noRefCheck() { }}
                                                //         onSelect={handleSelect15}

                                                //         options={item.filtersvalues.map(x => x.value)}
                                                //     />
                                                // </div> : item.id == 21 ? <div className="col-3 mb-2">
                                                //     <label className="form-label">
                                                //         {item.name}
                                                //     </label>
                                                //     <Multiselect
                                                //         isObject={false}
                                                //         displayValue="name"
                                                //         placeholder="All"

                                                //         onKeyPressFn={function noRefCheck() { }}
                                                //         onRemove={handleRemove21}
                                                //         onSearch={function noRefCheck() { }}
                                                //         onSelect={handleSelect21}

                                                //         options={item.filtersvalues.map(x => x.value)}
                                                //     />
                                                // </div> : ""

                                            ))
                                        ) : (null)
                                    }
                                    {/*<Multiselect*/}
                                    {/*    isObject={false}*/}
                                    {/*    onKeyPressFn={function noRefCheck() { }}*/}
                                    {/*    onRemove={function noRefCheck() { }}*/}
                                    {/*    onSearch={function noRefCheck() { }}*/}
                                    {/*    onSelect={function noRefCheck() { }}*/}
                                    {/*    options={[*/}
                                    {/*        'Option 1',*/}
                                    {/*        'Option 2',*/}
                                    {/*        'Option 3',*/}
                                    {/*        'Option 4',*/}
                                    {/*        'Option 5'*/}
                                    {/*    ]}*/}
                                    {/*/>*/}
                                    {/* <div className="col-3">*/}
                                    {/*     <label className="form-label">*/}
                                    {/*         Warehouse*/}
                                    {/*     </label>*/}
                                    {/*     <select className="form-select" onChange={(e) => changelocation(e)}>*/}
                                    {/*         <option selected value={0}>Select</option>*/}
                                    {/*         {*/}
                                    {/*             loclist.map((item, index) => (*/}
                                    {/*                 <option value={item.resultid}>{item.name}</option>*/}
                                    {/*             ))*/}
                                    {/*         }*/}
                                    {/*     </select>*/}
                                    {/* </div>*/}
                                    {/* <div className="col-3">*/}
                                    {/*     <label className="form-label">*/}
                                    {/*         Branch*/}
                                    {/*     </label>*/}
                                    {/*     <select className="form-select" onChange={(e) => changebranch(e)}>*/}
                                    {/*         <option selected value={0}>Select</option>*/}
                                    {/*         {*/}
                                    {/*             branchlist.map((item, index) => (*/}
                                    {/*                 <option value={item.resultid}>{item.name}</option>*/}
                                    {/*             ))*/}
                                    {/*         }*/}
                                    {/*     </select>*/}
                                    {/* </div>*/}
                                    {/* <div className="col-3">*/}
                                    {/*     <label className="form-label">*/}
                                    {/*         Supplier*/}
                                    {/*    </label>*/}
                                    {/*    <select className="form-select" onChange={(e) => changeSupplierId(e)}>*/}
                                    {/*         <option selected value={""}>Select</option>*/}
                                    {/*         {*/}
                                    {/*             suppList.map((item, index) => (*/}
                                    {/*                 <option value={item.entityId}>{item.entityName}</option>*/}
                                    {/*             ))*/}
                                    {/*         }*/}
                                    {/*     </select>*/}
                                    {/*</div>*/}
                                    {/* <div className="col-3">*/}
                                    {/*    <label className="form-label">*/}
                                    {/*        Product Group*/}
                                    {/*    </label>*/}
                                    {/*    <select className="form-select" onChange={(e) => changeprdgrp(e)}>*/}
                                    {/*        <option selected value="">Select</option>*/}
                                    {/*        {*/}
                                    {/*            Prdgrplist.map((item, index) => (*/}
                                    {/*                <option value={item.name}>{item.name}</option>*/}
                                    {/*            ))*/}
                                    {/*        }*/}
                                    {/*    </select>*/}
                                    {/*</div>*/}
                                    {/* <div className="col-3">*/}
                                    {/*    <label className="form-label">*/}
                                    {/*        Price Max*/}
                                    {/*    </label>*/}
                                    {/*    <select className="form-select" onChange={(e) => changeprimax(e)}>*/}
                                    {/*        <option selected value="">Select</option>*/}
                                    {/*        {*/}
                                    {/*            Pricemaxlist.map((item, index) => (*/}
                                    {/*                <option value={item.name}>{item.name}</option>*/}
                                    {/*            ))*/}
                                    {/*        }*/}
                                    {/*    </select>*/}
                                    {/*</div>*/}
                                    {/* <div className="col-3">*/}
                                    {/*    <label className="form-label">*/}
                                    {/*        Price Min*/}
                                    {/*    </label>*/}
                                    {/*    <select className="form-select" onChange={(e) => changeprimin(e)}>*/}
                                    {/*        <option selected value="">Select</option>*/}
                                    {/*        {*/}
                                    {/*            Priceminlist.map((item, index) => (*/}
                                    {/*                <option value={item.name}>{item.name}</option>*/}
                                    {/*            ))*/}
                                    {/*        }*/}
                                    {/*    </select>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-3">*/}
                                    {/*    <label className="form-label">*/}
                                    {/*        Manufacture*/}
                                    {/*    </label>*/}
                                    {/*    <select className="form-select" onChange={(e) => changemfgname(e)}>*/}
                                    {/*        <option selected value="">Select</option>*/}
                                    {/*        {*/}
                                    {/*            mfglist.map((item, index) => (*/}
                                    {/*                <option value={item.name}>{item.name}</option>*/}
                                    {/*            ))*/}
                                    {/*        }*/}
                                    {/*    </select>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <div className="d-flex justify-content-end align-items-center">
                                            <button type="button" disabled={APRules != true ? true : false} className="btn btn-wh-1 btn-primary" onClick={(e) => ApplyRules(e)}>Filter</button>
                                            <button type="button" disabled={APRules != true ? true : false} className="btn btn-wh-1 btn-primary ms-2" onClick={refreshPage}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-2 text-center">
                                    <button type="button" disabled={APRules != true ? true : false} className="btn btn-wh-2 btn-primary" onClick={AddProductRules}>Create Rule</button>
                                </div>
                                {/* <form>
                                    <div className="col-12 mb-2 text-end">
                                        <button type="button" className="btn btn-wh-2 btn-primary" onClick={(e) => OpenFileExplore()}>Import Rule</button>

                                    </div>
                                    <input
                                        ref={inputFile}
                                        style={{ display: "none" }} onChange={handleFileUpload}
                                        type="file"
                                    />
                                </form> */}

                            </div>
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h2 className="m-0 p-0 primarycolor">Products</h2>
                                        <span><strong>Total Records:{productcount}</strong></span>
                                        <a target="_blank" type="button" className="btn btn-wh-1 btn-primary float-end" aria-disabled={EPRules != true ? true : false} onClick={DownloadProductRule}>Download</a>

                                        {/*    <a target='_blank' href={`api/MP/RuleDataExport?filter=${filterobj}`} type="button" className="btn btn-wh-1 btn-primary float-end">Download</a>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div className="table-scroll-section">
                                        {
                                            isjsonv.isSuccess ? (
                                                <Atable message={isjsonv.message} />
                                            ) : (null)

                                        }
                                    </div>
                                    {/*    <table className="table table-bordered managepartno-table">*/}
                                    {/*        <thead className="table-light">*/}
                                    {/*            <tr>*/}
                                    {/*                <th className="col-th-01" scope="col">*/}
                                    {/*                    Active*/}
                                    {/*                    <div className="form-check">*/}
                                    {/*                        <input className="form-check-input" type="checkbox" name="entities" disabled={EProducts != true ? true : false} value="" onChange={(e) => handleAllClick(e)} />*/}
                                    {/*                    </div>*/}
                                    {/*                </th>*/}
                                    {/*                <th className="col-th-02" scope="col">*/}
                                    {/*                    ID*/}
                                    {/*                </th>*/}
                                    {/*                <th className="col-th-03" scope="col">*/}
                                    {/*                    SKU*/}
                                    {/*                </th>*/}
                                    {/*                <th className="col-th-04" scope="col">*/}
                                    {/*                    UOM*/}
                                    {/*                </th>*/}
                                    {/*                */}{/*<th className="col-th-04" scope="col">*/}
                                    {/*                */}{/*    Title*/}
                                    {/*                */}{/*</th>*/}
                                    {/*                <th className="col-th-05" scope="col">*/}
                                    {/*                    Description*/}
                                    {/*                </th>*/}
                                    {/*                <th className="col-th-06" scope="col">*/}
                                    {/*                    Customer Reference ID*/}
                                    {/*                </th>*/}
                                    {/*                <th className="col-th-07" scope="col">*/}
                                    {/*                    Mfg Partnumber*/}
                                    {/*                </th>*/}
                                    {/*                <th className="col-th-08" scope="col">*/}
                                    {/*                    Warehouse*/}
                                    {/*                </th>*/}
                                    {/*                <th className="col-th-09" scope="col">*/}
                                    {/*                    Branch*/}
                                    {/*                </th>*/}
                                    {/*                <th className="col-th-09" scope="col">*/}
                                    {/*                    Supplier*/}
                                    {/*                </th>*/}
                                    {/*                <th scope="col-th-09">Product Group</th>*/}
                                    {/*                <th scope="col-th-09">Price Max</th>*/}
                                    {/*                <th scope="col-th-09">Price Min</th>*/}
                                    {/*                <th scope="col-th-09">Manufacture</th>*/}
                                    {/*                <th className="col-th-10" scope="col">*/}
                                    {/*                    Amazon Inventory*/}
                                    {/*                </th>*/}
                                    {/*                <th className="col-th-011" scope="col">*/}
                                    {/*                    Amazon Updated DateTime*/}
                                    {/*                </th>*/}
                                    {/*                <th className="col-th-012" scope="col">*/}
                                    {/*                    Agility Available Inventory*/}
                                    {/*                </th>*/}
                                    {/*                <th className="col-th-013" scope="col">*/}
                                    {/*                    Agility Updated DateTime*/}
                                    {/*                </th>*/}
                                    {/*                <th className="col-th-014" scope="col">*/}
                                    {/*                    Post Rules Inventory*/}
                                    {/*                </th>*/}
                                    {/*            </tr>*/}
                                    {/*        </thead>*/}
                                    {/*        <tbody>*/}
                                    {/*            {*/}
                                    {/*                products != null ? (*/}
                                    {/*                    products.map((item, index) => (*/}
                                    {/*                        <tr key={index}>*/}
                                    {/*                            <td className="col-td-01">*/}
                                    {/*                                <div className="form-check">*/}
                                    {/*                                    <input className="form-check-input" type="checkbox" name="entities" disabled={EProducts != true ? true : false} onChange={(e) => handleActiveClick(item.id, e)} value={item.id} checked={item.isActive ? true : false} />*/}
                                    {/*                                </div>*/}
                                    {/*                            </td>*/}
                                    {/*                            <td className="col-td-02">*/}
                                    {/*                                {item.id}*/}
                                    {/*                            </td>*/}
                                    {/*                            <td className="col-td-03">*/}
                                    {/*                                {item.sku}*/}
                                    {/*                            </td>*/}
                                    {/*                            <td className="col-td-04">*/}
                                    {/*                                {item.uom}*/}
                                    {/*                            </td>*/}
                                    {/*                            */}{/*<td className="col-td-04">*/}
                                    {/*                            */}{/*    {item.title}*/}
                                    {/*                            */}{/*</td>*/}
                                    {/*                            <td className="col-td-05">*/}
                                    {/*                                {item.displayDescription}*/}
                                    {/*                            </td>*/}
                                    {/*                            <td className="col-td-06">*/}
                                    {/*                                {item.asin}*/}
                                    {/*                            </td>*/}
                                    {/*                            <td className="col-td-07">*/}
                                    {/*                                {item.msku}*/}
                                    {/*                            </td>*/}
                                    {/*                            <td className="col-td-08">*/}
                                    {/*                                {item.location}*/}
                                    {/*                            </td>*/}
                                    {/*                            <td className="col-td-09">*/}
                                    {/*                                {item.branch}*/}
                                    {/*                            </td>*/}
                                    {/*                            <td className="col-td-09">*/}
                                    {/*                                {item.brand}*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                {item.pgroup}*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                {item.pmax}*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                {item.pmin}*/}
                                    {/*                            </td>*/}
                                    {/*                            <td>*/}
                                    {/*                                {item.pmfg}*/}
                                    {/*                            </td>*/}
                                    {/*                            <td className="col-td-10">*/}
                                    {/*                                {item.qty}*/}
                                    {/*                            </td>*/}
                                    {/*                            <td className="col-td-11">*/}
                                    {/*                                {item.amazonDate != null ? moment(item.amazonDate).format('MM/DD/YYYY H:mm a') : ""}*/}
                                    {/*                            </td>*/}
                                    {/*                            <td className="col-td-012">*/}
                                    {/*                                {item.availableinventory}*/}
                                    {/*                            </td>*/}
                                    {/*                            <td className="col-td-013">*/}
                                    {/*                                {item.agilityInsertedDate != null ? moment(item.agilityInsertedDate).format('MM/DD/YYYY H:mm a') : ""}*/}
                                    {/*                            </td>*/}
                                    {/*                            <td className="col-td-014">*/}
                                    {/*                                {item.inventoryQty}*/}
                                    {/*                            </td>*/}

                                    {/*                        </tr>*/}
                                    {/*                    ))*/}
                                    {/*                ) : (null)*/}
                                    {/*            }*/}
                                    {/*        </tbody>*/}
                                    {/*    </table>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={activeEprdclass == true ? "" : "hidden"}>
                        <div className="tab-content">
                            <div className="section-border-new">
                                <h2 className="pb-2 primarycolor">Filters</h2>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <label className="form-label">
                                                    Rule Type
                                                </label>
                                                <div className="form-check p-0">
                                                    <select className="form-select" onChange={(e) => changeRuleTypeExisting(e)} >
                                                        <option value="0">Select</option>
                                                        {
                                                            ruleType.map((item, index) => (

                                                                <option value={item.resultid} >{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <label className="form-label">
                                                    Customer Type
                                                </label>
                                                <div className="form-check p-0">
                                                    <select className="form-select" onChange={(e) => changeFulFillmentTypeExisting(e)} >
                                                        <option value="0">Select</option>
                                                        {
                                                            fulfillmentType.map((item, index) => (

                                                                <option value={item.resultid}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="form-check p-0">
                                            <select className="form-select" aria-label="Default select example" onChange={changeEType}>
                                                <option selected value="4">All</option>
                                                <option value="0">Include</option>
                                                <option value="1">Exclude</option>
                                                <option value="2">Always Include</option>
                                            </select>
                                        </div> */}
                                    </div>
                                    <div className="col-3 mb-3">
                                        <label className="form-label">
                                            Account number
                                        </label>
                                        <div className="form-check p-0">
                                            <select className="form-select" onChange={(e) => changeAccNo(e)} >
                                                <option value="0">All</option>
                                                {
                                                    acclist.map((item, index) => (

                                                        <option value={item.customerId}>{item.message}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <label className="form-label">
                                            Customer Group
                                        </label>
                                        <div className="form-check p-0">
                                            <select className="form-select" aria-label="Default select example" onChange={changeEcustpmergroup}>
                                                <option selected value="0">All</option>
                                                {
                                                    cglist != null ? (
                                                        cglist.map((itemval, index) => (
                                                            <option value={itemval.resultid}>{itemval.name}</option>
                                                        ))
                                                    ) : (null)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div className="table-scroll-section">
                                        <table className="table table-bordered">
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col">
                                                        Rule Type
                                                    </th>
                                                    <th scope="col">
                                                        Rule Created Date
                                                    </th>
                                                    <th scope="col">
                                                        Customers
                                                    </th>
                                                    <th scope="col">
                                                        Customer Type
                                                    </th>
                                                    <th scope="col">
                                                        Customer Group
                                                    </th>
                                                    <th scope="col">
                                                        Rule Criteria
                                                    </th>
                                                    <th scope="col">
                                                        Action
                                                    </th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    lstprodrules != null ? (
                                                        lstprodrules.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    {item.rtname}
                                                                </td>
                                                                <td>
                                                                    {item.rDate}
                                                                </td>
                                                                <td>
                                                                    {item.rcustomers}
                                                                </td>
                                                                <td>
                                                                    {item.ftname}
                                                                </td>
                                                                <td>
                                                                    {item.marketplacename}
                                                                </td>
                                                                <td>
                                                                    {item.rrjson}
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-sm btn-danger" data-toggle="tooltip" disabled={DPRules != true ? true : false} data-placement="top" title="Delete Rule" onClick={(e) => DeleteAlert(item.id)} ><i className="bi bi-trash-fill"></i></button>
                                                                </td>
                                                            </tr>
                                                        ))) : (null)
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div onClick={ClosePopup}>
                        {
                            isloader.isfailed ? (
                                <AFailed message={isloader.message} />
                            ) : (null)
                        }
                    </div>
                    <div onClick={ClosePopup}>
                        {
                            isloader.isSuccess ? (
                                <ASuccess message={isloader.message} />
                            ) : (null)
                        }
                    </div>
                </div>


            </div>

        </React.Fragment>
    )

}

export default ProductRules;
