import React, { useEffect, useState } from "react";
import { job, createinitialjob } from "../../dataservices/model/joblist";
import GetJobListService from "../../dataservices/action/job/joblist";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../sidebar";
import { PaginationValues } from "../pagenation/pageflow";
import { useHistory } from 'react-router-dom';
import Loader from "../loader";
import { Getrolepermissionbyuserid } from "../../dataservices/action/marketplace/marketplace";


const JobList = (props: any) => {
    var intjob: job[] = new Array<job>();
    const [joblist, SetJoblist] = useState(intjob);
    const storeJob = useSelector((state: any) => { return state.JobList });
    const dispatch = useDispatch();
    const [TotalCount, setTotalCount] = useState(0);
    const history = useHistory();
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [PageSizeList, setPageSizeList] = useState([50, 100, 200]);
    const [SearchValue, setSearchValue] = useState("");
    const [isloader, setisloader] = useState(false);
    const [activePage, setActivePage] = useState(0);


    const [AIMsync, setAIMsync] = useState(false);
    const [EIMsync, setEIMsync] = useState(false);
    const [DIMsync, setDIMsync] = useState(false);
    const [CSIMsync, setCSIMsync] = useState(false);
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 6:
                                if (result[i].canadd == "1") {
                                    setAIMsync(true);
                                }
                                if (result[i].canedit == "1") {
                                    setEIMsync(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDIMsync(true);
                                }
                                if (result[i].cansync == "1") {
                                    setCSIMsync(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])

    useEffect(() => {

        setisloader(true);
        GetJobListService(Page, PageSize, function (result: job[]) {
            if (result.length != 0) {
                dispatch(
                    {
                        type: "GET_JOBLIST_COMPLETED",
                        payload: result
                    })
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                SetJoblist(result);
                setActivePage(Page)
                setisloader(false);
            } else {
                setisloader(false);
            }

        })
    }, [])

    const Details = (id: number) => {
        props.history.push({
            pathname: '/jobDetails',
            state: id
        });

    }
    const Pagenation = () => {
        let Pagination = new PaginationValues();
        Pagination.TotalItemCount = TotalCount;
        Pagination.CurrentPage = Page;
        Pagination.PageSlide = 2;
        Pagination.IsSort = true;
        Pagination.PageSize = PageSize;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page - 1) * PageSize) + 1}    - {((((Page - 1) * PageSize) + 1) + PageSize) > TotalCount ? TotalCount : ((((Page - 1) * PageSize)) + PageSize)} of {TotalCount} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">

                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                                {
                                    Pagination.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination.Pages().map((x, i) =>
                                        x == Page ?
                                            (
                                                <li className={`page-item  ${activePage == x ? 'active' : null}`} key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className="page-link" onClick={() => SetPage(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
    const SetPageSize = (size: number) => {
        setPageSize(size);
        GetJobListService(Page, size, function (result: job[]) {
            if (result != null) {
                SetJoblist(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
            }
        })
    }

    const SetPage = (page: number) => {
        setPage(page);
        GetJobListService(page, PageSize, function (result: job[]) {
            if (result != null) {
                SetJoblist(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(page)
            }
        })
    }

    const RefreshQuote = () => {
        let PoNumber: string = "";
        let OrderNumber: number = 0;
        let InvoiceNumber: string = "";
        if (SearchValue != "") {
        }
        GetJobListService(Page, PageSize, function (result: job[]) {
            if (result != null) {
                SetJoblist(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
            }
        })
    }
    const Back = () => {
        history.push(`/synchistory`);
    }
    return (
        <React.Fragment>
            <div className="row">
                {
                    isloader ? (<Loader isActive={isloader} />) : (null)
                }
                <div className="col-md-3">
                    <SideBar />
                </div>

                <div className="col-md-9">
                    <div className="heading-section">
                        <h1>InRiver Jobs List</h1>
                        <div>
                            <input className="btn btn-sm btn-primary" type="button" value="Back" onClick={Back} />
                        </div>
                    </div>

                    <div>
                        <table className="table table-bordered">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">
                                        Job Id
                                    </th>
                                    <th scope="col">
                                        Job date
                                    </th>
                                    <th scope="col">
                                        Job type
                                    </th>
                                    <th scope="col">
                                        Scope
                                    </th>
                                    <th scope="col">
                                        Status
                                    </th>
                                    <th scope="col">
                                        Details
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    joblist != null ? (
                                        joblist.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {item.id}
                                                </td>
                                                <td>
                                                    {item.createdDate}
                                                </td>
                                                <td>
                                                    {item.createdType}
                                                </td>
                                                <td>
                                                    {item.scope}
                                                </td>
                                                <td>
                                                    {item.status}
                                                </td>

                                                <td>
                                                    <button className="btn btn-sm btn-success" data-toggle="tooltip" data-placement="top" title="Details" onClick={(e) => Details(item.id)} ><i className="bi bi-eye-fill"></i></button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (

                                        <tr>
                                            <td>
                                                No Records found
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        <Pagenation />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default JobList;