import React, { useState, useEffect } from "react";

import InfoIcon from "@material-ui/icons/Info";
import Popover from "@material-ui/core/Popover";
import { ruleResultModel, createruleresultmodel } from "../../dataservices/model/ruleresult";

interface IProps {
    message: string;
}

const Atable = (message: IProps) => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const schedule = JSON.parse(message.message);

   // console.log("schedule....", schedule);
    const keys = Object.keys(schedule.length ? schedule[0] : {});
   // console.log("keys..", keys)
    const filterkey = keys.slice(0, 6);
    const filteredArray = filterkey.filter(item => item !== "ID" && item !== "Part Number" && item!=="mfgNumber");
   // console.log("filterkey..", filterkey)
    const filterschedule = schedule.slice(0, 7);
    // initialruleresult: ruleResultModel[] = new Array<ruleResultModel>();
    const [currentEntity, setCurrentEntity] = useState(createruleresultmodel);

    const handleInfoClick = (event: any, data: any) => {
       // console.log("data..", data)
        setAnchorEl(event.currentTarget);
        // var dataBind = JSON.stringify(data);
        setCurrentEntity(data);
       // console.log("currententity", currentEntity)
    }

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className="App">
            {schedule.length > 0 ? (
                <div>
                    <table className="table table-bordered">
                        <thead className="table-light sticky-top">
                            <tr>
                                {filteredArray.map((item, idx) => (
                                    <th scope="col" key={idx}>{item.replace("_", " ").replace("_", " ").replace("_", " ")}</th>
                                ))}
                                <th>More Information</th>
                            </tr>

                        </thead>
                        <tbody>
                            {schedule.map((item: { [x: string]: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, idx: React.Key | null | undefined) => (
                                <tr key={idx}>
                                    {filteredArray.map((key, idx) => (
                                        <td className="">{item[key]}</td>
                                    ))}
                                    <td>
                                        <div
                                            onClick={(e) => handleInfoClick(e, item)}
                                            className="attribute_icon"
                                        >
                                            <InfoIcon className="Icon_color_global" />
                                        </div>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                    >

                        <div className="order_attribute_container">
                            <div>
                                {/*{currentEntity != null && currentEntity.ID != null && (*/}
                                {/*    <div>*/}
                                {/*        <span className="attribute_label">ID:</span>*/}
                                {/*        <span className="attribute_Item">{currentEntity.ID}</span>*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                {currentEntity != null && currentEntity.NonTradeItemflag != null && (
                                    <div>
                                        <span className="attribute_label">Non Trade Item flag:</span>
                                        <span className="attribute_Item">{currentEntity.NonTradeItemflag}</span>
                                    </div>
                                )}
                                 {currentEntity != null && currentEntity.ProductMinorProductGroup != null && (
                                    <div>
                                        <span className="attribute_label">Product Minor Product Group:</span>
                                        <span className="attribute_Item">{currentEntity.ProductMinorProductGroup}</span>
                                    </div>
                                )}
                                 {currentEntity != null && currentEntity.ProductPriceCodeMajor != null && (
                                    <div>
                                        <span className="attribute_label">Product Price Code Major:</span>
                                        <span className="attribute_Item">{currentEntity.ProductPriceCodeMajor}</span>
                                    </div>
                                )}
                                 {currentEntity != null && currentEntity.ProductPriceCodeMinor != null && (
                                    <div>
                                        <span className="attribute_label">Product Price Code Minor:</span>
                                        <span className="attribute_Item">{currentEntity.ProductPriceCodeMinor}</span>
                                    </div>
                                )}
                                 {currentEntity != null && currentEntity.Product_Group_Major != null && (
                                    <div>
                                        <span className="attribute_label">Product Group Major:</span>
                                        <span className="attribute_Item">{currentEntity.Product_Group_Major}</span>
                                    </div>
                                )}
                                  {currentEntity != null && currentEntity.itemAuthorizationClassification != null && (
                                    <div>
                                        <span className="attribute_label">Authorization Classification:</span>
                                        <span className="attribute_Item">{currentEntity.itemAuthorizationClassification}</span>
                                    </div>
                                )}
                                {currentEntity != null && currentEntity.itemLTLOnly != null && (
                                    <div>
                                        <span className="attribute_label">LTLOnly:</span>
                                        <span className="attribute_Item">{currentEntity.itemLTLOnly}</span>
                                    </div>
                                )}
                                 {currentEntity != null && currentEntity.mfgNumber != null && (
                                    <div>
                                        <span className="attribute_label">mfg Number:</span>
                                        <span className="attribute_Item">{currentEntity.mfgNumber}</span>
                                    </div>
                                )}
                                 {currentEntity != null && currentEntity.PartNumber != null && (
                                    <div>
                                        <span className="attribute_label">Part Number:</span>
                                        <span className="attribute_Item">{currentEntity.PartNumber}</span>
                                    </div>
                                )}



                            </div>
                        </div>
                    </Popover>
                </div>

            ) : (<table className="table table-bordered">
                <tbody>
                    <tr><td>{"Apply filters to view records"}</td></tr>
                </tbody>
            </table>)}
        </div>
    )
    //return (!schedule) ? null : (
    //    <tbody>
    //        {schedule.map((item: any) => {                                // changed here
    //            console.log('item: ', item);
    //            return (
    //                <tr>
    //                    {Object.entries(item).map((field) => {        // changed here
    //                        console.log('field: ', field);
    //                        return <td>{field}</td>
    //                    })}
    //                </tr>
    //            );
    //        })}
    //    </tbody>
    //);
};

//return (


//    <div>
//        <table>
//            <thead style={{ background: "#8b8498" }}>
//                <tr>
//                    {Object.keys(schedule[0]).map((j, i) => (
//                        <td key={i}>{j}</td>
//                    ))}
//                </tr>
//            </thead>
//            <tbody>
//                {schedule.map((item, index) => (
//                    <tr key={index}>
//                        {Object.values(item).map((j, i) => (
//                            <td key={i}>{j}</td>
//                        ))}
//                    </tr>
//                ))}
//            </tbody>
//        </table>

//        {/*<>*/}

//        {/*    <ul className="list-group">*/}
//        {/*        <li className="list-group-item"><input className="form-check-input me-1" type="checkbox" value="" aria-label="..."/><a href="/userdashboard" className="nav-link link-dark px-2">Home</a></li>*/}
//        {/*        <li className="list-group-item"><input className="form-check-input me-1" type="checkbox" value="" aria-label="..."/><a href="/userslist" className="nav-link link-dark px-2">Users</a></li>*/}
//        {/*        <li className="list-group-item"><input className="form-check-input me-1" type="checkbox" value="" aria-label="..."/><a href="/webhook" className="nav-link link-dark px-2">Webhooks</a></li>                                      */}

//        {/*    </ul>*/}
//        {/*</>*/}
//    </div>


//    //<div className="toaster-section-success">           
//    //    <span className="check-btn"><i className="bi bi-x-circle-fill"></i></span>{message.message}<span className="close-btn"><i className="bi bi-x"></i></span>
//    //</div>
//)


export default Atable;