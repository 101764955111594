import React from "react";
import { Toast, ToastBody, ToastHeader } from 'reactstrap'
interface IProps {
    message: string;
}

const ASuccess = (message: IProps) => {
    return (
        <div className="toaster-section-success">           
            <span className="check-btn"><i className="bi bi-x-circle-fill"></i></span>{message.message}<span className="close-btn"><i className="bi bi-x"></i></span>
        </div>
    )

}
export default ASuccess;