import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import SideBar from "../sidebar";
import { channellist } from "../../dataservices/model/channellist";
import Box from '@material-ui/core/Box'
import { GetChannellistService, RefreshChannels, GetRunningJob, SyncAllInriverChannelservice } from "../../dataservices/action/dashboard/channellist";
import Loader from "../loader";
import { SyncByChannelIdservice } from "../../dataservices/action/dashboard/channellist";
import ASuccess from "../alretbox/success";
import AFailed from "../alretbox/failed";
import { moduleinfo } from "../../dataservices/model/user";
import { GetEditPermission } from "../../dataservices/action/account/register";
import { Getrolepermissionbyuserid } from "../../dataservices/action/marketplace/marketplace";



const SyncConnector = () => {
    var intchannelsiteslist: channellist[] = new Array<channellist>();
    const [channellist, Setchannellist] = useState(intchannelsiteslist);
    const [isloader, setisloader] = useState(false);
    const [isJobRunnning, setisJobRunnning] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const history = useHistory();


    var vperList: moduleinfo[] = new Array<moduleinfo>();
    const [VPList, setVPList] = useState(vperList);
    const [ECategoryAttributes, setVCategoryAttributes] = useState(false);
    const [EAPIsettings, setVAPIsettings] = useState(false);
    const [EMagentoSync, setVMagentoSync] = useState(false);
    const [EInriverSync, setVInriverSync] = useState(false);
    const [EProducts, setVProducts] = useState(false);
    const [EInventoryRules, setVInventoryRules] = useState(false);
    const [Elogs, setVlogs] = useState(false);
    const [ESettings, setVSettings] = useState(false);
    const [isDisabled, setisDisabled] = useState("disabled");
    const [AIMsync, setAIMsync] = useState(false);
    const [EIMsync, setEIMsync] = useState(false);
    const [DIMsync, setDIMsync] = useState(false);
    const [CSIMsync, setCSIMsync] = useState(false);
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 6:
                                if (result[i].canadd == "1") {
                                    setAIMsync(true);
                                }
                                if (result[i].canedit == "1") {
                                    setEIMsync(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDIMsync(true);
                                }
                                if (result[i].cansync == "1") {
                                    setCSIMsync(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])
    useEffect(() => {
        setisloader(true);
        GetChannellistService(function (result: any) {
            setisloader(false);
            Setchannellist(result)
        })
    }, [])

    useEffect(() => {
        setisloader(true);
        GetRunningJob(function (result: any) {
            setisJobRunnning(result);
            if(result){
                setCSIMsync(false);
            }else{
                setCSIMsync(true);
            }
            setisloader(false);
        })
    }, [])

    const GetJob = () => {
        history.push(`/joblist`);
    }
    const SyncEntities = () => {
        history.push(`/syncentities`);
    }
    const SyncSchedule = () => {
        history.push(`/synschedule`);
    }
   

    const RefreshChannel = () => {
        setisloader(true);
        RefreshChannels(function (result: any) {
            Setchannellist(result)
            setisloader(false);
        })
    }
    const SyncByChannelId = (channelId: number, autosync: boolean) => {
        setisloader(true);
        SyncByChannelIdservice(channelId, autosync, function (res: any) {
            setisloader(false);
            history.push(`/joblist`);
        })
    }
    const SyncAllInriverChannel = (autosync: boolean) => {
        setisloader(true);
        SyncAllInriverChannelservice(autosync, function (res: any) {
            setisloader(false);
            history.push(`/joblist`);
        })
    }
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <SideBar />
                </div>
                {
                    isloader ? (<Loader isActive={isloader} />) : (null)
                }
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-end align-items-center mb-3">
                                <input className="btn btn-primary me-2" type="button" disabled={CSIMsync != true ? true : false} onClick={SyncSchedule} value="Sync Schedule" />
                                <input className="btn btn-primary me-2" disabled={CSIMsync != true && isJobRunnning ? true : false} type="button" onClick={SyncEntities} value="Sync Specific Entities" />
                                <input className="btn btn-primary me-2" disabled={CSIMsync != true && isJobRunnning ? true : false} type="button" onClick={(e) => SyncAllInriverChannel(false)} value="InRiver Sync All" />
                                <input className="btn btn-primary me-2" disabled={CSIMsync != true && isJobRunnning ? true : false} type="button" onClick={(e) => SyncAllInriverChannel(true)} value="InRiver & Magento Sync All" />
                                <input className="btn btn-primary" type="button" disabled={EIMsync != true ? true : false} onClick={GetJob} value="Job List" />
                            </div>
                        </div>
                    </div>
                    <h1>InRiver Sync</h1>
                    <div className="row">
                        <div className="col-md-12">
                            <input className="btn btn-sm btn-primary mb-3" type="button" onClick={RefreshChannel} value="Refresh" />
                        </div>
                    </div>

                    <div className="row">
                        {
                            channellist != null ? (
                                channellist.map((item, index) => (
                                    <div className="col-sm-6 col-md-6">
                                        <div className="box-results" key={index}>
                                            <div className="box-results-row">
                                                <div className="box-results-col txt-name">
                                                    Channel Name
                                                </div>
                                                <div className="box-results-col">
                                                    {item.name}
                                                </div>
                                            </div>
                                            <div className="box-results-row mt-3">
                                                <input className="btn btn-sm btn-outline-secondary float-start " disabled={CSIMsync != true && isJobRunnning ? true : false } type="button" value="InRiver Sync" onClick={(e) => (SyncByChannelId(item.resultid, false))} />
                                                <input className="btn btn-sm btn-outline-secondary float-end " disabled={CSIMsync != true && isJobRunnning ? true : false} type="button" value="Inriver & Magento Sync" onClick={(e) => (SyncByChannelId(item.resultid, true))} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (null)
                        }
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}
export default SyncConnector;