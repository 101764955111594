import axios from 'axios';
import { user } from '../model/user';
import Globals from '../global';
import { useDispatch } from 'react-redux'


export const LoginService = (userName: string, password: string, callback: any) => {
        if (userName != "" && password != "") {
            axios.post(`api/Account/Login`, {
            UserName: userName,
            Password: password
        }).then(result => {
            localStorage.setItem('IsAuthendicated', "true");
            localStorage.setItem('Username', userName);
            Globals.IsAuthenticated = true;
            Globals.userName = result.data.UserName;
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}

export const GetIsAuth = async (callback: any) => {
    await axios.get("api/Account/IsUserAuthenticated").then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}

