import React, { useEffect, useState } from "react";
import { job, createinitialjob } from "../../dataservices/model/joblist";
import SideBar from "../sidebar";
import { useDispatch, useSelector } from 'react-redux'
import { ExportExcel } from "../../dataservices/action/job/joblist";
import { GetMagentoTaskDetails } from "../../dataservices/action/magentowebsync/magentosync";
import { jobDetails, createinitialjobDetails } from "../../dataservices/model/jobDetails";
import { PaginationValues } from "../pagenation/pageflow";
import { useHistory } from 'react-router-dom';
import Loader from "../loader";
import { GetMagentowebSiteListbyJobId, FailedMagentojobRetry } from "../../dataservices/action/magentowebsync/magentosync";
import { allwebsites, createinitialallwebsites } from "../../dataservices/model/allwebsites";
import { CancellAgilityJobandTaskService, FailedAgilityjobRetry, GetagilityTaskDetailsbyID, GetAllStatus, GetmpLocationsANDagilityBranch, GetMPTaskDetailsbyID, Getrolepermissionbyuserid, GetstatusandBranchbyAJID, GetstatusandtransbyID } from "../../dataservices/action/marketplace/marketplace";
import { channellist } from "../../dataservices/model/channellist";
import { moduleinfo } from "../../dataservices/model/user";
import { confirmAlert } from "react-confirm-alert";
import { GetEditPermission } from "../../dataservices/action/account/register";

const agilityTaskDetails = (props: any) => {
    const dispatch = useDispatch();
    var intjob: jobDetails[] = new Array<jobDetails>();
    const [tasklist, SetTasklist] = useState(intjob);
    const jobId = props.location.state;
    const [TotalCount, setTotalCount] = useState(0);
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [PageSizeList, setPageSizeList] = useState([50, 100, 200]);
    const [entityType, setentityType] = useState(0);
    const [entityValue, setentityValue] = useState("");
    const [websitevalue, setwebsitevalue] = useState("All");
    const [websiteId, setwebsiteId] = useState(0);
    const [taskName, settaskName] = useState("");
    const [statusValue, setstatusValue] = useState("All");
    const [statusId, setstatusId] = useState(0);
    const [isLoader, setisLoader] = useState(false);
    const [activePage, setActivePage] = useState(0);
    const [branchId, setbranchId] = useState("");

    var intbranchlist: channellist[] = new Array<channellist>();
    const [branchlist, setbranchlist] = useState(intbranchlist);
    var inttranslist: channellist[] = new Array<channellist>();
    const [translist, settranslist] = useState(inttranslist);
    var intstatuslist: channellist[] = new Array<channellist>();
    const [statuslist, setstatuslist] = useState(intstatuslist);
    const [ECategoryAttributes, setVCategoryAttributes] = useState(false);
    const [EAPIsettings, setVAPIsettings] = useState(false);
    const [EMagentoSync, setVMagentoSync] = useState(false);
    const [EInriverSync, setVInriverSync] = useState(false);
    const [EProducts, setVProducts] = useState(false);
    const [EInventoryRules, setVInventoryRules] = useState(false);
    const [Elogs, setVlogs] = useState(false);
    const [ESettings, setVSettings] = useState(false);
    const [isDisabled, setisDisabled] = useState("disabled");
    var vperList: moduleinfo[] = new Array<moduleinfo>();
    const [VPList, setVPList] = useState(vperList);


    const [AAginv, setAAginv] = useState(false);
    const [EAginv, setEAginv] = useState(false);
    const [DAginv, setDAginv] = useState(false);
    const [CSAginv, setCSAginv] = useState(false);
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 21:
                                if (result[i].canadd == "1") {
                                    setAAginv(true);
                                }
                                if (result[i].canedit == "1") {
                                    setEAginv(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDAginv(true);
                                }
                                if (result[i].cansync == "1") {
                                    setCSAginv(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])

    useEffect(() => {
        setisLoader(true);
        GetmpLocationsANDagilityBranch(2, function (result: any) {
            setbranchlist(result);
            setisLoader(false);

        })
    }, [])
    useEffect(() => {
        setisLoader(true);
        let info: moduleinfo = {
            moduleid: jobId,
            maid:0,
            appname: "1",
            permission: "",
            modulename: ""
        }
        GetstatusandBranchbyAJID(info, function (result: any) {
            setstatuslist(result);
            setisLoader(false);

        })
    }, [])
    useEffect(() => {
        setisLoader(true);
        let info: moduleinfo = {
            moduleid: jobId,
            maid:0,
            appname: "2",
            permission: "",
            modulename: ""
        }
        GetstatusandBranchbyAJID(info, function (result: any) {
            settranslist(result);
            setisLoader(false);

        })
    }, [])
    const history = useHistory();
    useEffect(() => {

        setisLoader(true);
        GetagilityTaskDetailsbyID(jobId, Page, PageSize, statusId, branchId, function (result: jobDetails[]) {
            if (result != null && result.length !== 0) {
                SetTasklist(result);
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                settaskName(result[0].jobName);
                setActivePage(Page);
                setisLoader(false);
            } else {
                SetTasklist(intjob);
                setTotalCount(0);
                setPage(Page);
                setPageSize(PageSize);
                settaskName(taskName);
                setActivePage(Page)
                setisLoader(false);
            }
            
        })
    }, [statusId])



    const Pagenation = () => {
        let Pagination = new PaginationValues();
        Pagination.TotalItemCount = TotalCount;
        Pagination.CurrentPage = Page;
        Pagination.PageSlide = 2;
        Pagination.IsSort = true;
        Pagination.PageSize = PageSize;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page - 1) * PageSize) + 1}    - {((((Page - 1) * PageSize) + 1) + PageSize) > TotalCount ? TotalCount : ((((Page - 1) * PageSize)) + PageSize)} of {TotalCount} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">

                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                                {
                                    Pagination.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination.Pages().map((x, i) =>

                                        x == Page ?
                                            (
                                                <li className={`page-item  ${activePage == x ? 'active' : null}`} key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className={`page-link`} onClick={() => SetPage(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
    const SetPageSize = (size: number) => {
        setisLoader(false);
        GetagilityTaskDetailsbyID(jobId, Page, size, statusId, branchId, function (result: jobDetails[]) {
            if (result != null) {
                SetTasklist(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setisLoader(true);
            }
        })
    }

    const SetPage = (page: number) => {
        setisLoader(true);
        GetagilityTaskDetailsbyID(jobId, page, PageSize, statusId, branchId, function (result: jobDetails[]) {
            if (result != null) {
                SetTasklist(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(page)
                setisLoader(false);

            }
        })
    }
   
    //useEffect(() => {
    //    setisLoader(true);
        
    //    GetAllStatus( function (result: any) {
    //        setstatuslist(result);
    //        setisLoader(false);

    //    })
    //}, [])

    const changeBranch = (e: any) => {
        setbranchId(e.target.value);
        setisLoader(true);
        GetagilityTaskDetailsbyID(jobId, Page, PageSize, statusId, e.target.value, function (result: jobDetails[]) {
            if (result != null) {
                SetTasklist(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                setisLoader(false);

            }
        })
    }



    const ChangeStatus = (e: any) => {
        setstatusValue(e.target.value);
        setstatusId(e.target.value);
        setisLoader(true);
        GetagilityTaskDetailsbyID(jobId, Page, PageSize,Number(e.target.value), branchId, function (result: jobDetails[]) {
            if (result != null) {
                SetTasklist(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                setisLoader(false);

            }
        })
        //switch (e.target.value) {
        //    case "New":
        //        setstatusValue(e.target.value);
        //        setstatusId(1);
        //        setPage(1);
        //        setPageSize(50);
        //        break;
        //    case "Processing":
        //        setstatusValue(e.target.value);
        //        setstatusId(2);
        //        setPage(1);
        //        setPageSize(50);
        //        break;
        //    case "Finished":
        //        setstatusValue(e.target.value);
        //        setstatusId(3);
        //        setPage(1);
        //        setPageSize(50);
        //        break;
        //    case "Failed":
        //        setstatusValue(e.target.value);
        //        setstatusId(4);
        //        setPage(1);
        //        setPageSize(50);
        //        break;
        //    case "Cancelled":
        //        setstatusValue(e.target.value);
        //        setstatusId(5);
        //        setPage(1);
        //        setPageSize(50);
        //        break;
        //    default:
        //        setstatusValue("All");
        //        setstatusId(0);
        //        setPage(1);
        //        setPageSize(50);
        //}

    }
    const Back = () => {
        history.push(`/agilityjoblist`);
    }
    const CancellJobandTaskAlert = (id: number) => {
        confirmAlert({
            title: 'Cancel Sync',
            message: 'Are you sure you want to cancel the sync?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => CancellJobandTask(id)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const CancellJobandTask = (id: number) => {

        setisLoader(true);
        CancellAgilityJobandTaskService(id, function (res: any) {
            setisLoader(false);
        })
        history.go(0);
    }
    const AgilityjobRetry = () => {
        FailedAgilityjobRetry(jobId, function (result: any) {
            if (result != null) {
                props.history.push({
                    pathname: '/agilityjoblist'
                });
            }
        })
    }
    return (
        <React.Fragment>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Home</a></li>
                    <li className="breadcrumb-item" aria-current="page">
                        <a href="/agilityjoblist"><i className="bi"></i>Agility Jobs List</a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                        <span>Task Details</span>
                    </li>
                    
                </ol>
            </nav>
            {isLoader ? (<Loader isActive={isLoader} />) : (null)}
            <div className="row">

                <div className="col-md-12">
                    <div className="heading-section">
                        <h1>Agility Sync Logs Job: {
                            "#" + jobId + " " + taskName
                        }</h1>
                        <div>
                            {/*<button type="button" className="btn btn-primary ms-2" onClick={Back}>Back</button>*/}
                            <input className="btn btn-primary me-2" type="button" onClick={(e) => { CancellJobandTaskAlert(jobId) }} disabled={CSAginv != true ? true : false} value="Cancel Sync" />
                            {/*<button type="button" className="btn btn-primary me-2" disabled={Elogs != true ? true : false} onClick={AgilityjobRetry}>Retry all Failed/Cancelled Task </button>*/}
                            <a target='_blank' aria-disabled={EAginv != true ? true : false} href={`api/MP/AgilityTASKExport?joibId=${jobId}&&statusId=${statusId}&&branchid=${branchId}`} type="button" className="btn btn-primary ms-2">Download</a>
                        </div>
                    </div>

                    <div className="box-results">
                        <div className="row">
                            <div className="col-6">
                                <label className="form-label">Status</label>
                                <select className="form-select" onChange={(e) => ChangeStatus(e)}>
                                    <option selected value="0">Select</option>
                                    {
                                        statuslist.map((item, index) => (
                                            <option value={item.resultid}>{item.name}</option>
                                        ))
                                    }
                                </select>
                                {/*<select className="form-select" onChange={(e) => ChangeStatus(e)} value={statusValue}>*/}
                                {/*    <option selected value="0">Select</option>*/}
                                {/*    {*/}
                                {/*        statuslist.map((item, index) => (*/}
                                {/*            <option value={item.name}>{item.name}</option>*/}
                                {/*        ))*/}
                                {/*    }*/}
                                {/*</select>*/}
                                {/*<select className="form-select" aria-label="Default select example" onChange={ChangeStatus} value={statusValue}>*/}
                                {/*    <option selected value="All">All</option>*/}
                                {/*    <option value="New">New</option>*/}
                                {/*    <option value="Processing">Processing</option>*/}
                                {/*    <option value="Finished">Finished</option>*/}
                                {/*    <option value="Failed">Failed</option>*/}
                                {/*    <option value="Cancelled">Cancelled</option>*/}
                                {/*</select>*/}
                            </div>
                            <div className="col-6">
                                <label className="form-label">
                                    Branch
                                </label>
                                <select className="form-select" onChange={(e) => changeBranch(e)}>
                                    <option selected value="">Select</option>
                                    {
                                        translist.map((item, index) => (
                                            <option value={item.resultid}>{item.name}</option>
                                        ))
                                    }
                                </select>
                                {/*<select className="form-select" onChange={changeBranch}>*/}
                                {/*    <option selected value={""} >Select</option>*/}
                                {/*    {*/}
                                {/*        branchlist.map((item, index) => (*/}
                                {/*            <option value={item.resultid}>{item.statusdisplayname}</option>*/}
                                {/*        ))*/}
                                {/*    }*/}
                                {/*</select>*/}
                            </div>
                        </div>
                        <hr></hr>

                        <table className="table table-bordered">
                            <thead className="thead-light">
                                <tr>


                                    <th className="col2">
                                        Item#
                                    </th>
                                    <th className="col1">
                                        Branch
                                    </th>
                                    <th className="col3">
                                        Status
                                    </th>
                                    <th className="col4">
                                        Error message
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tasklist != null && tasklist.length !== 0 ? (
                                        tasklist.map((item, index) => (
                                            <tr>
                                                {/*<td scope="col" key={index}>*/}
                                                {/*    {*/}
                                                {/*        "Task: #" + item.entityId*/}
                                                {/*    }*/}
                                                {/*</td>*/}
                                                <td scope="col">
                                                    {
                                                        item.entityName
                                                    }
                                                </td>
                                                <td scope="col">
                                                    {
                                                        item.locationname
                                                    }
                                                </td>
                                                <td scope="col">
                                                    {
                                                        item.status
                                                    }
                                                </td>
                                                <td scope="col" width="10%">
                                                    {
                                                        (item.errorMessage != null && item.errorMessage != "") ? item.errorMessage + "...." : ""
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td>No records found</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        <Pagenation />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default agilityTaskDetails;