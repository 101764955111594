import React from "react";
import { createinitialUser, user } from '../model/user'


const initaluser = createinitialUser();



export const Loginreducres = (state: user = initaluser, action: any) => {
    switch (action.type) {
        case "LOGIN_COMPLETED":
            return handleFetchCompleted(state, action.payload)
        case "LOGIN_FAILED":
            return
        case "LOG_OUT":
            return handleFetchCompleted(state, action.payload)
    }
    return state;
}

const handleFetchCompleted = (state: user, payload: user) => payload;