import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from 'react-router-dom';
import { GetViewPermission } from "../../dataservices/action/account/register";
import { GetAllMarketplaces, GetMarketplaceAccountNumberForRelationshipType, Getrolepermissionbyuserid, GetValuesFromMpProducts } from "../../dataservices/action/marketplace/marketplace";
import Globals from "../../dataservices/global";
import { channellist } from "../../dataservices/model/channellist";
import { createinitialpermission, applypermission } from "../../dataservices/model/filters";
import logo from "../images/magentologoresize.jpg";

const UserDashboard = (props: any) => {
    const history = useHistory();
    const [stateval, setStateval] = useState("");
    const [stateNFval, setStateNFval] = useState("");
    const [magentoConnect, setmagentoConnect] = useState(false);
    const [amazonMp, setamazonMp] = useState(false);
    var intMPlist: channellist[] = new Array<channellist>();
    const [mplist, Setmplist] = useState(intMPlist);
    const [isLoader, setisLoader] = useState(false);

    var intNFClist: channellist[] = new Array<channellist>();
    const [NFClist, SetNFClist] = useState(intNFClist);

    const [isauth, setisauth] = useState(localStorage.getItem('IsAuthendicated'));
    const [ispartner, setispartner] = useState(false);


    useEffect(() => {
        setisLoader(true);
        GetAllMarketplaces(function (result: any) {
            Setmplist(result);
            //setisLoader(false);

            GetMarketplaceAccountNumberForRelationshipType("D", function (result: any) {
                SetNFClist(result);
                setisLoader(false);



            })

        })
    }, [])

    //useEffect(() => {
    //    setisLoader(true);
    //    Getrolepermissionbyuserid(1, function (result: any) {
    //        setLoadpermission(result);
    //        setisLoader(false);

    //    });
    //}, [])


    const megantoCntClick = (e: any) => {

        e.preventDefault();
        // console.log(Globals.IsMagento);
        // console.log(Globals.IsAmazon);
        setmagentoConnect(true);
        setamazonMp(false);
        //if (magentoConnect == true) {
        Globals.IsMagento = true;
        Globals.IsAmazon = false;
        history.push(`/dashboard`);
        //}
    }
    const inventoryClick = (e: any) => {

        e.preventDefault();
        // console.log(Globals.IsMagento);
        // console.log(Globals.IsAmazon);
        setmagentoConnect(true);
        setamazonMp(false);
        //if (magentoConnect == true) {
        Globals.IsMagento = true;
        Globals.IsAmazon = false;
        Globals.IsAgility = false;
        history.push(`/inventoryRules`);
        //}
    }
    const orderlistingClick = (e: any) => {
        e.preventDefault();
        // console.log(Globals.IsMagento);
        // console.log(Globals.IsAmazon);
        setmagentoConnect(true);
        setamazonMp(false);
        //if (magentoConnect == true) {
        Globals.IsMagento = true;
        Globals.IsAmazon = false;
        Globals.IsAgility = false;
        history.push(`/orderlisting`);

    }
    const productClick = (e: any) => {

        e.preventDefault();
        // console.log(Globals.IsMagento);
        // console.log(Globals.IsAmazon);
        setmagentoConnect(true);
        setamazonMp(false);
        //if (magentoConnect == true) {
        Globals.IsMagento = true;
        Globals.IsAmazon = false;
        Globals.IsAgility = false;
        history.push(`/productrules`);
        //}
    }
    const mpCntClick = (accid: number, e: any) => {
        e.preventDefault();
        // console.log(Globals.IsMagento);
        // console.log(Globals.IsAmazon);
        Globals.IsAgility = false;
        setmagentoConnect(false);
        setamazonMp(true);
        Globals.IsMagento = false;
        Globals.IsAmazon = true;
        Globals.accountid = accid.toString();
        //props.history.push({
        //    pathname: '/productsindex',
        //    state: accid
        //});
        history.push("/productsindex/" + accid);
    }

    const handleClick = (e: any) => {
        e.preventDefault();
        if (stateval == 'clicked') {
            setStateval("");

        } else {
            setStateval("clicked");
            setStateNFval("");
        }
    };
    const handleNFClick = (e: any) => {
        e.preventDefault();
        if (stateNFval == 'clicked') {
            setStateNFval("");

        } else {
            setStateval("");
            setStateNFval("clicked");
        }
    };
    const agilityhandleClick = (e: any) => {
        e.preventDefault();
        setmagentoConnect(false);
        setamazonMp(true);
        Globals.IsMagento = false;
        Globals.IsAmazon = false;
        Globals.IsAgility = true;
        history.push(`/agilityjoblist`);
    }
    //view
    const [VMagentoconnector, setVMagentoconnector] = useState(false);
    const [VFullfillamentcustomer, setVFullfillamentcustomer] = useState(false);
    const [NFFullfillamentcustomer, setNFFullfillamentcustomer] = useState(false);
    const [VNFProductrules, setVNFProductrules] = useState(false);
    const [VNFInventoryrules, setVNFInventoryrules] = useState(false);
    const [VNFOrderlisting, setVNFOrderlisting] = useState(false);
    const [VNFCustomerAccounts, setVNFCustomerAccounts] = useState(false);
    const [VNFProducts, setVNFProducts] = useState(false);

    const [VNFSettings, setVNFSettings] = useState(false);

    const [VAgilityinventory, setVAgilityinventory] = useState(false);

    const [VProductrules, setVProductrules] = useState(false);
    const [VInventoryrules, setVInventoryrules] = useState(false);
    const [VOrderlisting, setVOrderlisting] = useState(false);
    const [VCustomerAccounts, setVCustomerAccounts] = useState(false);
    const [isLoadPermission, setLoadpermission] = useState(createinitialpermission());
    const [VProducts, setVProducts] = useState(false);
    const [Vlogs, setVlogs] = useState(false);
    const [VSettings, setVSettings] = useState(false);

    const [curl, setcurl] = useState("/productsindex/");
    const [nfurl, setnfurl] = useState("/nfproductlisting/");
    const [customerList, setCstomerList] = useState("/nfCustomerlist/");//if partner means need to show
    const [istrue, setistrue] = useState(0);
    const [allcper, setallcper] = useState(false);
    const [allnfcper, setallnfcper] = useState(false);
    useEffect(() => {
        setisLoader(true);
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    var ipermission = [...result];
                    for (var i = 0; i < result.length; i++) {
                        // console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 1:
                                if (result[i].canview == "1") {
                                    setVMagentoconnector(true);
                                }
                                break;

                            case 7:
                                if (result[i].canview == "1") {
                                    setVFullfillamentcustomer(true);
                                } break;
                            case 26:
                                if (result[i].canview == "1") {
                                    setNFFullfillamentcustomer(true);
                                } break;
                            case 27:
                                if (result[i].canview == "1") {
                                    setVNFProductrules(true);
                                } break;
                            case 28:
                                if (result[i].canview == "1") {
                                    setVNFInventoryrules(true);
                                } break;
                            case 29:
                                if (result[i].canview == "1") {
                                    setVNFOrderlisting(true);
                                } break;
                            case 21:
                                if (result[i].canview == "1") {
                                    setVAgilityinventory(true);
                                } break;
                            case 8:
                                if (result[i].canview == "1") {
                                    setVProductrules(true);
                                } break;
                            case 9:
                                if (result[i].canview == "1") {
                                    setVInventoryrules(true);
                                } break;
                            case 10:
                                if (result[i].canview == "1") {
                                    setVOrderlisting(true);
                                } break;
                            case 11:
                                if (result[i].canview == "1") {
                                    setVCustomerAccounts(true);
                                } break;
                            case 30:
                                if (result[i].canview == "1") {
                                    setVNFCustomerAccounts(true);
                                } break;
                            case 31:
                                if (result[i].canview == "1") {
                                    setVNFProducts(true);
                                    setnfurl("/nfproductlisting/");
                                    setallnfcper(true);
                                    // console.log(VProducts);
                                }
                                break;
                            case 12:
                                if (result[i].canview == "1") {
                                    setVProducts(true);
                                    setcurl("/productsindex/");
                                    setallcper(true);
                                    // console.log(VProducts);
                                }
                                break;
                            case 18:
                                if (result[i].canview == "1") {
                                    //if (VProducts != true) {
                                    //    setcurl("/mpjoblist/");
                                    //} 
                                    ipermission.forEach(element => {
                                        if (element.permissionid === 12 && element.canview == "1") {
                                            setcurl("/productsindex/");
                                        }
                                        else if (element.permissionid === 12 && element.canview == "0") {
                                            setcurl("/setup/");
                                        }
                                        //else if (element.permissionid === 13 && element.canview == "1") {
                                        //    setcurl("/setup/");
                                        //}
                                        else if (element.permissionid === 13 && element.canview == "0") {
                                            setcurl("/mpjoblist/");
                                        }

                                        //if (element.permissionid === 18 && element.canview == "1") {
                                        //    setistrue(true);
                                        //}
                                    });
                                    setVlogs(true);
                                    setallcper(true);
                                }
                                break;
                            case 13:
                                if (result[i].canview == "1") {
                                    // if (Vlogs != true && result.where != true) {
                                    ipermission.forEach(element => {
                                        if (element.permissionid === 12 && element.canview == "1") {
                                            setcurl("/productsindex/");
                                        }
                                        else if (element.permissionid === 12 && element.canview == "0") {
                                            setcurl("/setup/");
                                        }

                                        //if (element.permissionid === 18 && element.canview == "1") {
                                        //    setistrue(true);
                                        //}
                                    });
                                    //if (!istrue) {
                                    //    setcurl("/setup/");
                                    //}

                                    setVSettings(true);
                                    setallcper(true);
                                }
                                break;
                            case 32:
                                if (result[i].canview == "1") {
                                    // if (Vlogs != true && result.where != true) {
                                    ipermission.forEach(element => {
                                        if (element.permissionid === 31 && element.canview == "1") {
                                            setnfurl("/nfproductlisting/");
                                        }
                                        else if (element.permissionid === 31 && element.canview == "0") {
                                            setnfurl("/nfsetup/");
                                        }
                                        // else if (element.permissionid === 32 && element.canview == "1") {
                                        //     setnfurl("/nfsetup/");
                                        // }
                                        //if (element.permissionid === 18 && element.canview == "1") {
                                        //    setistrue(true);
                                        //}
                                    });


                                    setVNFSettings(true);
                                    setallnfcper(true);
                                }
                                break;
                        }
                    }
                }
            }
        })

    }, [])

    return (
        <React.Fragment>
            {
                <div className="main-dashboard">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="dashboard-heading">
                                <h2 className="primarycolor">Select Module</h2>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    {VMagentoconnector ?
                                        (<a href="" onClick={megantoCntClick}>
                                            <div className="dashboard">
                                                <div className="dashboard-inner">
                                                    <div className="dashboard-icon">
                                                        <img src="/images/shoppingcart-image.png" />
                                                    </div>
                                                    <div className="dashboard-content">
                                                        <h2>Magento Connector</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>) : (null)
                                    }
                                </div>

                                <div className="col-3">
                                    {VFullfillamentcustomer ?
                                        (<a href="" onClick={handleClick}>
                                            <div className="dashboard">
                                                <div className="dashboard-inner">
                                                    <div className="dashboard-icon">
                                                        <img src="/images/marketplace-image.png" />
                                                    </div>
                                                    <div className="dashboard-content">
                                                        <h2>Fulfillment Customer</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>) : (null)}
                                </div>

                                <div className="col-3">
                                    {NFFullfillamentcustomer ?
                                        (<a href="" onClick={handleNFClick}>
                                            <div className="dashboard">
                                                <div className="dashboard-inner">
                                                    <div className="dashboard-icon-new">
                                                        <i className="bi bi-shop-window"></i>
                                                    </div>
                                                    <div className="dashboard-content">
                                                        <h2>Non Fulfillment Customer</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>) : (null)}
                                </div>


                                <div className="col-3">
                                    {VAgilityinventory ?
                                        (<a href="" onClick={agilityhandleClick}>
                                            <div className="dashboard">
                                                <div className="dashboard-inner">
                                                    <div className="dashboard-icon">
                                                        <img src="/images/agileinventory-image.png" />
                                                    </div>
                                                    <div className="dashboard-content">
                                                        <h2>Agility Inventory</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>) : (null)
                                    }
                                </div>
                            </div>
                            <div id="first" style={stateval == 'clicked' ? { display: 'block' } : { display: 'none' }}>
                                <hr className=""></hr>
                                <div>
                                    <div className="dashboard-heading">
                                        <h2 className="primarycolor">Select Rules</h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            {VProductrules ?
                                                <a href="/productrules" >
                                                    <div className="dashboard">
                                                        <div className="dashboard-inner">
                                                            <div className="dashboard-icon">
                                                                <img src="/images/productrules-image.png" />
                                                            </div>
                                                            <div className="dashboard-content">
                                                                <h2>Product Rules</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a> : (null)
                                            }
                                        </div>
                                        <div className="col-4">
                                            {VInventoryrules ?
                                                <a href="/inventoryRules" >
                                                    <div className="dashboard">
                                                        <div className="dashboard-inner">
                                                            <div className="dashboard-icon">
                                                                <img src="/images/inventoryrules-image.png" />
                                                            </div>
                                                            <div className="dashboard-content">
                                                                <h2>Inventory Rules</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a> : (null)
                                            }
                                        </div>
                                        <div className="col-4">
                                            <a href="/orderRules" >
                                                <div className="dashboard">
                                                    <div className="dashboard-inner">
                                                        <div className="dashboard-icon">
                                                            <img src="/images/orderRules.png" />
                                                        </div>
                                                        <div className="dashboard-content">
                                                            <h2>Order Rules</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>

                                        </div>
                                        <div className="col-4">
                                            {VOrderlisting ?
                                                <a href="/orderlisting/F" >
                                                    <div className="dashboard">
                                                        <div className="dashboard-inner">
                                                            <div className="dashboard-icon">
                                                                <img src="/images/orderlisting-image.png" />
                                                            </div>
                                                            <div className="dashboard-content">
                                                                <h2>Order Listing</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a> : (null)
                                            }
                                        </div>
                                        <div className="col-4">
                                            <a href="/createOrder" >
                                                <div className="dashboard">
                                                    <div className="dashboard-inner">
                                                        <div className="dashboard-icon">
                                                            <img src="/images/createOrder.png" />
                                                        </div>
                                                        <div className="dashboard-content">
                                                            <h2>Create Order - Test</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>

                                        </div>
                                    </div>
                                </div>
                                <hr className=""></hr>
                                <div className="dashboard-heading">
                                    <h2 className="primarycolor">Select Fulfillment Customers</h2>
                                </div>
                                <div className="row">
                                    {
                                        VCustomerAccounts && allcper ? (

                                            mplist != null ? (
                                                mplist.map((item, index) => (
                                                    <div className="col-4">
                                                        <a href={curl + item.resultid} >
                                                            <div className="dashboard">
                                                                <div className="dashboard-inner">
                                                                    <div className="dashboard-icon">
                                                                        <img src="/images/amazon-image.png" />
                                                                    </div>
                                                                    <div className="dashboard-content">
                                                                        <h2>{item.name} - {item.message}</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                ))) : (null)
                                        ) : (null)
                                    }
                                </div>
                            </div>

                            <div id="second" style={stateNFval == 'clicked' ? { display: 'block' } : { display: 'none' }}>
                                <hr className=""></hr>
                                <div>
                                    <div className="dashboard-heading">
                                        <h2 className="primarycolor">Select Rules(NF)</h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            {VNFProductrules ?
                                                <a href="/nfproductrules" >
                                                    <div className="dashboard">
                                                        <div className="dashboard-inner">
                                                            <div className="dashboard-icon">
                                                                <img src="/images/productrules-image.png" />
                                                            </div>
                                                            <div className="dashboard-content">
                                                                <h2>Product Rules</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a> : (null)
                                            }
                                        </div>
                                        <div className="col-4">
                                            {VNFInventoryrules ?
                                                <a href="/nfinventoryRules" >
                                                    <div className="dashboard">
                                                        <div className="dashboard-inner">
                                                            <div className="dashboard-icon">
                                                                <img src="/images/inventoryrules-image.png" />
                                                            </div>
                                                            <div className="dashboard-content">
                                                                <h2>Inventory Rules</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a> : (null)
                                            }
                                        </div>
                                        <div className="col-4">
                                            {VNFOrderlisting ?
                                                <a href="/orderlisting/D" >
                                                    <div className="dashboard">
                                                        <div className="dashboard-inner">
                                                            <div className="dashboard-icon">
                                                                <img src="/images/orderlisting-image.png" />
                                                            </div>
                                                            <div className="dashboard-content">
                                                                <h2>Order Listing</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a> : (null)
                                            }
                                        </div>

                                    </div>
                                </div>
                                <hr className=""></hr>
                                <div className="dashboard-heading">
                                    <h2 className="primarycolor">Select Non-Fulfillment Customers</h2>
                                </div>
                                <div className="row">
                                    {
                                        VNFCustomerAccounts && allnfcper ? (
                                            NFClist != null ? (
                                                NFClist.map((item, index) => (

                                                    <div className="col-4"> 
                                                        {item.mpid == 6 ? (
                                                            <a href={customerList + item.resultid} >
                                                                <div className="dashboard">
                                                                    <div className="dashboard-inner">
                                                                        <div className="dashboard-icon">
                                                                            <img src="/images/amazon-image.png" />
                                                                        </div>
                                                                        <div className="dashboard-content">
                                                                            <h2>{item.name} - {item.message}</h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        ) : (
                                                            <a href={nfurl + item.resultid} >
                                                                <div className="dashboard">
                                                                    <div className="dashboard-inner">
                                                                        <div className="dashboard-icon">
                                                                            <img src="/images/amazon-image.png" />
                                                                        </div>
                                                                        <div className="dashboard-content">
                                                                            <h2>{item.name} - {item.message}</h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </a>

                                                        )}

                                                    </div>


                                                ))) : (null)
                                        ) : (null)
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            }

        </React.Fragment>
    )
}
export default UserDashboard;