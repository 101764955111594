import axios from "axios";
import { allwebsites } from "../../model/allwebsites";

export const GetWebsitelistService = async (callback: any) => {
    await axios.get("api/Website/GetwepsiteListFromapi").then(result => {
        callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}


export const GetWebsiteDetailsService = async (website: allwebsites, callback: any) => {
    await axios.post("api/Website/GetWebsiteBasedStoreDetails", {
        WebsiteId: website.websiteId,
        WebsiteName: website.websiteName
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}

export const SaveWebsiteDetailsService = async (websiteDetails: allwebsites, callback: any) => {
    await axios.post("api/Website/SaveAndUpdateWebsite", {
        id: websiteDetails.id,
        magentoWebsiteId: websiteDetails.magentoWebsiteId,
        channelsId: websiteDetails.channelsId,
        WebsiteId: websiteDetails.websiteId,
        WebsiteCode: websiteDetails.websiteCode,
        WebsiteName: websiteDetails.websiteName,
        StoreId: websiteDetails.storeId,
        StoreCode: websiteDetails.storeCode,
        StoreName: websiteDetails.storeName,
        StoreViewId: websiteDetails.storeViewId,
        StoreViewCode: websiteDetails.storeViewCode,
        StoreViewName: websiteDetails.storeViewName,
        CategoryId: websiteDetails.categoryId,
        CategoryName: websiteDetails.categoryName,
        channelsName: websiteDetails.channelsName
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}