import React, { useEffect, useState } from "react";
import { job, createinitialjob } from "../../dataservices/model/joblist";
import SideBar from "../sidebar";
import { useDispatch, useSelector } from 'react-redux';
import { GetMagentoJobDetailsService, CancellMagentoJobandTaskService } from "../../dataservices/action/magentowebsync/magentosync";
import { jobDetails, createinitialjobDetails } from "../../dataservices/model/jobDetails";
import { useHistory } from 'react-router-dom';
import Loader from "../loader";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Getrolepermissionbyuserid } from "../../dataservices/action/marketplace/marketplace";


const MagentoJobDetails = (props: any) => {
    const dispatch = useDispatch();
    var intjob: jobDetails[] = new Array<jobDetails>();
    const [joblist, SetJoblist] = useState(intjob);
    const [isloader, setisloader] = useState(false);
    const jobId = props.location.state;
    const history = useHistory();

    const Details = (id: number) => {
        props.history.push({
            pathname: '/magentotakdetails',
            state: id
        });
    }
    const CancellJobandTaskAlert=(id:number)=>{
        confirmAlert({
            title: 'Cancel Sync',
            message: 'Are you sure you want to cancel the sync?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => CancellJobandTask(id)
              },
              {
                label: 'No',
                onClick:()=>{}
               
              }
            ]
          });
    }
    const CancellJobandTask = (id: number) => {
        setisloader(true);
        CancellMagentoJobandTaskService(id, function (res: any) {
            setisloader(false);
        })
        history.go(0);
    }
    useEffect(() => {
        debugger
        GetMagentoJobDetailsService(jobId, function (result: jobDetails[]) {
            SetJoblist(result);
        })

    }, [])

    const Back = () => {
        history.push(`/magentojoblist`);
    }
    const [AMsync, setAMsync] = useState(false);
    const [EMsync, setEMsync] = useState(false);
    const [DMsync, setDMsync] = useState(false);
    const [CSMsync, setCSMsync] = useState(false);
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 5:
                                if (result[i].canadd == "1") {
                                    setAMsync(true);
                                }
                                if (result[i].canedit == "1") {
                                    setEMsync(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDMsync(true);
                                }
                                if (result[i].cansync == "1") {
                                    setCSMsync(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])

    return (
        <React.Fragment>
            <div className="row">
                {
                    isloader ? (<Loader isActive={isloader} />) : (null)
                }
                <div className="col-md-3">
                    <SideBar />
                </div>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h1 className="m-0">Magento Sync Summary</h1>
                                <div>
                                    <input className="btn btn-primary me-2" type="button" disabled={EMsync != true ? true : false} onClick={(e) => { CancellJobandTaskAlert(jobId) }} value="Cancel Sync" />
                                    <input className="btn btn-primary me-2" type="button" disabled={EMsync != true ? true : false} onClick={(e) => { Details(jobId) }} value="Sync Log" />
                                    <input className="btn btn-primary" type="button"  onClick={Back} value="Back" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="table table-bordered">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">
                                    Entity Name
                                </th>
                                <th scope="col">
                                    Modified Entities
                                </th>
                                <th scope="col">
                                    New Entities
                                </th>
                                <th scope="col">
                                    Failed Entities
                                </th>
                                <th scope="col">
                                    Status
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                joblist != null ? (
                                    joblist.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                {item.entityName}
                                            </td>
                                            <td>
                                                {item.modified}
                                            </td>
                                            <td>
                                                {item.new}
                                            </td>
                                            <td>
                                                {item.failed}
                                            </td>
                                            <td>
                                                {item.status}
                                            </td>

                                            {/* <td>
                                                <button className="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Delete Product" onClick={(e) => Details(item.id)} ><i className="fas fa-trash"></i></button>
                                            </td> */}
                                        </tr>
                                    ))
                                ) : (

                                    <tr>
                                        <td>
                                            No Records found
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MagentoJobDetails;