import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useDispatch, useSelector } from 'react-redux'
import { user, createinitialUser } from "../dataservices/model/user"
import { useHistory } from 'react-router-dom';
import logo from "../components/images/header-logo.png";
import Globals from '../dataservices/global';
import { useEffect } from 'react';
import { GetAllMarketplaces, Getrolepermissionbyuserid, GetuserbyAdmin } from '../dataservices/action/marketplace/marketplace';
import { GetAPISettings } from '../dataservices/action/users/getUserApiSettins';
import { APISettings, CreateinitialAPISettings } from '../dataservices/model/apisettings'
const NavMenu = () => {

    const [isOpen, SetIsOpen] = React.useState(false);
    const isAuth = useSelector((state: any) => { return state.isatuthendicated })
    let expiresAt = localStorage.getItem('IsAuthendicated');
    let emailId = localStorage.getItem('Username');
    const history = useHistory();
    const dispatch = useDispatch();
    const [IsAdmin, setIsAdmin] = React.useState(false);
    const [siteName, SetSiteName] = React.useState("");


    const [VUserlist, setVUserlist] = React.useState(false);
    const [VWebhook, setVWebhook] = React.useState(false);
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        // console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 22:
                                if (result[i].canview == "1") {
                                    setVWebhook(true);
                                }
                                break;
                            case 23:
                                if (result[i].canview == "1") {
                                    setVUserlist(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])

    const toggle = () => {
        SetIsOpen(!isOpen)
    }
    const Signout = () => {
        localStorage.clear();
        localStorage.setItem('IsAuthendicated', "false");
        localStorage.setItem('Username', "");
        expiresAt = localStorage.getItem('IsAuthendicated');
        dispatch({
            type: "LOG_OUT",
            payload: createinitialUser()
        })

        dispatch({
            type: "LOG_OUT_AUTH",
            payload: false
        })

        //caches.keys().then((names) => {
        //    names.forEach((name) => {
        //        caches.delete(name);
        //    });
        //});
        localStorage.clear();
        history.go(0);

    }
    useEffect(() => {
        GetuserbyAdmin(function (result: any) {
            setIsAdmin(result);
        }),
            GetAPISettings(10, function (result: APISettings[]) {
                if (result != null) {
                    dispatch({
                        type: "MAGENTO_CONNECTOR_COMPLETED",
                        payload: result
                    })
                    let page = result.filter(x => x.name == "SiteName")[0];
                    SetSiteName(page.token);
                    //SetApiSettings(result);
                }
            })

    }, [])

    return (
        <header className="header-section">
            <Container>
                <div className="header-section-inner">
                    <div className="header-section-logo">
                        {expiresAt == "true" ? (
                            <>
                                <NavbarBrand tag={Link} to="/userdashboard">
                                    <img src={logo} alt="" title="" />
                                </NavbarBrand>
                            </>

                        ) : (
                            <>
                                <NavbarBrand tag={Link} to="/">
                                    <img src={logo} alt="" title="" />
                                </NavbarBrand>
                            </>
                        )
                        }
                        <div className='sitename-info'>
                            {
                                "("+siteName+")"
                            }
                        </div>
                    </div>

                    <div className="header-section-links">
                        <Navbar className="navbar-expand-sm navbar-toggleable-sm" light>
                            <NavbarToggler onClick={toggle} className="mr-2" />
                            <Collapse className="header-section-links-inner" isOpen={isOpen} navbar>
                                <ul className="navbar-nav">
                                    {/*{*/}
                                    {/*    expiresAt != "true" ? (*/}
                                    {/*        <>*/}
                                    {/*            <NavItem>*/}
                                    {/*                <NavLink tag={Link} className="" to="/">Log In</NavLink>*/}
                                    {/*            </NavItem>*/}
                                    {/*        </>*/}
                                    {/*    ) : (null)*/}
                                    {/*}*/}
                                    {
                                        expiresAt == "true" ? (
                                            <>
                                                <NavItem>
                                                    <span className="welcome-text">Welcome {emailId}</span>
                                                </NavItem>
                                            </>
                                        ) : (null)
                                    }
                                </ul>
                                <div className="header-navlinks">
                                    {
                                        expiresAt == "true" ? (
                                            <>
                                                <ul className="nav">
                                                    <li className="nav-item"><a href="/userdashboard" className="nav-link">Home</a></li>
                                                    <li className="nav-item"><a href="/userslist" className="nav-link">Users/Roles</a></li>
                                                    <li className="nav-item"><a href="/webhook" className="nav-link">Webhooks</a></li>
                                                    <li className="nav-item"><NavLink tag={Link} className="nav-link" onClick={Signout}>Log Out</NavLink></li>
                                                </ul>
                                            </>
                                        ) : (null)
                                    }
                                </div>
                            </Collapse>
                        </Navbar>
                    </div>

                </div>
            </Container>
            {/*<Container>*/}
            {/*    <div className="header-navlinks">*/}
            {/*    {*/}
            {/*            expiresAt=="true" ? (*/}
            {/*            <>*/}
            {/*                <ul className="nav">*/}
            {/*                        <li className="nav-item"><a href="/userdashboard" className="nav-link">Home</a></li>*/}
            {/*                        <li className="nav-item"><a href="/userslist" className="nav-link">Users</a></li>*/}
            {/*                        <li className="nav-item"><a href="/webhook" className="nav-link">Webhooks</a></li>*/}

            {/*                    */}{/*{*/}
            {/*                    */}{/*        Globals.IsUAdmin ? (*/}
            {/*                    */}{/*        <>*/}
            {/*                    */}{/*            <li className="nav-item"><a href="/userslist" className="nav-link">Users</a></li>*/}
            {/*                    */}{/*            <li className="nav-item"><a href="/webhook" className="nav-link">Webhooks</a></li>*/}
            {/*                    */}{/*        </>*/}
            {/*                    */}{/*    ) : (null)*/}
            {/*                    */}{/*}*/}
            {/*                </ul>*/}
            {/*            </>*/}
            {/*        ) : (null)*/}
            {/*    }*/}
            {/*    </div>*/}
            {/*</Container>*/}
        </header>
    );

}

export default NavMenu;

