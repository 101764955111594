
import React from "react";

interface MyComponentProps {
  attributes: any;
  handleInputChanges: any
  indexValue: any
  isEdit:any
}

const MyAttributes: React.FC<MyComponentProps> = ({ attributes, handleInputChanges, indexValue,isEdit }) => {
  return (
    
    isEdit?(
      <div className="order_attribute_container">
      <div>
        {attributes.customerPartNumber && (
          <div>
            <span className="attribute_label">{"customerPartNumber"}</span>:
            <span className="attribute_Item">
              {" " + attributes.customerPartNumber}
            </span>
          </div>
        )}
        {attributes.agilityPartNumber && (
          <div>
            <span className="attribute_label">{"agilityPartNumber"}</span>:
            <span className="attribute_Item">
              {" " + attributes.agilityPartNumber}
            </span>
          </div>
        )}
        {attributes.mfg && (
          <div>
            <span className="attribute_label">{"mfg"}</span>:
            <span className="attribute_Item">{" " + attributes.mfg}</span>
          </div>
        )}
        {attributes.referenceNo && (
          <div>
            <span className="attribute_label">{"referenceNo"}</span>:
            <span className="attribute_Item">
              {" " + attributes.referenceNo}
            </span>
          </div>
        )}
        {attributes.ltlOnly && (
          <div>
            <span className="attribute_label">{"ltlOnly"}</span>:
            <span className="attribute_Item">{" " + attributes.ltlOnly}</span>
          </div>
        )}
        {/* {attributes.hazmat && (
          <div>
            <span className="attribute_label">{"hazmat"}</span>:
            <span className="attribute_Item">{" " + attributes.hazmat}</span>
          </div>
        )} */}

        <div>
          <span className="attribute_label">{"hazmat"}</span>:
          <span className="attribute_Item">
            {/* {attributes.hazmat ? " " + attributes.hazmat : "false"} */}
            {attributes.hazmat !== null && attributes.hazmat !== undefined ?
              (<select className="form-select" id="trueFalseSelect" name={`orderLineItems[${indexValue}].hazmat`} value={attributes.hazmat } onChange={handleInputChanges}>
              <option value={"Y"}>Yes</option>
              <option value={"N"}>No</option>
            </select>) :
             (<select className="form-select" id="trueFalseSelect" name={`orderLineItems[${indexValue}].hazmat`} value={attributes.hazmat } onChange={handleInputChanges}>
                <option value={"Y"}>Yes</option>
                <option value={"N"}>No</option>
              </select>)}

            {/* <select id="trueFalseSelect" value={} onChange={handleChange}>
              <option value="true">True</option>
              <option value="false">False</option>
            </select> */}
          </span>
        </div>

        {attributes.handlingCode && (
          <div>
            <span className="attribute_label">{"handlingCode"}</span>:
            <span className="attribute_Item">
              {" " + attributes.handlingCode}
            </span>
          </div>
        )}
        {attributes.packageWidth && (
          <div>
            <span className="attribute_label">{"packageWidth"}</span>:
            <span className="attribute_Item">
              {" " + attributes.packageWidth}
            </span>
          </div>
        )}
        {attributes.packageLength && (
          <div>
            <span className="attribute_label">{"packageLength"}</span>:
            <span className="attribute_Item">
              {" " + attributes.packageLength}
            </span>
          </div>
        )}
        {attributes.packageHeight && (
          <div>
            <span className="attribute_label">{"packageHeight"}</span>:
            <span className="attribute_Item">
              {" " + attributes.packageHeight}
            </span>
          </div>
        )}
        {attributes.smallPackage && (
          <div>
            <span className="attribute_label">{"smallPackage"}</span>:
            <span className="attribute_Item">
              {" " + attributes.smallPackage}
            </span>
          </div>
        )}
        {attributes.lineNumber && (
          <div>
            <span className="attribute_label">{"lineNumber"}</span>:
            <span className="attribute_Item">
              {" " + attributes.lineNumber}
            </span>
          </div>
        )}
        {attributes.quantity && (
          <div>
            <span className="attribute_label">{"quantity"}</span>:
            <span className="attribute_Item">{" " + attributes.quantity}</span>
          </div>
        )}
        {attributes.customerUOM && (
          <div>
            <span className="attribute_label">{"customerUOM"}</span>:
            <span className="attribute_Item">
              {" " + attributes.customerUOM}
            </span>
          </div>
        )}
        {attributes.agilityUOM && (
          <div>
            <span className="attribute_label">{"agilityUOM"}</span>:
            <span className="attribute_Item">
              {" " + attributes.agilityUOM}
            </span>
          </div>
        )}
        {attributes.itemSize && (
          <div>
            <span className="attribute_label">{"itemSize"}</span>:
            <span className="attribute_Item">{" " + attributes.itemSize}</span>
          </div>
        )}
        {attributes.itemDescription && (
          <div>
            <span className="attribute_label">{"itemDescription"}</span>:
            <span className="attribute_Item">
              {" " + attributes.itemDescription}
            </span>
          </div>
        )}
        {/* {attributes.salesPrice && ( */}
          <div>
            <span className="attribute_label">{"salesPrice"}</span>:
            <span className="attribute_Item">
              {/* {" " + attributes.salesPrice} */}
              <input type="text"
                className="form-control"
                name={`orderLineItems[${indexValue}].salesPrice`}
                placeholder="Sales Price"
                value={attributes.salesPrice}
                onChange={handleInputChanges}

              />
            </span>
          </div>
        {/* )}*/}
      </div>
    </div>
    ):(
      <div className="order_attribute_container">
      <div>
        {attributes.customerPartNumber && (
          <div>
            <span className="attribute_label">{"customerPartNumber"}</span>:
            <span className="attribute_Item">
              {" " + attributes.customerPartNumber}
            </span>
          </div>
        )}
        {attributes.agilityPartNumber && (
          <div>
            <span className="attribute_label">{"agilityPartNumber"}</span>:
            <span className="attribute_Item">
              {" " + attributes.agilityPartNumber}
            </span>
          </div>
        )}
        {attributes.mfg && (
          <div>
            <span className="attribute_label">{"mfg"}</span>:
            <span className="attribute_Item">{" " + attributes.mfg}</span>
          </div>
        )}
        {attributes.referenceNo && (
          <div>
            <span className="attribute_label">{"referenceNo"}</span>:
            <span className="attribute_Item">
              {" " + attributes.referenceNo}
            </span>
          </div>
        )}
        {attributes.ltlOnly && (
          <div>
            <span className="attribute_label">{"ltlOnly"}</span>:
            <span className="attribute_Item">{" " + attributes.ltlOnly}</span>
          </div>
        )}
        {/* {attributes.hazmat && (
          <div>
            <span className="attribute_label">{"hazmat"}</span>:
            <span className="attribute_Item">{" " + attributes.hazmat}</span>
          </div>
        )} */}

        <div>
          <span className="attribute_label">{"hazmat"}</span>:
          <span className="attribute_Item">
            {attributes.hazmat ? " " + attributes.hazmat : "N"}
            {/* {attributes.hazmat !== null && attributes.hazmat !== undefined ?
              (<select id="trueFalseSelect" name={`orderLineItems[${indexValue}].hazmat`} value={attributes.hazmat } onChange={handleInputChanges}>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>) :
             (<select id="trueFalseSelect" name={`orderLineItems[${indexValue}].hazmat`} value={attributes.hazmat } onChange={handleInputChanges}>
                <option value="false">False</option>
                <option value="true">True</option>
              </select>)} */}

            {/* <select id="trueFalseSelect" value={} onChange={handleChange}>
              <option value="true">True</option>
              <option value="false">False</option>
            </select> */}
          </span>
        </div>

        {attributes.handlingCode && (
          <div>
            <span className="attribute_label">{"handlingCode"}</span>:
            <span className="attribute_Item">
              {" " + attributes.handlingCode}
            </span>
          </div>
        )}
        {attributes.packageWidth && (
          <div>
            <span className="attribute_label">{"packageWidth"}</span>:
            <span className="attribute_Item">
              {" " + attributes.packageWidth}
            </span>
          </div>
        )}
        {attributes.packageLength && (
          <div>
            <span className="attribute_label">{"packageLength"}</span>:
            <span className="attribute_Item">
              {" " + attributes.packageLength}
            </span>
          </div>
        )}
        {attributes.packageHeight && (
          <div>
            <span className="attribute_label">{"packageHeight"}</span>:
            <span className="attribute_Item">
              {" " + attributes.packageHeight}
            </span>
          </div>
        )}
        {attributes.smallPackage && (
          <div>
            <span className="attribute_label">{"smallPackage"}</span>:
            <span className="attribute_Item">
              {" " + attributes.smallPackage}
            </span>
          </div>
        )}
        {attributes.lineNumber && (
          <div>
            <span className="attribute_label">{"lineNumber"}</span>:
            <span className="attribute_Item">
              {" " + attributes.lineNumber}
            </span>
          </div>
        )}
        {attributes.quantity && (
          <div>
            <span className="attribute_label">{"quantity"}</span>:
            <span className="attribute_Item">{" " + attributes.quantity}</span>
          </div>
        )}
        {attributes.customerUOM && (
          <div>
            <span className="attribute_label">{"customerUOM"}</span>:
            <span className="attribute_Item">
              {" " + attributes.customerUOM}
            </span>
          </div>
        )}
        {attributes.agilityUOM && (
          <div>
            <span className="attribute_label">{"agilityUOM"}</span>:
            <span className="attribute_Item">
              {" " + attributes.agilityUOM}
            </span>
          </div>
        )}
        {attributes.itemSize && (
          <div>
            <span className="attribute_label">{"itemSize"}</span>:
            <span className="attribute_Item">{" " + attributes.itemSize}</span>
          </div>
        )}
        {attributes.itemDescription && (
          <div>
            <span className="attribute_label">{"itemDescription"}</span>:
            <span className="attribute_Item">
              {" " + attributes.itemDescription}
            </span>
          </div>
        )}
        {attributes.salesPrice && (
          <div>
            <span className="attribute_label">{"salesPrice"}</span>:
            <span className="attribute_Item">
              {" " + attributes.salesPrice}
              {/* <input type="text"
                className="form-control"
                name={`orderLineItems[${indexValue}].salesPrice`}
                placeholder="Sales Price"
                value={attributes.salesPrice}
                onChange={handleInputChanges}

              /> */}
            </span>
          </div>
        )}
      </div>
    </div>
    )
  );
};

export default MyAttributes;




// // import React from "react";

// interface MyComponentOrderlistProps {
//   attributes: any;
// }

// export const MyOrderlistAttributes: React.FC<MyComponentOrderlistProps> = ({ attributes }) => {
//   return (
//     <div className="order_attribute_container">
//       <div>
//         {attributes.customerPartNumber && (
//           <div>
//             <span className="attribute_label">{"customerPartNumber"}</span>:
//             <span className="attribute_Item">
//               {" " + attributes.customerPartNumber}
//             </span>
//           </div>
//         )}
//         {attributes.agilityPartNumber && (
//           <div>
//             <span className="attribute_label">{"agilityPartNumber"}</span>:
//             <span className="attribute_Item">
//               {" " + attributes.agilityPartNumber}
//             </span>
//           </div>
//         )}
//         {attributes.mfg && (
//           <div>
//             <span className="attribute_label">{"mfg"}</span>:
//             <span className="attribute_Item">{" " + attributes.mfg}</span>
//           </div>
//         )}
//         {attributes.referenceNo && (
//           <div>
//             <span className="attribute_label">{"referenceNo"}</span>:
//             <span className="attribute_Item">
//               {" " + attributes.referenceNo}
//             </span>
//           </div>
//         )}
//         {attributes.ltlOnly && (
//           <div>
//             <span className="attribute_label">{"ltlOnly"}</span>:
//             <span className="attribute_Item">{" " + attributes.ltlOnly}</span>
//           </div>
//         )}
//         {attributes.hazmat && (
//           <div>
//             <span className="attribute_label">{"hazmat"}</span>:
//             <span className="attribute_Item">{" " + attributes.hazmat}</span>
//           </div>
//         )}
//         {attributes.handlingCode && (
//           <div>
//             <span className="attribute_label">{"handlingCode"}</span>:
//             <span className="attribute_Item">
//               {" " + attributes.handlingCode}
//             </span>
//           </div>
//         )}
//         {attributes.packageWidth && (
//           <div>
//             <span className="attribute_label">{"packageWidth"}</span>:
//             <span className="attribute_Item">
//               {" " + attributes.packageWidth}
//             </span>
//           </div>
//         )}
//         {attributes.packageLength && (
//           <div>
//             <span className="attribute_label">{"packageLength"}</span>:
//             <span className="attribute_Item">
//               {" " + attributes.packageLength}
//             </span>
//           </div>
//         )}
//         {attributes.packageHeight && (
//           <div>
//             <span className="attribute_label">{"packageHeight"}</span>:
//             <span className="attribute_Item">
//               {" " + attributes.packageHeight}
//             </span>
//           </div>
//         )}
//         {attributes.smallPackage && (
//           <div>
//             <span className="attribute_label">{"smallPackage"}</span>:
//             <span className="attribute_Item">
//               {" " + attributes.smallPackage}
//             </span>
//           </div>
//         )}
//         {attributes.lineNumber && (
//           <div>
//             <span className="attribute_label">{"lineNumber"}</span>:
//             <span className="attribute_Item">
//               {" " + attributes.lineNumber}
//             </span>
//           </div>
//         )}
//         {attributes.quantity && (
//           <div>
//             <span className="attribute_label">{"quantity"}</span>:
//             <span className="attribute_Item">{" " + attributes.quantity}</span>
//           </div>
//         )}
//         {attributes.customerUOM && (
//           <div>
//             <span className="attribute_label">{"customerUOM"}</span>:
//             <span className="attribute_Item">
//               {" " + attributes.customerUOM}
//             </span>
//           </div>
//         )}
//         {attributes.agilityUOM && (
//           <div>
//             <span className="attribute_label">{"agilityUOM"}</span>:
//             <span className="attribute_Item">
//               {" " + attributes.agilityUOM}
//             </span>
//           </div>
//         )}
//         {attributes.itemSize && (
//           <div>
//             <span className="attribute_label">{"itemSize"}</span>:
//             <span className="attribute_Item">{" " + attributes.itemSize}</span>
//           </div>
//         )}
//         {attributes.itemDescription && (
//           <div>
//             <span className="attribute_label">{"itemDescription"}</span>:
//             <span className="attribute_Item">
//               {" " + attributes.itemDescription}
//             </span>
//           </div>
//         )}
//         {attributes.salesPrice && (
//           <div>
//             <span className="attribute_label">{"salesPrice"}</span>:
//             <span className="attribute_Item">
//               {" " + attributes.salesPrice}
//             </span>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// //export default MyOrderlistAttributes;
