import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { GetEditPermission, UserRegister } from "../../dataservices/action/account/register";
import { user, createinitialUser, moduleinfo } from "../../dataservices/model/user";
import { useSelector } from 'react-redux'
import { GetUserLoad, DeleteUser, SaveEditUser } from "../../dataservices/action/users/usersload";
import SideBar from "../sidebar";
import { ValidateEmail } from '../../dataservices/helper/componenthelpers';
import ASuccess from "../alretbox/success";
import { PaginationValues } from "../pagenation/pageflow";
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
import Loader from "../loader";
import AFailed from "../alretbox/failed";
import { EALREADY } from "constants";
import { debug } from "console";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { settings } from "cluster";
import { products, createinitialProducts } from "../../dataservices/model/mpproducts";
import { GetAllMarketplaces, GetFilters, GetFiltersjson, GetmpLocationsANDagilityBranch, GetFilterDatabyModuleIdandFilterId, GetProductsLoad, GetProductsLoadbyFilter, Getrolepermissionbyuserid, GetSuppliers, GetValuesFromMpProducts, Inactivempproduct, UpdateActiveProduct,UpdateActiveProductsSelectAll, GetMPProductsByFilters } from "../../dataservices/action/marketplace/marketplace";
import Globals from "../../dataservices/global";
import { channellist } from "../../dataservices/model/channellist";
import moment from "moment";
import MPSideBar from "../mpsidebar";
import { jobDetails } from "../../dataservices/model/jobDetails";
import { filters, filtersobjs, storeFilter } from "../../dataservices/model/filters";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import InfoIcon from "@material-ui/icons/Info";
import Popover from "@material-ui/core/Popover";
const Productindex = (props: any) => {
    var intproducts: products[] = new Array<products>()
    const history = useHistory();
    const [products, setProducts] = useState(intproducts);
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const [activestatus, setactivestatus] = useState("2");
    const accountid = props.location.state;
    const { accid } = useParams();

    const store = useSelector((state: any) => { return state });
    const [TotalCount, setTotalCount] = useState(0);
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [PageSizeList, setPageSizeList] = useState([50, 100, 200]);
    const [activePage, setActivePage] = useState(0)
    const [magentoConnect, setmagentoConnect] = useState(false);
    const [amazonMp, setamazonMp] = useState(false);
    const [isLoader, setisLoader] = useState(false);
    const [sku, setsku] = useState("");
    const [fsku, setfsku] = useState("");
    var intlocationlist: channellist[] = new Array<channellist>();
    const [loclist, setloclist] = useState(intlocationlist);

    var intbranchlist: channellist[] = new Array<channellist>();
    const [branchlist, setbranchlist] = useState(intbranchlist);

    const [locationid, setlocationid] = useState(0);
    const [branchid, setbranchid] = useState(0);
    const [issearchcheck, setissearchcheck] = useState(false);

    const [accname, setaccname] = useState("");
    var intbAcclist: channellist[] = new Array<channellist>();
    const [bacclist, setbacclist] = useState(intbAcclist);
    const [ECategoryAttributes, setVCategoryAttributes] = useState(false);
    const [EAPIsettings, setVAPIsettings] = useState(false);
    const [EMagentoSync, setVMagentoSync] = useState(false);
    const [EInriverSync, setVInriverSync] = useState(false);
    const [EProducts, setVProducts] = useState(false);
    const [EInventoryRules, setVInventoryRules] = useState(false);
    const [Elogs, setVlogs] = useState(false);
    const [ESettings, setVSettings] = useState(false);
    const [isDisabled, setisDisabled] = useState("disabled");
    var vperList: moduleinfo[] = new Array<moduleinfo>();
    const [VPList, setVPList] = useState(vperList);
    var intsupplist: jobDetails[] = new Array<jobDetails>();
    const [suppList, setsuppList] = useState(intsupplist);
    const [supplierId, setsupplierId] = useState("");
    var intPrdgrplist: channellist[] = new Array<channellist>();
    const [Prdgrplist, setPrdgrplist] = useState(intPrdgrplist);

    var intPricemaxlist: channellist[] = new Array<channellist>();
    const [Pricemaxlist, setPricemaxlist] = useState(intPricemaxlist);

    var intPriceminlist: channellist[] = new Array<channellist>();
    const [Priceminlist, setPriceminlist] = useState(intPriceminlist);


    var intmfglist: channellist[] = new Array<channellist>();
    const [mfglist, setmfglist] = useState(intmfglist);


    var intfilterlist: filters[] = new Array<filters>();
    const [filter, setfilterlist] = useState(intfilterlist);

    var intfilterobj: filtersobjs[] = new Array<filtersobjs>();
    const [filterobj, setfilterobj] = useState(intfilterobj);

    var initialfil: filtersobjs[] = new Array<filtersobjs>();
    const scrollRef = useRef<HTMLDivElement>(null);

    const [prdgrp, setprdgrp] = useState("");
    const [pricemax, setpricemax] = useState("");
    const [pricemin, setpricemin] = useState("");
    const [mfg, setmfg] = useState("");
    const [tsku, settsku] = useState("");
    const changeFilterTSku = (e: any) => {
        settsku(e.target.value);

    }
    
  useEffect(() => {
    // Scroll to the top when the component mounts or updates
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  });
    //useEffect(() => {
    //    setisLoader(true);
    //    GetValuesFromMpProducts(1, function (result: any) {
    //        setPrdgrplist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    //useEffect(() => {
    //    setisLoader(true);
    //    GetValuesFromMpProducts(2, function (result: any) {
    //        setPricemaxlist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    //useEffect(() => {
    //    setisLoader(true);
    //    GetValuesFromMpProducts(3, function (result: any) {
    //        setPriceminlist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    //useEffect(() => {
    //    setisLoader(true);
    //    GetValuesFromMpProducts(4, function (result: any) {
    //        setmfglist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    //useEffect(() => {

    //    setisLoader(true);
    //    GetFilters(1, function (result: any) {

    //        setfilterlist(result);
    //        setisLoader(false);

    //    })
    //}, [])
    //useEffect(() => {

    //    setisLoader(true);
    //    GetFiltersjson(1, function (result: any) {

    //        setfilterobj(result);
    //        setisLoader(false);

    //    })
    //}, [])
    //useEffect(() => {
    //    setisLoader(true);
    //    //supplierList
    //    GetSuppliers(function (result: any) {
    //        setsuppList(result);
    //        setisLoader(false);
    //    })
    //}, [])
    const [AProduct, setAProduct] = useState(false);
    const [EProduct, setEProduct] = useState(false);
    const [DProduct, setDProduct] = useState(false);
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 12:
                                if (result[i].canadd == "1") {
                                    setAProduct(true);
                                }
                                if (result[i].canedit == "1") {
                                    setEProduct(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDProduct(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])
    useEffect(() => {
        setisLoader(true);
        GetAllMarketplaces(function (result: any) {
            if (result.length > 0) {
                setbacclist(result);
                for (var i = 0; i < result.length; i++) {
                    if (result[i].customerId == Number(accid)) {
                        setaccname(result[i].message);
                    }
                }

                setisLoader(false);
            }
        })
    }, [])

    useEffect(() => {
        setisLoader(true);
        GetmpLocationsANDagilityBranch(1, function (result: any) {
            setloclist(result);
            setisLoader(false);

        })
    }, [])
    useEffect(() => {
        setisLoader(true);
        GetmpLocationsANDagilityBranch(2, function (result: any) {
            setbranchlist(result);
            setisLoader(false);

        })
    }, [])

    useEffect(() => {

        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        //setisLoader(true);
        GetFilters(1, function (result: any) {

            setfilterlist(result);
            setisLoader(false);
            GetFiltersjson(1, function (result: any) {
                setisLoader(true);
                setfilterobj(result);
                GetProductsLoadbyFilter(accid, result, Page, PageSize, function (result: products[]) {
                    let load: Loadermessage = {
                        message: "",
                        isLoader: false,
                        isSuccess: false,
                        isfailed: false
                    }
                    if (result != null && result.length > 0) {
                        setProducts(result);
                        setTotalCount(result[0].totalRecord);
                        setPage(result[0].index);
                        setPageSize(result[0].recordPerPage)
                        setActivePage(Page)
                        setisLoader(false);


                    }
                    else {
                        setProducts(result);
                        setTotalCount(0);

                       
                    }
                    Setisloader(load);
                })

            })

        })

        //GetProductsLoad(accid, sku, locationid, branchid, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, Page, PageSize, function (result: products[]) {
        //    let load: Loadermessage = {
        //        message: "",
        //        isLoader: false,
        //        isSuccess: false,
        //        isfailed: false
        //    }
        //    if (result != null && result.length > 0) {
        //        setProducts(result);
        //        setTotalCount(result[0].totalRecord)
        //        setPage(result[0].index);
        //        setPageSize(result[0].recordPerPage)
        //        setActivePage(Page)


        //    }
        //    Setisloader(load);
        //})
    }, [])


    const Pagenation = () => {
        let Pagination = new PaginationValues();
        Pagination.TotalItemCount = TotalCount;
        Pagination.CurrentPage = Page;
        Pagination.PageSlide = 2;
        Pagination.IsSort = true;
        Pagination.PageSize = PageSize;
//console.log("pagi.....")


        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page - 1) * PageSize) + 1}    - {((((Page - 1) * PageSize) + 1) + PageSize) > TotalCount ? TotalCount : ((((Page - 1) * PageSize)) + PageSize)} of {TotalCount} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">

                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                                {
                                    Pagination.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination.Pages().map((x, i) =>
                                        x == Page ?
                                            (
                                                <li className={`page-item  ${activePage == x ? 'active' : null}`} key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className="page-link" onClick={() => SetPage(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }

    const SetPageSize = (size: number) => {
        setPageSize(size);
        //RefreshProducts();
        setisLoader(true);
        GetProductsLoad(accid, sku, locationid, branchid, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, Page, size, function (result: products[]) {

            if (result != null) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                setisLoader(false);

            }
        })
    }

    const SetPage = (page: number) => {
        setPage(page);
        //setActivePage(page)
        //RefreshProducts();
        //setisLoader(true);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoadbyFilter(accid, filterobj, page, PageSize, function (result: products[]) {
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(page);
                //setisLoader(false);

            } else {
                setProducts(result);
                setTotalCount(0);
            }

            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            Setisloader(load);
        })
    }

    const RefreshProducts = () => {
        // setisLoader(true);
        GetProductsLoadbyFilter(accid, filterobj, Page, PageSize, function (result: products[]) {
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                setActivePage(Page);
                //setisLoader(false);

            } else {
                setProducts(result);
                setTotalCount(0);
            }
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            Setisloader(load);
        })
    }


    const backUserDB = () => {
        setmagentoConnect(false);
        setamazonMp(false);
        Globals.IsAmazon = false;
        Globals.IsMagento = false;
        history.push('/userdashboard');
    }
    const changeSearchSku = (e: any) => {
        setsku(e.target.value);
        console.log(sku);

    }
    const changelocation = (e: any) => {
        let locid = e.target.value;
        setlocationid(locid);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoad(accid, sku, e.target.value, branchid, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, Page, PageSize, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page);
            }
            else {
                setProducts(result);
                setTotalCount(0);
            }

            Setisloader(load);
        })

    }
    const changebranch = (e: any) => {
        let branchid = e.target.value;
        setbranchid(branchid);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoad(accid, sku, locationid, e.target.value, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, Page, PageSize, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            else {
                setProducts(result);
                setTotalCount(0);
            }
            Setisloader(load);
        })

    }
    const changeSupplierId = (e: any) => {
        let supplierid = e.target.value;
        setsupplierId(supplierid);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoad(accid, sku, locationid, branchid, activestatus, e.target.value, prdgrp, pricemax, pricemin, mfg, Page, PageSize, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            else {
                setProducts(result);
                setTotalCount(0);
            }
            Setisloader(load);
        })
    }
    const changeprdgrp = (e: any) => {
        setprdgrp(e.target.value);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoad(accid, sku, locationid, branchid, activestatus, supplierId, e.target.value, pricemax, pricemin, mfg, Page, PageSize, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            else {
                setProducts(result);
                setTotalCount(0);
            }
            Setisloader(load);
        })
    }
    const changeprimax = (e: any) => {
        setpricemax(e.target.value);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoad(accid, sku, locationid, branchid, activestatus, supplierId, prdgrp, e.target.value, pricemin, mfg, Page, PageSize, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            else {
                setProducts(result);
                setTotalCount(0);
            }
            Setisloader(load);
        })
    }
    const changeprimin = (e: any) => {
        setpricemin(e.target.value);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoad(accid, sku, locationid, branchid, activestatus, supplierId, prdgrp, pricemax, e.target.value, mfg, Page, PageSize, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            else {
                setProducts(result);
                setTotalCount(0);
            }
            Setisloader(load);
        })
    }
    const changemfgname = (e: any) => {
        setmfg(e.target.value);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoad(accid, sku, locationid, branchid, activestatus, supplierId, prdgrp, pricemax, pricemin, e.target.value, Page, PageSize, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            else {
                setProducts(result);
                setTotalCount(0);
            }
            Setisloader(load);
        })
    }

    const SearchValues = (e: any) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetProductsLoad(accid, sku, locationid, branchid, activestatus, supplierId, prdgrp, pricemax, pricemin, mfg, Page, PageSize, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)

            }
            else {
                setProducts(result);
                setTotalCount(0);
            }
            Setisloader(load);
        })
    }

    const handleActiveClick = (id: number, e: any) => {
        const { value, checked } = e.target;
        confirmAlert({
            title: 'Update Product Status',
            message: 'Pls confirm to Update Status ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Inactiveproduct(id, checked)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const Inactiveproduct = (id: number, ischk: boolean) => {

        let tmpproduct = products.map((mp) => mp.id === Number(id) ? { ...mp, isActive: ischk } : mp);
        setProducts(tmpproduct);

        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        Inactivempproduct(id, ischk, function (result: any) {
            if (result.isSuccess) {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "product active status updated successfully"
                Setisloader(result);
                RefreshProducts();
            }
        })
    }
    const handleAllClick = (e: any) => {
        const { value, checked } = e.target;
        let tmpproduct = products.map((mp) => {
            return { ...mp, isActive: checked };
        });
        setProducts(tmpproduct);
        confirmAlert({
            title: 'Update Product Status',
            message: 'Pls confirm to Update Status ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Inactiveallproduct(checked)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });

    }


    const Inactiveallproduct = (ischk: boolean) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        //UpdateActiveProduct(accid, sku, locationid, branchid, activestatus, ischk, Page, PageSize, function (result: any) {
            UpdateActiveProductsSelectAll(accid , activestatus, ischk,filterobj, Page, PageSize, function (result: any) {
            let result1 = CreateinitialLoadermessage();
            result1.isSuccess = true;
            result1.message = "product active status updated successfully"
            Setisloader(result1);
            RefreshProducts();
        })
    }

    const changeFilters = (id: number, name: string, e: any) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);

        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === id) {
                if (name == "SKU") {
                    element.value1 = fsku

                } else {
                    element.value1 = e.target.value;
                }
            }
        });

        setfilterobj(ifilter);
        //GetProductsLoadbyFilter(accid, filterobj, Page, PageSize, function (result: products[]) {
        //    debugger;
        //    let load: Loadermessage = {
        //        message: "",
        //        isLoader: false,
        //        isSuccess: false,
        //        isfailed: false
        //    }
        //    if (result != null && result.length>0) {
        //        setProducts(result);
        //        setTotalCount(result[0].totalRecord)
        //        setPage(result[0].index);
        //        setPageSize(result[0].recordPerPage)
        //        setActivePage(Page)
        //    }
        //    else {
        //        setProducts(result);
        //        setTotalCount(0);
        //        let load: Loadermessage = {
        //            message: "",
        //            isLoader: false,
        //            isSuccess: false,
        //            isfailed: false
        //        }
        //        Setisloader(load);
        //    }
        //    Setisloader(load);
        //})
        let load1: Loadermessage = {
            message: "",
            isLoader: false,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load1);
    }
    const changeFilterSku = (e: any) => {
        setfsku(e.target.value);

    }
    const searchisactive = (e: any) => {
        const { value, checked } = e.target;
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        setactivestatus(e.target.value);
        GetProductsLoad(accid, sku, locationid, branchid, e.target.value, supplierId, prdgrp, pricemax, pricemin, mfg, Page, PageSize, function (result: products[]) {
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page);
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            else {
                setProducts(result);
                setTotalCount(0);
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
        })
    }
    const ApplyRules = (e: any) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);


        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.code === "SKU") {
                element.value1 = fsku;
                element.value2 = tsku;
            }
        });
        setfilterobj(ifilter);
        GetProductsLoadbyFilter(accid, filterobj, 1, 50, function (result: products[]) {
            let load: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            }
            if (result != null && result.length > 0) {
                setProducts(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
            }
            else {
                setProducts(result);
                setTotalCount(0);
                setPage(1);
                setPageSize(50);
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
            }
            Setisloader(load);
        })
    }
    const DownloadProduct = () => {
        if (filterobj != null) {
            try {
                let load: Loadermessage = {
                    message: "",
                    isLoader: true,
                    isSuccess: false,
                    isfailed: false
                }
                Setisloader(load);
                const data = new FormData();
                data.append('filters', JSON.stringify(filterobj));
                data.append('ACCID', accid);

                const response = axios({
                    method: "post",
                    url: "api/MP/DownloadProduct",
                    data: data,
                    headers: { "Content-Type": "multipart/form-data" },
                    responseType: 'blob',
                }).then((result) => {

                    let resultmes = CreateinitialLoadermessage();
                    if (result != null) {
                        const url = window.URL.createObjectURL(new Blob([result.data]));
                        const a = document.createElement('a');
                        a.href = url;
                        a.setAttribute('download', "CustomerProducts.csv");
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        URL.revokeObjectURL(url);

                    }
                    resultmes.isLoader = false;
                    Setisloader(resultmes);
                });
                //Setisloader(load);
            } catch (error) {
                console.log(error)
            }
        }
        else {
            let load: Loadermessage = {
                message: "Select Atleast one filter and download",
                isLoader: false,
                isSuccess: false,
                isfailed: true
            }
            Setisloader(load);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        }
    }
    const resetPage = () => {
        history.go(0);
    }

    const handleSelect = (selectedList: any, selectedItem: any, filterId: any) => {
        console.log("sele....", filterId)
        //alert("hi");
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === filterId) {
                //  console.log("coming....")
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
        // console.log("selectedItem....",ifilter);
    }
    const eventRef = React.useRef(null);
    const handleRemove = (selectedList: any, selectedItem: any, filterId: any) => {
        // debugger;
        var ifilter = [...filterobj];
        ifilter.forEach(element => {
            if (element.id === filterId) {
                element.value1 = "";
                element.value1 = selectedList.join(',');
            }
        });
        setfilterobj(ifilter);
        const event: any = eventRef.current;
        event.stopPropagation();
        //console.log("DeselectedItem....",ifilter);
    }
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const [currentEntity, setCurrentEntity] = useState(createinitialProducts);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleInfoClick = (event: any, data: any) => {
        setAnchorEl(event.currentTarget);
        setCurrentEntity(data);
        console.log(data)

    }
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    //     function MultiselectDropdown(dropdownId:any) {
    //         console.log("dropdownId....",dropdownId);
    //     const [options, setOptions] = useState([]);
    //     const [selectedValues, setSelectedValues] = useState([]);
    //     const [isLoading, setIsLoading] = useState(false);

    //     // useEffect(() => {
    //         // Fetch options for the dropdown using the dropdownI
    //     //     fetch(`https://example.com/api/options/${dropdownId}`)
    //     //         .then(response => response.json())
    //     //         .then(data => {
    //     //             setOptions(data);
    //     //         })
    //     //         .catch(error => {
    //     //             console.error('Error:', error);
    //     //         });
    //     // }, [dropdownId]);

    //     // const handleSelect = (selectedList) => {
    //     //     setSelectedValues(selectedList);
    //     // };

    //     return (
    //         <div>
    //             <Multiselect
    //                 options={options}
    //                 selectedValues={selectedValues}
    //                // onSelect={handleSelect}
    //                 placeholder="Select options"
    //             />
    //             {isLoading && <p>Loading options...</p>}
    //         </div>
    //     );
    // }

    // var intfilterlist1: filters[] = new Array<filters>();
    // const [options, setOptions] = useState(intfilterlist1);
    // const [isLoading, setIsLoading] = useState(false);
    // const loadOptions = (e: any) => {
    //     console.log("calling")
    //     console.log("DropDownClicked....", e.currentTarget.getAttribute('data-d-id'));
    //     let filterId = e.currentTarget.getAttribute('data-d-id');
    //     // setOptions([]);
    //     setIsLoading(true);
    //     GetFilterDatabyModuleIdandFilterId(1, filterId, function (result: any) {
    //         const optionsList = result.map((item: any) => item.value);
    //         setOptions(optionsList);
    //         setIsLoading(false);
    //     });

    // }
    const [options, setOptions] = useState<string[]>([]);
    const [storeFilters, setStoreFilters] = useState<storeFilter[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const loadOptions = (e: any) => {
        setOptions([]);
        let filterId: number = e.currentTarget.getAttribute('data-d-id');
        setIsLoading(true);
        let isFilterExist: storeFilter[] = storeFilters.filter(x => x.filterID == filterId);
        if (isFilterExist.length > 0) {
            setOptions(isFilterExist[0].values);
            setIsLoading(false);
        } else {
            GetFilterDatabyModuleIdandFilterId(1, filterId, function (result: any) {
                const optionsList: string[] = result.map((item: any) => item.value);
                let makeFilteredStoredData: storeFilter = {
                    filterID: filterId,
                    values: optionsList
                };
                let sum: storeFilter[] = storeFilters;
                sum.push(makeFilteredStoredData);
                setStoreFilters(storeFilters);
                setOptions(optionsList);
                setIsLoading(false);
            });
        }
    }


    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div className="mpc-menu">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Home</a></li>
                                <li className="breadcrumb-item" aria-current="page">
                                    <span>
                                        Product Listing</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <div className="head-accno">
                                        Account No: <span>{accname}</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                        <MPSideBar id={accid} />
                    </div>
                </div>
            </div>
            <div className="row">
                {

                    isloader.isLoader ? (<Loader isActive={isloader} />) : (null)
                }

                <div className="col-md-12">
                    <div className="heading-section">
                        <h1>Customer Product Listing</h1>
                        {/*<div>*/}
                        {/*    <button type="button" className="btn btn-primary" onClick={RefreshProducts}>Refresh List</button>*/}
                        {/*    <input className="btn btn-primary ms-2" type="button" onClick={backUserDB} value="Back" />*/}
                        {/*</div>*/}
                    </div>
                    <div className="section-border-new">
                        <h2 className="pb-2 primarycolor">Filters</h2>
                        <div className="row">
                            {/* <div className="col-6">*/}
                            {/*     <div className="row g-3 align-items-end">*/}
                            {/*         <div className="col-6">*/}
                            {/*             <label className="form-label">SKU/ Customer ReferenceID/ Mfg</label>*/}
                            {/*             <input type="text" onChange={changeSearchSku} placeholder="" className="form-control" value={sku} />*/}
                            {/*         </div>*/}
                            {/*         <div className="col-2">*/}
                            {/*             <button type="button" className="btn btn-outline-primary" onClick={SearchValues}>Search</button>*/}
                            {/*         </div>*/}
                            {/*         */}{/*<div className="col-4">*/}
                            {/*         */}{/*    <div className="form-check">*/}
                            {/*         */}{/*        <label className="form-label">*/}
                            {/*         */}{/*            Is Active*/}
                            {/*         */}{/*        </label>*/}
                            {/*         */}{/*        <select className="form-select" aria-label="Default select example" onChange={searchisactive}>*/}
                            {/*         */}{/*            <option selected value="2">Select</option>*/}
                            {/*         */}{/*            <option value="2">All</option>*/}
                            {/*         */}{/*            <option value="1">Active</option>*/}
                            {/*         */}{/*            <option value="0">InActive</option>*/}
                            {/*         */}{/*        </select>*/}
                            {/*         */}{/*        */}{/*    <input className="form-check-input" type="checkbox" name="entities" value="" onChange={(e) => searchisactive(e)} />*/}
                            {/*         */}{/*    </div>*/}
                            {/*         */}{/*</div>*/}
                            {/*     </div>*/}
                            {/*</div>*/}
                            {
                                filter != null ? (
                                    filter.map((item, index) => (

                                        item.ftid == 3 ? <div className="col-6 mb-2">
                                            <div className="row g-3 align-items-end">
                                                <div className="col-6">
                                                    <label className="form-label">From SKU</label>
                                                    <input type="text" onChange={changeFilterSku} placeholder="" className="form-control" value={fsku} />
                                                </div>
                                                <div className="col-6">
                                                    <label className="form-label">To SKU</label>
                                                    <input type="text" onChange={changeFilterTSku} placeholder="" className="form-control" value={tsku} />
                                                </div>

                                            </div>
                                        </div> :

                                            //item.ftid == 2 ? <div className="col-6">
                                            //    <div className="row g-3 align-items-end">
                                            //        <div className="col-6">
                                            //            <label className="form-label">SKU</label>
                                            //            <input type="text" onChange={changeFilterSku} placeholder="" className="form-control" value={fsku} />
                                            //        </div>
                                            //        <div className="col-2">
                                            //            <button type="button" className="btn btn-outline-primary" onClick={(e) => changeFilters(item.id, item.name, e)}>Search</button>
                                            //        </div>
                                            //    </div>
                                            //</div> : 

                                            // item.ftid == 1 ? <div className="col-3 mb-2">
                                            //     <label className="form-label">
                                            //         {item.name}
                                            //     </label>
                                            //     <select className="form-select" onChange={(e) => changeFilters(item.id, item.name, e)}>
                                            //         <option selected value={""}>All</option>
                                            //         {
                                            //             item.filtersvalues != null ? (
                                            //                 item.filtersvalues.map((itemval, index) => (
                                            //                     <option value={itemval.key}>{itemval.value}</option>
                                            //                 ))
                                            //             ) : (null)
                                            //         }
                                            //     </select>
                                            // </div> : ""

                                            // item.ftid == 1 ? <div className="col-3 mb-2">
                                            //     <label className="form-label">
                                            //         {item.name}
                                            //     </label>
                                            //     <Multiselect 
                                            //         isObject={false}
                                            //         displayValue="name"
                                            //         placeholder="All"

                                            //         onKeyPressFn={function noRefCheck() { }}
                                            //         // onRemove={handleRemove}
                                            //         onRemove={(selectedList, selectedItem) => handleRemove(selectedList, selectedItem, item.id)}
                                            //         onSearch={function noRefCheck() { }}
                                            //         // onSelect={handleSelect}
                                            //         onSelect={(selectedList, selectedItem) => handleSelect(selectedList, selectedItem, item.id)}
                                            //         options={item.filtersvalues.map(x => x.value)}

                                            //     />
                                            // </div> : ""

                                            //     item.ftid == 1 ? <div className="col-3 mb-2">
                                            //     <label className="form-label">
                                            //         {item.name}
                                            //     </label>
                                            //     <Multiselect 
                                            //         isObject={false}
                                            //         displayValue="name"
                                            //         placeholder="All"
                                            //        // onDropdownToggle={handleDropdownToggle}

                                            //         onKeyPressFn={function noRefCheck() { }}
                                            //         // onRemove={handleRemove}
                                            //         onRemove={(selectedList, selectedItem) => handleRemove(selectedList, selectedItem, item.id)}
                                            //         onSearch={function noRefCheck() { }}
                                            //         // onSelect={handleSelect}
                                            //         onSelect={(selectedList, selectedItem) => handleSelect(selectedList, selectedItem, item.id)}
                                            //         options={item.filtersvalues.map(x => x.value)}

                                            //     />
                                            // </div> : ""

                                            // item.ftid == 1 ? <div className="col-3 mb-2">
                                            //     <label className="form-label">
                                            //         {item.name}
                                            //     </label>
                                            //     <MultiselectDropdown key={index} dropdownId={item.id} />
                                            // </div> : ""
                                            item.ftid == 1 ? <div className="col-3 mb-2">
                                                <label className="form-label">
                                                    {item.name}
                                                </label>
                                                <div onClick={loadOptions} data-d-id={item.id}>
                                                    <Multiselect
                                                        isObject={false}
                                                        displayValue="name"
                                                       // placeholder="All"
                                                        // onDropdownToggle={handleDropdownToggle}

                                                        onKeyPressFn={function noRefCheck() { }}
                                                        // onRemove={handleRemove}
                                                        onRemove={(selectedList, selectedItem) => handleRemove(selectedList, selectedItem, item.id)}
                                                        onSearch={function noRefCheck() { }}
                                                        // onSelect={handleSelect}
                                                        onSelect={(selectedList, selectedItem) => handleSelect(selectedList, selectedItem, item.id)}
                                                        // options={item.filtersvalues.map(x => x.value)}
                                                        options={options}
                                                        loading={isLoading}

                                                    />
                                                </div>
                                            </div> : ""



                                    ))
                                ) : (null)
                            }
                            {/* <div className="col-3">*/}
                            {/*     <label className="form-label">*/}
                            {/*         Warehouse*/}
                            {/*     </label>*/}
                            {/*     <select className="form-select" onChange={(e) => changelocation(e)}>*/}
                            {/*         <option selected value={0}>Select</option>*/}
                            {/*         {*/}
                            {/*             loclist.map((item, index) => (*/}
                            {/*                 <option value={item.resultid}>{item.name}</option>*/}
                            {/*             ))*/}
                            {/*         }*/}
                            {/*     </select>*/}
                            {/* </div>*/}
                            {/* <div className="col-3">*/}
                            {/*     <label className="form-label">*/}
                            {/*         Branch*/}
                            {/*     </label>*/}
                            {/*     <select className="form-select" onChange={(e) => changebranch(e)}>*/}
                            {/*         <option selected value={0}>Select</option>*/}
                            {/*         {*/}
                            {/*             branchlist.map((item, index) => (*/}
                            {/*                 <option value={item.resultid}>{item.name}</option>*/}
                            {/*             ))*/}
                            {/*         }*/}
                            {/*     </select>*/}
                            {/* </div>*/}
                            {/* <div className="col-3">*/}
                            {/*     <label className="form-label">*/}
                            {/*         Supplier*/}
                            {/*    </label>*/}
                            {/*    <select className="form-select" onChange={(e) => changeSupplierId(e)}>*/}
                            {/*         <option selected value={""}>Select</option>*/}
                            {/*         {*/}
                            {/*             suppList.map((item, index) => (*/}
                            {/*                 <option value={item.entityId}>{item.entityName}</option>*/}
                            {/*             ))*/}
                            {/*         }*/}
                            {/*     </select>*/}
                            {/*</div>*/}
                            {/* <div className="col-3">*/}
                            {/*    <label className="form-label">*/}
                            {/*        Product Group*/}
                            {/*    </label>*/}
                            {/*    <select className="form-select" onChange={(e) => changeprdgrp(e)}>*/}
                            {/*        <option selected value="">Select</option>*/}
                            {/*        {*/}
                            {/*            Prdgrplist.map((item, index) => (*/}
                            {/*                <option value={item.name}>{item.name}</option>*/}
                            {/*            ))*/}
                            {/*        }*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                            {/* <div className="col-3">*/}
                            {/*    <label className="form-label">*/}
                            {/*        Price Max*/}
                            {/*    </label>*/}
                            {/*    <select className="form-select" onChange={(e) => changeprimax(e)}>*/}
                            {/*        <option selected value="">Select</option>*/}
                            {/*        {*/}
                            {/*            Pricemaxlist.map((item, index) => (*/}
                            {/*                <option value={item.name}>{item.name}</option>*/}
                            {/*            ))*/}
                            {/*        }*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                            {/* <div className="col-3">*/}
                            {/*    <label className="form-label">*/}
                            {/*        Price Min*/}
                            {/*    </label>*/}
                            {/*    <select className="form-select" onChange={(e) => changeprimin(e)}>*/}
                            {/*        <option selected value="">Select</option>*/}
                            {/*        {*/}
                            {/*            Priceminlist.map((item, index) => (*/}
                            {/*                <option value={item.name}>{item.name}</option>*/}
                            {/*            ))*/}
                            {/*        }*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                            {/*<div className="col-3">*/}
                            {/*    <label className="form-label">*/}
                            {/*        Manufacture*/}
                            {/*    </label>*/}
                            {/*    <select className="form-select" onChange={(e) => changemfgname(e)}>*/}
                            {/*        <option selected value="">Select</option>*/}
                            {/*        {*/}
                            {/*            mfglist.map((item, index) => (*/}
                            {/*                <option value={item.name}>{item.name}</option>*/}
                            {/*            ))*/}
                            {/*        }*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="d-flex justify-content-end align-items-center">
                                    {/*<button type="button" className="btn btn-secondary"*/}
                                    {/*    data-bs-toggle="tooltip" data-bs-placement="top"*/}
                                    {/*    data-bs-custom-class="custom-tooltip"*/}
                                    {/*    data-bs-title="This top tooltip is themed via CSS variables.">*/}
                                    {/*    Custom tooltip*/}
                                    {/*</button>*/}
                                    {/*<a type="button" className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Tooltip on left">Hover over me</a>*/}
                                    <button type="button" className="btn btn-primary filter-btn-tt btn-wh-1" data-bs-custom-class="custom-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title={EProduct != true ? "Restricted" : ""} disabled={EProduct != true ? true : false} onClick={(e) => ApplyRules(e)}>Filter</button>
                                    <button type="button" className="btn btn-wh-1 btn-primary ms-2" onClick={resetPage}>Reset</button>
                                    {/*<a href="#" data-bs-toggle="tooltip" data-bs-title="Some tooltip text!">Hover over me</a>*/}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h2 className="primarycolor">Products</h2>
                                <a target="_blank" type="button" className="btn btn-wh-1 btn-primary float-end" onClick={DownloadProduct}>Download</a>
                                {/*    <a target='_blank' href={`api/MP/MPExport?accountid=${accid}&&sku=${sku}&&locationid=${locationid}&&branchid=${branchid}&&activestatus=${activestatus}&&suppkey=${supplierId}`} type="button" className="btn btn-wh-1 btn-primary float-end">Download</a>*/}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <Pagenation />
                            <div className="table-scroll-section" ref={scrollRef}>
                                <table className="table table-bordered managepartno-table">
                                    <thead className="table-light sticky-top">
                                        <tr>
                                            <th className="col-th-01" scope="col">
                                                Override
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" name="entities" disabled={EProduct != true ? true : false} value="" onChange={(e) => handleAllClick(e)} />
                                               </div>
                                            </th>
                                            <th className="col-th-02" scope="col">
                                                ID
                                            </th>
                                            <th className="col-th-03" scope="col">
                                                SKU
                                            </th>
                                            <th className="col-th-04" scope="col">
                                                Agility UOM
                                            </th>
                                            <th className="col-th-06" scope="col">
                                                Customer Reference ID
                                            </th>
                                            <th className="col-th-06" scope="col">
                                                Customer  UOM
                                            </th>
                                            {/*<th className="col-th-04" scope="col">*/}
                                            {/*    Title*/}
                                            {/*</th>*/}
                                            <th className="col-th-05" scope="col">
                                                Description
                                            </th>
                                            <th className="col-th-08" scope="col">
                                                Warehouse
                                            </th>
                                            {/*<th className="col-th-07" scope="col">*/}
                                            {/*    Mfg Partnumber*/}
                                            {/*</th>*/}

                                            {/*<th className="col-th-09" scope="col">*/}
                                            {/*    Branch*/}
                                            {/*</th>*/}
                                            {/*<th className="col-th-09" scope="col">*/}
                                            {/*    Brand*/}
                                            {/*</th>*/}

                                            <th className="col-th-10" scope="col">
                                                Customer Inventory
                                            </th>
                                            <th className="col-th-011" scope="col">
                                                Customer Updated DateTime
                                            </th>
                                            <th className="col-th-012" scope="col">
                                                Agility Available Inventory
                                            </th>
                                            <th className="col-th-013" scope="col">
                                                Agility Updated DateTime
                                            </th>
                                            <th className="col-th-014" scope="col">
                                                Post Rules Inventory
                                            </th>
                                            <th className="col-th-09" scope="col">
                                                More Information
                                            </th>
                                            {/* <th scope="col-th-09">Product Group Major</th>
                                            <th scope="col-th-09">Product Group Minor</th>
                                            <th scope="col-th-09">Price Group Major</th>
                                            <th scope="col-th-09">Price Group Minor</th>
                                            <th scope="col-th-09">Supplier</th>
                                            <th className="col-th-10" scope="col">
                                                Customer Inventory
                                            </th>
                                            <th className="col-th-011" scope="col">
                                                Customer Updated DateTime
                                            </th>
                                            <th className="col-th-012" scope="col">
                                                Agility Available Inventory
                                            </th>
                                            <th className="col-th-013" scope="col">
                                                Agility Updated DateTime
                                            </th>
                                            <th className="col-th-014" scope="col">
                                                Post Rules Inventory
                                            </th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            products != null ? (
                                                products.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="col-td-01">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="entities" disabled={EProduct != true ? true : false} onChange={(e) => handleActiveClick(item.id, e)} value={item.id} checked={item.isActive ? true : false} />
                                                            </div>
                                                        </td>
                                                        <td className="col-td-02">
                                                            {item.id}
                                                        </td>
                                                        <td className="col-td-03">
                                                            {item.sku}
                                                        </td>
                                                        <td className="col-td-04">
                                                            {item.uom}
                                                        </td>
                                                        <td className="col-td-06">
                                                            {item.asin}
                                                        </td>
                                                        <td className="col-td-04">
                                                            {item.touom}
                                                        </td>
                                                        {/*<td className="col-td-04">*/}
                                                        {/*    {item.title}*/}
                                                        {/*</td>*/}
                                                        <td className="col-td-05">
                                                            {item.displayDescription}
                                                        </td>
                                                        <td className="col-td-08">
                                                            {item.location}
                                                        </td>
                                                        <td className="col-td-10">
                                                            {item.qty}
                                                        </td>
                                                        <td className="col-td-11">
                                                            {item.amazonDate != null ? moment(item.amazonDate).format('MM/DD/YYYY H:mm a') : ""}
                                                        </td>
                                                        <td className="col-td-012">
                                                            {item.availableinventory}
                                                        </td>
                                                        <td className="col-td-013">
                                                            {item.agilityInsertedDate != null ? moment(item.agilityInsertedDate).format('MM/DD/YYYY H:mm a') : ""}
                                                        </td>
                                                        <td className="col-td-014">
                                                            {item.inventoryQty}
                                                        </td>
                                                        {/*<td className="col-td-07">*/}
                                                        {/*    {item.msku}*/}
                                                        {/*</td>*/}

                                                        {/*<td className="col-td-09">*/}
                                                        {/*    {item.branch}*/}
                                                        {/*</td>*/}
                                                        {/*<td className="col-td-09">*/}
                                                        {/*    {item.brand}*/}
                                                        {/*</td>*/}
                                                        <td className="col-td-10">
                                                            <div
                                                                onClick={(e) => handleInfoClick(e, item)}
                                                                className="attribute_icon"
                                                            >
                                                                <InfoIcon className="Icon_color_global" />
                                                            </div>
                                                        </td>
                                                        {/* <td>
                                                            {item.pgroup}
                                                        </td>
                                                        <td>
                                                            {item.productgrp}
                                                        </td>
                                                        <td>
                                                            {item.pmax}
                                                        </td>
                                                        <td>
                                                            {item.pmin}
                                                        </td>
                                                        <td>
                                                            {item.pmfg}
                                                        </td>
                                                        <td className="col-td-10">
                                                            {item.qty}
                                                        </td>
                                                        <td className="col-td-11">
                                                            {item.amazonDate != null ? moment(item.amazonDate).format('MM/DD/YYYY H:mm a') : ""}
                                                        </td>
                                                        <td className="col-td-012">
                                                            {item.availableinventory}
                                                        </td>
                                                        <td className="col-td-013">
                                                            {item.agilityInsertedDate != null ? moment(item.agilityInsertedDate).format('MM/DD/YYYY H:mm a') : ""}
                                                        </td>
                                                        <td className="col-td-014">
                                                            {item.inventoryQty}
                                                        </td> */}

                                                    </tr>
                                                ))
                                            ) : (null)
                                        }
                                    </tbody>
                                </table>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                >
                                    <div className="order_attribute_container">
                                        <div>

                                            {currentEntity != null && currentEntity.brand != null && (
                                                <div>
                                                    <span className="attribute_label">Brand:</span>
                                                    <span className="attribute_Item">{currentEntity.brand}</span>
                                                </div>

                                            )}
                                            {currentEntity != null && currentEntity.msku != null && (
                                                <div>
                                                    <span className="attribute_label">Mfg Partnumber:</span>
                                                    <span className="attribute_Item">{currentEntity.msku}</span>
                                                </div>

                                            )}
                                            {currentEntity != null && currentEntity.pgroup != null && (
                                                <div>
                                                    <span className="attribute_label">Product Group Major:</span>
                                                    <span className="attribute_Item">{currentEntity.pgroup}</span>
                                                </div>

                                            )}
                                            {currentEntity != null && currentEntity.productgrp != null && (
                                                <div>
                                                    <span className="attribute_label">Product Group Minor:</span>
                                                    <span className="attribute_Item">{currentEntity.productgrp}</span>
                                                </div>

                                            )}
                                            {currentEntity != null && currentEntity.pmax != null && (
                                                <div>
                                                    <span className="attribute_label">Price Group Major:</span>
                                                    <span className="attribute_Item">{currentEntity.pmax}</span>
                                                </div>

                                            )}
                                            {currentEntity != null && currentEntity.pmin != null && (
                                                <div>
                                                    <span className="attribute_label">Price Group Minor:</span>
                                                    <span className="attribute_Item">{currentEntity.pmin}</span>
                                                </div>

                                            )}
                                            {currentEntity != null && currentEntity.pmfg != null && (
                                                <div>
                                                    <span className="attribute_label">Supplier:</span>
                                                    <span className="attribute_Item">{currentEntity.pmfg}</span>
                                                </div>

                                            )}
                                            {/* {currentEntity != null && currentEntity.qty != null && (*/}
                                            {/*    <div>*/}
                                            {/*        <span className="attribute_label">Customer Inventory:</span>*/}
                                            {/*        <span className="attribute_Item">{currentEntity.qty}</span>*/}
                                            {/*    </div>*/}

                                            {/*)}*/}
                                            {/*{currentEntity != null && currentEntity.amazonDate != null && (*/}
                                            {/*    <div>*/}
                                            {/*        <span className="attribute_label">Customer Updated DateTime:</span>*/}
                                            {/*        <span className="attribute_Item">{ currentEntity.amazonDate != null ? moment(currentEntity.amazonDate).format('MM/DD/YYYY H:mm a') : ""}</span>*/}
                                            {/*    </div>*/}

                                            {/*)}*/}
                                            {/*{currentEntity != null && currentEntity.availableinventory != null && (*/}
                                            {/*    <div>*/}
                                            {/*        <span className="attribute_label">Agility Available Inventory:</span>*/}
                                            {/*        <span className="attribute_Item">{currentEntity.availableinventory}</span>*/}
                                            {/*    </div>*/}

                                            {/*)}*/}
                                            {/* {currentEntity != null && currentEntity.agilityInsertedDate != null && (*/}
                                            {/*    <div>*/}
                                            {/*        <span className="attribute_label">Agility Updated DateTime:</span>*/}
                                            {/*        <span className="attribute_Item">{ currentEntity.agilityInsertedDate != null ? moment(currentEntity.agilityInsertedDate).format('MM/DD/YYYY H:mm a') : ""}</span>*/}
                                            {/*    </div>*/}

                                            {/*)}*/}
                                            {/*{currentEntity != null && currentEntity.inventoryQty != null && (*/}
                                            {/*    <div>*/}
                                            {/*        <span className="attribute_label">Post Rules Inventory:</span>*/}
                                            {/*        <span className="attribute_Item">{currentEntity.inventoryQty}</span>*/}
                                            {/*    </div>*/}

                                            {/*)}*/}
                                        </div>
                                    </div>
                                </Popover>

                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </React.Fragment>
    )

}

export default Productindex;