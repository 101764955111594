import { fail } from "assert"


export interface filters {
    id: number,
    name: string,
    ftid: number,
    filtersvalues: Array<filtersvalues>   
}

export interface applypermission {
    permissionid: number,
    name: string,
    canview: string,
    canadd: string,
    canedit:string,
    candelete: string,
    cansync:string
}
export interface filtersvalues {
    id: number,
    name: string,
    key: string,
    value: string   
}

export interface storeFilter {
    filterID: number,
    values: string[]
}

export interface filtersobjs {
    id: number,
    code: string,
    attributename: string,
    filtertype: string,
    value1: string,
    value2: string,
    modelname: string

}
export const createinitialfiltersobjs = (): filtersobjs => {
    return ({
        id: 0,
        attributename: "",
        filtertype:"",
        code: "",
        value1: "",
        value2: "",
        modelname:""
    })
}
export const createinitialfilters = (): filters => {
    return ({
        id: 0,
        name: "",
        ftid:0,
        filtersvalues: []
    })
}
export const createinitialpermission = (): applypermission => {
    return ({
        permissionid: 0,
        name: "",
        canview: "",
        canadd: "",
        canedit: "",
        candelete: "",
        cansync:""

    })
}
