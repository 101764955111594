import React from "react";
export  const Loginreducres = (state: boolean =false, action: any) => {
    switch (action.type) {
        case "LOG_IN_AUTH":
            return handleFetchCompleted(state,action.payload)
        case "LOG_OUT_AUTH":
            return handleFetchCompleted(state,action.payload)
            return 
    }
    return state;
}

const handleFetchCompleted = (state: boolean, payload: boolean) => payload;