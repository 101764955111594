import * as WeatherForecasts from './WeatherForecasts';
import * as Counter from './Counter';
import * as login from '../dataservices/reducers/loginreducers';
import {Loginreducres} from '../dataservices/reducers/isatuthreducers';
import { LoadMagentoCOnnectorreducers} from '../dataservices/reducers/loadmagentoconncetor';
import { user } from '../dataservices/model/user';
import {APISettings} from '../dataservices/model/apisettings';
import { OrderListingReducer, OrderListingState } from './OrderListing';
import { OrderListReducer, OrderListState } from './OrderList';

// The top-level state object
export interface ApplicationState {
    counter: Counter.CounterState | undefined;
    weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
    user: user | undefined;
    isatuthendicated : number | 0;
    APISettings : APISettings[] | null;
    OrderListing : OrderListingState;
    OrderList : OrderListState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    counter: Counter.reducer,
    weatherForecasts: WeatherForecasts.reducer,
    user :login.Loginreducres,
    isatuthendicated :Loginreducres,
    APISettings :LoadMagentoCOnnectorreducers,
    OrderListing : OrderListingReducer,
    OrderList : OrderListReducer  
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
