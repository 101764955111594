import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import SideBar from "../sidebar";
import Box from '@material-ui/core/Box'
import Loader from "../loader";
import ASuccess from "../alretbox/success";
import AFailed from "../alretbox/failed";
import { GetMagentowebSiteList, GetMagentoRunningJob, GetSyncPauseval, PauseSync, GetBulkEndPoint, UpdateBulkEndPoint, SyncMagentoWebsiteService, GetMagentoLatestSyncDate } from "../../dataservices/action/magentowebsync/magentosync";
import { allwebsites } from '../../dataservices/model/allwebsites';
import moment from "moment";

import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
import { APISettings } from "../../dataservices/model/apisettings";
import { parse } from "url";
import { GetEditPermission } from "../../dataservices/action/account/register";
import { moduleinfo } from "../../dataservices/model/user";
import { Getrolepermissionbyuserid } from "../../dataservices/action/marketplace/marketplace";

const MagentoSyncConnector = () => {
    var initWebsitelist: allwebsites[] = new Array<allwebsites>();
    const [Websitelist, SetWebsitelist] = useState(initWebsitelist);
    const [isPause, setisPause] = useState(false);
    const [isJobRunnning, setisJobRunnning] = useState(false);
    const [isShowpopup, SetisShowpopup] = useState(false);
    const [selectWebsiteId, SetselectWebsiteId] = useState(0);
    const [jobType, SetselectjobType] = useState(2);
    const [deltaDate, SetselectdeltaDate] = useState(new Date());
    const [syncName, SetsyncName] = useState("")
    const [isDate, SetisDate] = useState(true)
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const [isBulkPoint, setisBulkPoint] = useState(false);
    const [isBindDeltaDate, setBindDeltaDate] = useState(false);
    const [deltaBindDate, SetselectdeltaBindDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState("");
    const [isBindTime, setBindTime] = useState(false);
    const [isLoader, setisLoader] = useState(false);

    const history = useHistory();
    var vperList: moduleinfo[] = new Array<moduleinfo>();
    const [VPList, setVPList] = useState(vperList);
    const [ECategoryAttributes, setVCategoryAttributes] = useState(false);
    const [EAPIsettings, setVAPIsettings] = useState(false);
    const [EMagentoSync, setVMagentoSync] = useState(false);
    const [EInriverSync, setVInriverSync] = useState(false);
    const [EProducts, setVProducts] = useState(false);
    const [EInventoryRules, setVInventoryRules] = useState(false);
    const [Elogs, setVlogs] = useState(false);
    const [ESettings, setVSettings] = useState(false);
    const [isDisabled, setisDisabled] = useState("disabled");


    const [AMsync, setAMsync] = useState(false);
    const [EMsync, setEMsync] = useState(false);
    const [DMsync, setDMsync] = useState(false);
    const [CSMsync, setCSMsync] = useState(false);
    useEffect(() => {
        setisLoader(true);
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 5:
                                if (result[i].canadd == "1") {
                                    setAMsync(true);
                                }
                                if (result[i].canedit == "1") {
                                    setEMsync(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDMsync(true);
                                }
                                if (result[i].cansync == "1") {
                                    setCSMsync(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])


    const GetJob = () => {
        history.push(`/magentojoblist`);
    }

    const SyncSchedule = () => {
        history.push(`/synschedule`);
    }
    useEffect(() => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetMagentowebSiteList(function (result: allwebsites[]) {
            let resultmes = CreateinitialLoadermessage();
            if (result != null) {
                SetWebsitelist(result)
            }
            resultmes.isLoader = false;
            Setisloader(resultmes);

            GetMagentoLatestSyncDate(function (result: Date) {
                //alert(result);
                if (result != null) {
                    let deldte = new Date(moment(result).format("MM/DD/YYYY"));
                    //SetselectdeltaDate(result);
                    SetselectdeltaDate(deldte);
                    let syncTime = moment(result).format("HH:mm");
                    setSelectedTime(syncTime);
                    setBindDeltaDate(true);
                }

            })

        })


    }, [])

    useEffect(() => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetMagentoRunningJob(function (result: any) {
            setisJobRunnning(result)
            if (result) {
                setCSMsync(false)
            } else {
                setCSMsync(true);
            }
            let resultmes = CreateinitialLoadermessage();

            resultmes.isLoader = false;
            Setisloader(resultmes);
        })
    }, [])


    useEffect(() => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        PauseSync(function (result: any) {
            setisPause(result)
            let resultmes = CreateinitialLoadermessage();
            resultmes.isLoader = false;
            Setisloader(resultmes);
        })
    }, [])

    const Pause = (id: number) => {
        GetSyncPauseval(id, function (result: APISettings) {
            if (result.isMagentoApi) {
                let result = CreateinitialLoadermessage()
                result.isfailed = true;
                result.message = "Paused"
                Setisloader(result);
                refreshPage();
            }
            else {
                //alert("Resumed Successfully");
                let result = CreateinitialLoadermessage()
                result.isfailed = true;
                result.message = "Resumed"
                Setisloader(result);
                refreshPage();
            }
        })
    }

    useEffect(() => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetBulkEndPoint(function (result: any) {
            setisBulkPoint(result)
            let resultmes = CreateinitialLoadermessage();
            resultmes.isLoader = false;
            Setisloader(resultmes);
        })
    }, [])

    const BulkEndPoint = (id: number) => {
        UpdateBulkEndPoint(id, function (result: APISettings) {
            if (result.isMagentoApi) {
                let result = CreateinitialLoadermessage()
                result.isfailed = true;
                result.message = "Enabled"
                Setisloader(result);
                refreshPage();
            }
            else {
                //alert("Resumed Successfully");
                let result = CreateinitialLoadermessage()
                result.isfailed = true;
                result.message = "Disabled"
                Setisloader(result);
                refreshPage();
            }
        })
    }
    const SyncMagentoWebsite = () => {
        debugger
        //var date = moment(new Date()).format("MM/DD/YYYY");
        // var sldate = moment(deltaDate).format("MM/DD/YYYY");
        var date = moment(new Date().getTime());
        var sldate = moment(new Date(deltaDate).getTime());
        var Date1 = moment(deltaDate).format("YYYY/MM/DD") + " " + selectedTime;
        var utcDate = moment.utc(moment(Date1).utc()).format();

        console.log(moment.utc(moment(Date1).utc()).format());

        let isdatevalid = true;
        if (sldate > date) {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please don't select future date"
            Setisloader(result);
            isdatevalid = false;
        }
        if (jobType !== 0) {
            let load: Loadermessage = {
                message: "",
                isLoader: true,
                isSuccess: false,
                isfailed: false
            }
            Setisloader(load);
            let dt = deltaDate
            SyncMagentoWebsiteService(selectWebsiteId, jobType, utcDate, selectedTime, function (res: any) {
                let result = CreateinitialLoadermessage()
                if (res) {
                    result.isSuccess = true;
                    result.message = "Sync Started Successfully";
                    // setSuccess(true);
                    Setisloader(result);
                }
                else {
                    result.isfailed = true;
                    result.message = "Sync not started";
                    Setisloader(result);
                    //  setSuccess(true);
                }
                SetisShowpopup(!isShowpopup);
            })
        } else {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please select jobtype ";
            Setisloader(result);
        }
    }
    const ChnageJobType = (jobType: number) => {
        // debugger;
        if (jobType == 1)
            SetisDate(false);
        else
            SetisDate(true);
        SetselectjobType(jobType)
    }

    function refreshPage() {
        window.location.reload();
    }

    const OpenClosePopUp = (id: number) => {
        SetisShowpopup(!isShowpopup);
        SetselectWebsiteId(id);
    }
    const SyncAllOpenClosePopUp = (id: number) => {
        GetMagentoLatestSyncDate(function (result: Date) {
            if (result != null) {
                var momentDate = moment(result);
                // alert(new Date(result));
                SetselectdeltaDate(new Date(result));
                setBindDeltaDate(true);
            }
            else {
                setBindDeltaDate(false);
            }

        })

        SetisShowpopup(!isShowpopup);
        SetselectWebsiteId(id);
    }


    const showPopUpUpdateDate = (id: number, newDate: Date) => {
        debugger
        var ndate = moment(newDate).format("MM/DD/YYYY h:mm")
        //const newDates = new Date(newDate);
        //alert(newDate)
        //debugger;
        if (newDate != null) {
            let dltdt = new Date(moment(newDate).format("MM/DD/YYYY"))
            SetselectdeltaDate(dltdt);

            let syncTime = moment(newDate).format("HH:mm");
            setSelectedTime(syncTime);
            setBindDeltaDate(true);
            setBindTime(true);

        }
        else {
            setBindDeltaDate(false);
            SetselectdeltaDate(new Date());
        }
        SetisShowpopup(!isShowpopup);
        SetselectWebsiteId(id);
    }
    const onChangeDate = (e: any) => {
        debugger;
        const newDate = e.target.value;
        //var ndate = moment(newDate).format("MM/DD/YYYY")
        //var date = moment(new Date()).format("MM/DD/YYYY")
        var ndate = moment(new Date(newDate).getTime())
        var date = moment(new Date().getTime())
        if (ndate > date) {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please don't select future date"
            //result.message = "Please don't select past date"
            Setisloader(result);
        }
        else {
            SetselectdeltaDate(newDate)
        }
    };
    const OnChangeTime = (e: any) => {
        debugger;
        var newTime = e.target.value;
        setBindTime(true);
        setSelectedTime(newTime);
        var sldate = new Date(moment(deltaDate).format("MM/DD/YYYY"));
        //let dte=new Date(sldate)
        //const dateTime = new Date(sldate + ' ' + newTime );
        //let getHr=dateTime.getHours();
        //let finalHr=dateTime.setHours(getHr-6)
        // let finHrMin= moment(finalHr).subtract(30, 'minutes').toDate();
        SetselectdeltaDate(sldate);
    }
    const ClosePopup = () => {
        Setisloader(CreateinitialLoadermessage())
    }

    const AddWebsite = () => {
        history.push('/addwebsite');
    }
    const MagentoSyncSchedule = () => {
        history.push(`/MagentoSyncSchedule`);
    }
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <SideBar />
                </div>
                {
                    isloader.isLoader ? (<Loader isActive={isloader} />) : (null)
                }
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-end align-items-center mb-4">
                                <input className="btn btn-primary me-2" type="button" disabled={CSMsync != true && isJobRunnning ? true : false} onClick={MagentoSyncSchedule} value="Sync Schedule" />
                                <input className="btn btn-primary me-2" onClick={AddWebsite} type="button" disabled={AMsync != true ? true : false} value="Add Website" />
                                <input className="btn btn-primary me-2" type="button" disabled={CSMsync != true && isJobRunnning ? true : false} onClick={(e) => SyncAllOpenClosePopUp(0)} value="Sync All" />
                                {!isPause ? <input className="btn btn-primary me-2" type="button" disabled={CSMsync != true ? true : false} value="Pause Sync" onClick={(e) => Pause(1)} /> : <input className="btn btn-primary me-2" onClick={(e) => Pause(0)} type="button" disabled={CSMsync != true ? true : false} value="Resume Sync" />}
                                <input className="btn btn-primary" type="button" disabled={EMsync != true ? true : false} onClick={GetJob} value="Job List" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-end align-items-center mb-4">
                                {/*<div className="form-check form-switch">*/}
                                {/*    {isBulkPoint ? <input className="form-check-input" type="checkbox" id="" onChange={(e) => BulkEndPoint(0)} /> : <input className="form-check-input" type="checkbox" id="" onChange={(e) => BulkEndPoint(1)} />}*/}
                                {/*    <label className="form-check-label">Bulk Product Import</label>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">

                        </div>
                    </div>

                    <div className="row">
                        {

                            Websitelist != null ? (

                                Websitelist.map((item, index) => (

                                    <div className="col-sm-6 col-md-6">
                                        <div className="box-results" key={index}>
                                            <div className="box-results-row">
                                                <div className="box-results-col txt-name">
                                                    Website
                                                </div>
                                                <div className="box-results-col">
                                                    {
                                                        item.websiteName
                                                    }
                                                </div>
                                                <div className="box-results-col txt-red">
                                                    {/* <i className="bi bi-trash-fill"></i> */}
                                                </div>
                                            </div>
                                            <div className="box-results-row">
                                                <div className="box-results-col txt-name">
                                                    Sync Date
                                                </div>
                                                <div className="box-results-col">
                                                    {
                                                        item.deltaDate != null ? item.deltaDate : null
                                                        //item.deltaDate != null ? new Date(item.deltaDate).toLocaleString() : null
                                                        // item.deltaDate != null ? new Date(item.deltaDate).toLocaleDateString('en-CA') : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end align-items-center  mt-3">
                                                <input className="btn btn-sm btn-outline-secondary" disabled={CSMsync != true ? true : false} type="button" value="Sync" onClick={(e) => showPopUpUpdateDate(item.magentoWebsiteId, item.deltaDate)} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (null)
                        }
                    </div>
                    {
                        isShowpopup ? (
                            <div id="popup1" className="overlay">
                                <div className="popup">
                                    <h4>Magento Sync Options</h4>
                                    <a className="close" onClick={(e) => OpenClosePopUp(0)}>&times;</a>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="isSyncetype" id="" onClick={(e) => ChnageJobType(1)} />
                                                <label className="form-check-label">
                                                    Full Sync
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" defaultChecked name="isSyncetype" id="" onClick={(e) => ChnageJobType(2)} />
                                                <label className="form-check-label">
                                                    Delta Sync
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                    {
                                        isDate ? (<div className="row mb-3">
                                            <div className="col-auto">
                                                <label className="col-form-label">Last Sync Date & Time</label>
                                            </div>
                                            {isBindDeltaDate ?
                                                (<div className="box-results-col mb-2">
                                                    <input type="date" className="form-control" id="datetime" value={new Date(deltaDate).toLocaleDateString('en-CA')} aria-describedby="datetimehelp" onChange={onChangeDate} />

                                                </div>) : (<div className="box-results-col mb-2">
                                                    <input type="date" className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeDate} required />
                                                </div>)

                                            }
                                            {
                                                isBindTime ? (<div className="box-results-col">
                                                    <input type="time" value={selectedTime} className="form-control" id="datetime" onChange={OnChangeTime} aria-describedby="datetimehelp" required />
                                                </div>) : (<div className="box-results-col">
                                                    <input type="time" value={selectedTime} className="form-control" id="datetime" onChange={OnChangeTime} aria-describedby="datetimehelp" />
                                                </div>)
                                            }

                                        </div>) : (null)
                                    }

                                    <div className="d-flex justify-content-center align-items-center">
                                        <button type="button" className="btn btn-sm btn-primary me-2" disabled={CSMsync != true && isJobRunnning ? true : false} onClick={(e) => SyncMagentoWebsite()}>Sync</button>
                                    </div>
                                </div>
                            </div>
                        ) : (null)
                    }
                    <div onClick={ClosePopup}>
                        {
                            isloader.isfailed ? (
                                <AFailed message={isloader.message} />
                            ) : (null)
                        }
                    </div>
                    <div onClick={ClosePopup}>
                        {
                            isloader.isSuccess ? (
                                <ASuccess message={isloader.message} />
                            ) : (null)
                        }
                    </div>


                </div>
            </div>
        </React.Fragment >

    )
}
export default MagentoSyncConnector;