import axios from 'axios';


export const GetJobListService = async (page: number, size: number, callback: any) => {
    await axios.post("api/Job/GetJobList", {
        index: page,
        recordPerPage: size
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}
export default GetJobListService;

export const GetJobDetailsService = (id: number, callback: any) => {
    if (id != null) {
        axios.get(`api/Job/GetJobDetailsById?jobId=${id}`).then(result => {
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}

export const FailedjobRetry = (id: number, callback: any) => {
    if (id != null) {
        axios.get(`api/MagentoWebSync/FailedjobRetry?jobId=${id}`).then(result => {
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}
export const GetTaskDetails = (jobId: number, page: number, size: number, entityType: number, statusId: number, callback: any) => {
    if (jobId != null) {
        axios.post(`api/Job/GetTaskDetailsById`, {
            Id: jobId,
            index: page,
            recordPerPage: size,
            entityTypeId: entityType,
            statusId: statusId
        }).then(result => {
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}
export const ExportExcel = (jobId: number, page: number, size: number, entityType: number, statusId: number, callback: any) => {
    if (jobId != null) {
        axios.get(`api/Account/Export`, {
            params: {
                Id: jobId,
                index: page,
                recordPerPage: size,
                entityTypeId: entityType,
                statusId: statusId
            }
        }).then(result => {
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}
export const CancellJobandTaskService = (id: number, callback: any) => {
    if (id != null) {
        axios.get(`api/Job/CancellJobandTaskService?jobId=${id}`).then(result => {
            return callback(result.data);
        }).catch(err => {
            console.log(err)
        })
    }
}