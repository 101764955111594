import React, { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';

import { GetRoleLoad, DeleteUserRule } from "../../dataservices/action/users/getUserApiSettins";
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
import { role, createinitialRole,createinitialpermission } from "../../dataservices/model/role";
import ASuccess from "../alretbox/success";
import AFailed from "../alretbox/failed";

import Loader from "../loader";
import UserSideBar from "../usersidebar";
import { useLocation } from "react-router-dom";


const CustomModal = ({ show, onHide ,data}:any) => {

    //console.log("show....",show)
    const [isLoader, SetisLoader] = useState(false);
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const [newRoleId, setNewRoleId] = useState(0);
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    var introles: role[] = new Array<role>();
    const [roles, setRoles] = useState(introles);
    var introles: role[] = new Array<role>();

    useEffect(() => {
        SetisLoader(true);

        GetRoleLoad(Page, PageSize, function (result: role[]) {
            if (result != null) {
                setRoles(result);
            
                SetisLoader(false);
            }
           

        })
    }, [])

    const handleSelectChange = (event: any) => {
        setNewRoleId(event.target.value);
       // console.log("event....", event.target.value);
       // console.log("event.target..", event.target);

    }

    const saveRole=()=>{
           // console.log("save....")
           // console.log("old.roleid.", data);
           // console.log("new.roleid.", newRoleId);
            
            let load: Loadermessage = {
                message: "",
                isLoader: true,
                isSuccess: false,
                isfailed: false
            }
            Setisloader(load);
            const roles: role = {
                id:newRoleId,
                name:"",
                index: 0,
                recordPerPage: 50,
                totalRecord: 500,
                applypermission: createinitialpermission(),
                createdOn:new Date(),
                existingId:data,
            }
            DeleteUserRule(roles, function (result: any) {
                if (result.isSuccess) {
                    let result = CreateinitialLoadermessage();
                    result.isSuccess = true;
                    result.message = "Role deleted successfully"
                    Setisloader(result);
                    // let updateduser = users.filter((user: { id: number; }) => user.id != id);
                    //setUsers(updateduser);
                    history.go(0);
                }
            })
    
    }
    return (
     
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>This role is assigned to an user,please change other role to that user before delete.</Modal.Title>
                {/* <p>This role is already assigned to an user,please change other role to that user before delete.</p> */}
            </Modal.Header>
            <Modal.Body>
            <select id="dropdown" className="form-select" value={newRoleId} onChange={handleSelectChange}>
                            <option value={0}>Select</option>
                            {
                                roles.filter(role => role.id != data).map((option, index) => (
                                    <option key={index} value={option.id}>{option.name}</option>
                                ))}
                        </select>
              
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    No
                </Button>
                <Button variant="primary" onClick={saveRole}>
                    Yes
                </Button>
            </Modal.Footer> 
            <div>
                    {
                        isloader.isfailed ? (
                            <AFailed message={isloader.message} />
                        ) : (null)
                    }
                </div>
                <div>
                    {
                        isloader.isSuccess ? (
                            <ASuccess message={isloader.message} />
                        ) : (null)
                    }
                </div>

            
        </Modal>
    );
};

export default CustomModal;
