export interface ruleResultModel {
    Brand: string,
    ID: number,
    ItemName: string,
    NonTradeItemflag: string,
    PartNumber: string,
    ProductMinorProductGroup: string,
    ProductPriceCodeMajor: string,
    ProductPriceCodeMinor: string,
    Product_Group_Major: string,
    SKU: string,
    itemAuthorizationClassification: string,
    itemLTLOnly: string,
    mfgNumber: string




}


export const createruleresultmodel = (): ruleResultModel => {
    return ({
        Brand: "",
        ID: 0,
        ItemName: "",
        NonTradeItemflag: "",
        PartNumber: "",
        ProductMinorProductGroup: "",
        ProductPriceCodeMajor: "",
        ProductPriceCodeMinor: "",
        Product_Group_Major: "",
        SKU: "",
        itemAuthorizationClassification: "",
        itemLTLOnly: "",
        mfgNumber: ""


    })
}