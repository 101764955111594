import React from "react";

interface IProps {
    message: string;
  }

const AFailed = (message: IProps) => {

    return (
        <div className="toaster-section-danger">
            <span className="check-btn"><i className="bi bi-x-circle-fill"></i></span>{message.message}<span className="close-btn"><i className="bi bi-x"></i></span>
        </div>
    )
}
export default AFailed;