import React, { useState, useEffect } from "react";
import InfoIcon from "@material-ui/icons/Info";
import Popover from "@material-ui/core/Popover";

const EditableRow = ({
    editFormData,
    allProducts,
    handleEditFormChange,
    handleCancelClick,
}) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const [currentEntity, setCurrentEntity] = useState(allProducts);
    const [isAllNull, setIsAllNull] = useState(false);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleInfoClick = (event: any, data: any) => {
        setAnchorEl(event.currentTarget);
        setCurrentEntity(data);
        const arePropertiesNull = !data.pgroup && !data.productgrp && !data.pmax && !data.pmin && !data.pmfg;
        setIsAllNull(arePropertiesNull);
        console.log("click....", data)

    }
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
        <tr>
            <td>
                {editFormData.id}
                {/*<input*/}
                {/*  type="text"*/}
                {/*  required="required"*/}
                {/*  placeholder="Enter a id..."*/}
                {/*  name="id"*/}
                {/*  value={editFormData.id}*/}
                {/*  onChange={handleEditFormChange}*/}
                {/*></input>*/}
            </td>
            <td>
                {editFormData.sku}
                {/*<input*/}
                {/*    type="text"*/}
                {/*    required="required"*/}
                {/*    placeholder="Enter an sku..."*/}
                {/*    name="sku"*/}
                {/*    value={editFormData.sku}*/}
                {/*    onChange={handleEditFormChange}*/}
                {/*></input>*/}
            </td>
            <td className="col-td-033">
                {editFormData.uom}
            </td>
            {/* <td>
                <input
                    type="text"
                    required="required"
                    placeholder="Enter a UOM..."
                    name="uom"
                    className="form-control px-1"
                    value={editFormData.uom}
                    onChange={handleEditFormChange}
                ></input>
            </td> */}
            <td>
                {editFormData.asin}
                {/*<input*/}
                {/*    type="text"*/}
                {/*    required="required"*/}
                {/*    placeholder="Enter an ASIN..."*/}
                {/*    name="asin"*/}
                {/*    value={editFormData.asin}*/}
                {/*    onChange={handleEditFormChange}*/}
                {/*></input>*/}
            </td>
            <td>
                <input
                    type="text"
                    required="required"
                    placeholder="Enter an ToUOM..."
                    name="touom"
                    className="form-control px-1"
                    value={editFormData.touom}
                    onChange={handleEditFormChange}
                ></input>
            </td>
            <td>
                {editFormData.displayDescription}
                {/*<input*/}
                {/*    type="text"*/}
                {/*    required="required"*/}
                {/*    placeholder="Enter an description..."*/}
                {/*    name="description"*/}
                {/*    value={editFormData.displayDescription}*/}
                {/*    onChange={handleEditFormChange}*/}
                {/*></input>*/}
            </td>

            <td>
                {editFormData.msku}
                {/*<input*/}
                {/*    type="text"*/}
                {/*    required="required"*/}
                {/*    placeholder="Enter an Mfg..."*/}
                {/*    name="mfg"*/}
                {/*    value={editFormData.msku}*/}
                {/*    onChange={handleEditFormChange}*/}
                {/*></input>*/}
            </td>
            <td>
                {/*<input*/}
                {/*    type="text"*/}
                {/*    required="required"*/}
                {/*    placeholder="Enter an Supplier..."*/}
                {/*    name="brand"*/}
                {/*    value={editFormData.brand}*/}
                {/*    onChange={handleEditFormChange}*/}
                {/*></input>*/}
                {editFormData.brand}
            </td>
            {/*<td>*/}
            {/*    {editFormData.pgroup}*/}
            {/*    */}{/*<input*/}
            {/*    */}{/*    type="text"*/}
            {/*    */}{/*    required="required"*/}
            {/*    */}{/*    placeholder="Enter an pgroup..."*/}
            {/*    */}{/*    name="pgroup"*/}
            {/*    */}{/*    value={editFormData.pgroup}*/}
            {/*    */}{/*    onChange={handleEditFormChange}*/}
            {/*    */}{/*></input>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*    {editFormData.productgrp}*/}
            {/*    */}{/*<input*/}
            {/*    */}{/*    type="text"*/}
            {/*    */}{/*    required="required"*/}
            {/*    */}{/*    placeholder="Enter an pgroup..."*/}
            {/*    */}{/*    name="pgroup"*/}
            {/*    */}{/*    value={editFormData.pgroup}*/}
            {/*    */}{/*    onChange={handleEditFormChange}*/}
            {/*    */}{/*></input>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*    {editFormData.pmax}*/}
            {/*    */}{/*<input*/}
            {/*    */}{/*    type="text"*/}
            {/*    */}{/*    required="required"*/}
            {/*    */}{/*    placeholder="Enter an price max..."*/}
            {/*    */}{/*    name="pmax"*/}
            {/*    */}{/*    value={editFormData.pmax}*/}
            {/*    */}{/*    onChange={handleEditFormChange}*/}
            {/*    */}{/*></input>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*    {editFormData.pmin}*/}
            {/*    */}{/*<input*/}
            {/*    */}{/*    type="text"*/}
            {/*    */}{/*    required="required"*/}
            {/*    */}{/*    placeholder="Enter an price min..."*/}
            {/*    */}{/*    name="pmin"*/}
            {/*    */}{/*    value={editFormData.pmin}*/}
            {/*    */}{/*    onChange={handleEditFormChange}*/}
            {/*    */}{/*></input>*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*    {editFormData.pmfg}*/}
            {/*    */}{/*<input*/}
            {/*    */}{/*    type="text"*/}
            {/*    */}{/*    required="required"*/}
            {/*    */}{/*    placeholder="Enter an MFG..."*/}
            {/*    */}{/*    name="pmfg"*/}
            {/*    */}{/*    value={editFormData.pmfg}*/}
            {/*    */}{/*    onChange={handleEditFormChange}*/}
            {/*    */}{/*></input>*/}
            {/*</td>*/}
            <td className="col-td-10">
                <div
                    onClick={(e) => handleInfoClick(e, item)}
                    className="attribute_icon"
                >
                    <InfoIcon className="Icon_color_global" />
                </div>
            </td>
            <td>
                <div className="btn-style">
                    <button type="submit" className="btn btn-sm btn-primary mb-1"><i className="bi bi-journal-check"></i></button>
                    <button type="button" className="btn btn-sm btn-primary" onClick={handleCancelClick}>
                        <i className="bi bi-x"></i>
                    </button>
                </div>
            </td>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                 {isAllNull != true ? (
                <div className="order_attribute_container">
                    <div>

                        {currentEntity != null && currentEntity.pgroup != null&&(
                            <div>
                                <span className="attribute_label">Product Group Major:</span>
                                <span className="attribute_Item">{currentEntity.pgroup}</span>
                            </div>

                        )
                        }


                        {currentEntity != null && currentEntity.productgrp != null && (
                            <div>
                                <span className="attribute_label">Product Group Minor:</span>
                                <span className="attribute_Item">{currentEntity.productgrp}</span>
                            </div>

                        )}
                        {currentEntity != null && currentEntity.pmax != null && (
                            <div>
                                <span className="attribute_label">Price Group Major:</span>
                                <span className="attribute_Item">{currentEntity.pmax}</span>
                            </div>

                        )}
                        {currentEntity != null && currentEntity.pmin != null && (
                            <div>
                                <span className="attribute_label">Price Group Minor:</span>
                                <span className="attribute_Item">{currentEntity.pmin}</span>
                            </div>

                        )}
                        {currentEntity != null && currentEntity.pmfg != null && (
                            <div>
                                <span className="attribute_label">Supplier:</span>
                                <span className="attribute_Item">{currentEntity.pmfg}</span>
                            </div>

                        )}
                    </div>
                </div>): (
                    <div className="order_attribute_container">
                        <div>
                            <span className="attribute_Item">No Records Found</span>
                        </div>
                    </div>

                )}

            </Popover>

        </tr>
    );
};

export default EditableRow;
