import React, { useState, useEffect } from "react";
import axios from 'axios';
import { CreateOrderPayload } from "../../dataservices/model/orderRules";
import { GetCreateOrderPayloads } from "../../dataservices/action/marketplace/marketplace";
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
import Loader from "../loader";
import ASuccess from "../alretbox/success";
import AFailed from "../alretbox/failed";
const CreateOrderComponent = () => {
    var intorderCreatePayload: CreateOrderPayload[] = new Array<CreateOrderPayload>();
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const [isLoader, setisLoader] = useState(false);
    const [rulesPayload, setRulesPayload] = useState(intorderCreatePayload);
    const [selectedPayload, setSelectedPayload] = useState("");
    const [token, setToken] = useState("");
    const [responseText, setResponseText] = useState("");
    useEffect(() => {
        GetCreateOrderPayloads(function (result: any) {
            console.log("GetCreateOrderPayloads....", result);
            setRulesPayload(result);
        })


    }, []);
    useEffect(() => {
        const getToken = async (cliId: string, cliSec: string) => {
            try {
                const response = await axios.post(`https://dev-apiservices.aquaquickship.com/oauth/authorize/gettoken`, {

                    client_id: cliId,
                    client_secret: cliSec

                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                //console.log("token....", response.data.access_token);
                setToken(response.data.access_token);
            }
            catch (error) {
                console.error('Failed to fetch token:', error);
                throw error;
            }
        }
        getToken("708508bd-4ed7-4b98-a87a-de74c3d76d30", "lWa8Q~DKDEtwiUwRsyFhGW5Qed~Z7yWLphu-vaYs");

    }, []);

    const generateCustomerOrderId = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let id = '';
        for (let i = 0; i < 9; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    };
    const handleSelectChange = (e: any) => {
        const selectedRule = rulesPayload.find(rule => rule.id === parseInt(e.target.value));
        setResponseText("");
        if (selectedRule) {
            // Replace "customerOrderId" in payload with the generated ID
            const newCustomerOrderId = generateCustomerOrderId();
            const updatedPayload = selectedRule.payload.replace('"customerOrderId": ""', `"customerOrderId": "${newCustomerOrderId}"`);
            setSelectedPayload(updatedPayload);
        } else {
            setSelectedPayload("");
        }
    };

    const createOrderClick = () => {
        //console.log("selectedPayload....",selectedPayload)
        setResponseText("");
        const placeOrderAPI = async () => {
            setisLoader(true);
            let load: Loadermessage = {
                message: "",
                isLoader: true,
                isSuccess: false,
                isfailed: false
            }
            Setisloader(load);
            try {

                const response = await axios.post('https://dev-apiservices.aquaquickship.com/order/createSalesOrder', selectedPayload, {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json',

                    },
                });
                console.log("orderRespone....", response);

                if (response.status == 200) {
                    const formattedResponse = JSON.stringify(response.data);
                    setResponseText(formattedResponse);
                    let load: Loadermessage = {
                        message: "",
                        isLoader: false,
                        isSuccess: true,
                        isfailed: false
                    }
                    Setisloader(load);
                    setisLoader(false);
                }
                setisLoader(false);

            }
            catch (error) {
                let errorMessage = "An unexpected error occurred";

                if (error && (error as any).response && (error as any).response.data) {
                    errorMessage = (error as any).response.data.errorMessage;
                }

                setResponseText(errorMessage);
                console.log("Error while placing order:", error);
                let load: Loadermessage = {
                    message: "",
                    isLoader: false,
                    isSuccess: true,
                    isfailed: false
                }
                Setisloader(load);
                setisLoader(false);
            }
        }

        placeOrderAPI();

    }
    return (
        <div>
            <div className="row">
                {

                    isloader.isLoader ? (<Loader isActive={isloader} />) : (null)
                }
                <div className="col-md-12">
                    <div className="mpc-menu">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Create Order
                                </li>
                            </ol>
                        </nav>
                        <div className="heading-section">
                            <h1>Create Order</h1>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-6">
                    <div className="row g-2 mb-3 align-items-center">
                        <div className="col-4">
                            <label className="form-label">
                                Select Order Rule
                            </label>
                        </div>
                        <div className="col-8">
                            <div className="form-check">
                                <select className="form-select" onChange={handleSelectChange}>
                                    <option value="" >-- Select a Rule --</option>
                                    {rulesPayload.map(rule => (
                                        <option key={rule.id} value={rule.id}>
                                            {rule.ruleName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="mb-3">
                                <textarea className="form-control" value={selectedPayload} onChange={(e)=>setSelectedPayload(e.target.value)} rows={20}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <button type="button" className="btn btn-wh-2 btn-primary" onClick={createOrderClick}>Create Order</button>
                        </div>
                    </div>

                </div>
                <div className="col-6">
                    <span style={{ color: 'red' }}>{responseText}</span>
                </div>
            </div>


        </div>
    )

}
export default CreateOrderComponent;