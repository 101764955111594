import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import SideBar from "../sidebar";
import { allwebsites } from "../../dataservices/model/allwebsites";
import { GetAllWebsitesService } from "../../dataservices/action/dashboard/allwebsites";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
import Loader from "../loader";
import { DeleteWebsiteService } from "../../dataservices/action/dashboard/allwebsites";
import ASuccess from "../alretbox/success";
import AFailed from "../alretbox/failed";
import Globals from "../../dataservices/global";
import { GetEditPermission } from "../../dataservices/action/account/register";
import { moduleinfo } from "../../dataservices/model/user";
import { Getrolepermissionbyuserid } from "../../dataservices/action/marketplace/marketplace";
const Dashboard = () => {

    var intallwebsites: allwebsites[] = new Array<allwebsites>();
    const [allwebsites, SetAllWebsites] = useState(intallwebsites)
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const history = useHistory();
    const [magentoConnect, setmagentoConnect] = useState(false);
    const [amazonMp, setamazonMp] = useState(false);
    var vperList: moduleinfo[] = new Array<moduleinfo>();
    const [VPList, setVPList] = useState(vperList);
    const [isLoader, setisLoader] = useState(false);
    //Edit
    const [ECategoryAttributes, setVCategoryAttributes] = useState(false);
    const [EAPIsettings, setVAPIsettings] = useState(false);
    const [EMagentoSync, setVMagentoSync] = useState(false);
    const [EInriverSync, setVInriverSync] = useState(false);
    const [EProducts, setVProducts] = useState(false);
    const [EInventoryRules, setVInventoryRules] = useState(false);
    const [Elogs, setVlogs] = useState(false);
    const [ESettings, setVSettings] = useState(false);
    const [isDisabled, setisDisabled] = useState("disabled");

    const [ADashboard, setADashboard] = useState(false);
    const [EDashboard, setEDashboard] = useState(false);
    const [DDashboard, setDDashboard] = useState(false);



    useEffect(() => {
        GetAllWebsitesService(function (result: any) {
            SetAllWebsites(result)
        })

    }, [])
    useEffect(() => {
        setisLoader(true);
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 2:                                
                                if (result[i].canadd == "1") {
                                    setADashboard(true);
                                }
                                if (result[i].canedit == "1") {
                                    setEDashboard(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDDashboard(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])
    const backUserDB = () => {
        setmagentoConnect(false);
        setamazonMp(false);
        Globals.IsAmazon = false;
        Globals.IsMagento = false;
        history.push('/userdashboard');
    }

    const AddWebsite = () => {
        history.push('/addwebsite');
    }
    const DeleteAlert = (chennalid: number, webSiteId: number, websiteName: string) => {
        confirmAlert({
            title: 'Delete Website',
            message: 'Are you sure you want to delete the website "' + websiteName + '"?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteWebsite(chennalid, webSiteId)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const DeleteWebsite = (channelId: number, webSiteId: number) => {
        // debugger;
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        DeleteWebsiteService(channelId, webSiteId, function (res: any) {
            // debugger;
            if (res) {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "Website deleted successfully"
                Setisloader(result);
                history.go(0);
            }
        })

    }
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <SideBar />
                </div>
                {

                    isloader.isLoader ? (<Loader isActive={isloader} />) : (null)
                }
                <div className="col-md-9">
                    <div className="heading-section">
                        <h1>Dashboard</h1>
                        <div>
                            <input className="btn btn-primary" type="button" disabled={ADashboard != true ? true : false} onClick={AddWebsite} value="+ Add Website" />
                            <input className="btn btn-primary ms-2" type="button" onClick={backUserDB} value="Back" />
                        </div>
                    </div>
                    <div className="row">
                        {
                            allwebsites != null ? (
                                allwebsites.map((item, index) => (
                                    <div className="col-sm-6 col-md-6">
                                        <div className="box-results" key={index}>
                                            <div className="box-results-row">
                                                <div className="box-results-col txt-name">
                                                    Channel Name
                                                </div>
                                                <div className="box-results-col">
                                                    {item.channelsName}
                                                </div>
                                                <div className="box-results-col txt-red">
                                                    <button className="btn btn-sm btn-outline-danger" disabled={DDashboard != true ? true : false} data-toggle="tooltip" data-placement="top" title="Delete Channel" onClick={(e) => DeleteAlert(item.channelsId, item.websiteId, item.websiteName)} ><i className="bi bi-trash-fill"></i></button>
                                                </div>
                                            </div>
                                            <div className="box-results-row">
                                                <div className="box-results-col txt-name">
                                                    Website Name
                                                </div>
                                                <div className="box-results-col">
                                                    {item.websiteName}
                                                </div>
                                                <div className="box-results-col">

                                                </div>
                                            </div>
                                            <div className="box-results-row">
                                                <div className="box-results-col txt-name">
                                                    Store Name
                                                </div>
                                                <div className="box-results-col">
                                                    {item.storeName}
                                                </div>
                                                <div className="box-results-col" >

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ))
                            ) : (null)
                        }
                    </div>

                </div>
                <div >
                    {
                        isloader.isfailed ? (
                            <AFailed message={isloader.message} />
                        ) : (null)
                    }
                </div>
                <div >
                    {
                        isloader.isSuccess ? (
                            <ASuccess message={isloader.message} />
                        ) : (null)
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
export default Dashboard;