export interface IPagination extends PaginationValues
{
    // CurrentPage: number;
    TotalItemCount: number;
    PageSize: number;
   
    LastPage:()=>number;
//     HasNextPage:()=>boolean;
//     NextPageUrl:()=>string;
//     HasPrevPage:()=>boolean;
//     PrevPageUrl:()=>string;
//     PageUrlFor:()=>string;
//     PageUrl:()=>string;
//     FirstItemIndex:()=>number;
//     LastItemIndex:()=>number; 
}
export class PaginationValues
{
    public CurrentPage:number =50;
    public TotalItemCount:number =50;
    public PageSize: number =50;
    public PageSlide: number =0;
    // public IsResult: boolean;
    // public IsResultBottom: boolean;
    public IsGrid :boolean=true;
    public IsSort: boolean=true;
    // public IsSortBottom: boolean;
    // public IsPage: boolean;
    // public IsPageBottom: boolean;
    // public IsCompare: boolean;
    // public IsCompareBottom: boolean;
    // public Sort: string;
   
        public Pages()
        {
            let pageCount:number = this.LastPage();
            let pageFrom = Math.max(1, this.CurrentPage - this.PageSlide);
            let pageTo = Math.min(pageCount , this.CurrentPage + this.PageSlide);
          
          
            // pageFrom = Math.max(1, Math.min(pageTo - 2 * this.PageSlide, pageFrom));
            // pageTo = Math.min(pageCount, Math.max(pageFrom + 2 * this.PageSlide, pageTo));
            let ary:number[]=new Array();
            
            
            let i=pageFrom;                     
            for(i;i <= pageTo;i++ )
            {
            ary.push(i);
            }
            return ary;
        }

        public LastPage():number
        {
            return Math.floor((this.TotalItemCount -1 ) / this.PageSize) + 1;
        }

        public HasNextPage()
        {
            return this.CurrentPage < this.LastPage();
        }

        // public NextPageUrl()
        // {
        //   return this.HasNextPage ? this.PageUrlFor(this.CurrentPage + 1) : null;
        // }

        public HasPrevPage()
        {
          return this.CurrentPage > 1;
        }

        public PrevPageUrl()
        {
           return this.HasPrevPage() ? this.PageUrlFor(this.CurrentPage - 1) : null;
        }

        public PageUrlFor(page:number)
        {
             return this.PageUrl= page.toString();
          //return this.PageUrl.replace("!0", page.toString());
        }

        public PageUrl :string ="";

        public FirstItemIndex():number
        {
          return this.PageSize * (this.CurrentPage - 1) + 1;
        }

        public LastItemIndex()
        {
          return Math.min(this.FirstItemIndex() + this.PageSize - 1, this.TotalItemCount);
        }
    // public HasNextPage(){
        
    // }
    // public NextPageUrl(){
        
    // }
    // public HasPrevPage:()=>boolean;
    // public PrevPageUrl:()=>string;
    // public PageUrlFor:()=>string;
    // public PageUrl:()=>string;
    // public FirstItemIndex:()=>number;
    // public LastItemIndex:()=>number; 

}