import axios from "axios"

export const GetChannellistService = async (callback: any) => {
    await axios.get("api/Website/GetAllChannels").then(result => {
         callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}
export const GetRunningJob = async (callback: any) => {
    await axios.get("api/Job/GetRunningJob").then(result => {
         callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}

export const RefreshChannels = async (callback: any) => {
    await axios.get("api/Website/RefreshChannels").then(result => {
         callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}

export const SyncByChannelIdservice = async (id: number,autosync :boolean,callback: any) => {
    await axios.get(`api/Website/SyncByChannelId?channelId=${id}&&autoSync=${autosync}`).then(result => {
         callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}
export const SyncAllInriverChannelservice = async (autosync :boolean,callback: any) => {
    await axios.get(`api/Website/SyncAllInriverChannelservice?autoSync=${autosync}`).then(result => {
         callback(result.data)
    }).catch(err => {
        console.log(err)
    })
}

