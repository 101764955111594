import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import ASuccess from "../alretbox/success";
import AFailed from "../alretbox/failed";
import UserSideBar from "../usersidebar";
import { role, createinitialRole ,createinitialpermission} from "../../dataservices/model/role";
import { GetRoleLoad, DeleteUserRule, Getrolepermissionbyuserid,CheckIsRoleAssigned } from "../../dataservices/action/users/getUserApiSettins";
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";

import { PaginationValues } from "../pagenation/pageflow";
import Loader from "../loader";
import { confirmAlert } from 'react-confirm-alert';
import CustomModal  from "./assignRole";


const roleList = () => {
    var introles: role[] = new Array<role>()
    const history = useHistory();
    const [roles, setRoles] = useState(introles);
    const [isLoader, SetisLoader] = useState(false);
    const [activePage, setActivePage] = useState(0);
    const [TotalCount, setTotalCount] = useState(0);
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [PageSizeList, setPageSizeList] = useState([50, 100, 200]);
    const [SearchValue, setSearchValue] = useState("");
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const [ARole, setARole] = useState(false);
    const [ERole, setERole] = useState(false);
    const [DRole, setDRole] = useState(false);
    //const [CSIMsync, setCSIMsync] = useState(false);
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 24:
                                if (result[i].canadd == "1") {
                                    setARole(true);
                                }
                                if (result[i].canedit == "1") {
                                    setERole(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDRole(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])
    const [showModal, setShowModal] = useState(false);
    const [selectedRoleId,setSelectedRoleId]=useState(0)


    useEffect(() => {
        SetisLoader(true);

        GetRoleLoad(Page, PageSize, function (result: role[]) {
            if (result != null) {
                setRoles(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page);
                SetisLoader(false);
            }
           // console.log("roles....",result)
        })
    }, [])

    const Pagenation = () => {
        let Pagination = new PaginationValues();
        Pagination.TotalItemCount = TotalCount;
        Pagination.CurrentPage = Page;
        Pagination.PageSlide = 2;
        Pagination.IsSort = true;
        Pagination.PageSize = PageSize;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page - 1) * PageSize) + 1}    - {((((Page - 1) * PageSize) + 1) + PageSize) > TotalCount ? TotalCount : ((((Page - 1) * PageSize)) + PageSize)} of {TotalCount} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">

                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                                {
                                    Pagination.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination.Pages().map((x, i) =>
                                        x == Page ?
                                            (
                                                <li className={`page-item  ${activePage == x ? 'active' : null}`} key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className="page-link" onClick={() => SetPage(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
    const backUserDB = () => {

        history.push('/userdashboard');
    }
    const goToAddRole = () => {
        history.push('/addRole');
    }

    const SetPageSize = (size: number) => {
        setPageSize(size);
        RefreshQuote();
    }

    const SetPage = (page: number) => {
        setPage(page);
        setActivePage(page)
        RefreshQuote();
    }

    const RefreshQuote = () => {
        let PoNumber: string = "";
        let OrderNumber: number = 0;
        let InvoiceNumber: string = "";
        if (SearchValue != "") {
        }
        GetRoleLoad(Page, PageSize, function (result: role[]) {
            if (result != null) {
                setRoles(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
            }
        })
    }
    const EditRoleClick = (id: number) => {
        //const data = { name: "John", age: 30 };
        history.push({ pathname: "/editRole", state: id });
    }
//     const handleShowModal = (id:number) => {
// //console.log("RoleID....",id)
//         setSelectedRoleId(id);
//         setShowModal(true);
       
//       };
const handleShowModal = (id: number) => {
    //console.log("RoleID....",id)
    CheckIsRoleAssigned(id, function (result: any) {
        if (result) {
            setSelectedRoleId(id);
            setShowModal(true);

        } else {
            console.log("assigned,,,,")
            
                confirmAlert({
                    title: 'Delete Role',
                    message: 'Are you sure you want to delete the Role?',
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: () => Delete()
                        },
                        {
                            label: 'No',
                            onClick: () => { }
                        }
                    ]
                });
            const Delete=()=>{
                const roles: role = {
                    id:0,
                    name:"",
                    index: 0,
                    recordPerPage: 50,
                    totalRecord: 500,
                    applypermission: createinitialpermission(),
                    createdOn:new Date(),
                    existingId:id,
                }
                DeleteUserRule(roles, function (result: any) {
                    if (result.isSuccess) {
                        let result = CreateinitialLoadermessage();
                        result.isSuccess = true;
                        result.message = "Role deleted successfully"
                        Setisloader(result);
                        // let updateduser = users.filter((user: { id: number; }) => user.id != id);
                        //setUsers(updateduser);
                        history.go(0);
                    }
                })

            }
            
           
        }

    });
    //setSelectedRoleId(id);
    // setShowModal(true);

};
    
      const handleCloseModal = () => {
        setShowModal(false);
      };
    const DeleteAlert = (id: number, name: string) => {

        // confirmAlert({
        //     title: 'Delete Role',
        //     message: 'Are you sure you want to delete the role ' + name + '?',
        //     buttons: [
        //         {
        //             label: 'Yes',
        //             //onClick: () => Delete(id)
        //             onClick() {
        //                 history.push({pathname:'/assignRole', state: id});
        //             },
        //         },
        //         {
        //             label: 'No',
        //             onClick: () => { }
        //         }
        //     ]
        // });
    }
    const Delete = (id: number) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        DeleteUserRule(id, function (result: any) {
            if (result.isSuccess) {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "Role deleted successfully"
                Setisloader(result);
                // let updateduser = users.filter((user: { id: number; }) => user.id != id);
                //setUsers(updateduser);
                history.go(0);
            }
        })



    }


    return (
        <React.Fragment>
            <CustomModal show={showModal} onHide={handleCloseModal} data={selectedRoleId}/>
            <div className="row">
                <div className="col-md-3">
                    <UserSideBar />
                </div>
                {
                    isLoader ? (<Loader isActive={isLoader} />) : (null)
                }
                <div className="col-sm-9 col-md-9">

                    <div className="heading-section">
                        <h1>Manage Roles</h1>
                        <div>
                            <button type="button" className="btn btn-primary" disabled={ARole != true ? true : false} onClick={goToAddRole}>Add Role</button>
                            <button type="button" className="btn btn-primary ms-2" onClick={backUserDB}>Back</button>
                        </div>
                    </div>
                    <table className="table table-bordered">
                        <thead className="table-light">
                            <tr>
                                <th scope="col">
                                    Name
                                </th>
                                <th scope="col">
                                   Created Date
                                </th>
                                <th scope="col">
                                    Edit
                                </th>
                                <th scope="col">
                                    Delete
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {

                                roles != null ? (
                                    roles.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                {item.name}
                                            </td>
                                            <td>
                                                {item.createdOn}
                                            </td>
                                            <td>
                                                <button className="btn btn-sm btn-primary" disabled={ERole != true ? true : false} onClick={(e) => EditRoleClick(item.id)}><i className="bi bi-pencil-square"></i></button>
                                            </td>
                                            <td>
                                                <button className="btn btn-sm btn-danger" disabled={DRole != true ? true : false} data-toggle="tooltip" data-placement="top" title="Delete Role" onClick={()=>handleShowModal(item.id)}><i className="bi bi-trash-fill"></i></button>
                                            </td>                                            
                                        </tr>
                                    ))
                                ) : (null)
                            }
                        </tbody>
                    </table>
                    <Pagenation />
                </div>
                <div>
                    {
                        isloader.isfailed ? (
                            <AFailed message={isloader.message} />
                        ) : (null)
                    }
                </div>
                <div>
                    {
                        isloader.isSuccess ? (
                            <ASuccess message={isloader.message} />
                        ) : (null)
                    }
                </div>
                
            </div>
        </React.Fragment>
    )
}
export default roleList;