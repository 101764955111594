import { applypermission } from "./filters"
export interface role {

    id: number,
    name: string,
    index: number,
    recordPerPage: number,
    totalRecord: number,
    applypermission: applypermission,
    createdOn:Date,
    existingId:number

}
export const createinitialpermission = (): applypermission => {
    return ({
        permissionid: 0,
        name: "",
        canview: "",
        canadd: "",
        canedit: "",
        candelete: "",
        cansync: ""

    })
}
export const createinitialRole = (): role => {
    return ({
        id: 0,
        name: "",
        index: 0,
        recordPerPage: 0,
        totalRecord: 0,
        applypermission: createinitialpermission(),
        createdOn:new Date(),
        existingId:0
    })
}

export interface roleSave {
    id: number,
    Name: string,
    IsActive: boolean,
    

}
export const createinitialRoleSave = (): roleSave => {
    return ({
        id: 0,
        Name: "",
        IsActive: false
    })
}
