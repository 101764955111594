import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import Multiselect from "multiselect-react-dropdown";
import { channellist } from "../../dataservices/model/channellist";
import { orderRulesObj, orderExistingRule } from "../../dataservices/model/orderRules";
import { GetAllMarketplaces } from "../../dataservices/action/marketplace/marketplace";
import { GetOrderRuleModelType, GetOrderRuleAttributes, GetOrderRuleOperators, GetExistingOrderRules, SaveOrderRules, GetOrderRuleAttributeRelationName, DeleteOrderRule,UpdateIsEnabledInExistingOrderRules } from "../../dataservices/action/marketplace/marketplace";
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
import Loader from "../loader";
import ASuccess from "../alretbox/success";
import AFailed from "../alretbox/failed";
import { confirmAlert } from "react-confirm-alert";


interface Condition {
    type: string;
    attribute: string;
    condition: string;
    value: string;
    logic: string;
}
interface TypeOption {
    id: number;
    name: string;
    operator: string;
    label: string;
}

interface DropdownOptions {
    types: TypeOption[];
    attributes: TypeOption[];
    conditions: TypeOption[];
    values: TypeOption[];
}
interface DropdownOption {
    types: any[];
    attributes: any[];
    conditions: any[];
}
type ValidationError = {
    type: boolean;
    attribute: boolean;
    condition: boolean;
    value: boolean;
};
const OrderRulesComponent = () => {
    const history = useHistory();
    const [activeprodclass, setactiveprodclass] = useState(true);
    const [activeEprdclass, setactiveEprdclass] = useState(false);
    const [ruleName, setRuleName] = useState("");
    const [ruleType, setRuleType] = useState("");
    const [conditions, setConditions] = useState<Condition[]>([]);
    const [startDate, setStartDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");
    const [code, setCode] = useState(["ALL", "ANY"]);
    const [selectedCode, setSelectedCode] = useState("ALL");
    const [isDecimal, setIsDecimal] = useState(["FALSE", "TRUE"]);
    const [selectedisdecimal, setSelectedIsDecimal] = useState("FALSE");
    const [customerType, setCustomerType] = useState(["ALL", "CustomerList"]);
    const [customerChanged, setCustomerChanged] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [isCustomerList, setIsCustomerList] = useState(false);
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState("");
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [isAllCustomer, setIsAllCustomer] = useState(false);
    var intMPlist: channellist[] = new Array<channellist>();
    const [mplist, Setmplist] = useState(intMPlist);
    const [modelTypeId, setModelTypeId] = useState<any>(null);
    const [attributeId, setAttributeId] = useState(0);
    const [showAddCondition, setShowAddCondition] = useState(true);
    var intallruletype: orderExistingRule[] = new Array<orderExistingRule>();
    const [existingRule, setExistingRule] = useState(intallruletype);
    const [dropdownOptions, setDropdownOptions] = useState<DropdownOptions>({
        types: [],
        attributes: [],
        conditions: [],
        values: []
    });

    const [newDropdownOptions, setNewDropdownOptions] = useState<{ types: any[]; attributes: any[]; conditions: any[]; valueOptions: any[] }[]>([]);
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]); //Tracks the validation errors for each set of dropdowns.
    const [isLoader, setisLoader] = useState(false);
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const [types, setTypes] = useState<[]>([]);
    var activeclassname = "nav-link active";
    var inactiveclassname = "nav-link";
    const backUserDB = () => {
        history.push('/userdashboard');
    }
    useEffect(() => {
        GetAllMarketplaces(function (result: any) {
            Setmplist(result);
            console.log("mplist....", result)
        })

    }, []);
    useEffect(() => {
        GetOrderRuleModelType(function (result: any) {
            console.log("orderModelType....", result)
            setDropdownOptions((prevOptions) => ({
                ...prevOptions,
                types: result
            }));
            //setNewDropdownOptions([{ types: result, attributes: [], conditions: [], valueOptions: [] }]);
            setTypes(result);
        })

    }, []);

    useEffect(() => {
        GetExistingOrderRules(function (result: any) {
            setExistingRule(result);
            console.log("existingRule....", existingRule)
        })



    }, [])
    const RefreshOrderRules = () => {
        GetExistingOrderRules(function (result: any) {
            setExistingRule(result);
            console.log("existingRule....", existingRule)
        })


    }

    const onChangeRuleType = (e: any) => {
        console.log("e.target......", e.target.value)
        setRuleType(e.target.value);

    }
    // console.log("dropdownOptions....1", newDropdownOptions.types)

    // Handle changes in dropdown selection for a specific set
    const handleDropdownChange = (index: number, key: string, value: any) => {
        console.log("index.....", index)
        console.log("Calling handleDropdownChange.....", newDropdownOptions);
        // if (key === "condition") {
        //     console.log("dropdownOptions....4444", dropdownOptions.conditions)
        //     const result = dropdownOptions.conditions.find(item => item.name === value);
        //     const operatorValue: any = result ? result.operator : null
        //     console.log("operatorValue....", operatorValue)

        //     const updatedConditions = conditions.map((condition, i) =>
        //         i === index ? { ...condition, [key]: operatorValue } : condition
        //     );
        //     setConditions(updatedConditions);

        // } else {
        const updatedConditions = conditions.map((condition, i) =>
            i === index ? { ...condition, [key]: value } : condition
        );
        setConditions(updatedConditions);
        //}

        // Reset validation error for the field if it's now valid
        const updatedValidationErrors = validationErrors.map((error, i) =>
            i === index ? { ...error, [key]: !value } : error
        );
        setValidationErrors(updatedValidationErrors);




        if (key === "type") {
            //Setisloader({ message: "Loading data...", isLoader: true, isSuccess: false, isfailed: false });

            //console.log("dropdownOptions....22222", dropdownOptions.types)
            //const result = newDropdownOptions[0].types.find(item => item.name === value);
            const result = newDropdownOptions[index].types.find(item => item.name === value);
            const idValue: any = result ? result.id : null
            console.log("idValue....2222", idValue)
            setModelTypeId(idValue);


            GetOrderRuleAttributes(idValue, function (result: any) {
                // console.log("orderAttributes....",result)
                // setDropdownOptions((prevOptions) => ({
                //     ...prevOptions,
                //     attributes: result
                // }));
                const updatedDropdownOptions = newDropdownOptions.map((options, i) =>
                    i === index ? { ...options, attributes: result } : options
                );
                setNewDropdownOptions(updatedDropdownOptions);
                //Setisloader({ message: "Loading data...", isLoader: false, isSuccess: false, isfailed: false });

            })
            // setAttributeId(value)

        }
        if (key === "attribute") {
            Setisloader({ message: "Loading data...", isLoader: true, isSuccess: false, isfailed: false });
            //const result = newDropdownOptions[0].attributes.find(item => item.name === value);
            const result = newDropdownOptions[index].attributes.find(item => item.name === value);
            const idValue: any = result ? result.id : null

            console.log("idValue....attribute", idValue)

            GetOrderRuleOperators(modelTypeId, idValue, function (result: any) {
                // const updatedDropdownOptions = newDropdownOptions.map((options, i) =>
                //     i === index ? { ...options, conditions: result } : options
                // );
                const getOperators = result;
                GetOrderRuleAttributeRelationName(idValue, function (result: any) {
                    const updatedDropdownOptions = newDropdownOptions;
                    updatedDropdownOptions[index].conditions = getOperators;
                    updatedDropdownOptions[index].valueOptions = result;
                    setNewDropdownOptions(updatedDropdownOptions);
                    Setisloader({ message: "Loading data...", isLoader: false, isSuccess: false, isfailed: false });
                })
            })






        }


    };
    const [firstClick, setFirstClick] = useState(false);
    // Add a new set of dropdowns with a textbox for "Value"
    const handleAddCondition = () => {
        if (firstClick == false) {
            setConditions([
                ...conditions,
                { type: "", attribute: "", condition: "", value: "", logic: "" }
            ]);
            setFirstClick(true)

        } else {
            setConditions([
                ...conditions,
                { type: "", attribute: "", condition: "", value: "", logic: "AND" }
            ]);

        }


        // Add a new set of options for the new set of dropdowns
        setNewDropdownOptions([...newDropdownOptions, { types: types, attributes: [], conditions: [], valueOptions: [] }]);
        setValidationErrors([...validationErrors, { type: false, attribute: false, condition: false, value: false }]); // Add new empty validation errors

        setShowAddCondition(false);
    };

    // Remove a specific dropdown row based on its index

    // Handle changes in dropdown selection or textbox input for a specific set
    const handleInputChange = (index: number, key: string, value: string) => {
        const updatedConditions = conditions.map((condition, i) =>
            i === index ? { ...condition, [key]: value } : condition
        );
        setConditions(updatedConditions); setValidationErrors([...validationErrors, { type: false, attribute: false, condition: false, value: false }]);





    };
    const handleRemoveCondition = (index: number) => {
        // setConditions(conditions.filter((_, i) => i !== index));
        // setValidationErrors(validationErrors.filter((_, i) => i !== index)); // Remove validation error for the removed row
        console.log("Before removal:-------", conditions);
        setConditions(prevConditions => {
            const newConditions = prevConditions.filter((_, i) => i !== index);
            console.log("After removal:-------", newConditions);
            return newConditions;

        });
        setValidationErrors(prevValidationErrors =>
            prevValidationErrors.filter((_, i) => i !== index)
        );
        // Remove dropdown options for the deleted row
        setNewDropdownOptions(newDropdownOptions.filter((_, i) => i !== index));

    };
    //console.log("conditions....", conditions);
    const handleStartDate = (e: any) => {

        setStartDate(e.target.value);
    };
    const handleEndtDate = (e: any) => {

        setEndDate(e.target.value);
    };
    const handleCustomerTypeChange = (e: any) => {
        setSelectedCustomer(e.target.value);
        setCustomerChanged(true);
        if (e.target.value == "CustomerList") {
            setIsCustomerList(true);
            setIsAllCustomer(false);
        } else if (e.target.value == "ALL") {
            setIsCustomerList(false);
            setSelectedCustomers([]);
            setIsAllCustomer(true);
        }
        // e.target.value == "CustomerList" ? setIsCustomerList(true) : setIsCustomerList(false);
        console.log("cust....", e.target.value)


    }
    const onSelectCustomers = (selectedList: any, selectedItem: any) => {
        console.log("Selected item:", selectedList);
        const customers = selectedList.map((item: any) => item.customerId);
        setSelectedCustomers(customers);
        console.log("Selected customerIds:", customers);

    }
    const onRemoveCustomers = (selectedList: any, selectedItem: any) => {
        console.log("removed item:", selectedList);
        const customers = selectedList.map((item: any) => item.customerId);
        setSelectedCustomers(customers);
        console.log("removed customerIds:", customers);

    }
    const handleChange = (e: any) => {
        if (e.target.id == 1) {
            setactiveprodclass(true);
            setactiveEprdclass(false);

        }
        if (e.target.id == 2) {
            setactiveprodclass(false);
            setactiveEprdclass(true);
            //RefreshProductRules();
        }

    }
    // Validate all dropdowns before submission
    const validateConditions = () => {
        const errors = conditions.map((condition) => ({
            type: !condition.type,
            attribute: !condition.attribute,
            condition: !condition.condition,
            value: !condition.value
        }));
        setValidationErrors(errors);

        // Check if there are any errors
        return errors.every((error) => Object.values(error).every((isValid) => !isValid));
    };

    const ClosePopup = () => {
        Setisloader(CreateinitialLoadermessage())
    }
    const applyRule = () => {

        setisLoader(true);
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);

        if (!ruleType) {

            Setisloader({ ...load, message: "Rule Type is required", isLoader: false, isfailed: true });
            return;
        }

        if (!ruleName) {

            Setisloader({ ...load, message: "Rule Name is required", isLoader: false, isfailed: true });
            return;
        }

        if (customerChanged == false) {

            Setisloader({ ...load, message: "Please Select Customer", isLoader: false, isfailed: true });
            return;
        }
        if (conditions.length == 0) {
            Setisloader({ ...load, message: "Please Add atleast one condition", isLoader: false, isfailed: true });
            return;

        }

        const ruleObj: orderRulesObj = {
            ruleType: ruleType,
            ruleName: ruleName,
            description: description,
            priority: priority,
            startDate: startDate,
            endDate: endDate,
            // code: selectedCode,
            // isDecimal: selectedisdecimal,
            isAllCustomer: isAllCustomer,
            customers: selectedCustomers,
            conditions: conditions

        }
        console.log("ruleData....", ruleObj);
        try {
            SaveOrderRules(ruleObj, function (result: any) {
                console.log("result", result);
                if (result.isSuccess) {

                    let load: Loadermessage = {
                        message: "Rule Activated Successfully",
                        isLoader: false,
                        isSuccess: true,
                        isfailed: false
                    }
                    Setisloader(load);
                    setisLoader(false);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);

                }

            })

        } catch (error) {
            console.error("Error while saving conditions:", error);
        }


        //console.log("mplist....", selectedCustomers);

        // Create the new array by mapping over customers
        // const orderRule = selectedCustomers.map((customer) => ({
        //     customerId: customer,
        //     ruleType: ruleObj.ruleType,
        //     description: ruleObj.description,
        //     priority: ruleObj.priority,
        //     startDate: ruleObj.startDate,
        //     endDate: ruleObj.endDate,
        //     code: ruleObj.code,
        //     isDecimal: ruleObj.isDecimal,
        //     isAllCustomer: ruleObj.isAllCustomer,
        //     conditions: ruleObj.conditions.map(condition => ({
        //         ...condition,
        //         ActionValues: [customer],  // Use customer name in ActionValues
        //         //value: customer === "Amazon" ? "true" : "1000" // Adjust the condition value based on the customer
        //     }))
        // }));
        // console.log("OrderRule...........", orderRule);

    }
    const DeleteAlert = (ruleId: number) => {
        confirmAlert({
            title: 'Delete Rule',
            message: 'Are you sure you want to delete this Rule?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteRule(ruleId)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });

    }
    const DeleteRule = (ruleId: number) => {

        //setExistingRule((prevItems) => prevItems.filter((item) => item.ruleId !== ruleId));
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        DeleteOrderRule(ruleId, function (result: any) {
            if (result.isSuccess) {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "Rule removed successfully"
                Setisloader(result);
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                RefreshOrderRules();
            }
        })
    }
const checkBoxChange=(id:number,status:boolean)=>{

    console.log("id....",id)
    console.log("status....",status)
    setExistingRule(prevItems =>
        prevItems.map(item => 
            item.ruleId === id 
                ? { ...item, isEnabled: status } 
                : item
        )
    );
UpdateIsEnabledInExistingOrderRules(id,status,function(result:any){
    console.log("resultIsEnabled....",result)

})

}


    return (
        <div className="orderrules-section">
            <div className="row">
                {

                    isloader.isLoader ? (<Loader isActive={isloader} />) : (null)
                }
                <div className="col-md-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Fulfillment Customers</a></li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Order Rules
                            </li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-section">
                                <h1>Order Rules</h1>
                                <div>
                                    <button type="button" className="btn btn-primary float-end" onClick={backUserDB}>Back</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <div className="row g-2 mb-3 align-items-center">
                                        <div className="col-5">
                                            <label className="form-label ff-i fs-16 fw-b">
                                                Choose Rule Type: <span className="required">*</span>
                                            </label>
                                        </div>
                                        <div className="col-7">
                                            <select className="form-select" value={ruleType} onChange={onChangeRuleType}>
                                                <option value="">Select</option>
                                                <option value="Hold">Hold</option>
                                                <option value="Split">Split</option>
                                                <option value="Route">Route</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="nav nav-tabs">
                                        <li className="nav-item">
                                            <button className={activeprodclass == true ? activeclassname : inactiveclassname} aria-current="page" id="1" onClick={handleChange}>New Rule</button>
                                        </li>
                                        <li className="nav-item">
                                            <button id="2" className={activeEprdclass == true ? activeclassname : inactiveclassname} onClick={handleChange}>Existing Rules</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={activeprodclass == true ? "" : "hidden"}>
                                <div className="tab-content">
                                    <h2 className="ff-i fw-m pb-2 primarycolor">Create New Rule</h2>

                                    <div className="row">
                                        {/* <div className="col-6">
                                            <div className="row g-2 mb-3 align-items-center">
                                                <div className="col-4">
                                                    <label className="form-label ff-i fs-16 fw-b">
                                                        Choose Rule Type: <span className="required">*</span>
                                                    </label>
                                                </div>
                                                <div className="col-8">
                                                    <select className="form-select" value={ruleType} onChange={(e) => setRuleType(e.target.value)}>
                                                        <option value="">Select</option>
                                                        <option value="Hold">Hold</option>
                                                        <option value="Split">Split</option>
                                                        <option value="Route">Route</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-6">
                                            <div className="row g-2 mb-3 align-items-center">
                                                <div className="col-2">
                                                    <label className="form-label ff-i fs-14 fw-b">
                                                        Rule Name: <span className="required">*</span>
                                                    </label>
                                                </div>
                                                <div className="col-10">
                                                    <input type="text" value={ruleName} onChange={(e) => setRuleName(e.target.value)} className="form-control" aria-describedby="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="row g-2 mb-3 align-items-center">
                                                <div className="col-2">
                                                    <label className="form-label ff-i fs-14 fw-b">
                                                        Description:
                                                    </label>
                                                </div>
                                                <div className="col-10">
                                                    <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} className="form-control" aria-describedby="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-3">
                                            <div className="row g-2 mb-3 align-items-center">
                                                <div className="col-3">
                                                    <label className="form-label ff-i fs-14 fw-b">
                                                        Customer<span className="required">*</span>:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <select className="form-select form-select-lg" value={selectedCustomer} onChange={handleCustomerTypeChange}>
                                                        <option selected value="">Select</option>
                                                        {
                                                            customerType.map((option, index) =>
                                                                <option key={index} value={option}>{option}</option>
                                                            )

                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="row g-2 mb-3 align-items-center">
                                                <div className="col-3">
                                                    <label className="form-label ff-i fs-14 fw-b">
                                                        Priority:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <div className="input-group">
                                                        <input type="text" value={priority} onChange={(e) => setPriority(e.target.value)} className="form-control br-n" aria-describedby="" />
                                                        <span className="input-group-text" id="basic-addon1" data-bs-toggle="tooltip" data-bs-placement="left" title="Higher value takes precedence"><i className="bi bi-info-circle-fill"></i></span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <div className="row g-2 mb-3 align-items-center">
                                                <div className="col-6">
                                                    <label className="form-label ff-i fs-14 fw-b">
                                                        Effective Start Date:
                                                    </label>
                                                </div>
                                                <div className="col-6">
                                                    <input type="date" value={startDate} className="form-control" aria-describedby="" onChange={handleStartDate} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="row g-2 mb-3 align-items-center">
                                                <div className="col-6">
                                                    <label className="form-label ff-i fs-14 fw-b">
                                                        Effective End Date:
                                                    </label>
                                                </div>
                                                <div className="col-6">
                                                    <input type="date" value={endDate} className="form-control" aria-describedby="" onChange={handleEndtDate} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        isCustomerList ? (
                                            <div className="row">
                                                <div className="col-3 mb-3">
                                                    <Multiselect
                                                        placeholder="Select Customers"
                                                        options={mplist}
                                                        displayValue="name"
                                                        onSelect={onSelectCustomers}
                                                        onRemove={onRemoveCustomers}
                                                    />
                                                </div>

                                            </div>

                                        ) : (null)
                                    }

                                    {
                                        conditions.map((condition: any, index: any) => (

                                            <div className="row" key={index}>
                                                <div className="col-11">
                                                    {index > 0 && (
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <div className="mb-3">
                                                                    <label className="form-label ff-i fs-14 fw-b">Logic</label>
                                                                    <select
                                                                        className="form-select form-select-lg"
                                                                        value={condition.logic || "AND"} // Default to "AND"
                                                                        onChange={(e) => handleDropdownChange(index, "logic", e.target.value)}
                                                                    >
                                                                        <option value="AND">AND</option>
                                                                        <option value="OR">OR</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="row">
                                                        <div className="col-3">
                                                            <div className="mb-3">
                                                                <label className="form-label ff-i fs-14 fw-b">Type</label>
                                                                <select className="form-select form-select-lg" value={condition.type}
                                                                    onChange={(e) => handleDropdownChange(index, "type", e.target.value)}
                                                                    style={{ borderColor: validationErrors[index].type ? "red" : "" }}
                                                                >

                                                                    <option value="" disabled>Select Type</option>
                                                                    {newDropdownOptions[index].types.map((type: any) => (
                                                                        <option key={type.id} value={type.name}>{type.name} </option>
                                                                    ))}
                                                                </select>
                                                                {validationErrors[index].type && (<span className="required">Type is required</span>)}

                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="mb-3">
                                                                <label className="form-label ff-i fs-14 fw-b">Attribute</label>
                                                                <select className="form-select form-select-lg" value={condition.attribute}
                                                                    onChange={(e) => handleDropdownChange(index, "attribute", e.target.value)}
                                                                    style={{ borderColor: validationErrors[index].attribute ? "red" : "" }}>
                                                                    <option value="" >Select Attribute</option>
                                                                    {newDropdownOptions[index].attributes.map((attribute: any) => (
                                                                        <option key={attribute.id} value={attribute.name}>{attribute.name}</option>
                                                                    ))}
                                                                </select>
                                                                {validationErrors[index].attribute && (<span className="required">Attribute is required</span>)}
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="mb-3">
                                                                <label className="form-label ff-i fs-14 fw-b">Operator</label>
                                                                <select className="form-select form-select-lg" value={condition.condition}
                                                                    onChange={(e) => handleDropdownChange(index, "condition", e.target.value)}
                                                                    style={{ borderColor: validationErrors[index].condition ? "red" : "" }}>
                                                                    <option value="" >Select Operator</option>
                                                                    {newDropdownOptions[index].conditions.map((cond: any) => (
                                                                        <option key={cond.id} value={cond.name}>{cond.name}</option>
                                                                    ))}
                                                                </select>
                                                                {validationErrors[index].condition && (<span className="required">Operator is required</span>)}
                                                            </div>
                                                        </div>
                                                        {
                                                            newDropdownOptions[index].valueOptions && newDropdownOptions[index].valueOptions.length > 0 ? (
                                                                <div className="col-3">
                                                                    <div className="mb-3">
                                                                        <label className="form-label ff-i fs-14 fw-b">Value</label>
                                                                        {condition.condition === "Contains" ? (
                                                                            <textarea
                                                                                className="form-control"
                                                                               
                                                                                value={condition.value}
                                                                                onChange={(e) => handleInputChange(index, "value", e.target.value)}
                                                                            ></textarea>

                                                                        ) : (
                                                                            <select
                                                                                className="form-select form-select-lg"
                                                                                value={condition.value}
                                                                                onChange={(e) => handleDropdownChange(index, "value", e.target.value)}
                                                                            >
                                                                                <option value="">Select Value</option>
                                                                                {newDropdownOptions[index].valueOptions.map((option: any) => (
                                                                                    <option key={option.id} value={option.name}>{option.name}</option>
                                                                                ))}
                                                                            </select>

                                                                        )}

                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="col-3">
                                                                    <div className="mb-3">
                                                                        <label className="form-label ff-i fs-14 fw-b">Value</label>
                                                                        { condition.condition === "Contains" ?(
                                                                            <textarea
                                                                            className="form-control"
                                                                            
                                                                            value={condition.value}
                                                                            onChange={(e) => handleInputChange(index, "value", e.target.value)}
                                                                          ></textarea>
                                                                        ):(
                                                                            <input type="text" className="form-control" aria-describedby="" value={condition.value} onChange={(e) => handleInputChange(index, "value", e.target.value)} />

                                                                        )}
                                                                        
                                                                        {/* {validationErrors[index].value && (<span className="required">Value is required</span>)} */}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-1">
                                                    <div className="del-btn-main">
                                                        <button className="btn btn-md btn-outline-danger del-btn" onClick={() => handleRemoveCondition(index)}><i className="bi bi-trash-fill"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                    {/* <div className="row">
                                    <div className="col-3">
                                        <div className="mb-4">
                                            <label className="form-label ff-i fs-14 fw-b">Type</label>
                                            <select class="form-select form-select-lg">
                                                <option selected>Choose Type</option>
                                                <option value="1">Order</option>
                                                <option value="2">Customer</option>
                                                <option value="3">Product</option>
                                                <option value="3">Branch</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <label className="form-label ff-i fs-14 fw-b">Add Attribute</label>
                                        <select class="form-select form-select-lg">
                                            <option selected>Choose Attributes</option>
                                            <option value="1">Total</option>
                                            <option value="2">Price</option>
                                            <option value="3">SKU</option>
                                            <option value="4">Order No</option>
                                            <option value="5">Name</option>
                                        </select>
                                    </div>
                                    <div className="col-3">
                                        <label className="form-label ff-i fs-14 fw-b">Add Condition</label>
                                        <select class="form-select form-select-lg">
                                            <option selected>Choose Attributes</option>
                                            <option value="1">Greater than</option>
                                            <option value="2">Less than</option>
                                            <option value="3">Equals to</option>
                                            <option value="3">Contains</option>
                                        </select>
                                    </div>
                                    <div className="col-3">
                                        <label className="form-label ff-i fs-14 fw-b">Value</label>
                                        <input type="text" class="form-control" aria-describedby="" />
                                    </div>
                                </div> */}
                                    {/* {showAddCondition ?
                                        ( */}
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="addcondition-div" >
                                                <span className="plus-icon" onClick={handleAddCondition}><i className="bi bi-plus-circle"></i></span> Add Condition
                                            </div>
                                        </div>
                                    </div>
                                    {/* //     ) 
                                    //     : (null)

                                    // } */}

                                    {/* <div className="mt-4 mb-3">
                                        <button type="button" className="btn btn-primary btn-lg select-btn">Select</button>
                                    </div> */}
                                    {/* <div className="orderrules-table">
                                        <table className="table table-bordered">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="" />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        ID
                                                        <select className="form-select mt-3">
                                                            <option selected>All IDs</option>
                                                            <option value="1">18</option>
                                                            <option value="2">19</option>
                                                            <option value="3">20</option>
                                                        </select>
                                                    </th>
                                                    <th>
                                                        Type
                                                        <select className="form-select mt-3">
                                                            <option selected>All Types</option>
                                                            <option value="1">Simple Product</option>
                                                            <option value="2">Simple Product 1</option>
                                                            <option value="3">Simple Product 2</option>
                                                        </select>
                                                    </th>
                                                    <th>
                                                        Attribute Set
                                                        <select className="form-select mt-3">
                                                            <option selected>All Attribute Sets</option>
                                                            <option value="1">Backflow</option>
                                                            <option value="2">Backflow 1</option>
                                                            <option value="3">Backflow 2</option>
                                                        </select>
                                                    </th>
                                                    <th>
                                                        SKU
                                                        <select className="form-select mt-3">
                                                            <option selected>All SKUs</option>
                                                            <option value="1">FEU765-100</option>
                                                            <option value="2">FEU765-100</option>
                                                            <option value="3">FEU765-100</option>
                                                        </select>
                                                    </th>
                                                    <th>
                                                        Product
                                                        <select className="form-select mt-3">
                                                            <option selected>All Products</option>
                                                            <option value="1">Product 1</option>
                                                            <option value="2">Product 2</option>
                                                            <option value="3">Product 3</option>
                                                        </select>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="" />
                                                        </div>
                                                    </td>
                                                    <td>18</td>
                                                    <td>Simple Product</td>
                                                    <td>Backflow</td>
                                                    <td>FEU765-100</td>
                                                    <td>
                                                        <div className="d-flex justify-content-between">
                                                            <p>Aquachek High Range Chlorine Test Stripstests:<br /> Chlorine Levels Up To 600 Ppm<br /> (100 Per Bottle) | 652013</p>
                                                            <span className="info-icon"><i className="bi bi-info-circle"></i></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="" />
                                                        </div>
                                                    </td>
                                                    <td>18</td>
                                                    <td>Simple Product</td>
                                                    <td>Backflow</td>
                                                    <td>FEU765-100</td>
                                                    <td>
                                                        <div className="d-flex justify-content-between">
                                                            <p>Aquachek High Range Chlorine Test Stripstests:<br /> Chlorine Levels Up To 600 Ppm<br /> (100 Per Bottle) | 652013</p>
                                                            <span className="info-icon"><i className="bi bi-info-circle"></i></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="" />
                                                        </div>
                                                    </td>
                                                    <td>18</td>
                                                    <td>Simple Product</td>
                                                    <td>Backflow</td>
                                                    <td>FEU765-100</td>
                                                    <td>
                                                        <div className="d-flex justify-content-between">
                                                            <p>Aquachek High Range Chlorine Test Stripstests:<br /> Chlorine Levels Up To 600 Ppm<br /> (100 Per Bottle) | 652013</p>
                                                            <span className="info-icon"><i className="bi bi-info-circle"></i></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="" />
                                                        </div>
                                                    </td>
                                                    <td>18</td>
                                                    <td>Simple Product</td>
                                                    <td>Backflow</td>
                                                    <td>FEU765-100</td>
                                                    <td>
                                                        <div className="d-flex justify-content-between">
                                                            <p>Aquachek High Range Chlorine Test Stripstests:<br /> Chlorine Levels Up To 600 Ppm<br /> (100 Per Bottle) | 652013</p>
                                                            <span className="info-icon"><i className="bi bi-info-circle"></i></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="" />
                                                        </div>
                                                    </td>
                                                    <td>18</td>
                                                    <td>Simple Product</td>
                                                    <td>Backflow</td>
                                                    <td>FEU765-100</td>
                                                    <td>
                                                        <div className="d-flex justify-content-between">
                                                            <p>Aquachek High Range Chlorine Test Stripstests:<br /> Chlorine Levels Up To 600 Ppm<br /> (100 Per Bottle) | 652013</p>
                                                            <span className="info-icon"><i className="bi bi-info-circle"></i></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> */}
                                    <div className="mt-4">
                                        <button type="button" className="btn btn-primary btn-lg apply-btn" onClick={applyRule}>Apply</button>
                                    </div>
                                </div>

                            </div>

                            <div className={activeEprdclass == true ? "" : "hidden"}>
                                <div className="tab-content">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12">
                                            <div className="table-scroll-section">
                                                <table className="table table-bordered">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col">
                                                                Name
                                                            </th>
                                                            <th scope="col">
                                                                Description
                                                            </th>
                                                            <th scope="col">
                                                                Customers
                                                            </th>

                                                            <th scope="col">
                                                                Priority
                                                            </th>
                                                            <th scope="col">
                                                                Created Date
                                                            </th>
                                                            <th scope="col">
                                                                Is Enabled
                                                            </th>
                                                            <th scope="col">
                                                                Delete
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            existingRule != null ? (
                                                                existingRule.map((item, index) => (
                                                                    <tr>
                                                                        <td>
                                                                            {item.ruleName}
                                                                        </td>
                                                                        <td>
                                                                            {item.description}
                                                                        </td>
                                                                        <td>
                                                                            {item.customers}
                                                                        </td>
                                                                        <td>
                                                                            {item.priority}
                                                                        </td>
                                                                        <td>
                                                                            {item.createdDate}
                                                                        </td>
                                                                        <td>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" checked={item.isEnabled} onChange={(e)=>checkBoxChange(item.ruleId,e.target.checked)} id="" />
                                                                            </div>
                                                                        </td>
                                                                        <td><button className="btn btn-sm btn-danger" onClick={(e) => DeleteAlert(item.ruleId)}><i className="bi bi-trash-fill"></i></button></td>
                                                                    </tr>

                                                                )
                                                                )

                                                            ) : (null)

                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div onClick={ClosePopup}>
                        {
                            isloader.isfailed ? (
                                <AFailed message={isloader.message} />
                            ) : (null)
                        }
                    </div>
                    <div onClick={ClosePopup}>
                        {
                            isloader.isSuccess ? (
                                <ASuccess message={isloader.message} />
                            ) : (null)
                        }
                    </div>

                </div>

            </div>
        </div>
    )
}
export default OrderRulesComponent;