
import React, { useMemo } from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import Button from "@material-ui/core/Button";
import toast from "react-hot-toast";
import Popover from "@material-ui/core/Popover";
import {
    baseUrl,
    SubscriptionKey,
    client_id,
    client_secret,
    tokenApi,
} from "../config";
import "./style.css";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Multiselect from "multiselect-react-dropdown";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { DownloadOrderLists } from "../../../dataservices/action/marketplace/marketplace";
import { Loadermessage, CreateinitialLoadermessage } from "../../../dataservices/model/loadermessage";
import Loader from "../../loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { event } from "jquery";
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../../../store/OrderListing';
import { actionCreatorsList } from '../../../store/OrderList';
import { ApplicationState } from "../../../store";
import MyOrderlistAttributes from "./orderlistattributes";
//const MyOrderlistAttributes = React.lazy(() => import("./orderlistattributes"));
const useStyles = makeStyles({
    paper: {
        background: "#FFFF",
    },
});

const label = { inputProps: { "aria-label": "Checkbox demo" } };

interface OrderProps {
    pageNumber: number;
}

const Order: React.FC<OrderProps> = ({ pageNumber }) => {
    const dispatch = useDispatch();

    // Get the selectedWarehouse value from the Redux store
    const { selectedBranches, selectedShippings, selectedStatus: selectedStatusFromStore,
        selectedCustomers, selectedWarhouse: selectedWarhouseFromStore, searchCustomerId,
        fromDate, toDate, internalCustomerId } = useSelector((state: ApplicationState) => state.OrderListing);
        const { shippingMethodList, branchCodeList, customerCodeList, statusList, warehouseList } = useSelector((state: ApplicationState) => state.OrderList);
    const classes = useStyles();
    const [partnerList, setPartnerList] = useState([
        { value: "", title: "" },
    ]);
    const [customerOrderIdList, setCustomerOrderIdList] = useState([]);
    const [warehouse, setWarehouse] = useState("");
    const [customerOrderId, setCustomerOrderId] = useState({
        title: "",
        value: "",
    });
  
    const { OLT }: any = useParams();
    const [customerCode, setCustomerCode] = useState("");
    const [beanchCodes, setBranchCodes] = useState("");
    const [shippingMethods, setShippingMethods] = useState("");
    const [statusCode, setStatusCode] = useState("");
    const [partner, setPartner] = useState("");
    const [startDate, setStartDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");
    const [statusStartDate, setStatusStartDate] = useState<any>("");
    const [statusEndDate, setStatusEndDate] = useState<any>("");
    const [startDateErr, setStartDateErr] = useState<any>(false);
    const [endDateErr, setEndDateErr] = useState<any>(false);
    const [statusStartDateErr, setStatusStartDateErr] = useState<any>(false);
    const [statusEndDateErr, setStatusEndDateErr] = useState<any>(false)
    const [lastDays, setLastDays] = useState("none");
    const [jsonData, setJsonData] = useState<any>([]);
    const [modelData, setModelData] = useState<any>([]);
    const [pageSize, setPageSize] = useState(0);
    const [checkAll, setCheckAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [nodatafound, setNodataFound] = useState(false);
    const [clear, setClear] = useState(false);
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const inputFile: any = useRef();
    const [PaginationProps, setPaginationProps] = useState<any>({
        start: 0,
        end: 0,
    });
    const [arrowDirection, setArrowDirection] = useState(true);
    const [selectedHeader, setSelectedHeader] = useState("");
    const [filename, setFilename] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedOrderId, setSelectedOrderId] = useState("");
    const [triggerApiCall, setTriggerApiCall] = useState(false); // For handling when to call the API
    const [isChecked, setIsChecked] = useState(false);
    const [itemSubTotal, setItemSubTotal] = useState<any>(0);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const [attributes, setAttribute] = useState<any>({});
    const [isFocused, setIsFocused] = useState(false);
    const [isFocusedEndDate, setIsFocusedEndDate] = useState(false);
    const navigate = useHistory();
    function formatDate(date: any) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [month, day, year].join("-");
    }

    const handleStartDate = (e: any) => {
        setLastDays("");
        setStartDate(e.target.value);
    };
    const handleEndDate = (e: any) => {
        setLastDays("");
        setEndDate(e.target.value);
    };
    const handleStatusStartDate = (e: any) => {
        setLastDays("");
        setStatusStartDate(e.target.value);
    };
    const handleStatusEndDate = (e: any) => {
        setLastDays("");
        setStatusEndDate(e.target.value);
    };
    const HandleDateValidate = (e: any, type: any) => {
        if (type === "start") {
            if (new Date(e.target.value) > new Date()) {
                setStartDateErr(true);
            } else {
                setStartDateErr(false);
            }
        }
        if (type === "end") {
            if (new Date(e.target.value) > new Date()) {
                setEndDateErr(true);
            } else {
                setEndDateErr(false);
            }
        }
    };

    const HandleStatusDateValidate = (e: any, type: any) => {
        if (type === "start") {
            if (new Date(e.target.value) > new Date()) {
                setStatusStartDateErr(true);
            } else {
                setStatusStartDateErr(false);
            }
        }
        if (type === "end") {
            if (new Date(e.target.value) > new Date()) {
                setStatusEndDateErr(true);
            } else {
                setStatusEndDateErr(false);
            }
        }
    };

    const handleClickDetails = (item: any) => {
        let date = item.transactionDate.split(" ");
        let mydata = {
            transactionId: item.transactionId,
            date: date[0],
            path: "",
        };
        localStorage.setItem("orderItem", JSON.stringify(mydata));

        let orderdetailsAPIfun = "";

        if (OLT == "F") {
            navigate.push("/orderdetails/F");

        }
        else {
            navigate.push("/orderdetails/D");

        }

    };

    const handleChangeWarehouse = (event: any) => {
        setWarehouse(event.target.value);
    };

    const handleChangeYourOrder = (event: any) => {
        let a = event.target.value;
        setCustomerOrderId({
            title: event.target.value,
            value: event.target.value,
        });
        dispatch(actionCreators.setSearchCustomerId({
            title: event.target.value,
            value: event.target.value,
        }));
        if (event.target.value.length > 2) {
            const headers = {
                "Content-Type": "application/json",
                Authorization: `${localStorage.getItem("Authorization")}`,
            };
            axios
                .get(`${baseUrl}/GetCustomerOrderId?searchText=${event.target.value}`, {
                    headers,
                })
                .then((response) => {
                    let orders: any = [];
                    response.data.map((x: any) => {
                        orders.push({
                            title: x.customerOrderId,
                            value: x.customerOrderId,
                        });
                    });
                    if(orders.length > 0)
                    dispatch(actionCreators.setInternalCustomerId(orders));
                else dispatch(actionCreators.setSearchCustomerId({
                    title: a,
                    value: a,
                }));
                    setCustomerOrderIdList(orders);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            setCustomerOrderIdList([]);
        }
    };
    const handleChangeOrderId = (event: any, value: any) => {
        if (value === null) {
            setCustomerOrderId({
                title: "",
                value: "",
            });
        }
        setCustomerOrderId(value);
        dispatch(actionCreators.setInternalCustomerId(value));
    };

    const handleChangeCustomerCode = (event: any) => {
        setCustomerCode(event.target.value);
    };

    const handleChangeLastDays = (event: any) => {
        setLastDays(event.target.value);
        if (event.target.value === "7") {
            let date = new Date();
            date.setDate(date.getDate() - 7);
            setStartDate(formatDate(date));
            setEndDate(formatDate(new Date()));
        }
        if (event.target.value === "30") {
            let date = new Date();
            date.setDate(date.getDate() - 30);
            setStartDate(formatDate(date));
            setEndDate(formatDate(new Date()));
        }
        if (event.target.value === "90") {
            let date = new Date();
            date.setDate(date.getDate() - 90);
            setStartDate(formatDate(date));
            setEndDate(formatDate(new Date()));
        }
        if (event.target.value === "none") {
            setStartDate("");
            setEndDate("");
        }
    };

    const handlePageChange = (data: any, page: number) => {
        getOrderlistApi(page);
    };
    useEffect(() => {
        axios.post("api/MP/gettoken").then((response: any) => {
            localStorage.setItem("Authorization", response.data);
            initialApis();
            getOrderlistApi(currentPage);
        });
    }, []);
   

    const initialApis = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("Authorization")}`,
        };
        if(statusList.length === 0) {
        axios
            .get(`${baseUrl}/GetOrderSatus`, { headers })
            .then((response) => {
                let status: any = [];
                response.data.map((x: any) => {
                    status.push({
                        title: x.statusDescription,
                        value: x.statusCode,
                    });                    
                });
                dispatch(actionCreatorsList.setStatusList(status));
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        }
        let orderwarehouse = "";
        if (OLT == "F") {
            orderwarehouse = "GetWarehouse"

        }
        else {
            orderwarehouse = "GetNFWarehouse"

        }
        if(warehouseList.length === 0) {
        axios
            .get(`${baseUrl}/${orderwarehouse}`, { headers })
            .then((response) => {
                let ware: any = [];
                response.data.map((x: any) => {
                    if (ware.find((y: any) => y.title === x.wareHouseId)) {
                    } else {
                        ware.push({
                            title: x.wareHouseId,
                            value: x.wareHouseId,
                        });
                    }
                });
                dispatch(actionCreatorsList.setWarehouseList(ware));
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        }
        let customercode = "";
        if (OLT == "F") {
            customercode = "GetCustomerCode"

        }
        else {
            customercode = "GetNFCustomerCode"

        }
        if(customerCodeList.length === 0) {
        axios
            .get(`${baseUrl}/${customercode}`, { headers })
            .then((response) => {
                let cust: any = [];
                response.data.map((x: any) => {
                    cust.push({
                        title: x.customerCode,
                        value: x.customerCode,
                    });                    
                });
                dispatch(actionCreatorsList.setCustomerCodeList(cust));
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        }
        let partner = "GetPartnerType"

        axios
            .get(`${baseUrl}/${partner}`, { headers })
            .then((response) => {
                let part: any = [];
                response.data.map((x: any) => {

                    part.push({
                        title: x.partnerType,
                        value: x.partnerType,
                    });
                    setPartnerList(part);
                });
            })
            .catch((error) => {
                console.error("Error:", error);
            });
            if(branchCodeList.length === 0) {
        axios
            .get(`${baseUrl}/GetBranchCodes`, { headers })
            .then((response) => {
                let part: any = [];
                response.data.map((x: any) => {
                    part.push({
                        title: x.branchCode,
                        value: x.branchCode,
                    });                    
                });
                dispatch(actionCreatorsList.setBranchCodeList(part));
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        }
        if(shippingMethodList.length === 0) {
        axios.get(`${baseUrl}/GetShippingMethods`, { headers })
            .then((response) => {
                let part: any = [];
                response.data.map((x: any) => {
                    if (x.shippingMethod != null) {
                        part.push({
                            title: x.shippingMethod,
                            value: x.shippingMethod,
                        });                        
                    }
                });
                dispatch(actionCreatorsList.setShippingMethodList(part));

            })
            .catch((error) => {
                console.error("Error:", error);
            });
        }
    };

    function getOrderlistApi(page: any) {
        let token = localStorage.getItem("Authorization");
        //console.log("token....", token)
        //console.log(OLT);
        setCurrentPage(page);
        setLoading(true);
        let transactionDate = "";
        // if (startDate && endDate) {
        //     transactionDate = formatDate(startDate) + "," + formatDate(endDate);
        // } else {
        //     transactionDate = "";
        // }

        if (fromDate && toDate) {
            transactionDate = formatDate(fromDate) + "," + formatDate(toDate);
        } else {
            transactionDate = "";
        }

        let statusDateRange = "";
        if (statusStartDate && statusEndDate) {
            statusDateRange = formatDate(statusStartDate) + "," + formatDate(statusEndDate);
        } else {
            statusDateRange = "";
        }
        let orderlistingAPIfun = "";
        if (OLT == "F") {
            orderlistingAPIfun = "GetSalesOrdersData"
        }
        else {
            orderlistingAPIfun = "GetNFSalesOrdersData"
        }

        const headers = {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("Authorization")}`,
        };

        let branchList = selectedBranches.map((item: any) => item.value).join(",");
        let shippingsList = selectedShippings.map((item: any) => item.value).join(",");
        let customersList = selectedCustomers.map((item: any) => item.value).join(",");
        let warhouseList = selectedWarhouseFromStore.map((item: any) => item.value).join(",");
        let statusList = selectedStatusFromStore.map((item: any) => item.value).join(",")

        let internalid = internalCustomerId.map((item: any) => item.value).join(",")
        console.log('internalCustomerId', internalCustomerId)
console.log('internalid', internalid)
console.log('searchCustomerId', searchCustomerId)

        //console.log("baseUrl....", baseUrl + "/" + orderlistingAPIfun + "?pageNum=" + page + "&customerCode=" + customerCode + "&wareHouseId=" + warehouse + "&customerOrderId=" + customerOrderId.value + "&DateRange=" + transactionDate + "&OrderStatusCode=" + statusCode + "&StatusDateRange=" + statusDateRange + "&orderbycolumn=" + selectedHeader);
        axios
            .get(
                `${baseUrl}/${orderlistingAPIfun}?pageNum=${page}&customerCode=${customersList}&wareHouseId=${warhouseList}&customerOrderId=${internalCustomerId.length > 0 ? internalid : (searchCustomerId.value != "" && searchCustomerId.value != undefined && searchCustomerId.value != null ? searchCustomerId.value : "")}&DateRange=${transactionDate}&OrderStatusCode=${statusList}&StatusDateRange=${statusDateRange}&orderbycolumn=${selectedHeader}&PartnerType=${partner}&branchCode=${branchList}&shippingMethod=${shippingsList}`,
                { headers }
            )
            .then((response) => {
                if (response.data.salesOrder.length === 0) {
                    setNodataFound(true);
                } else {
                    setNodataFound(false);
                }
                setLoading(false);
                var merged: any[] = [];
                response.data.salesOrder.forEach((x: any) => {
                    merged.push({ ...x, isChecked: false });
                });
                setJsonData(merged);
                //console.log("setJsonData....", merged)
                setPageSize(parseInt(response.data.pageSize));
                setTotalRecordCount(parseInt(response.data.totalRecordCount));
            })
            .catch((error) => {
                setLoading(false);
                if (error && error.response && error.response.status !== 200) {
                    setJsonData([]);
                    setNodataFound(true);
                    // toast.error(error.response.data.errorMessage, { duration: 50000000 });
                    if (error.response.data.errorMessage.slice(0, 5) === "DateR") {
                        toast.error(
                            "Invalid date format. Please use the format MM-DD-YYYY for Start Date and End Date",
                            { duration: 50000000 }
                        );
                    }
                }
            });
    }

    const memoizedSelectedBranches = useMemo(() => selectedBranches, [selectedBranches]);
const memoizedSelectedShippings = useMemo(() => selectedShippings, [selectedShippings]);
const memoizedselectedStatusFromStore = useMemo(() => selectedStatusFromStore, [selectedStatusFromStore]);
const memoizedSelectedCustomers = useMemo(() => selectedCustomers, [selectedCustomers]);
const memoizedSelectedWarhouseFromStore = useMemo(() => selectedWarhouseFromStore, [selectedWarhouseFromStore]);
const memoizedToDate = useMemo(() => toDate, [toDate]);
const memoizedInternalCustomerId = useMemo(() => internalCustomerId, [internalCustomerId]);

const memoizedShippingMethodList = useMemo(() => shippingMethodList, [shippingMethodList]);
const memoizedStatusList = useMemo(() => statusList, [statusList]);
const memoizedWarehouseList = useMemo(() => warehouseList, [warehouseList]);
const memoizedBranchCodeList = useMemo(() => branchCodeList, [branchCodeList]);
const memoizedCustomerCodeList = useMemo(() => customerCodeList, [customerCodeList]);

    useEffect(() => {
        getOrderlistApi(1);
    }, [memoizedSelectedBranches, memoizedSelectedShippings, memoizedselectedStatusFromStore, memoizedSelectedCustomers, memoizedSelectedWarhouseFromStore, 
        memoizedToDate, memoizedInternalCustomerId]);

    const handleCheckAll = (value: any) => {
        // e.stopPropagation();
        setCheckAll(value);
        var merged: any[] = [];
        jsonData.forEach((x: any) => {
            merged.push({ ...x, isChecked: value });
        });
        setJsonData(merged);
        var checkedItems = merged.filter((item: any) => item.isChecked == true)
        //console.log("checkedItems....", checkedItems)
        const orderIdsArr = checkedItems.map((obj: any) => obj.customerOrderId);
        const result = orderIdsArr.join(',');
        setSelectedOrderId(result);
        // console.log("allselect....",result)
    };
    const handleStartDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(actionCreators.setFromDate(e.target.value));
    };

    const handleEndDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(actionCreators.setToDate(e.target.value));
    };
    const handleSearch = () => {
        if (!startDateErr && !endDateErr) {
            getOrderlistApi(1);
        }
    };

    const handleShowHoldOrders = () => {
        let statusAry: any = []; // Properly typed array
        statusAry.push({ title: "Hold", value: "17" }); // Add the object to the array
        dispatch(actionCreators.setSelectedStatus(statusAry)); // Dispatch the updated array
        setTriggerApiCall(true); // Trigger API call after status code change
    }

    function formatAMPM(date: any) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
    }

    const dateConverter = (x: any) => {
        var today = new Date(x);
        var month = today.toLocaleString("default", { month: "short" });
        let time = formatAMPM(today);
        let date = `${month} ${today.getDate()}, ${today.getFullYear()} ${time}`;
        return date;
    };
    const ShippingDate = (x: any) => {
        var today = new Date(x);
        var month = today.toLocaleString("default", { month: "short" });
        let date = `${month} ${today.getDate()}, ${today.getFullYear()}`;
        return date;
    };

    const findstatus = (item: any) => {
        if (item) {
            let result: any = statusList.find((x) => x.value === item);
            return result && result.title;
        }
    };
    const handleClear = () => {
        setStartDateErr(false);
        setEndDateErr(false);
        setWarehouse("");
        setCustomerCode("");
        setCustomerOrderId({
            title: "",
            value: "",
        });
        setStartDate("");
        setEndDate("");
        setLastDays("");
        setStatusCode("");
        setClear(true);
        setCheckAll(false);
        window.location.reload();
    };
    // useEffect(() => {
    //     if (clear === true) {
    //         getOrderlistApi(1);
    //         setClear(false);
    //     }
    // }, [clear]);

    const handleChangeStatus = (event: any) => {
        setStatusCode(event.target.value);
    };

    const handleChangeCheckbox = (e: any, index: any) => {
        let arr = jsonData;
        arr[index].isChecked = e.target.checked;
        setJsonData([...arr]);
        var checkedItems = arr.filter((item: any) => item.isChecked == true)
        //console.log("checkedItems....", checkedItems)
        const orderIdsArr = checkedItems.map((obj: any) => obj.customerOrderId);
        const result = orderIdsArr.join(',');
        setSelectedOrderId(result);
        //console.log("orderIds....", result)
        if (e.target.checked === false) {
            setCheckAll(false);
        }
        const allValuesAreTrue = arr.every((obj: any) => obj.isChecked === true);
        if (allValuesAreTrue) {
            setCheckAll(true);
        }
    };

    const DownloadOrderList = (page: any) => {

        try {

            debugger;
            let token = localStorage.getItem("Authorization");
            let transactionDate = "";
            if (startDate && endDate) {
                transactionDate = formatDate(startDate) + "," + formatDate(endDate);
            } else {
                transactionDate = "";
            }
            let orderlistingAPIfun = "";
            if (OLT == "F") {
                orderlistingAPIfun = "GetSalesOrdersData"
            }
            else {
                orderlistingAPIfun = "GetNFSalesOrdersData"
            }

            let statusDateRange = "";
            if (statusStartDate && statusEndDate) {
                statusDateRange = formatDate(statusStartDate) + "," + formatDate(statusEndDate);
            } else {
                statusDateRange = "";
            }
            const filterobj = {
                //orderlistingAPIfun: orderlistingAPIfun,
                pageNum: page,
                customerCode: customerCode,
                wareHouseId: warehouse,
                customerOrderId: customerOrderId.value,
                DateRange: transactionDate,
                OrderStatusCode: statusCode,
                StatusDateRange: statusDateRange,
                OrderId: selectedOrderId,
                //token: token
            }
            // var hasThreeValidProperties = function (filterObj: any) {
            //     const keysToCheck = ['customerCode', 'wareHouseId', 'customerOrderId', 'DateRange', 'OrderStatusCode','StatusDateRange'];
            //     let validCount = 0;

            //     // Loop over the specified keys and check for valid (non-null and non-empty) values
            //     for (const key of keysToCheck) {
            //         if (filterObj[key] !== null && filterObj[key] !== undefined && filterObj[key] !== '') {
            //             validCount++;
            //             if (validCount >= 3) {
            //                 return true; // Return true as soon as we find at least three valid properties
            //             }
            //         }
            //     }

            //     return false; // Return false if less than three valid properties are found
            // }
            // const isValid = hasThreeValidProperties(filterobj);
            //if (isValid) {

            const data = new FormData();
            data.append('filters', JSON.stringify(filterobj));
            setLoading(true);
            const response = axios({
                method: "post",
                url: "api/MP/DownloadOrderLists",
                data: data,
                headers: { "Content-Type": "multipart/form-data" },
                responseType: 'blob',
            }).then((result) => {



                if (result != null && result.status == 200) {
                    debugger;
                    const url = window.URL.createObjectURL(new Blob([result.data]));
                    const a = document.createElement('a');
                    a.href = url;
                    a.setAttribute('download', "OrderList.csv");
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    URL.revokeObjectURL(url);

                    setLoading(false);

                } else {
                    toast.error(
                        "No Records Found",
                        { duration: 50000000 }
                    );
                    setLoading(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }


            });
            // } else {
            //     toast.error(
            //         "Select at least three filters",
            //         { duration: 50000000 }
            //     );

            // }

        } catch (error) {
            console.log(error)
        }

    }
    const OpenFileExplore = () => {
        inputFile.current.click();
    };
    const handleFileUpload = (e: any) => {

        e.preventDefault();
        const { files } = e.target;
        if (files && files.length) {
            setFilename(files[0].name);
            setSelectedFile(files[0]);
            const formData = new FormData();
            formData.append('File', files[0]);

            setLoading(true);
            try {
                const response = axios({
                    method: "post",
                    url: "api/MP/UploadOrderFile",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                }).then(result => {
                    toast.error(
                        "File Uploaded Successfully",
                        { duration: 50000000 }
                    );
                    setLoading(false);
                    console.log(result);
                });



            } catch (error) {
                console.log(error)
            }
        }
    }

    const DownloadInvoice = (page: any) => {
        try {
            let transactionDate = "";
            if (startDate && endDate) {
                transactionDate = formatDate(startDate) + "," + formatDate(endDate);
            } else {
                transactionDate = "";
            }
            let orderlistingAPIfun = "";
            if (OLT == "F") {
                orderlistingAPIfun = "GetSalesOrdersData"
            }
            else {
                orderlistingAPIfun = "GetNFSalesOrdersData"
            }

            const filterobj = {
                orderlistingAPIfun: orderlistingAPIfun,
                pageNum: page,
                customerCode: customerCode,
                wareHouseId: warehouse,
                customerOrderId: customerOrderId.value,
                DateRange: transactionDate,
                OrderStatusCode: statusCode,
                OrderId: selectedOrderId,
                //token: token
            }
            const data = new FormData();
            data.append('filters', JSON.stringify(filterobj));
            setLoading(true);
            const response = axios({
                method: "post",
                url: "api/MP/DownloadInvoice",
                data: data,
                headers: { "Content-Type": "multipart/form-data" },
                responseType: 'blob',
            }).then((result) => {
                if (result != null && result.status == 200) {
                    debugger;
                    const url = window.URL.createObjectURL(new Blob([result.data]));
                    const a = document.createElement('a');
                    a.href = url;
                    a.setAttribute('download', "Invoice.csv");
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    URL.revokeObjectURL(url);

                    setLoading(false);

                } else {
                    toast.error(
                        "No Records Found",
                        { duration: 50000000 }
                    );
                    setLoading(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }
                //resultmes.isLoader = false;
                //Setisloader(resultmes);
            });

        } catch (error) {
            console.log(error)

        }


    }
    const DownloadShipping = (page: any) => {
        try {
            let transactionDate = "";
            if (startDate && endDate) {
                transactionDate = formatDate(startDate) + "," + formatDate(endDate);
            } else {
                transactionDate = "";
            }
            let orderlistingAPIfun = "";
            if (OLT == "F") {
                orderlistingAPIfun = "GetSalesOrdersData"
            }
            else {
                orderlistingAPIfun = "GetNFSalesOrdersData"
            }
            const filterobj = {
                orderlistingAPIfun: orderlistingAPIfun,
                pageNum: page,
                customerCode: customerCode,
                wareHouseId: warehouse,
                customerOrderId: customerOrderId.value,
                DateRange: transactionDate,
                OrderStatusCode: statusCode,
                OrderId: selectedOrderId,
                //token: token
            }

            const data = new FormData();
            data.append('filters', JSON.stringify(filterobj));
            setLoading(true);
            const response = axios({
                method: "post",
                url: "api/MP/DownloadShipping",
                data: data,
                headers: { "Content-Type": "multipart/form-data" },
                responseType: 'blob',
            }).then((result) => {
                if (result != null && result.status == 200) {
                    debugger;
                    const url = window.URL.createObjectURL(new Blob([result.data]));
                    const a = document.createElement('a');
                    a.href = url;
                    a.setAttribute('download', "Shipping.csv");
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    URL.revokeObjectURL(url);

                    setLoading(false);

                } else {
                    toast.error(
                        "No Records Found",
                        { duration: 50000000 }
                    );
                    setLoading(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }
                //resultmes.isLoader = false;
                //Setisloader(resultmes);
            });

        } catch (error) {
            console.log(error)

        }

    }

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedWarhouse, setSelectedWarhouse] = useState("");
    //const [statusList,setStatussList]=useState("");
    const handleSelect = (selectedList: any, selectedItem: any) => {

        let selectedstatus: string = selectedList.map((option: any) => option.value).join(',');
        setStatusCode(selectedstatus);
        dispatch(actionCreators.setSelectedStatus(selectedList));

    }

    const handleRemove = (selectedList: any, selectedItem: any) => {
        let selectedstatus: string = selectedList.map((option: any) => option.value).join(',');
        setStatusCode(selectedstatus);
        dispatch(actionCreators.setSelectedStatus(selectedList));

    }

    const handleSelectWarhouse = (selectedList: any, selectedItem: any) => {

        let selectedWarhouse: string = selectedList.map((option: any) => option.value).join(',');
        setWarehouse(selectedWarhouse);
        dispatch(actionCreators.setSelectedWarehouse(selectedList));

    }

    const handleRemoveWarhouse = (selectedList: any, selectedItem: any) => {
        let selectedWarhouse: string = selectedList.map((option: any) => option.value).join(',');
        setWarehouse(selectedWarhouse);
        dispatch(actionCreators.setSelectedWarehouse(selectedList));

    }

    const handleSelectCustomerCode = (selectedList: any, selectedItem: any) => {

        let selectedCustomerCode: string = selectedList.map((option: any) => option.value).join(',');
        setCustomerCode(selectedCustomerCode);
        dispatch(actionCreators.setSelectedCustomers(selectedList));

    }
    const handleSelectBranch = (selectedList: any, selectedItem: any) => {
        let selectedBranch: string = selectedList.map((option: any) => option.value).join(',');
        setBranchCodes(selectedBranch);
        dispatch(actionCreators.setSelectedBranches(selectedList));
    }
    const handleSelectShippingMethods = (selectedList: any, selectedItem: any) => {
        let selectedShipMethods: string = selectedList.map((option: any) => option.value).join(',');
        setShippingMethods(selectedShipMethods);
        dispatch(actionCreators.setSelectedShippings(selectedList));

    }
    const handleRemoveBranch = (selectedList: any, selectedItem: any) => {
        let selectedBranch: string = selectedList.map((option: any) => option.value).join(',');
        setBranchCodes(selectedBranch);
        dispatch(actionCreators.setSelectedBranches(selectedList));

    }
    const handleRemoveShippingMethod = (selectedList: any, selectedItem: any) => {
        let selectedShipMethod: string = selectedList.map((option: any) => option.value).join(',');
        setShippingMethods(selectedShipMethod);
        dispatch(actionCreators.setSelectedShippings(selectedList));

    }

    const handleRemoveCustomerCode = (selectedList: any, selectedItem: any) => {
        let selectedCustomerCode: string = selectedList.map((option: any) => option.value).join(',');
        setCustomerCode(selectedCustomerCode);
        dispatch(actionCreators.setSelectedCustomers(selectedList));

    }
    const handleSelectPartnerType = (selectedList: any, selectedItem: any) => {

        let selectedPartner: string = selectedList.map((option: any) => option.value).join(',');
        setPartner(selectedPartner);

    }
    const handleRemovePartnerType = (selectedList: any, selectedItem: any) => {
        let selectedPartner: string = selectedList.map((option: any) => option.value).join(',');
        setPartner(selectedPartner);

    }


    const onRemove = (selectedList: any, removedItem: any) => {
        setSelectedOptions(selectedList);
    };

    const requestSort = (header: string) => {
        console.log("header...", header);
        setArrowDirection(!arrowDirection);

        // if(header=="orderdate"){
        //   let hname="date(`order`.CreatedDate),"+arrowDirection;
        //   setSelectedHeader(hname);

        // }else if(header=="status"){
        //   let hname="`orderacknowledgement`.StatusCode,"+arrowDirection;
        //   setSelectedHeader(hname);

        // }else{
        //   let hname="`Order`.CustomerAccountId,"+arrowDirection;
        //   setSelectedHeader(hname);
        // }
        let hname = "";
        if (header === "orderdate") {
            hname = `date,${arrowDirection}`;
        } else if (header === "status") {
            hname = `status,${arrowDirection}`;
        } else {
            hname = `customeraccount,${arrowDirection}`;
        }
        //getOrderlistApi(1);
        setSelectedHeader(hname);

    };
    // Effect to call API when selectedHeader changes
    useEffect(() => {
        if (selectedHeader !== "") {
            console.log("Calling API with header:", selectedHeader);
            getOrderlistApi(1);
        }
    }, [selectedHeader]); // Only re-run the effect if selectedHeader changes.

    const getSortDirection = () => {

        return arrowDirection ?
            <FontAwesomeIcon icon={faCaretUp} /> :
            <FontAwesomeIcon icon={faCaretDown} />;


    };
    const modelPopupClick = (indexVal: number) => {
        const selectedObject = jsonData[indexVal];
        //console.log("indexVal....", indexVal);
        console.log("modelData....", selectedObject);
        let itemSub = 0;

        let orderLineItems = selectedObject.orderLineItems;
        orderLineItems.forEach((x: any) => {
            itemSub = itemSub + x.salesPrice;
        });
        setItemSubTotal(itemSub);
        setModelData(selectedObject);
    }
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
        if (event.target.checked == true) {
            handleShowHoldOrders();
        } else if (event.target.checked == false) {
            let statusAry: any = [];
            dispatch(actionCreators.setSelectedStatus(statusAry));
           
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    // const handleClick = (event: any, list: any) => {
    //     setAttribute(list);
    //     setAnchorEl(event.currentTarget);
    // };

    const handleClick = useCallback((event: any, list: any) => {
        setAttribute(list); // Only update if 'list' changes
        setAnchorEl(event.currentTarget); // Anchor to the clicked element
    }, []);

    console.log('selectedStatusFromStore', selectedStatusFromStore);
    return (
        <>
            {loading && (
                <div className="loader_orderlisting">
                    <CircularProgress style={{ color: "#000000" }} />
                </div>
            )}
            <div className="fc-orderlisting">
                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        {
                            OLT == "F" ? <div className="heading-section"><h1>Fulfillment Order Listing</h1></div> : <div className="heading-section"><h1>Non Fulfillment Order Listing</h1></div>
                        }
                    </div>
                </div>
                <br />
                <div className="mb-6">
                    <div className="d-flex justify-content-end align-items-center" >
                        {/* <button type="button" className="btn  btn-primary float-end" onClick={handleShowHoldOrders} >Show Hold Orders</button> */}
                        <div className="tr-btns">
                            <form>
                                <div>
                                    <a target="_blank" type="button" className="tr-btns-links" onClick={OpenFileExplore} ><span><i className="bi bi-journal-check"></i></span>Import Order</a>
                                </div>
                                <input
                                    ref={inputFile}
                                    style={{ display: "none" }} onChange={handleFileUpload}
                                    type="file"
                                />
                            </form>
                            <a target="_blank" type="button" className="tr-btns-links" onClick={() => DownloadInvoice(currentPage)} ><span><i className="bi bi-download"></i></span>Invoices</a>
                            <a target="_blank" type="button" className="tr-btns-links" onClick={() => DownloadShipping(currentPage)} ><span><i className="bi bi-download"></i></span>Shipment</a>
                            <a target="_blank" type="button" className="tr-btns-links b-n" onClick={() => DownloadOrderList(currentPage)} ><span><i className="bi bi-download"></i></span>Order List</a>
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <div className="ol-t-tp">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" checked={isChecked} onChange={handleCheckboxChange} value="" id="" />
                                <label className="form-check-label">
                                    Hold Orders
                                </label>
                            </div>
                            {/* <select className="form-select w-140" aria-label="Default select example">
                                <option selected>All Warehouse</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select> */}
                            {
                                Array.isArray(memoizedWarehouseList) &&
                                <Multiselect
                                    aria-label="Default select example"
                                    placeholder="All Warehouse"
                                    options={memoizedWarehouseList}
                                    selectedValues={selectedWarhouseFromStore}
                                    onSelect={(selectedList, selectedItem) => handleSelectWarhouse(selectedList, selectedItem)}
                                    onRemove={(selectedList, selectedItem) => handleRemoveWarhouse(selectedList, selectedItem)}
                                    displayValue="title"
                                />

                            }

                            {/* <input type="text" placeholder="Order/Transaction ID/AgilityOrder ID" name="search" onChange={handleChangeYourOrder} value={customerOrderId && customerOrderId.value} />
                                <button type="submit"><i className="bi bi-search" onClick={handleSearch}></i></button> */}
                            <div className="sb-d">
                                <div className="input-group">
                                    <FormControl size="small">
                                        <Autocomplete
                                            size="small"
                                            value={searchCustomerId && searchCustomerId.value}
                                            onChange={handleChangeOrderId}
                                            options={customerOrderIdList}
                                            getOptionLabel={(option: any) => option.title}
                                            fullWidth
                                            renderInput={(params: any) => (
                                                <TextField
                                                    {...params}
                                                    onChange={handleChangeYourOrder}
                                                    placeholder="Your Order/Transaction ID/AgilityOrder ID"
                                                    variant="outlined"
                                                    value={internalCustomerId && internalCustomerId.value}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            handleSearch();
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    <span onClick={handleSearch} className="input-group-text">
                                        <i className="bi bi-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pagination_container">
                    <div>Items Per Page:{pageSize}</div>
                    <div>
                        Showing {Math.min((currentPage - 1) * pageSize + 1, totalRecordCount)}{" "}
                        - {Math.min(currentPage * pageSize, totalRecordCount)} of{" "}
                        {totalRecordCount} results
                    </div>
                    <div>
                        <Pagination
                            count={Math.ceil(totalRecordCount / pageSize)}
                            page={currentPage}
                            variant="outlined"
                            className="customize_pagination"
                            shape="rounded"
                            onChange={handlePageChange}
                        />
                    </div>
                </div>
                <table className="orderlisting-table table table-bordered">
                    <thead>
                        <tr>
                            <th className="c-th-1" onClick={() => requestSort('orderdate')}>
                                <div className="th-title">
                                    <div className="th-checkbox-text-style" onClick={(e) => e.stopPropagation()}>
                                        <Checkbox
                                            onChange={(e) => handleCheckAll(e.target.checked)}
                                            {...label}
                                            checked={checkAll}
                                        ></Checkbox>
                                    </div>
                                    Order Date<span>{getSortDirection()}</span></div>
                                <div className="th-dropd" onClick={(e) => e.stopPropagation()}>
                                    {/* <input type="date" className="form-control"  id="datetime" value={fromDate} aria-describedby="datetimehelp" onChange={handleStartDateTimeChange} />
                                    <input type="date" className="form-control"  id="datetime" value={toDate} aria-describedby="datetimehelp" onChange={handleEndDateTimeChange} /> */}
                                    <input type={isFocused || fromDate ? "date" : "text"} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} placeholder={!isFocused && !fromDate ? "Start Date" : ""} style={{ textTransform: "uppercase" }} className="form-control mb-1"  id="datetime" value={fromDate} aria-describedby="datetimehelp" onChange={handleStartDateTimeChange} />
                                    <input type={isFocusedEndDate || toDate ? "date" : "text"} onFocus={() => setIsFocusedEndDate(true)} onBlur={() => setIsFocusedEndDate(false)} placeholder={!isFocusedEndDate && !toDate ? "End Date" : ""} style={{ textTransform: "uppercase" }} className="form-control"  id="datetime" value={toDate} aria-describedby="datetimehelp" onChange={handleEndDateTimeChange} />
                                </div>
                            </th>
                            {/* <th onClick={() => requestSort('OrderDetails')}>Order Details {getSortDirection('OrderDetails')}</th>  */}
                            <th className="c-th-2">
                                {/* <div className="th-title">Order Details<span>{getSortDirection()}</span></div> */}
                                <div className="th-title">Order Details</div>

                            </th>
                            <th className="c-th-3">
                                {/* <div className="th-title">Shipping<span>{getSortDirection()}</span></div> */}
                                <div className="th-title">Shipping</div>
                                <div className="th-dropd" onClick={(e) => e.stopPropagation()}>

                                    {Array.isArray(memoizedShippingMethodList) &&
                                        <Multiselect
                                            options={memoizedShippingMethodList}
                                            placeholder="All Shipping"
                                            selectedValues={selectedShippings}
                                            onSelect={(selectedList, selectedItem) => handleSelectShippingMethods(selectedList, selectedItem)}
                                            onRemove={(selectedList, selectedItem) => handleRemoveShippingMethod(selectedList, selectedItem)}
                                            displayValue="title"
                                        />}
                                </div>
                            </th>
                            <th className="c-th-4">
                                {/* <div className="th-title">Branch<span>{getSortDirection()}</span></div> */}
                                <div className="th-title">Branch</div>
                                <div className="th-dropd" onClick={(e) => e.stopPropagation()}>

                                    {Array.isArray(memoizedBranchCodeList) &&
                                        <Multiselect
                                            options={memoizedBranchCodeList}
                                            placeholder="All Branches"
                                            selectedValues={selectedBranches}
                                            //onSelect={(selectedList, selectedItem) => handleSelectBranch(selectedList, selectedItem)}
                                            onSelect={handleSelectBranch}
                                            onRemove={(selectedList, selectedItem) => handleRemoveBranch(selectedList, selectedItem)}
                                            displayValue="title"
                                        />}
                                </div>
                            </th>
                            <th className="c-th-5" onClick={() => requestSort('status')}>
                                <div className="th-title">Status<span>{getSortDirection()}</span></div>
                                <div className="th-dropd" onClick={(e) => e.stopPropagation()}>

                                    {Array.isArray(memoizedStatusList) &&

                                        <Multiselect
                                            placeholder="All Status"
                                            options={memoizedStatusList}
                                            selectedValues={selectedStatusFromStore}
                                            onSelect={(selectedList, selectedItem) => handleSelect(selectedList, selectedItem)}
                                            onRemove={(selectedList, selectedItem) => handleRemove(selectedList, selectedItem)}
                                            displayValue="title"
                                        />

                                    }
                                </div>
                            </th>
                            <th className="c-th-6" onClick={() => requestSort('customeraccount')}>
                                <div className="th-title">Customer<span>{getSortDirection()}</span></div>
                                <div className="th-dropd" onClick={(e) => e.stopPropagation()}>
                                    <Multiselect
                                        options={memoizedCustomerCodeList}
                                        placeholder="All Customer/ID"
                                        selectedValues={selectedCustomers}
                                        onSelect={(selectedList, selectedItem) => handleSelectCustomerCode(selectedList, selectedItem)}
                                        onRemove={(selectedList, selectedItem) => handleRemoveCustomerCode(selectedList, selectedItem)}
                                        displayValue="title"
                                    />
                                </div>
                            </th>
                            <th className="c-th-7">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(jsonData) &&
                            jsonData.map((item: any, index: number) => (
                                <tr key={index}>
                                    <td className="c-td-1">
                                        <div className="td-checkbox-text-style">
                                            <div>
                                                <Checkbox
                                                    checked={item.isChecked}
                                                    {...label}
                                                    onChange={(e) => handleChangeCheckbox(e, index)}
                                                ></Checkbox>
                                            </div>
                                            <div>
                                                <b>
                                                    {dateConverter(item.customerOrderDateTime).substring(
                                                        0,
                                                        3
                                                    )}
                                                </b>
                                                {dateConverter(item.customerOrderDateTime).substring(
                                                    3,
                                                    12
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="c-td-2">
                                        <div className="pb-3">
                                            <button type="button" className="od-m-link" onClick={() => modelPopupClick(index)} data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">Order # <strong>{item.customerOrderId}</strong></button>
                                            <div className="offcanvas offcanvas-end offcanvas-custom-width"
                                                tabIndex={-1}
                                                id="offcanvasExample"
                                                aria-labelledby="offcanvasExampleLabel">
                                                <div className="offcanvas-header">
                                                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                                                        Order Details
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        data-bs-dismiss="offcanvas"
                                                        aria-label="Close"
                                                    ></button>
                                                </div>
                                                <div className="offcanvas-body">
                                                    <div className="od-model">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-12">
                                                                <div className="ogt-d">
                                                                    Order Grand Total: ${itemSubTotal.toFixed(2)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-12">
                                                                <div className="pad-20">
                                                                    <div className="od-o-nv-main">
                                                                        <div className="od-o-nv">
                                                                            {modelData.customerOrderDateTime &&
                                                                                <div className="o-nv-d">
                                                                                    <div className="o-n">
                                                                                        Ordered on:
                                                                                    </div>
                                                                                    <div className="o-v">
                                                                                        <b>
                                                                                            {dateConverter(modelData.customerOrderDateTime).substring(
                                                                                                0,
                                                                                                3
                                                                                            )}
                                                                                        </b>
                                                                                        {dateConverter(modelData.customerOrderDateTime).substring(
                                                                                            3,
                                                                                            12
                                                                                        )}
                                                                                    </div>
                                                                                </div>}
                                                                            {modelData.customerOrderId && <div className="o-nv-d">
                                                                                <div className="o-n">
                                                                                    Order #:
                                                                                </div>
                                                                                <div className="o-v">
                                                                                    {modelData.customerOrderId}
                                                                                </div>
                                                                            </div>}
                                                                            {modelData.orderDetails && modelData.orderDetails.shippingMethod &&
                                                                                <div className="o-nv-d m-0">
                                                                                    <div className="o-n">
                                                                                        Shipping Method:
                                                                                    </div>
                                                                                    <div className="o-v">
                                                                                        {modelData.orderDetails && modelData.orderDetails.shippingMethod}
                                                                                    </div>
                                                                                </div>}
                                                                        </div>

                                                                        <div className="od-o-nv br-n">
                                                                            {modelData.transactionId &&
                                                                                <div className="o-nv-d">
                                                                                    <div className="o-n">
                                                                                        Transaction Id:
                                                                                    </div>
                                                                                    <div className="o-v">
                                                                                        {modelData.transactionId}
                                                                                    </div>
                                                                                </div>}
                                                                            {modelData.branchCode && <div className="o-nv-d">
                                                                                <div className="o-n">
                                                                                    Branch Code:
                                                                                </div>
                                                                                <div className="o-v">
                                                                                    {modelData.branchCode}
                                                                                </div>
                                                                            </div>}
                                                                            {modelData.customerWarehouseId &&
                                                                                <div className="o-nv-d m-0">
                                                                                    <div className="o-n">
                                                                                        Customer Warehouse:
                                                                                    </div>
                                                                                    <div className="o-v">
                                                                                        {modelData.customerWarehouseId}
                                                                                    </div>
                                                                                </div>}
                                                                        </div>
                                                                    </div>
                                                                    <hr className="hr-style"></hr>
                                                                    <div className="od-o-nv-main">
                                                                        <div className="od-o-nv">
                                                                            {modelData.agilityOrderId &&
                                                                                <div className="o-nv-d">
                                                                                    <div className="o-n">
                                                                                        Agility Order Id:
                                                                                    </div>
                                                                                    <div className="o-v">
                                                                                        {modelData.agilityOrderId && modelData.agilityOrderId}
                                                                                    </div>
                                                                                </div>}
                                                                            {Array.isArray(modelData.orderShipments) && modelData.orderShipments.length > 0 &&
                                                                                <div className="o-nv-d">
                                                                                    <div className="o-n">
                                                                                        Tracking #:
                                                                                    </div>
                                                                                    <div className="o-v">
                                                                                        {modelData.orderShipments[0].containers[0].trackingNumber}
                                                                                    </div>
                                                                                </div>}
                                                                            {modelData.orderDetails && modelData.orderDetails.agilityShipVia &&
                                                                                <div className="o-nv-d m-0">
                                                                                    <div className="o-n">
                                                                                        Agility Ship Via:
                                                                                    </div>
                                                                                    <div className="o-v">
                                                                                        {modelData.orderDetails && modelData.orderDetails.agilityShipVia}
                                                                                    </div>
                                                                                </div>}
                                                                        </div>

                                                                        <div className="od-o-nv br-n">
                                                                            {modelData.accountNumber &&
                                                                                <div className="o-nv-d">
                                                                                    <div className="o-n">
                                                                                        Customer Account Id:
                                                                                    </div>
                                                                                    <div className="o-v">
                                                                                        {modelData.accountNumber && modelData.accountNumber}
                                                                                    </div>
                                                                                </div>}
                                                                            {modelData.sourceSystem &&
                                                                                <div className="o-nv-d">
                                                                                    <div className="o-n">
                                                                                        Source System:
                                                                                    </div>
                                                                                    <div className="o-v">
                                                                                        {modelData.sourceSystem && modelData.sourceSystem}
                                                                                    </div>
                                                                                </div>}
                                                                            {modelData.shipToSequenceNumber &&
                                                                                <div className="o-nv-d m-0">
                                                                                    <div className="o-n">
                                                                                        Ship To Sequence Number:
                                                                                    </div>
                                                                                    <div className="o-v">
                                                                                        {modelData.shipToSequenceNumber && modelData.shipToSequenceNumber}
                                                                                    </div>
                                                                                </div>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="od-a-v-main">
                                                            <div className="row">
                                                                <div className="col-sm-6 col-md-4">
                                                                    <div className="od-a-v">
                                                                        <div className="od-a-v-d">
                                                                            <div className="od-n">
                                                                                Shipping Address
                                                                            </div>
                                                                            <div className="od-v">
                                                                                <span>{modelData.shipTo && modelData.shipTo.company}</span>
                                                                                <span>{modelData.shipTo && modelData.shipTo.addressLine1}</span>
                                                                                <span>{modelData.shipTo && modelData.shipTo.addressLine2}</span>
                                                                                <span>{modelData.shipTo && modelData.shipTo.city}{modelData.shipTo && modelData.shipTo.state && ","}
                                                                                    {modelData.shipTo && modelData.shipTo.state}  {modelData.shipTo && modelData.shipTo.country && ","}
                                                                                    {modelData.shipTo && modelData.shipTo.country}</span>
                                                                                <span>{modelData.shipTo && modelData.shipTo.zipCode}{modelData.billTo && modelData.shipTo.shipToPhone && ","}
                                                                                    {modelData.shipTo && modelData.shipTo.shipToPhone}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-md-4">
                                                                    <div className="od-a-v">
                                                                        <div className="od-a-v-d">
                                                                            <div className="od-n">
                                                                                Billing Address
                                                                            </div>
                                                                            <div className="od-v">
                                                                                <span>{modelData.billTo && modelData.billTo.company}</span>
                                                                                <span>{modelData.billTo && modelData.billTo.addressLine1}</span>
                                                                                <span>{modelData.billTo && modelData.billTo.addressLine2}</span>
                                                                                <span>{modelData.billTo && modelData.billTo.city} {modelData.billTo && modelData.billTo.state && ","}
                                                                                    {modelData.billTo && modelData.billTo.state}  {modelData.billTo && modelData.billTo.country && ","}
                                                                                    {modelData.billTo && modelData.billTo.country}</span>
                                                                                <span>{modelData.billTo && modelData.billTo.zipCode}{modelData.billTo && modelData.billTo.shipToPhone && ","}
                                                                                    {modelData.billTo && modelData.billTo.shipToPhone}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-md-4">
                                                                    <div className="od-a-v br-n">
                                                                        <div className="od-a-v-d">
                                                                            <div className="od-n">
                                                                                Customer Information
                                                                            </div>
                                                                            <div className="od-v">
                                                                                <span>{modelData.customerContactInformation && modelData.customerContactInformation.customerContactAddress && modelData.customerContactInformation.customerContactAddress.addressLine1}</span>
                                                                                <span>{modelData.customerContactInformation && modelData.customerContactInformation.customerContactAddress && modelData.customerContactInformation.customerContactAddress.addressLine2}</span>
                                                                                <span>{modelData.customerContactInformation && modelData.customerContactInformation.customerContactAddress && modelData.customerContactInformation.customerContactAddress.city} {modelData.customerContactInformation && modelData.customerContactInformation.customerContactAddress && modelData.customerContactInformation.customerContactAddress.state && ","}
                                                                                    {modelData.customerContactInformation && modelData.customerContactInformation.customerContactAddress && modelData.customerContactInformation.customerContactAddress.state}  {modelData.customerContactInformation && modelData.customerContactInformation.customerContactAddress && modelData.customerContactInformation.customerContactAddress.country && ","}
                                                                                    {modelData.customerContactInformation && modelData.customerContactInformation.customerContactAddress && modelData.customerContactInformation.customerContactAddress.country}</span>
                                                                                <span>{modelData.customerContactInformation && modelData.customerContactInformation.customerContactAddress && modelData.customerContactInformation.customerContactAddress.zipCode}{modelData.customerContactInformation && modelData.customerContactInformation.customerContactAddress && modelData.customerContactInformation.customerContactAddress.phone && ","}
                                                                                    {modelData.customerContactInformation && modelData.customerContactInformation.customerContactAddress && modelData.customerContactInformation.customerContactAddress.phone}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12 col-md-12">
                                                                <div className="heading-section m-0">
                                                                    <h2 className="fc-h2 m-0">Order Content & Shipment Details</h2>
                                                                </div>

                                                                <div className="oc-sd-rw jc-s">
                                                                    {Array.isArray(modelData.orderShipments) && modelData.orderShipments.length > 0 &&
                                                                        <div className="oc-sd-cm pe-48">
                                                                            <div className="s-d">
                                                                                <span>Shipped Date:</span>
                                                                                {
                                                                                    Array.isArray(modelData.orderShipments) && modelData.orderShipments.length > 0 &&
                                                                                    modelData.orderShipments[0].shipmentDetails.shippedDate
                                                                                }
                                                                            </div>
                                                                        </div>}
                                                                    {Array.isArray(modelData.orderShipments) && modelData.orderShipments.length > 0 &&
                                                                        <div className="oc-sd-cm pe-48">
                                                                            <div className="b-x">
                                                                                <span>BOX: </span>
                                                                                {
                                                                                    Array.isArray(modelData.orderShipments) && modelData.orderShipments.length > 0 &&
                                                                                    modelData.orderShipments[0].containers[0].containerIdentifier
                                                                                }
                                                                            </div>
                                                                        </div>}
                                                                    {Array.isArray(modelData.orderShipments) && modelData.orderShipments.length > 0 &&
                                                                        <div className="oc-sd-cm">
                                                                            <div className="es-d">
                                                                                <span>Expected Shipment Delivery Date: </span>
                                                                                {
                                                                                    Array.isArray(modelData.orderShipments) && modelData.orderShipments.length > 0 &&
                                                                                    modelData.orderShipments[0].shipmentDetails.estimatedDeliveryDate
                                                                                }
                                                                            </div>
                                                                        </div>}
                                                                </div>

                                                                {Array.isArray(modelData.orderLineItems) && modelData.orderLineItems.map((x: any, i: any) =>
                                                                (<div className="oc-sd-rw bt-c9">
                                                                    {/* <div className="oc-sd-cm">
                                                                        <img src="/images/orderlisting-image.png" />
                                                                    </div> */}
                                                                    <div className="oc-sd-cm">
                                                                        <span className="f-500">{x.productDescription}</span>
                                                                        <div className="id">
                                                                            <div className="id-cm">
                                                                                <span>Unit:</span>
                                                                                {x.quantity}
                                                                            </div>
                                                                            <div className="id-cm">
                                                                                <span>SKU:</span>
                                                                                {x.customerPartNumber}
                                                                            </div>
                                                                            <div className="id-cm">
                                                                                <span>Price:</span>
                                                                                {x.salesPrice}
                                                                            </div>
                                                                            {Array.isArray(modelData.orderShipments) && modelData.orderShipments.length > 0 &&
                                                                                <div className="id-cm">
                                                                                    <span>Tracking Number:</span>
                                                                                    {
                                                                                        Array.isArray(modelData.orderShipments) && modelData.orderShipments.length > 0 &&
                                                                                        modelData.orderShipments[0].containers[0].trackingNumber
                                                                                    }
                                                                                </div>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="oc-sd-cm" onClick={(e) => handleClick(e, x)}>
                                                                        <span className="info-icon">
                                                                            <i className="bi bi-info-circle"></i>
                                                                        </span>

                                                                    </div>

                                                                </div>))}
                                                            </div>


                                                        </div>
                                                        {Array.isArray(modelData.orderHistory) && modelData.orderHistory.length > 0 &&
                                                            <div className="row">
                                                                <div className="col-sm-12 col-md-12 mt-4">
                                                                    <div className="heading-section m-0">
                                                                        <h2 className="fc-h2">Order History</h2>
                                                                    </div>
                                                                    <table className="custom-table table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th><span className="th-title">Date</span></th>
                                                                                <th><span className="th-title">Logs</span></th>
                                                                                <th><span className="th-title">Modified User</span></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                modelData.orderHistory.map((x: any, i: any) => (
                                                                                    <tr>
                                                                                        <td>
                                                                                            {x.createdDate}
                                                                                        </td>
                                                                                        <td>
                                                                                            {x.comments}
                                                                                        </td>
                                                                                        <td>
                                                                                            {"System"}
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}


                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        }
                                                        <button
                                                            type="button"
                                                            className="btn-close-style"
                                                            data-bs-dismiss="offcanvas"
                                                            aria-label="Close"
                                                        >Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pb-3">
                                            Transaction Id : <strong>{item.transactionId}</strong>
                                        </div>

                                        {item.agilityOrderId && (
                                            <div className="pb-3">
                                                Agility Order Id: <strong>{item.agilityOrderId}</strong>
                                            </div>
                                        )}
                                        {Array.isArray(item.orderLineItems) &&
                                            item.orderLineItems.map((x: any, i: any) => (
                                                <React.Fragment key={i}>
                                                    <div className="orderlineitem">
                                                        {x.productName != null && x.productDescription != null ?
                                                            (<div style={{ paddingBottom: 5 }}>
                                                                <b>{x.productName}</b>
                                                            </div>) :
                                                            (<div style={{ paddingBottom: 5 }}>
                                                                <b>{x.agilityPartNumber}</b>
                                                            </div>)}

                                                        {/* <div style={{ paddingBottom: 10 }}>
                                                            {x.productDescription}
                                                        </div> */}
                                                        <div>
                                                            <b>QTY:{x.quantity}</b>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                        {/* {item.transactionDate && (
                    <div style={{ paddingBottom: "15px" }}>
                      <b>Order Received Date: {ShippingDate(item.transactionDate).substring(0,3)}</b>
                      {ShippingDate(item.transactionDate).substring(3,20)}
                    </div>
                  )} */}
                                    </td>

                                    <td className="c-td-3">
                                        <b>{item.orderDetails.agilityShipVia}</b>

                                        <br />
                                        <b>
                                            {item.orderDetails.expectedDeliveryDate &&
                                                "Expected Ship Date"}
                                        </b>
                                        <br />
                                        <b>
                                            {item.orderDetails.expectedDeliveryDate &&
                                                ShippingDate(
                                                    item.orderDetails.expectedDeliveryDate
                                                ).substring(0, 3)}
                                        </b>
                                        {item.orderDetails.expectedDeliveryDate &&
                                            ShippingDate(
                                                item.orderDetails.expectedDeliveryDate
                                            ).substring(3, 20)}
                                        <br />
                                        <br />
                                        <b>Received On</b>
                                        <br />

                                        <b>{dateConverter(item.transactionDate).substring(0, 3)}</b>
                                        {dateConverter(item.transactionDate).substring(3, 50)}

                                    </td>
                                    <td className="c-td-4">{item.branchCode}</td>
                                    <td className="c-td-5">{findstatus(item.statusCode)}

                                        {item.acknowledgementDate && (
                                            <div>
                                                <b>Last Updated On: <br />{dateConverter(item.acknowledgementDate).substring(0, 3)}</b>
                                                {dateConverter(item.acknowledgementDate).substring(3, 50)}
                                            </div>
                                        )}
                                    </td>
                                    <td className="c-td-6">{item.accountNumber}</td>
                                    <td className="c-td-7">
                                        <div className="orderlisting_actions">
                                            <button
                                                onClick={() => handleClickDetails(item)}
                                                className="button-order"
                                            >
                                                Details
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <MyOrderlistAttributes attributes={attributes} />
                </Popover>
                {nodatafound && (
                    <div className="no_data_found">
                        <h2>No data found !</h2>
                    </div>
                )}
                <div className="pagination_container">
                    <div>Items Per Page:{pageSize}</div>
                    <div>
                        Showing {Math.min((currentPage - 1) * pageSize + 1, totalRecordCount)}{" "}
                        - {Math.min(currentPage * pageSize, totalRecordCount)} of{" "}
                        {totalRecordCount} results
                    </div>
                    <div>
                        <Pagination
                            count={Math.ceil(totalRecordCount / pageSize)}
                            page={currentPage}
                            variant="outlined"
                            className="customize_pagination"
                            shape="rounded"
                            onChange={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Order;