import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { SSL_OP_COOKIE_EXCHANGE } from "constants";
import SideBar from "../sidebar";
import { useHistory } from "react-router";
import { CreateinitialLoadermessage, Loadermessage } from "../../dataservices/model/loadermessage";
import { APISettings } from "../../dataservices/model/apisettings";
import { DeleteWH, GetmpLocationsANDagilityBranch, Getrolepermissionbyuserid, GetWebhookList, SaveWebhook } from "../../dataservices/action/marketplace/marketplace";
import { PaginationValues } from "../pagenation/pageflow";
import { jobDetails } from "../../dataservices/model/jobDetails";
import Loader from "../loader";
import { confirmAlert } from "react-confirm-alert";
import { channellist } from "../../dataservices/model/channellist";


const webhook = () => {

    const history = useHistory();
    const initialSettings: APISettings[] = new Array<APISettings>();
    const [apiSettings, SetApiSettings] = useState(initialSettings);

    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const store = useSelector((state: any) => { return state })
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [url, setURL] = useState("");
    const [eventId, setEventId] = useState(0);
    const [branchId, setbranchId] = useState("");

    const [activePage, setActivePage] = useState(0);
    const [TotalCount, setTotalCount] = useState(0);
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [PageSizeList, setPageSizeList] = useState([50, 100, 200]);
    const [SearchValue, setSearchValue] = useState("");
    var intweblist: jobDetails[] = new Array<jobDetails>();
    const [webList, setwebList] = useState(intweblist);
    const [isLoader, setisLoader] = useState(false);


    const [AWebhook, setAWebhook] = useState(false);
    const [EWebhook, setEWebhook] = useState(false);
    const [DWebhook, setDWebhook] = useState(false);
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 22:
                                if (result[i].canadd == "1") {
                                    setAWebhook(true);
                                }
                                if (result[i].canedit == "1") {
                                    setEWebhook(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDWebhook(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])

    useEffect(() => {
        setisLoader(true);
        GetWebhookList(Page, PageSize, function (result: any) {
            setwebList(result);
            setisLoader(false);
        })
    }, [])

    const refreshList = () => {
        setisLoader(true);
        GetWebhookList(Page, PageSize, function (result: any) {
            setwebList(result);
            setisLoader(false);
        })
    }
    const backUserDB = () => {
        history.push('/userdashboard');
    }
    const changeName = (e: any) => {
        setName(e.target.value)
    }
    const changeURl = (e: any) => {
        setURL(e.target.value)
    }
    const changeType = (e: any) => {
        setEventId(e.target.value)
    }
    const changeBranch = (e: any) => {
        setbranchId(e.target.value)
    }

    const SaveWebHook = (e: any) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load)
        if (name != "" && url != "" && eventId > 0) {
            SaveWebhook(name, url, eventId, branchId, function (result: any) {
                if (result.isSuccess) {
                    let result = CreateinitialLoadermessage();
                    result.isSuccess = true;
                    result.message = "Saved Successfully"
                    Setisloader(result);
                    refreshList();
                    history.go(0);
                }
            });
        }
        else {
            alert("Enter name and URL and Select Event");
        }
    }
    var intbranchlist: channellist[] = new Array<channellist>();
    const [branchlist, setbranchlist] = useState(intbranchlist);   

    useEffect(() => {
        setisLoader(true);
        GetmpLocationsANDagilityBranch(2, function (result: any) {
            setbranchlist(result);
            setisLoader(false);

        })
    }, [])


    const Pagenation = () => {
        let Pagination = new PaginationValues();
        Pagination.TotalItemCount = TotalCount;
        Pagination.CurrentPage = Page;
        Pagination.PageSlide = 2;
        Pagination.IsSort = true;
        Pagination.PageSize = PageSize;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page - 1) * PageSize) + 1}    - {((((Page - 1) * PageSize) + 1) + PageSize) > TotalCount ? TotalCount : ((((Page - 1) * PageSize)) + PageSize)} of {TotalCount} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">

                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                                {
                                    Pagination.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination.Pages().map((x, i) =>
                                        x == Page ?
                                            (
                                                <li className={`page-item  ${activePage == x ? 'active' : null}`} key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className="page-link" onClick={() => SetPage(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>


            </div>
        )
    }

    const SetPageSize = (size: number) => {
        setPageSize(size);
        //refreshList();
        setisLoader(true);
        GetWebhookList(Page, size, function (result: any) {
            setwebList(result);
            setisLoader(false);
        })
    }

    const SetPage = (page: number) => {
        setPage(page);
        setActivePage(page);
        setisLoader(true);
        GetWebhookList(page, PageSize, function (result: any) {
            setwebList(result);
            setisLoader(false);
        })
        //refreshList();
    }
    const DeleteAlert = (id: number) => {
        confirmAlert({
            title: 'Delete Webhook',
            message: 'Are you sure you want to delete this Webhook ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Delete(id)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const Delete = (id: number) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        DeleteWH(id, function (result: any) {
            if (result.isSuccess) {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "Webhook deleted successfully"
                Setisloader(result);
                refreshList();
            }
        })
    }
    return (
        <React.Fragment>
            {isLoader ? (<Loader isActive={isLoader} />) : (null)}
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Manage Webhooks
                    </li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12">
                    <div className="heading-section">
                        <h1>Manage Webhooks</h1>
                        <div>
                            <button type="button" className="btn btn-primary ms-2" onClick={backUserDB}>Back</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label className="form-label">Name</label>
                        <input className="form-control" type="text" value={name} onChange={changeName} />
                    </div>
                    <div className="form-group mb-3">
                        <label className="form-label">URL</label>
                        <input className="form-control" type="text" value={url} onChange={changeURl} />
                    </div>
                    <div className="form-group mb-3" >
                        <label className="form-label">
                            Select Event
                        </label>
                        <select className="form-select" aria-label="Default select example" onChange={changeType}>
                            <option selected>Select Option</option>
                            <option value="1">Inventory Updated</option>
                        </select>
                    </div>
                    <div className="form-group mb-3" >
                        <label className="form-label">
                            Select Branch
                        </label>
                        <select className="form-select" onChange={(e) =>changeBranch(e)} >
                            <option selected value={0}>Select</option>
                            {
                                branchlist.map((item, index) => (
                                    <option value={item.resultid}>{item.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="mb-5">
                        <input className="btn btn-primary" disabled={AWebhook != true ? true : false} type="button" value="Create Webhook" onClick={SaveWebHook} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    <div className="heading-section">
                        <h1>
                            Webhook List
                        </h1>
                    </div>
                    <table className="table table-bordered">
                        <thead className="table-light">
                            <tr>
                                <th scope="col">
                                    ID
                                </th>
                                <th scope="col">
                                    Name
                                </th>
                                <th scope="col">
                                    URl
                                </th>
                                <th scope="col">
                                    Event
                                </th>
                                <th scope="col">
                                    Branch
                                </th>
                                <th scope="col">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                webList != null ? (
                                    webList.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                {item.id}
                                            </td>
                                            <td>
                                                {item.entityName}
                                            </td>
                                            <td>
                                                {item.searchValue}
                                            </td>
                                            <td>
                                                {item.jobName}
                                            </td>
                                            <td>
                                                {item.locationname}
                                            </td>
                                            <td>
                                                <button className="btn btn-sm btn-danger" data-toggle="tooltip" disabled={DWebhook != true ? true : false} data-placement="top" title="Delete Rule" onClick={(e) => DeleteAlert(item.id)} ><i className="bi bi-trash-fill"></i></button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (null)
                            }
                        </tbody>
                    </table>
                    <Pagenation />
                </div>

            </div>

        </React.Fragment >
    )
}
export default webhook;


