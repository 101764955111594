import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { UserRegister } from "../../dataservices/action/account/register";
import { user, createinitialUser, moduleinfo } from "../../dataservices/model/user";
import { useSelector } from 'react-redux'
import { GetUserLoad, DeleteUser, SaveEditUser } from "../../dataservices/action/users/usersload";
import SideBar from "../sidebar";
import { ValidateEmail } from '../../dataservices/helper/componenthelpers';
import ASuccess from "../alretbox/success";
import { PaginationValues } from "../pagenation/pageflow";
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
import Loader from "../loader";
import AFailed from "../alretbox/failed";
import { EALREADY } from "constants";
import { debug } from "console";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
const LoadUsers = () => {
    var intusers: user[] = new Array<user>()
    const history = useHistory();
    const [users, setUsers] = useState(intusers);
    const [neusers, SetNEUser] = useState(createinitialUser());
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    const [showdownloadPopup, SetuserEditadd] = useState(false);
    const [islogin, setislogin] = useState(false)
    const store = useSelector((state: any) => { return state });
    const [TotalCount, setTotalCount] = useState(0);
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [PageSizeList, setPageSizeList] = useState([50, 100, 200]);
    const [SearchValue, setSearchValue] = useState("");
    const [uname, setuname] = useState("");
    const [uemail, setemail] = useState("");
    const [upwd, setpwd] = useState("");
    const [activePage,setActivePage]=useState(0)

    useEffect(() => {
        setislogin(true);
        GetUserLoad(Page, PageSize, function (result: user[]) {
            if (result != null) {
                setUsers(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page)
            }
        })
    }, [])


    const Pagenation = () => {
        let Pagination = new PaginationValues();
        Pagination.TotalItemCount = TotalCount;
        Pagination.CurrentPage = Page;
        Pagination.PageSlide = 2;
        Pagination.IsSort = true;
        Pagination.PageSize = PageSize;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page - 1) * PageSize) + 1}    - {((((Page - 1) * PageSize) + 1) + PageSize) > TotalCount ? TotalCount : ((((Page - 1) * PageSize)) + PageSize)} of {TotalCount} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">

                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                                {
                                    Pagination.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination.Pages().map((x, i) =>
                                        x == Page ?
                                            (
                                                <li className={`page-item  ${activePage==x ? 'active' : null}` } key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className="page-link" onClick={() => SetPage(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }

    const SetPageSize = (size: number) => {
        setPageSize(size);
        RefreshQuote();
    }

    const SetPage = (page: number) => {
        setPage(page);
        setActivePage(page)
        RefreshQuote();
    }

    const RefreshQuote = () => {
        let PoNumber: string = "";
        let OrderNumber: number = 0;
        let InvoiceNumber: string = "";
        if (SearchValue != "") {
        }
        GetUserLoad(Page, PageSize, function (result: user[]) {
            if (result != null) {
                setUsers(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
            }
        })
    }


    const CloseLOginPopup = () => {
        setislogin(false);
    }
const DeleteAlert=(id:number,emailId:string)=>{
    confirmAlert({
        title: 'Delete User',
        message: 'Are you sure you want to delete the user '+emailId+'?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => Delete(id)
          },
          {
            label: 'No',
            onClick:()=>{}
          }
        ]
      });
}
    const Delete = (id: number) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        DeleteUser(id, function (result: user) {
            if (result.isSuccess) {
                //alert("User deleted successfully")
                //let updateduser = users.filter((user: { id: number; }) => user.id != id);
                //setUsers(updateduser);
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "User deleted successfully"
                Setisloader(result);
                let updateduser = users.filter((user: { id: number; }) => user.id != id);
                setUsers(updateduser);
                history.go(0);
            }
        })
    }

    const EnableuserEditAdd = (id: number) => {
        debugger;
        if (id != 0) {
            let user = users.filter(x => x.id == id)[0];

            setuname(user.userName);
            setemail(user.emailId);
            setpwd(user.password);

            SetNEUser(user);
        }
        SetuserEditadd(true)
    }
    const Saveuser = () => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        let objrole: moduleinfo[] = new Array<moduleinfo>();
        if (neusers.emailId != "" && neusers.password != "") {
            debugger;
            if (ValidateEmail(neusers.emailId)) {
                SaveEditUser(store.user.customerId, neusers, objrole, false, function (result: user) {
                    if (result.isSuccess) {
                        //alert("User saved successfully")
                        let result = CreateinitialLoadermessage();
                        result.isSuccess = true;
                        result.message = "Saved Successfully"
                        Setisloader(result);
                        //history.push(`/userindex`);
                        history.go(0);
                    }
                })
            } else {
                let result = CreateinitialLoadermessage();
                result.isSuccess = true;
                result.message = "please enter correct email Id"
                Setisloader(result);
            }
        } else {
            let result = CreateinitialLoadermessage();
            result.isSuccess = true;
            result.message = "*Please fill required field"

        }

    }
    const CloseUserPopup = () => {
        SetuserEditadd(false)
        SetNEUser(createinitialUser())
    }

    const ChangEmail = (e: any) => {
        neusers.emailId = e.target.value;
        setemail(e.target.value);
        SetNEUser(neusers);
        //SetNEUser((prev: any) => ({ ...prev, emailId: e.target.value }))
        //alert(e.target.value);
    };
    const ChangeName = (e: any) => {
        neusers.userName = e.target.value;
        setuname(e.target.value);
        SetNEUser(neusers);
        //SetNEUser((prev: any) => ({ ...prev, name: e.target.value }))
        //alert(target.value);
    };
    const ChangePassword = (e: any) => {
        //alert(e.target.value);
        neusers.password = e.target.value;
        setpwd(e.target.value);
        SetNEUser(neusers);
        //SetNEUser((prev: any) => ({ ...prev, password: e.target.value }))       
    };
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <SideBar />
                </div>
                {

                    isloader.isLoader ? (<Loader isActive={isloader} />) : (null)
                }
                {
                    showdownloadPopup != true ? (
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h1>Manage Users</h1>
                                <button type="button" className="btn btn-primary" onClick={(e) => EnableuserEditAdd(0)}>Add User</button>
                            </div>

                            <table className="table table-bordered">
                                <thead className="thead-light">
                                    <tr>
                                        {/* <th scope="col">
                                            User Name
                                        </th> */}
                                        <th scope="col">
                                            Email Id
                                        </th>
                                        <th scope="col">
                                            IsActive
                                        </th>
                                        <th scope="col">
                                            Createdon
                                        </th>
                                        <th scope="col">
                                            Edit
                                        </th>
                                        <th scope="col">
                                            Delete
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        users != null ? (
                                            users.map((item, index) => (
                                                <tr key={index}>
                                                    {/* <td>
                                                        {item.userName}
                                                    </td> */}
                                                    <td>
                                                        {item.emailId}
                                                    </td>
                                                    <td>
                                                        {item.isActive == true ? (<span>Active</span>) : (<span>In Active</span>)}
                                                    </td>
                                                    <td>
                                                        {item.createdon}
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-sm btn-primary" onClick={(e) => EnableuserEditAdd(item.id)}><i className="bi bi-pencil-square"></i></button>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-sm btn-danger" data-toggle="tooltip" data-placement="top" title="Delete User" onClick={(e) => DeleteAlert(item.id,item.emailId)} ><i className="bi bi-trash-fill"></i></button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (null)
                                    }
                                </tbody>
                            </table>
                            <Pagenation />
                        </div>
                    )
                        : (
                            <div className="col-md-9">
                                <h1>User details</h1>
                                <div className="row">
                                    <div className="col-sm-12 col-mvd-12">
                                        <div className="form-group mb-3" hidden>
                                            <label className="form-label">User Name</label>
                                            <input className="form-control" type="text" onChange={ChangeName} value={uname} />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="form-label">Email Id</label>
                                            <input className="form-control" type="text" onChange={ChangEmail} value={uemail} />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="form-label">Password</label>
                                            <input className="form-control" type="password" onChange={ChangePassword} value={upwd} />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-primary me-2" onClick={Saveuser}>Save</button>
                                    <button type="button" className="btn btn-primary" onClick={CloseUserPopup} >Close</button>
                                </div>
                            </div>)
                }

                <div onClick={CloseUserPopup}>
                    {
                        isloader.isfailed ? (
                            <AFailed message={isloader.message} />
                        ) : (null)
                    }
                </div>
                <div onClick={CloseUserPopup}>
                    {
                        isloader.isSuccess ? (
                            <ASuccess message={isloader.message} />
                        ) : (null)
                    }
                </div>
            </div>
        </React.Fragment>
    )

}

export default LoadUsers;