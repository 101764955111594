export default class Globals {
    public static IsAuthenticated = false;
    public static IsUAdmin = false;
    public static CustomerAccessLevel = [];
    public static userName = "";
    public static IsMagento = false;
    public static IsAmazon = false;
    public static IsAgility = false;
    public static accountid = "";

}