import { filtersobjs } from "./filters"
import { filterentities } from "./mpproducts"

export interface jobDetails{
    id:number,
    entityId:string,
    type:string,
    createdby:number,
    createdDate:Date,
    completedDate:Date,
    status:string,
    stage:string,
    new: number,
    failed: number,
    entityName:string,
    modified:number,
    isActive:boolean,
    noChange:number,
    index: number;
    recordPerPage: number;
    searchValue: string;
    totalRecord: number;   
    entityTypeId : number,
    jobName: string,
    errorMessage: string,
    minimumQty: number,
    ismarkdown: boolean,
    rulevalue: string,
    uom: string,
    entities: Array<entities>,
    ismporloc: boolean,
    accountname: string,
    locationname: string,
    reservedqty: string,
    startdate: Date,
    enddate: Date,
    searchdate: string,
    searchenddate: string,
    validity: string,
    transactionId: string,
    utcstartdate: string,
    utcenddate: string,
    supplierkey: string,
    brand: string,
    productgrp: string,
    pricemax: string,
    pricemin: string,
    mfg: string,
    pgroup: string,
    pmax: string,
    pmin: string,
    pmfg: string
    filterobj: Array<filtersobjs>,
    filterentities: Array<filterentities>,
    ischeckall:boolean
}

export interface entities {
    entityid: string,
    uom: string,
    isactive: boolean
}




export const createinitialjobDetails = (): jobDetails => {
    return ({
        id:0,
        entityId:"",
        type:"",
        createdby:0,
        createdDate:new Date,
        completedDate:new Date,
        status:"",
        stage:"",
        new: 0,
        failed: 0,
        entityName:"",
        modified:0,
        isActive:false,
        noChange:0,
        index: 1,
        recordPerPage: 50,
        searchValue: "",
        totalRecord: 0,
        entityTypeId: 0,
        jobName: "",
        errorMessage: "",
        minimumQty: 0,
        ismarkdown: false,
        rulevalue: "",
        uom: "",
        entities: [],
        ismporloc: false,
        accountname: "",
        locationname: "",
        reservedqty: "",
        startdate: new Date,
        enddate: new Date,
        searchdate: "",
        searchenddate: "",
        validity: "",
        transactionId: "",
        utcstartdate: "",
        utcenddate: "",
        supplierkey: "",
        brand: "",
        productgrp: "",
        pricemax: "",
        pricemin: "",
        mfg: "",        
        pgroup: "",
        pmax: "",
        pmin: "",
        pmfg: "",
        filterobj: [],
        filterentities:[],
        ischeckall:false
    })
}