import { fail, strict } from "assert"
import { Data } from "popper.js"
import { filtersobjs } from "./filters"


export interface products {
    id: number,
    sku: string,
    title: string,
    asin: string,
    isActive: boolean,
    index: number;
    recordPerPage: number;
    searchValue: string;
    totalRecord: number;
    uom: string;
    qty: string;
    location: string;
    branch: string;
    displayDescription: string;
    availableinventory: string;
    entityTypeId: number,
    msku: string,
    upc: string
    amazonDate?: Date,
    marketplacename: string,
    accountnumber: string,
    agilityInsertedDate?: Date,
    inventoryQty: string,
    isselected: boolean,
    accountid: string,
    isselectall: boolean,
    fromuom: string,
    touom: string,
    activestatus: string,
    supplierid: string,
    brand: string,
    productgrp: string,
    pgroup: string,
    pricemax: string,
    pricemin: string,
    mfg: string,    
    pmax: string,
    pmin: string,
    pmfg: string,
    filterobj: Array<filtersobjs>,
    invset: string,
    fillist: Array<string>,
    filterentities: Array<filterentities>,
    ischangefilter: boolean,
    rtype: number,
    rname: string,
    rfjson: string,
    rrjson: string,
    rtname: string,
    rDate?: Date
    rcustomers: string,
    classfication: string,
    ltlonly: string,
    nontradeitemflag: string,
    ftname:string
}
export interface filterentities {
    id: string
    
}
export const createinitialProducts = (): products => {
    return ({
        id: 0,
        sku: "",
        title: "",
        asin: "",
        index: 1,
        recordPerPage: 50,
        searchValue: "",
        totalRecord: 0,
        isActive: false,
        uom: "",
        qty: "",
        location: "",
        branch: "",
        displayDescription: "",
        availableinventory: "",
        entityTypeId: 0,
        msku: "",
        upc: "",
        amazonDate: new Date,
        marketplacename: "",
        accountnumber: "",
        agilityInsertedDate: new Date,
        inventoryQty: "",
        isselected: false,
        accountid: "",
        isselectall: false,
        fromuom: "",
        touom: "",
        activestatus: "",
        supplierid: "",
        brand: "",
        productgrp: "",
        pricemax: "",
        pricemin: "",
        mfg: "",
        pgroup: "",
        pmax: "",
        pmin: "",
        pmfg: "",
        filterobj: [],
        invset: "",
        fillist: [],
        ischangefilter: false,
        filterentities: [],
        rtype: 0,
        rname: "",
        rfjson: "",
        rrjson: "",
        rtname: "",
        rcustomers: "",
        rDate: new Date,
        classfication: "",
        ltlonly: "",
        nontradeitemflag:"",
        ftname:""
    })
}

