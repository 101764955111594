import { Action, Reducer } from 'redux';

// -----------------
// STATE - Defines the structure of the OrderListing state.

export interface OrderListState {
    shippingMethodList: List[];
    branchCodeList: List[];
    statusList: List[];
    customerCodeList: List[];
    warehouseList: List[];
}
;
interface List {
    title: string,
    value: string,
}
// Initial state
const initialState: OrderListState = {
    shippingMethodList: [],
    branchCodeList: [],
    statusList: [],
    customerCodeList: [],
    warehouseList: [],
};

// -----------------
// ACTIONS - Define distinct action types and structures for each state property.

export interface SetShippingMethodListAction {
    type: 'SET_SHIPPING_METHOD_LIST';
    payload: List[];
}
export interface SetBranchCodeListAction {
    type: 'SET_BRANCH_CODE_LIST';
    payload: List[];
}
export interface SetStatusListAction {
    type: 'SET_STATUS_LIST';
    payload: List[];
}
export interface SetCustomerCodeListAction {
    type: 'SET_CUSTOMER_CODE_LIST';
    payload: List[];
}
export interface SetWarehouseListAction {
    type: 'SET_WAREHOUSE_LIST';
    payload: List[];
}

// Declare the KnownAction type to include all action interfaces.
export type KnownAction =
    | SetShippingMethodListAction
    | SetBranchCodeListAction
    | SetStatusListAction
    | SetCustomerCodeListAction
    | SetWarehouseListAction

// -----------------
// ACTION CREATORS - Create functions to dispatch actions for each state property.

export const actionCreatorsList = {
    setShippingMethodList: (list: List[]) => ({
        type: 'SET_SHIPPING_METHOD_LIST',
        payload: list
    } as SetShippingMethodListAction),
    setBranchCodeList: (list: List[]) => ({
        type: 'SET_BRANCH_CODE_LIST',
        payload: list
    } as SetBranchCodeListAction),
    setStatusList: (list: List[]) => ({
        type: 'SET_STATUS_LIST',
        payload: list
    } as SetStatusListAction),
    setCustomerCodeList: (list: List[]) => ({
        type: 'SET_CUSTOMER_CODE_LIST',
        payload: list
    } as SetCustomerCodeListAction),
    setWarehouseList: (list: List[]) => ({
        type: 'SET_WAREHOUSE_LIST',
        payload: list
    } as SetWarehouseListAction),
};

// -----------------
// REDUCER - Handles state updates based on action type.

export const OrderListReducer: Reducer<OrderListState> = (
    state: OrderListState | undefined = initialState,
    incomingAction: Action
): OrderListState => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'SET_SHIPPING_METHOD_LIST':
            return { ...state, shippingMethodList: action.payload };
        case 'SET_BRANCH_CODE_LIST':
            return { ...state, branchCodeList: action.payload };
        case 'SET_STATUS_LIST':
            return { ...state, statusList: action.payload };
        case 'SET_CUSTOMER_CODE_LIST':
            return { ...state, customerCodeList: action.payload };
        case 'SET_WAREHOUSE_LIST':
            return { ...state, warehouseList: action.payload };
        default:
            return state || initialState;
    }
};