import React, { useEffect, useState } from "react";

import { generatePath, Link } from "react-router-dom";
import { NavLink } from "reactstrap";
import { useHistory } from "react-router-dom";
import Globals from "../dataservices/global";
import { moduleinfo } from "../dataservices/model/user";
import { GetViewPermission } from "../dataservices/action/account/register";
import { channellist } from "../dataservices/model/channellist";
import { GetAllMarketplaces } from "../dataservices/action/marketplace/marketplace";

const UserSideBar = (props: any) => {
    const [isSyncSubMenu, setisSyncSubMenu] = useState(false);
    const history = useHistory();
    const accountId = props.id;
    const [magentoConnect, setmagentoConnect] = useState(false);
    const [amazonMp, setamazonMp] = useState(false);

    var intAcclist: channellist[] = new Array<channellist>();
    const [acclist, setacclist] = useState(intAcclist);

    var vperList: moduleinfo[] = new Array<moduleinfo>();
    const [VPList, setVPList] = useState(vperList);
    const [isLoader, setisLoader] = useState(false);
    const accid = Globals.accountid;
    const [setup, setissetup] = useState("/setup/" + accountId);
    const [product, setisproduct] = useState("/productsindex/" + accountId);
    const [logs, setislogs] = useState("/mpjoblist/" + accountId);
    const [orderlisting] = useState("/orderlisting/" + accountId);
    const [activeclass, setactiveclass] = useState("nav-link active");
    const [deactiveclass, setdeactiveclass] = useState("nav-link");
    const [issetup, setsetup] = useState(false);
    const [isproduct, setproduct] = useState(false);
    const [islogs, setlogs] = useState(false);

    //view
    const [VCategoryAttributes, setVCategoryAttributes] = useState(false);
    const [VAPIsettings, setVAPIsettings] = useState(false);
    const [VMagentoSync, setVMagentoSync] = useState(false);
    const [VInriverSync, setVInriverSync] = useState(false);
    const [VProducts, setVProducts] = useState(false);
    const [VInventoryRules, setVInventoryRules] = useState(false);
    const [Vlogs, setVlogs] = useState(false);
    const [VSettings, setVSettings] = useState(false);
    const [VOrderListing, setVOrderListing] = useState(true);
    const [accname, setaccname] = useState("");

    const [userpath, setuserpath] = useState("/userslist");

    const [rolepath, setrolepath] = useState("/roleslist");

    let magentoCnt = Globals.IsMagento;

    useEffect(() => {
        let expiresAt = localStorage.getItem("IsAuthendicated");
        if (expiresAt !== "true") {
            history.push(`/joblist`);
        }
    }, []);

    useEffect(() => {
        setisLoader(true);
        GetAllMarketplaces(function (result: any) {
            if (result.length > 0) {
                setacclist(result);
                for (var i = 0; i < result.length; i++) {
                    if (result[i].customerId == Number(accountId)) {
                        setaccname(result[i].message);
                    }
                }

                setisLoader(false);
            }
        });
    }, []);

    useEffect(() => {
        setisLoader(true);
        GetViewPermission(function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].modulename);
                        switch (result[i].moduleid) {
                            case 1:
                                if (result[i].permission == "1") {
                                    setVCategoryAttributes(true);
                                }
                                break;

                            case 2:
                                if (result[i].permission == "1") {
                                    setVAPIsettings(true);
                                }
                                break;

                            case 3:
                                if (result[i].permission == "1") {
                                    setVMagentoSync(true);
                                }
                                break;

                            case 4:
                                if (result[i].permission == "1") {
                                    setVInriverSync(true);
                                }
                                break;

                            case 5:
                                if (result[i].permission == "1") {
                                    setVProducts(true);
                                }
                                break;

                            case 6:
                                if (result[i].permission == "1") {
                                    setVInventoryRules(true);
                                }
                                break;

                            case 7:
                                if (result[i].permission == "1") {
                                    setVlogs(true);
                                }
                                break;

                            case 8:
                                if (result[i].permission == "1") {
                                    setVSettings(true);
                                }
                                break;
                            case 9:
                                if (result[i].permission == "1") {
                                    setVOrderListing(true);
                                }
                                break;
                        }
                    }
                }

                setVPList(result);
            }
        });
    }, []);

    const Showhidesubmenu = () => {
        setisSyncSubMenu(!isSyncSubMenu);
    };

    const IsActiveMenu = () => {
        const allWithClass = Array.from(document.querySelectorAll("nav-link"));

        allWithClass.forEach((element) => {
            element.classList.remove("active");
        });
    };
    const handleProceed = () => {
        history.push(generatePath("/setup/:accid", { accid }));
    };

    return (
        <div>
            <div className="sidebar-section">
                <ul className="nav flex-column">
                    <>
                        {<li className="nav-item b-l-0"></li>}

                        <li className="nav-item">
                            <Link to={userpath} className={activeclass}>
                                <i className="bi bi-file-earmark-ppt-fill"></i>Users
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={rolepath} className={activeclass}>
                                <i className="bi bi-person-lines-fill"></i>Roles
                            </Link>
                        </li>
                    </>
                </ul>
            </div>
        </div>
    );
};

export default UserSideBar;
