import React, { useEffect, useState } from "react";
import { job, createinitialjob } from "../../dataservices/model/joblist";
import SideBar from "../sidebar";
import { useDispatch, useSelector } from 'react-redux'
import { GetTaskDetails, ExportExcel, FailedjobRetry } from "../../dataservices/action/job/joblist";
import { jobDetails, createinitialjobDetails } from "../../dataservices/model/jobDetails";
import { PaginationValues } from "../pagenation/pageflow";
import { useHistory } from 'react-router-dom';
import Loader from "../loader";
import { Getrolepermissionbyuserid } from "../../dataservices/action/marketplace/marketplace";
const TakDetails = (props: any) => {
    const dispatch = useDispatch();
    var intjob: jobDetails[] = new Array<jobDetails>();
    const [tasklist, SetTasklist] = useState(intjob);
    const jobId = props.location.state;
    const [TotalCount, setTotalCount] = useState(0);
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [PageSizeList, setPageSizeList] = useState([50, 100, 200]);
    const [entityType, setentityType] = useState(0);
    const [entityValue, setentityValue] = useState("");
    const [taskName, settaskName] = useState("");
    const [statusValue, setstatusValue] = useState("All");
    const [statusId, setstatusId] = useState(0);
    const [isLoader, setisLoader] = useState(false);
    const [activePage,setActivePage]=useState(0);
    const [AIMsync, setAIMsync] = useState(false);
    const [EIMsync, setEIMsync] = useState(false);
    const [DIMsync, setDIMsync] = useState(false);
    const [CSIMsync, setCSIMsync] = useState(false);
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 6:
                                if (result[i].canadd == "1") {
                                    setAIMsync(true);
                                }
                                if (result[i].canedit == "1") {
                                    setEIMsync(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDIMsync(true);
                                }
                                if (result[i].cansync == "1") {
                                    setCSIMsync(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])
    const history = useHistory();
    useEffect(() => {
        setisLoader(true);
        GetTaskDetails(jobId, Page, PageSize, entityType, statusId, function (result: jobDetails[]) {
            if (result != null && result.length !== 0) {
                SetTasklist(result);
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                settaskName(result[0].jobName);
                setActivePage(Page)
            } else {
                SetTasklist(intjob);
                setTotalCount(0);
                setPage(Page);
                setPageSize(PageSize);
                settaskName(taskName);
                setActivePage(Page)
            }
            setisLoader(false);
        })
    }, [entityType, statusId])

    const Pagenation = () => {
        let Pagination = new PaginationValues();
        Pagination.TotalItemCount = TotalCount;
        Pagination.CurrentPage = Page;
        Pagination.PageSlide = 2;
        Pagination.IsSort = true;
        Pagination.PageSize = PageSize;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page - 1) * PageSize) + 1}    - {((((Page - 1) * PageSize) + 1) + PageSize) > TotalCount ? TotalCount : ((((Page - 1) * PageSize)) + PageSize)} of {TotalCount} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">

                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                                {
                                    Pagination.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination.Pages().map((x, i) =>
                                        x == Page ?
                                            (
                                                <li className={`page-item  ${activePage==x ? 'active' : null}` } key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className="page-link" onClick={() => SetPage(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
    const SetPageSize = (size: number) => {
        setisLoader(false);
        GetTaskDetails(jobId, Page, size, entityType, statusId, function (result: jobDetails[]) {
            if (result != null) {
                SetTasklist(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setisLoader(true);
            }
        })
    }

    const SetPage = (page: number) => {
        setisLoader(true);
        GetTaskDetails(jobId, page, PageSize, entityType, statusId, function (result: jobDetails[]) {
            if (result != null) {
                SetTasklist(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(page)
                setisLoader(false);

            }
        })
    }

    const ExportExcelforTask = () => {
        ExportExcel(jobId, 1, 50, entityType, statusId, function (result: jobDetails[]) {
            if (result != null) {
                SetTasklist(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setisLoader(true);
            }
        })
    }

    const ChangeEntity = (e: any) => {

        switch (e.target.value) {
            case "Category":
                setentityValue(e.target.value);
                setentityType(1);
                setPage(1)
                break;
            case "Product":
                setentityValue(e.target.value);
                setentityType(2);
                setPage(1)
                break;
            case "Item":
                setentityValue(e.target.value);
                setentityType(3);
                setPage(1)
                break;
            default:
                setentityValue("All");
                setentityType(0);
                setPage(1)
        }
    }
    const ChangeStatus = (e: any) => {
        switch (e.target.value) {
            case "New":
                setstatusValue(e.target.value);
                setstatusId(1);
                setPage(1);
                setPageSize(50);
                break;
            case "Processing":
                setstatusValue(e.target.value);
                setstatusId(2);
                setPage(1);
                setPageSize(50);
                break;
            case "Finished":
                setstatusValue(e.target.value);
                setstatusId(3);
                setPage(1);
                setPageSize(50);
                break;
            case "Failed":
                setstatusValue(e.target.value);
                setstatusId(4);
                setPage(1);
                setPageSize(50);
                break;
            case "Cancelled":
                setstatusValue(e.target.value);
                setstatusId(5);
                setPage(1);
                setPageSize(50);
                break;
            default:
                setstatusValue("All");
                setstatusId(0);
                setPage(1);
                setPageSize(50);
        }        
    }
    const jobRetry = () => {
        FailedjobRetry(jobId, function (result: any) {
            if (result != null) {
                props.history.push({
                    pathname: '/joblist',
                    state: jobId
                });
            }
        })
    }

    const Back = () => {
        props.history.push({
            pathname: '/jobDetails',
            state: jobId
        });
    }
    return (
        <React.Fragment>
            {isLoader ? (<Loader isActive={isLoader} />) : (null)}
            <div className="row">
                <div className="col-md-3">
                    <SideBar />
                </div>
                <div className="col-md-9">
                    <div>
                        <h1>InRiver Sync Logs Job: {
                            "#"+jobId+" "+taskName
                        }</h1>
                    </div>
                    <div className="d-flex justify-content-end align-items-center mb-3" area-disabled={EIMsync != true ? true : false}>
                        <a target='_blank' href={`api/Account/Export?joibId=${jobId}&&statusId=${statusId}&&entityId=${entityType}&&jobName=${taskName}&&webSiteId=${0}&&isInriver=${true}`} type="button" className="btn btn-primary btn-sm me-2">Export</a>
                        <button type="button" className="btn btn-primary btn-sm" onClick={Back}>Back</button>
                    </div>
                    <div className="box-results">
                        <div className="row">
                            <div className="col">
                                <label className="col-form-label">Entity</label>
                                <select className="form-select" aria-label="Default select example" onChange={ChangeEntity} value={entityValue}>
                                    <option value="All">All</option>
                                    <option value="Category">Category</option>
                                    <option value="Product">Product</option>
                                    <option value="Item">Item</option>
                                </select>
                            </div>
                            {/* <div className="col">
                    <label className="col-form-label">Operation</label>
                    <select className="form-select" aria-label="Default select example">
                        <option selected value="1">Insert</option>
                        <option value="2">updated</option>
                    </select>
                </div> */}
                            <div className="col">
                                <label className="col-form-label">Status</label>
                                <select className="form-select" aria-label="Default select example" onChange={ChangeStatus} value={statusValue}>
                                    <option selected value="All">All</option>
                                    <option selected value="New">New</option>
                                    <option value="Processing">Processing</option>
                                    <option value="Finished">Finished</option>
                                    <option value="Failed">Failed</option>
                                    <option value="Cancelled">Cancelled</option>
                                </select>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="d-flex justify-content-end align-items-center mb-3">
                            <button type="button" className="btn btn-primary btn-sm" disabled={CSIMsync != true ? true : false} onClick={jobRetry}>Retry All</button>
                        </div>
                        <table className="table table-bordered">
                            <thead className="thead-light">
                                <tr>

                                    <th className="col1">
                                        Task Id
                                    </th>
                                    <th className="col2">
                                        Task name
                                    </th>
                                    <th className="col3">
                                        Status
                                    </th>
                                    <th className="col4">
                                        Error message
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tasklist != null && tasklist.length !== 0 ? (
                                        tasklist.map((item, index) => (
                                            <tr>
                                                <td scope="col" key={index}>
                                                    {
                                                        "Task: #" + item.entityId
                                                    }
                                                </td>
                                                <td scope="col">
                                                    {
                                                         item.entityName
                                                    }
                                                </td>
                                                <td scope="col">
                                                    {
                                                        item.status
                                                    }
                                                </td>
                                                <td scope="col" width="10%">
                                                    {
                                                        (item.errorMessage != null && item.errorMessage != "") ? item.errorMessage + "...." : ""
                                                    }
                                                </td>
                                                {/* <td scope="col">
                                        Status: Error
                                    </td> */}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td>No records found</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        <Pagenation />
                    </div>



                </div>
            </div>



        </React.Fragment>
    )
}

export default TakDetails;