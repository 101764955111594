import axios from 'axios';
import { debug } from 'console';
import { scheduledSync } from '../../model/scheduledSync';


export const ReadFiledate = (file: FormData, filetype: string, callback: any) => {
    const response = axios({
        method: "post",
        url: "api/Website/UploadFile",
        data: file,
        headers: { "Content-Type": "multipart/form-data" },
    }).then(result => {
        return callback(result.data);
    }).catch(err => {
        console.log(err)
    })
}
export const GetAllPhases = async (callback: any) => {
    await axios.get("api/Job/GetAllPhases")
        .then(res => {
            callback(res.data)
        })
        .catch(err => console.log(err))
}
export const GetscheduledSync = (ismagentoApi: boolean, callback: any) => {
    axios.get(`api/Job/GetscheduledSync?ismagentoApi=${ismagentoApi}`).then(result => {
        return callback(result.data);
    }).catch(err => {
        console.log(err)
    })

}


export const SaveAndUpdateScheduledReports = async (sync: scheduledSync, callback: any) => {
    await axios.post("api/Job/SaveAndUpdateScheduledReports", {
        id: sync.id,
        phaseid: sync.phaseid,
        nextsyncdate: sync.nextsyncdate,
        ismagentoSync: sync.ismagentoSync,
        days: sync.days,
        month: sync.month,
        name: "",
        time: sync.time,
        isactive:sync.isActive   
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}