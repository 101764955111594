import React, { Component, useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from 'react-redux'
import Header from "./Header";
import { user, createinitialUser } from '../dataservices/model/user';
import { GetIsAuth, LoginService } from '../dataservices/action/login';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import AFailed from "./alretbox/failed";
import Loader from "./loader";
import Globals from "../dataservices/global";
function Login() {
    const initaluser = createinitialUser();
    const [username, setName] = useState("")
    const [password, setPassword] = useState("")
    const [islogin, setislogin] = useState(true)
    const [isLoader, setisLoader] = useState(false);
    const [auth, setisauth] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const store = useSelector((state: any) => { return state })
    const history = useHistory();
    const dispatch = useDispatch();
    const isAuth = useSelector((state: any) => { return state.isatuthendicated })
    Globals.IsAuthenticated = false;
    let expiresAt = localStorage.getItem('IsAuthendicated');
    useEffect(() => {        
        
        const token = localStorage.getItem('IsAuthendicated');
        if (token == "true") {            
            GetIsAuth(function (result: any) {                
                setisauth(result);
                if (auth) {
                    history.push('/userdashboard');
                }
                else {
                    localStorage.clear();
                    localStorage.setItem('IsAuthendicated', "false");
                    localStorage.setItem('Username', "");
                    expiresAt = localStorage.getItem('IsAuthendicated');
                    dispatch({
                        type: "LOG_OUT",
                        payload: createinitialUser()
                    })

                    dispatch({
                        type: "LOG_OUT_AUTH",
                        payload: false
                    })

                    //caches.keys().then((names) => {
                    //    names.forEach((name) => {
                    //        caches.delete(name);
                    //    });
                    //});
                    localStorage.clear();
                    history.go(0);                 
                }
            });
        }
        else {
            history.push('/login');

        }
        //expiresAt = localStorage.getItem('IsAuthendicated');
        //dispatch({
        //    type: "LOG_OUT",
        //    payload: createinitialUser()
        //})

        //dispatch({
        //    type: "LOG_OUT_AUTH",
        //    payload: false
        //})
        
    }, [])
    const ChangeName = (e: any) => {
        setName(e.target.value)
    }
    const CloseLoginPopup = () => {
        setislogin(true);
    }
    const ChangePassword = (e: any) => {
        setPassword(e.target.value)
    }
    // const showToastMessage = () => {
    //     toast.success('Success Notification !', {
    //         position: toast.POSITION.TOP_RIGHT
    //     });
    // };
    const Login = () => {

        setisLoader(true);
       // localStorage.clear();
        //Globals.IsAuthenticated = true;
        //history.push(`/userdashboard`);
        //caches.keys().then((names) => {
        //    names.forEach((name) => {
        //        caches.delete(name);
        //    });
        //});
        //alert('Complete Cache Cleared')
        if (username != "" && password != "") {
            LoginService(username, password, function (result: user) {
                if (result.isSuccess) {
                    Globals.IsAuthenticated = true;
                    Globals.IsUAdmin = result.role;
                    // localStorage.setItem('IsAuthendicated', "true");
                    //alert("Login Success");
                    // toast.success('Success Notification !', {
                    //     position: toast.POSITION.TOP_RIGHT
                    // });
                    dispatch({
                        type: "LOGIN_COMPLETED",
                        payload: result
                    })
                    dispatch({
                        type: "LOG_IN_AUTH",
                        payload: true
                    })
                    setisLoader(true);
                    localStorage.setItem('IsAuthendicated', "true");
                    history.push(`/userdashboard`);

                }
                else {
                    localStorage.clear();
                    localStorage.setItem('IsAuthendicated', "false");
                    setislogin(false);
                    setisLoader(false);
                }
            })
        } else {
            setislogin(false);
            setisLoader(false);
        }
    }

    const handleSubmit = () => {
                 setisLoader(true);
        LoginService(username, password, function (result: user) {
            if (result.isSuccess) {
                //alert("Login Success");
                // toast.success('Success Notification !', {
                //     position: toast.POSITION.TOP_RIGHT
                // });
                dispatch({
                    type: "LOGIN_COMPLETED",
                    payload: result
                })
                dispatch({
                    type: "LOG_IN_AUTH",
                    payload: true
                })
                setisLoader(true);
                history.push(`/userdashboard`);

            }
            else {
                setislogin(false);
                setisLoader(false);
            }
        })
    }
    const onFormSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div className="section-padding">
            {isLoader ? (<Loader isActive={isLoader} />) : (null)}
            <Header />
            <div className="form-wrapper">
                <h1 className="text-center">Login</h1>
                <form onSubmit={onFormSubmit} >
                    <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input type="text" onChange={ChangeName} placeholder="" className="form-control" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Password</label>
                        {/* <input type="password" onChange={ChangePassword} placeholder="" className="form-control" /> */}
                        <div className="input-group">
                        <input type={showPassword ? 'text' : 'password'}  onChange={ChangePassword} placeholder="" className="form-control" />
                        {showPassword ?<button className="px-2 btn btn-primary" type="button" onClick={togglePasswordVisibility}><i className="bi bi-eye-slash"></i></button> :<button className="px-2 btn btn-primary" type="button" onClick={togglePasswordVisibility}><i className="bi bi-eye"></i></button> }  

                        </div>
                    </div>
                    {/*< div className="mb-3">
                        <Link to="/forgotpassword">Forgot Password?</Link>
                    </div>*/}
                    <div className="mb-3 text-center">
                        
                         <input type="submit" className="btn btn-primary" onClick={Login} name="Login" value="Login" />
                    </div>
                    {/* <button onClick={showToastMessage}>Notify</button>
                    <ToastContainer /> */}
                </form>
            </div>
            <div onClick={CloseLoginPopup}>
                {

                    !islogin ? (
                        <AFailed message={"Incorrect Username or Password"} />
                    ) : (null)
                }

            </div>
        </div>
    )
}

export default Login;