import React, { useState, useEffect } from "react";
import UserSideBar from "../usersidebar";
import { useHistory } from 'react-router-dom';
import { roleSave } from "../../dataservices/model/role";
import { SaveRole } from "../../dataservices/action/users/getUserApiSettins";
import roleEdit from "../../components/userdashboard/editRole";
import ASuccess from "../alretbox/success";
import AFailed from "../alretbox/failed";
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
import { confirmAlert } from 'react-confirm-alert';

const AddRoleComponent = () => {
    const history = useHistory();
    const [roleName, setRoleName] = useState("");
    const [checkActive, setcheckActive] = useState(false);
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());

    const OnChangeRoleName = (e: any) => {
        // console.log(e.target.value);
        setRoleName(e.target.value)


    };
    // const RoleActiveclick = (e: any) => {
    //     setcheckActive(e.target.checked)
    //     console.log("checkActivecheckActive", checkActive);
    // }

    const SaveRoleButtonClick = () => {
        if (roleName != "") {
            const roleSave: roleSave = {
                id: 0,
                Name: roleName,
                IsActive: checkActive

            }
            let load: Loadermessage = {
                message: "",
                isLoader: true,
                isSuccess: false,
                isfailed: false
            }
            Setisloader(load);
            SaveRole(roleSave, function (result: any) {
                console.log("res..", result);
                if (result) {
                    let results = CreateinitialLoadermessage();
                    results.isSuccess = true;
                    results.message = "Role saved successfully"
                    Setisloader(results);

                    setTimeout(() => {
                        history.push({ pathname: "/editRole", state: result })
                    }, 2500);
                    //history.push({ pathname: "/editRole", state: result })
                }
                else {
                    console.log("else....")
                    let results = CreateinitialLoadermessage();
                    results.isSuccess = false;
                    results.isfailed=true;
                    results.message = "Role name already exists"
                    Setisloader(results);


                }

            })

        }else{
            confirmAlert({
                  //  title: 'Em Role',
                    message: 'Please enter a name for role.',
                    buttons: [
                       
                        {
                            label: 'Ok',
                            onClick: () => { }
                        }
                    ]
                });
        }

    }
    const CloseUserPopup=()=>{
        Setisloader(CreateinitialLoadermessage);
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <UserSideBar />
                </div>
                <div className="col-sm-9 col-md-9">
                    <div className="heading-section">
                        <h1>Add Role</h1>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Name</label>
                                <input className="form-control" type="text" onChange={OnChangeRoleName} value={roleName} />
                            </div>

                            {/* <div className="mb-3 form-check">
                                <input className="form-check-input" type="checkbox" onChange={RoleActiveclick} checked={checkActive} />
                                <label className="form-check-label">
                                    Active
                                </label>
                            </div> */}
                            <button type="button" className="btn btn-primary me-2" onClick={SaveRoleButtonClick}>Save</button>
                        </div>
                    </div>
                    <div onClick={CloseUserPopup}>
                        {
                            isloader.isfailed ? (
                                <AFailed message={isloader.message} />
                            ) : (null)
                        }
                    </div>
                    <div onClick={CloseUserPopup}>
                        {
                            isloader.isSuccess ? (
                                <ASuccess message={isloader.message} />
                            ) : (null)
                        }
                    </div>
                </div>

            </div>
        </React.Fragment>
    )

}
export default AddRoleComponent;