import React, { useEffect, useState } from "react";
import { job, createinitialjob } from "../../dataservices/model/joblist";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "../sidebar";
import { PaginationValues } from "../pagenation/pageflow";
import { useHistory, useParams } from 'react-router-dom';
import Loader from "../loader";
import { debug } from "console";
import { GetAllMarketplaces, GetMPJobList, GetMPLatestSyncDate, GetMpSyncPauseval, Getrolepermissionbyuserid, PauseMPSync, SyncMPDate } from "../../dataservices/action/marketplace/marketplace";
import moment from "moment";
import { CreateinitialLoadermessage, Loadermessage } from "../../dataservices/model/loadermessage";
import AFailed from "../alretbox/failed";
import ASuccess from "../alretbox/success";
import { scheduledSync } from "../../dataservices/model/scheduledSync";
import MPSideBar from "../mpsidebar";
import { channellist } from "../../dataservices/model/channellist";
import { moduleinfo } from "../../dataservices/model/user";
import { GetEditPermission } from "../../dataservices/action/account/register";


const mpjobList = (props: any) => {
    var intjob: job[] = new Array<job>();
    const [joblist, SetJoblist] = useState(intjob);
    const storeJob = useSelector((state: any) => { return state.JobList });
    const dispatch = useDispatch();
    const [TotalCount, setTotalCount] = useState(0);
    const history = useHistory();
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [PageSizeList, setPageSizeList] = useState([50, 100, 200]);
    const [SearchValue, setSearchValue] = useState("");
    const [isloader, setisloader] = useState(false);
    const [activePage, setActivePage] = useState(0);
    const [deltaDate, SetselectdeltaDate] = useState(new Date());
    const [isBindDeltaDate, setBindDeltaDate] = useState(false);
    const [isShowpopup, SetisShowpopup] = useState(false);
    const { accid } = useParams();
    const [accname, setaccname] = useState("");
    var intbAcclist: channellist[] = new Array<channellist>();
    const [bacclist, setbacclist] = useState(intbAcclist);
    const [ECategoryAttributes, setVCategoryAttributes] = useState(false);
    const [EAPIsettings, setVAPIsettings] = useState(false);
    const [EMagentoSync, setVMagentoSync] = useState(false);
    const [EInriverSync, setVInriverSync] = useState(false);
    const [EProducts, setVProducts] = useState(false);
    const [EInventoryRules, setVInventoryRules] = useState(false);
   // const [Elogs, setVlogs] = useState(false);
    const [ESettings, setVSettings] = useState(false);
    const [isDisabled, setisDisabled] = useState("disabled");
    var vperList: moduleinfo[] = new Array<moduleinfo>();
    const [VPList, setVPList] = useState(vperList);
    const [ALogs, setALogs] = useState(false);
    const [Elogs, setElogs] = useState(false);
    const [DLogs, setDLogs] = useState(false);
    const [CSLogs, setCSLogs] = useState(false);
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 18:
                                if (result[i].canadd == "1") {
                                    setALogs(true);
                                }
                                if (result[i].canedit == "1") {
                                    setElogs(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDLogs(true);
                                }
                                if (result[i].cansync == "1") {
                                    setCSLogs(true);
                                }
                                break;
                        }
                    }
                }
            }
        })
    }, [])
    useEffect(() => {
        setisloader(true);
        GetAllMarketplaces(function (result: any) {
            if (result.length > 0) {
                setbacclist(result);
                for (var i = 0; i < result.length; i++) {
                    if (result[i].customerId == Number(accid)) {
                        setaccname(result[i].message);
                    }
                }

                setisloader(false);
            }
        })
    }, [])
    useEffect(() => {

        setisloader(true);
        GetMPJobList(Page, PageSize, accid, function (result: job[]) {
            if (result.length != 0) {
                dispatch(
                    {
                        type: "GET_JOBLIST_COMPLETED",
                        payload: result
                    })
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                SetJoblist(result);
                setActivePage(Page)
                setisloader(false);
            } else {
                setisloader(false);
            }
        })
    }, [])

    const Details = (id: number) => {
        props.history.push({
            pathname: '/mptaskdetails',

            state: {
                id: id,
                accountid: accid
            }
        });

    }


    const SyncAllOpenClosePopUp = (id: number) => {
        GetMPLatestSyncDate(function (result: Date) {
            if (result != null) {
                var momentDate = moment(result);
                // alert(new Date(result));
                SetselectdeltaDate(new Date(result));
                let syncTime = moment(result).format("HH:mm");
                setSelectedTime(syncTime);
                setBindDeltaDate(true);
            }
            else {
                setBindDeltaDate(false);
            }

        })

        SetisShowpopup(!isShowpopup);
    }
    const Pagenation = () => {
        let Pagination = new PaginationValues();
        Pagination.TotalItemCount = TotalCount;
        Pagination.CurrentPage = Page;
        Pagination.PageSlide = 2;
        Pagination.IsSort = true;
        Pagination.PageSize = PageSize;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page - 1) * PageSize) + 1}    - {((((Page - 1) * PageSize) + 1) + PageSize) > TotalCount ? TotalCount : ((((Page - 1) * PageSize)) + PageSize)} of {TotalCount} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">

                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                                {
                                    Pagination.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination.Pages().map((x, i) =>
                                        x == Page ?
                                            (
                                                <li className={`page-item  ${activePage == x ? 'active' : null}`} key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className="page-link" onClick={() => SetPage(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
    const SetPageSize = (size: number) => {
        setPageSize(size);
        GetMPJobList(Page, size, accid, function (result: job[]) {
            if (result != null) {
                SetJoblist(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
            }
        })
    }

    const SetPage = (page: number) => {
        setPage(page);
        GetMPJobList(page, PageSize, accid, function (result: job[]) {
            if (result != null) {
                SetJoblist(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(page)
            }
        })
    }

    const Refresh = () => {
        let PoNumber: string = "";
        let OrderNumber: number = 0;
        let InvoiceNumber: string = "";
        if (SearchValue != "") {
        }
        GetMPJobList(Page, PageSize, accid, function (result: job[]) {
            if (result != null) {
                SetJoblist(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
            }
        })
    }
    const Back = () => {
        history.push(`/userdashboard`);
    }
    const OpenClosePopUp = (id: number) => {
        SetisShowpopup(!isShowpopup);
    }
    const [isDate, SetisDate] = useState(true)
    const [jobType, SetselectjobType] = useState(2);
    const [isLoader, SetisLoader] = useState(CreateinitialLoadermessage());
    const [isBindTime, setBindTime] = useState(false);
    const [selectedTime, setSelectedTime] = useState("");

    const ChnageJobType = (jobType: number) => {
        // debugger;
        if (jobType == 1)
            SetisDate(false);
        else
            SetisDate(true);
        SetselectjobType(jobType)
    }
    const onChangeDate = (e: any) => {
        const newDate = e.target.value;
        //var ndate = moment(newDate).format("MM/DD/YYYY")
        //var date = moment(new Date()).format("MM/DD/YYYY")
        var ndate = moment(new Date(newDate).getTime())
        var date = moment(new Date().getTime())
        if (ndate > date) {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please don't select future date"
            //result.message = "Please don't select past date"
            SetisLoader(result);
        }
        else {
            SetselectdeltaDate(newDate)
        }
    };
    const OnChangeTime = (e: any) => {
        var newTime = e.target.value;
        setBindTime(true);
        setSelectedTime(newTime);
        var sldate = new Date(moment(deltaDate).format("MM/DD/YYYY"));
        //let dte=new Date(sldate)
        //const dateTime = new Date(sldate + ' ' + newTime );
        //let getHr=dateTime.getHours();
        //let finalHr=dateTime.setHours(getHr-6)
        // let finHrMin= moment(finalHr).subtract(30, 'minutes').toDate();
        SetselectdeltaDate(sldate);
    }
    const ClosePopup = () => {
        SetisLoader(CreateinitialLoadermessage())
    }
    const SyncMP = () => {
        debugger;
        var date = moment(new Date().getTime())
        var sldate = moment(new Date(deltaDate).getTime())

        var Date1 = moment(deltaDate).format("YYYY/MM/DD")+" " + selectedTime;

        var utcDate = moment.utc(moment(Date1).utc()).format();

        console.log(moment.utc(moment(Date1).utc()).format());

        let isdatevalid = true;
        if (sldate > date) {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please don't select future date"
            SetisLoader(result);
            isdatevalid = false;
        }
        if (jobType !== 0) {
            let load: Loadermessage = {
                message: "",
                isLoader: true,
                isSuccess: false,
                isfailed: false
            }
            //SetisLoader(load);
            setisloader(true);
            let dt = deltaDate

            SyncMPDate(jobType, utcDate, selectedTime, accid, function (res: any) {
                let result = CreateinitialLoadermessage()
                if (res) {
                    result.isSuccess = true;
                    result.message = "Sync Started Successfully";
                    SetisLoader(result);
                    history.go(0);

                }
                else {
                    result.isfailed = true;
                    result.message = "Sync not started";
                    SetisLoader(result);
                }
                setisloader(false);
                SetisShowpopup(!isShowpopup);
            })
        } else {
            let result = CreateinitialLoadermessage()
            result.isfailed = true;
            result.message = "Please select jobtype ";
            SetisLoader(result);
        }
    }
    const HandleSync = () => {
        debugger;
        history.push(`/mpschedule/` + accid);
    }
    const [isPause, setisPause] = useState(false);
    const Pause = (id: number) => {
        setisloader(true);

        GetMpSyncPauseval(id, function (result: scheduledSync) {
            refreshPage();
            setisloader(false);
            //if (result.isPauseSync) {
            //    let result = CreateinitialLoadermessage()
            //    result.isfailed = true;
            //    result.isLoader = false;
            //    result.message = "Paused";
            //    SetisLoader(result);
            //    refreshPage();
            //}
            //else {
            //    //alert("Resumed Successfully");
            //    let result = CreateinitialLoadermessage()
            //    result.isfailed = true;
            //    result.isLoader = false;
            //    result.message = "Resumed";
            //    SetisLoader(result);
            //    refreshPage();
            //}
        })
    }
    function refreshPage() {
        window.location.reload();
    }
    useEffect(() => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        SetisLoader(load);
        PauseMPSync(function (result: any) {
            setisPause(result)
            let resultmes = CreateinitialLoadermessage();
            resultmes.isLoader = false;
            SetisLoader(resultmes);
        })
    }, [])
    return (
        <React.Fragment>
            <div>
                {
                    isloader ? (<Loader isActive={isloader} />) : (null)
                }
                <div className="row">
                    <div className="col-md-12">
                        <div className="mpc-menu">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Home</a></li>
                                    <li className="breadcrumb-item" aria-current="page">
                                        <span>Logs</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <div className="head-accno">
                                            Account No: <span>{accname}</span>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                            <MPSideBar id={accid} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="heading-section">
                            <h1>Marketplace Job List</h1>
                            <div>
                                <input className="btn btn-wh-1 btn-primary ms-2" type="button" disabled={CSLogs != true ? true : false} onClick={HandleSync} value="Sync Schedule" />
                                <input className="btn btn-wh-1 btn-primary ms-2" type="button" disabled={CSLogs != true ? true : false} onClick={(e) => SyncAllOpenClosePopUp(0)} value="Sync All" />
                                {!isPause ? <input className="btn btn-wh-1 btn-primary ms-2" disabled={CSLogs != true ? true : false} type="button" value="Pause Sync" onClick={(e) => Pause(1)} /> : <input className="btn btn-primary ms-2" disabled={Elogs != true ? true : false} onClick={(e) => Pause(0)} type="button" value="Resume Sync" />}

                                {/*    <input className="btn btn-primary ms-2" type="button" value="Back" onClick={Back} />*/}
                            </div>
                        </div>

                        <div className="table-scroll-section">
                            <table className="table table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col">
                                            Job Id
                                        </th>
                                        <th scope="col">
                                            Job date
                                        </th>
                                        <th scope="col">
                                            Job type
                                        </th>
                                        {/*<th scope="col">*/}
                                        {/*    Marketplace Account number*/}
                                        {/*</th>*/}
                                        {/*<th scope="col">*/}
                                        {/*    Transactionid*/}
                                        {/*</th>*/}
                                        {/*<th scope="col">*/}
                                        {/*    Job*/}
                                        {/*</th>*/}
                                        <th scope="col">
                                            Status
                                        </th>
                                        <th scope="col">
                                            Details
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        joblist != null ? (
                                            joblist.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {item.id}
                                                    </td>
                                                    <td>
                                                        {item.createdDate != null ? moment(item.createdDate).format('MM/DD/YYYY H:mm a') : ""}                                                       
                                                    </td>
                                                    <td>
                                                        {item.createdType}
                                                    </td>
                                                    {/*<td>*/}
                                                    {/*    {item.scope}*/}
                                                    {/*</td>*/}
                                                    {/*<td>*/}
                                                    {/*    {item.transactionid}*/}
                                                    {/*</td>*/}
                                                    {/*<td>*/}
                                                    {/*    {item.name}*/}
                                                    {/*</td>*/}
                                                    <td>
                                                        {item.status}
                                                    </td>

                                                    <td>
                                                        <button className="btn btn-sm btn-success" data-toggle="tooltip" data-placement="top" title="Details" onClick={(e) => Details(item.id)} ><i className="bi bi-eye-fill"></i></button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (

                                            <tr>
                                                <td>
                                                    No Records found
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            <Pagenation />
                        </div>
                        {
                            isShowpopup ? (
                                <div id="popup1" className="overlay">
                                    <div className="popup">
                                        <h4> Sync Options</h4>
                                        <a className="close" onClick={(e) => OpenClosePopUp(0)}>&times;</a>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="isSyncetype" id="" onClick={(e) => ChnageJobType(1)} />
                                                    <label className="form-check-label">
                                                        Full Sync
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" defaultChecked name="isSyncetype" id="" onClick={(e) => ChnageJobType(2)} />
                                                    <label className="form-check-label">
                                                        Delta Sync
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                        {
                                            isDate ? (<div className="row mb-3">
                                                <div className="col-auto">
                                                    <label className="col-form-label">Last Sync Date & Time</label>
                                                </div>
                                                {isBindDeltaDate ?
                                                    (<div className="box-results-col mb-2">
                                                        <input type="date" className="form-control" id="datetime" value={new Date(deltaDate).toLocaleDateString('en-CA')} aria-describedby="datetimehelp" onChange={onChangeDate} />

                                                    </div>) : (<div className="box-results-col mb-2">
                                                        <input type="date" className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeDate} required />
                                                    </div>)

                                                }
                                                {
                                                    isBindTime ? (<div className="box-results-col">
                                                        <input type="time" value={selectedTime} className="form-control" id="datetime" onChange={OnChangeTime} aria-describedby="datetimehelp" required />
                                                    </div>) : (<div className="box-results-col">
                                                        <input type="time" value={selectedTime} className="form-control" id="datetime" onChange={OnChangeTime} aria-describedby="datetimehelp" />
                                                    </div>)
                                                }

                                            </div>) : (null)
                                        }

                                        <div className="d-flex justify-content-center align-items-center">
                                            <button type="button" className="btn btn-sm btn-primary me-2" disabled={CSLogs != true ? true : false} onClick={(e) => SyncMP()}>Sync</button>
                                        </div>
                                    </div>
                                </div>
                            ) : (null)
                        }
                        <div onClick={ClosePopup}>
                            {
                                isLoader.isfailed ? (
                                    <AFailed message={isLoader.message} />
                                ) : (null)
                            }
                        </div>
                        <div onClick={ClosePopup}>
                            {
                                isLoader.isSuccess ? (
                                    <ASuccess message={isLoader.message} />
                                ) : (null)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default mpjobList;