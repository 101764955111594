import React, { useEffect, useState } from "react";
import { job, createinitialjob } from "../../dataservices/model/joblist";
import SideBar from "../sidebar";
import { useDispatch, useSelector } from 'react-redux'
import { CancellJobandTaskService, ExportExcel } from "../../dataservices/action/job/joblist";
import { GetMagentoTaskDetails } from "../../dataservices/action/magentowebsync/magentosync";
import { jobDetails, createinitialjobDetails } from "../../dataservices/model/jobDetails";
import { PaginationValues } from "../pagenation/pageflow";
import { generatePath, useHistory } from 'react-router-dom';
import Loader from "../loader";
import { GetMagentowebSiteListbyJobId, FailedMagentojobRetry } from "../../dataservices/action/magentowebsync/magentosync";
import { allwebsites, createinitialallwebsites } from "../../dataservices/model/allwebsites";
import { CancellMPJobandTaskService, FailedMPjobRetry, GetAllMarketplaces, GetMPTaskDetailsbyID, Getrolepermissionbyuserid, GetstatusandtransbyID } from "../../dataservices/action/marketplace/marketplace";
import { channellist } from "../../dataservices/model/channellist";
import { moduleinfo } from "../../dataservices/model/user";
import MPSideBar from "../mpsidebar";
import { confirmAlert } from "react-confirm-alert";
import { GetEditPermission } from "../../dataservices/action/account/register";

const MPTaskDetails = (props: any) => {
    const dispatch = useDispatch();
    var intjob: jobDetails[] = new Array<jobDetails>();
    const [tasklist, SetTasklist] = useState(intjob);
    const [displayList, setDisplayList] = useState(intjob);
    const jobId = props.location.state.id;
    const accid = props.location.state.accountid;
    // console.log("accid....",accid);
    const [TotalCount, setTotalCount] = useState(0);
    const [Page, setPage] = useState(1);
    const [PageSize, setPageSize] = useState(50);
    const [PageSizeList, setPageSizeList] = useState([50, 100, 200]);
    const [entityType, setentityType] = useState(0);
    const [entityValue, setentityValue] = useState("");
    const [websitevalue, setwebsitevalue] = useState("All");
    const [websiteId, setwebsiteId] = useState(0);
    const [taskName, settaskName] = useState("");
    const [statusValue, setstatusValue] = useState("All");
    const [statusId, setstatusId] = useState(0);
    const [locationId, setLocationId] = useState(0);
    const [isLoader, setisLoader] = useState(false);
    const [activePage, setActivePage] = useState(0);
    var intstatuslist: channellist[] = new Array<channellist>();
    const [statuslist, setstatuslist] = useState(intstatuslist);
    const [searchTerm, setSearchTerm] = useState('');

    var inttranslist: channellist[] = new Array<channellist>();
    const [translist, settranslist] = useState(inttranslist);
    var intlocationlist: channellist[] = new Array<channellist>();
    const [locationlist, setlocationlist] = useState(intlocationlist);
    const [transId, settransId] = useState("");
    const history = useHistory();
    const [accname, setaccname] = useState("");
    const [logsurl, setislogsurl] = useState("/mpjoblist/" + accid);
    var intbAcclist: channellist[] = new Array<channellist>();
    const [bacclist, setbacclist] = useState(intbAcclist);
    const [ECategoryAttributes, setVCategoryAttributes] = useState(false);
    const [EAPIsettings, setVAPIsettings] = useState(false);
    const [EMagentoSync, setVMagentoSync] = useState(false);
    const [EInriverSync, setVInriverSync] = useState(false);
    const [EProducts, setVProducts] = useState(false);
    const [EInventoryRules, setVInventoryRules] = useState(false);
    // const [Elogs, setVlogs] = useState(false);
    const [ESettings, setVSettings] = useState(false);
    const [isDisabled, setisDisabled] = useState("disabled");
    var vperList: moduleinfo[] = new Array<moduleinfo>();
    const [VPList, setVPList] = useState(vperList);
    const [ALogs, setALogs] = useState(false);
    const [Elogs, setElogs] = useState(false);
    const [DLogs, setDLogs] = useState(false);
    const [CSLogs, setCSLogs] = useState(false);
    useEffect(() => {
        Getrolepermissionbyuserid(1, function (result: any) {
            if (result != null) {
                if (result != null) {
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].name);
                        switch (result[i].permissionid) {
                            case 18:
                                if (result[i].canadd == "1") {
                                    setALogs(true);
                                }
                                if (result[i].canedit == "1") {
                                    setElogs(true);
                                }
                                if (result[i].candelete == "1") {
                                    setDLogs(true);
                                }
                                if (result[i].cansync == "1") {
                                    setCSLogs(true);
                                }

                                break;
                        }
                    }
                }
            }
        })
    }, [])
    useEffect(() => {
        setisLoader(true);
        GetAllMarketplaces(function (result: any) {
            if (result.length > 0) {
                setbacclist(result);
                for (var i = 0; i < result.length; i++) {
                    if (result[i].customerId == Number(accid)) {
                        setaccname(result[i].message);
                    }
                }

                setisLoader(false);
            }
        })
    }, [])
    useEffect(() => {

        setisLoader(true);
        GetMPTaskDetailsbyID(accid, jobId, Page, PageSize, statusId, transId, locationId, function (result: jobDetails[]) {
            if (result != null && result.length !== 0) {
                SetTasklist(result);
                setDisplayList(result);
                setTotalCount(result[0].totalRecord);
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage);
                settaskName(result[0].jobName);
                setActivePage(Page)
            } else {
                SetTasklist(intjob);
                setDisplayList(intjob);
                setTotalCount(0);
                setPage(Page);
                setPageSize(PageSize);
                settaskName(taskName);
                setActivePage(Page)
            }
            setisLoader(false);
        })
    }, [statusId])



    useEffect(() => {
        setisLoader(true);
        let info: moduleinfo = {
            moduleid: jobId,
            maid: accid,
            appname: "1",
            permission: "",
            modulename: ""
        }
        GetstatusandtransbyID(info, function (result: any) {
            setstatuslist(result);
            setisLoader(false);

        })
    }, [])
    useEffect(() => {
        setisLoader(true);
        let info: moduleinfo = {
            moduleid: jobId,
            maid: accid,
            appname: "2",
            permission: "",
            modulename: ""
        }
        GetstatusandtransbyID(info, function (result: any) {
            settranslist(result);
            setisLoader(false);

        })
    }, [])
    useEffect(() => {
        setisLoader(true);
        let info: moduleinfo = {
            moduleid: jobId,
            maid: accid,
            appname: "3",
            permission: "",
            modulename: ""
        }
        GetstatusandtransbyID(info, function (result: any) {
            setlocationlist(result);
            setisLoader(false);

        })
    }, [])

    const Pagenation = () => {
        let Pagination = new PaginationValues();
        Pagination.TotalItemCount = TotalCount;
        Pagination.CurrentPage = Page;
        Pagination.PageSlide = 2;
        Pagination.IsSort = true;
        Pagination.PageSize = PageSize;

        return (
            <div className="pagination-section">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-4">
                        <div className="item-page-results">
                            Items Per Page:50
                            {/* {
                                PageSizeList.map((x, i) => (
                                    x == PageSize ? (
                                        <span key={i}> {x} |</span>
                                    ) : (
                                        <span key={i}>   <a onClick={() => SetPageSize(x)} > {x}</a> | </span>
                                    )
                                ))
                            } */}
                        </div>
                    </div>
                    {
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="sorting-results">
                                Showing {((Page - 1) * PageSize) + 1}    - {((((Page - 1) * PageSize) + 1) + PageSize) > TotalCount ? TotalCount : ((((Page - 1) * PageSize)) + PageSize)} of {TotalCount} results
                            </div>
                        </div>
                    }
                    <div className="col-sm-4 col-md-4 col-lg-4">

                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-end mb-0">
                                {
                                    Pagination.HasPrevPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page - 1)}>
                                                &#8592;</a>
                                        </li>
                                        )
                                        : ("")
                                }
                                {
                                    Pagination.Pages().map((x, i) =>

                                        x == Page ?
                                            (
                                                <li className={`page-item  ${activePage == x ? 'active' : null}`} key={i}><a className="page-link" >{x}</a></li>)
                                            : (
                                                <li className="page-item" key={i}>
                                                    <a className={`page-link`} onClick={() => SetPage(x)}>{x}
                                                    </a>
                                                </li>
                                            )
                                    )
                                }
                                {
                                    Pagination.HasNextPage() ?
                                        (<li className="page-item">
                                            <a className="page-link" onClick={() => SetPage(Page + 1)}>
                                                &#8594;</a>
                                        </li>) : ("")
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
    const SetPageSize = (size: number) => {
        setisLoader(false);
        GetMPTaskDetailsbyID(accid, jobId, Page, size, statusId, transId, locationId, function (result: jobDetails[]) {
            if (result != null) {
                SetTasklist(result);
                setDisplayList(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setisLoader(true);
            }
        })
    }
    const RefreshTask = () => {
        setisLoader(true);
        GetMPTaskDetailsbyID(accid, jobId, 1, 50, statusId, transId, locationId, function (result: jobDetails[]) {
            if (result != null) {
                SetTasklist(result);
                setDisplayList(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setisLoader(false);
            }
        })
    }
    const SetPage = (page: number) => {
        setisLoader(true);
        GetMPTaskDetailsbyID(accid, jobId, page, PageSize, statusId, transId, locationId, function (result: jobDetails[]) {
            if (result != null) {
                SetTasklist(result);
                setDisplayList(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(page)
                setisLoader(false);

            }
        })
    }

    const Changetrans = (e: any) => {
        settransId(e.target.value);
        setisLoader(true);
        GetMPTaskDetailsbyID(accid, jobId, 1, 50, statusId, e.target.value, locationId, function (result: jobDetails[]) {
            if (result != null && result.length > 0) {
                SetTasklist(result);
                setDisplayList(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setisLoader(false);
            }
            else {
                SetTasklist(intjob);
                setDisplayList(intjob);
                setTotalCount(0);
                setPage(Page);
                setPageSize(PageSize);
                settaskName(taskName);
                setActivePage(Page);
                setisLoader(false);
            }
            //setisLoader(false);
        })
    }

    const ChangeLocation = (e: any) => {
        setisLoader(true);
        setLocationId(e.target.value);
        GetMPTaskDetailsbyID(accid, jobId, Page, PageSize, statusId, transId, Number(e.target.value), function (result: jobDetails[]) {
            if (result != null) {
                SetTasklist(result);
                setDisplayList(result);
                //setTotalCount(result[0].totalRecord)
                //setPage(result[0].index);
                // setPageSize(result[0].recordPerPage)
                setActivePage(Page);
                setisLoader(false);

            }
        })

    }


    const ChangeStatus = (e: any) => {
        setisLoader(true);
        setstatusValue(e.target.value);
        setstatusId(e.target.value);
        GetMPTaskDetailsbyID(accid, jobId, Page, PageSize, Number(e.target.value), transId, locationId, function (result: jobDetails[]) {
            if (result != null) {
                SetTasklist(result);
                setDisplayList(result);
                setTotalCount(result[0].totalRecord)
                setPage(result[0].index);
                setPageSize(result[0].recordPerPage)
                setActivePage(Page);
                setisLoader(false);

            }
        })
        //switch (e.target.value) {
        //    case "New":
        //        setstatusValue(e.target.value);
        //        setstatusId(1);
        //        setPage(1);
        //        setPageSize(50);
        //        break;
        //    case "Processing":
        //        setstatusValue(e.target.value);
        //        setstatusId(2);
        //        setPage(1);
        //        setPageSize(50);
        //        break;
        //    case "Finished":
        //        setstatusValue(e.target.value);
        //        setstatusId(3);
        //        setPage(1);
        //        setPageSize(50);
        //        break;
        //    case "Failed":
        //        setstatusValue(e.target.value);
        //        setstatusId(4);
        //        setPage(1);
        //        setPageSize(50);
        //        break;
        //    case "Cancelled":
        //        setstatusValue(e.target.value);
        //        setstatusId(5);
        //        setPage(1);
        //        setPageSize(50);
        //        break;
        //    case "Pushed to Amazon":
        //        setstatusValue(e.target.value);
        //        setstatusId(6);
        //        setPage(1);
        //        setPageSize(50);
        //        break;
        //    case "Completed":
        //        setstatusValue(e.target.value);
        //        setstatusId(7);
        //        setPage(1);
        //        setPageSize(50);
        //        break;
        //    case "Success":
        //        setstatusValue(e.target.value);
        //        setstatusId(8);
        //        setPage(1);
        //        setPageSize(50);
        //        break;
        //    case "Failure":
        //        setstatusValue(e.target.value);
        //        setstatusId(9);
        //        setPage(1);
        //        setPageSize(50);
        //        break;
        //    default:
        //        setstatusValue("All");
        //        setstatusId(0);
        //        setPage(1);
        //        setPageSize(50);
        //}



    }
    const Back = () => {
        history.push(`/mpjoblist`);
    }
    const CancellJobandTaskAlert = (id: number) => {
        confirmAlert({
            title: 'Cancel Sync',
            message: 'Are you sure you want to cancel the sync?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => CancellJobandTask(id)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const CancellJobandTask = (id: number) => {

        setisLoader(true);
        CancellMPJobandTaskService(id, function (res: any) {
            setisLoader(false);
        })
        history.go(0);
    }
    const MPjobRetry = () => {
        FailedMPjobRetry(jobId, function (result: any) {
            if (result != null) {
                history.push(generatePath("/mpjoblist/:accid", { accid }));

            }
        })
    }

    const handleSearchItemNo = (event: any) => {
        setSearchTerm(event.target.value);

        if (event.target.value != "") {
            var tmpArr = [...tasklist]
            const results = tmpArr.filter((item) =>
                item.entityName.toLowerCase().includes(event.target.value.toLowerCase())
            );
            // SetTasklist(results);
            setDisplayList(results)

        } else {
            console.log("test")
            // SetTasklist(tasklist)
            setDisplayList(tasklist)
        }


    }
    return (
        <React.Fragment>
            {isLoader ? (<Loader isActive={isLoader} />) : (null)}
            <div className="row">
                <div className="col-md-12">
                    <div className="mpc-menu">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/userdashboard"><i className="bi bi-house-door-fill pe-1"></i>Home</a></li>
                                <li className="breadcrumb-item" aria-current="page">
                                    {/*<span>Sync Logs Job</span>*/}
                                    <a href={logsurl}><i className="bi"></i>Logs</a>
                                </li>
                                <li className="breadcrumb-item" aria-current="page">
                                    <span>Task Details</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <div className="head-accno">
                                        Account No: <span>{accname}</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                        <MPSideBar id={accid} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="heading-section">
                        <h1>MarketPlace Sync Logs Job: {
                            "#" + jobId + " " + taskName
                        }</h1>
                        <div>
                            <input className="btn btn-primary me-2" type="button" onClick={(e) => { CancellJobandTaskAlert(jobId) }} disabled={CSLogs != true ? true : false} value="Cancel Sync" />
                            <button type="button" disabled={CSLogs != true ? true : false} className="btn btn-primary btn-sm" onClick={MPjobRetry}>Retry all Failed/Cancelled Task </button>

                            <a target='_blank' href={`api/MP/MPTASKExport?joibId=${jobId}&&statusId=${statusId}&&transid=${transId}`} aria-disabled={Elogs != true ? true : false} type="button" className="btn btn-primary ms-2">Download</a>
                            {/*    <button type="button" className="btn btn-primary ms-2" onClick={Back}>Back</button>*/}
                        </div>
                    </div>

                    <div className="box-results">
                        <div className="row">
                            <div className="col-3">
                                <label className="col-form-label">Search by Item#</label>
                                <input type="text" id="" className="form-control" aria-describedby="" value={searchTerm} onChange={handleSearchItemNo} />

                            </div>
                            <div className="col-3">

                                <label className="col-form-label">Status</label>
                                {/*<select className="form-select" aria-label="Default select example" onChange={ChangeStatus} value={statusValue}>*/}
                                {/*    <option selected value="All">All</option>*/}
                                {/*    <option value="New">New</option>*/}
                                {/*    <option value="Processing">Processing</option>*/}
                                {/*    <option value="Finished">Finished</option>*/}
                                {/*    <option value="Failed">Failed</option>*/}
                                {/*    <option value="Cancelled">Cancelled</option>*/}
                                {/*    <option value="Pushed to Amazon">Pushed to Amazon</option>*/}
                                {/*    <option value="Completed">Completed</option>*/}
                                {/*    <option value="Success">Success</option>*/}
                                {/*    <option value="Failure">Failure</option>*/}
                                {/*</select>*/}
                                <select className="form-select" onChange={(e) => ChangeStatus(e)}>
                                    <option selected value="0">Select</option>
                                    {
                                        statuslist.map((item, index) => (
                                            item.name != null && (
                                                <option value={item.resultid}>{item.name}</option>
                                            )
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-3">
                                <label className="col-form-label">TransactionId</label>

                                <select className="form-select" onChange={(e) => Changetrans(e)}>
                                    <option selected value="">Select</option>
                                    {
                                        translist.map((item, index) => (
                                            item.name != null && (
                                                <option value={item.name}>{item.name}</option>
                                            )


                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-3">
                                <label className="col-form-label">Warehouse</label>

                                <select className="form-select" onChange={(e) => ChangeLocation(e)}>
                                    <option selected value="">Select</option>
                                    {
                                        locationlist.map((item, index) => (
                                            item.locationname != null && (
                                                <option value={item.resultid}>{item.locationname}</option>
                                            )
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <hr></hr>

                        <table className="table table-bordered mt-4">
                            <thead className="thead-light">
                                <tr>

                                    {/*<th className="col1">*/}
                                    {/*    Task Id*/}
                                    {/*</th>*/}
                                    <th className="col2">
                                        Item#
                                    </th>

                                    <th className="col3">
                                        UOM
                                    </th>
                                    <th className="col3">
                                        Qty
                                    </th>
                                    <th className="col3">
                                        Status
                                    </th>
                                    <th className="col2">
                                        External Transaction ID
                                    </th>
                                    <th className="col2">
                                        Warehouse
                                    </th>
                                    <th className="col4">
                                        Error message
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    displayList != null && displayList.length !== 0 ? (
                                        displayList.map((item, index) => (
                                            <tr>
                                                {/*<td scope="col" key={index}>*/}
                                                {/*    {*/}
                                                {/*        "Task: #" + item.entityId*/}
                                                {/*    }*/}
                                                {/*</td>*/}
                                                <td scope="col">
                                                    {
                                                        item.entityName
                                                    }
                                                </td>

                                                <td scope="col">
                                                    {
                                                        item.uom
                                                    }
                                                </td>
                                                <td scope="col">
                                                    {
                                                        item.reservedqty
                                                    }
                                                </td>
                                                <td scope="col">
                                                    {
                                                        item.status
                                                    }
                                                </td>
                                                <td scope="col">
                                                    {
                                                        item.transactionId
                                                    }
                                                </td>
                                                <td scope="col">
                                                    {
                                                        item.locationname
                                                    }
                                                </td>
                                                <td scope="col" width="10%">
                                                    {
                                                        (item.errorMessage != null && item.errorMessage != "") ? item.errorMessage + "...." : ""
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td>No records found</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        <Pagenation />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MPTaskDetails;